import React, { useLayoutEffect, useState } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const CustomRouter = ({ ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};
