import { CountryEnv, LANGUAGE } from "@type/index";

const _countryEnv: CountryEnv = {
  clientId: process.env.REACT_APP_CLIENT_ID_AT_UQ || "uq.at.styling-book",
  sbRootId: process.env.REACT_APP_FILTERS_SB_ROOT_ID_AT_UQ || 24295,
  shRootId: process.env.REACT_APP_FILTERS_SH_ROOT_ID_AT_UQ || 24296,
  firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY_EU_UQ || "",
  firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_EU_UQ || "",
  firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID_EU_UQ || "",
  firebaseMeasurementId: "",
  storeDomain: process.env.REACT_APP_STORE_DOMAIN_UQ || "",
  faqUrl: "https://faq-eu.uniqlo.com/",
  showAboutApp: false,
  showDualPrice: false,
  languages: [LANGUAGE.EN]
};

export default _countryEnv;
