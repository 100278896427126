import { ActionsUnion, createAction } from "@redux/helpers";
import {
  DelFavoritesItemPayload,
  FavoritesItemData,
  FavoritesItemPayload,
  ItemWishListImagesData,
  ItemWishlistData,
  L2,
  NewPostFavoritesItemPayload,
  ProductSkusPayload,
  WishlistL2ItemType
} from "@type/index";

export const GET_FAVORITES_ITEM = "[favorites item] get favorites item request";
const GET_FAVORITES_ITEM_SUCCESS =
  "[favorites item] get favorites item success";
const GET_FAVORITES_ITEM_LOADING =
  "[favorites item] get favorites item loading";
export const POST_FAVORITES_ITEM =
  "[favorites item] post favorites item request";
const FAVORITES_ITEM_POST_SUCCESS =
  "[favorites item] favorites item post success";
export const DELETE_FAVORITES_ITEM =
  "[favorites item] del favorites item request";
const FAVORITES_ITEM_DELETE_SUCCESS =
  "[favorites item] favorites item delete success";
const INIT_FAVORITES_ITEM = "[favorites item] init favorites item";
export const FETCH_PRODUCT_SKU_KEY =
  "[product sku] get product sku key request";
const FETCH_PRODUCT_SKU_KEY_LOADING =
  "[product sku] get product sku key loading";
const FETCH_PRODUCT_SKU_KEY_SUCCESS =
  "[product sku] get product sku key success";
const FETCH_PRODUCT_SKU_IMAGES_KEY_SUCCESS =
  "[product sku images] get product sku images key success";
const FETCH_PRODUCT_SKU_KEY_FAIL = "[product sku] get product sku key fail";
const GET_WISHLIST_SUCCESS = "[wishlist] get wishlist success";
export const FETCH_PRODUCT_L2S_WITH_IMAGE =
  "[product sku] get product l2s with image request";

export const Actions = {
  getFavoritesItem: (payload: FavoritesItemPayload) =>
    createAction(GET_FAVORITES_ITEM, payload),
  getFavoritesItemSuccess: (payload: FavoritesItemData) =>
    createAction(GET_FAVORITES_ITEM_SUCCESS, payload),
  getFavoritesItemLoading: (payload: boolean) =>
    createAction(GET_FAVORITES_ITEM_LOADING, payload),
  postFavoritesItem: (payload: NewPostFavoritesItemPayload) =>
    createAction(POST_FAVORITES_ITEM, payload),
  postFavoritesItemSuccess: (payload: string) =>
    createAction(FAVORITES_ITEM_POST_SUCCESS, payload),
  delFavoritesItem: (payload: DelFavoritesItemPayload) =>
    createAction(DELETE_FAVORITES_ITEM, payload),
  delFavoritesItemSuccess: (payload: string) =>
    createAction(FAVORITES_ITEM_DELETE_SUCCESS, payload),
  initFavoritesItem: () => createAction(INIT_FAVORITES_ITEM),
  fetchProductSKUs: (payload: ProductSkusPayload) =>
    createAction(FETCH_PRODUCT_SKU_KEY, payload),
  fetchProductSKUsLoading: (payload: boolean) =>
    createAction(FETCH_PRODUCT_SKU_KEY_LOADING, payload),
  fetchProductSKUsFail: (payload: boolean) =>
    createAction(FETCH_PRODUCT_SKU_KEY_FAIL, payload),
  fetchProductSKUsSuccess: (payload: { [key: string]: L2[] }) =>
    createAction(FETCH_PRODUCT_SKU_KEY_SUCCESS, payload),
  fetchProductSKUsImagesSuccess: (payload: ItemWishListImagesData) =>
    createAction(FETCH_PRODUCT_SKU_IMAGES_KEY_SUCCESS, payload),
  getWishlistSuccess: (payload: WishlistL2ItemType[]) =>
    createAction(GET_WISHLIST_SUCCESS, payload),
  fetchProductL2sWithImage: (payload: ProductSkusPayload) =>
    createAction(FETCH_PRODUCT_L2S_WITH_IMAGE, payload)
};

type ActionType = ActionsUnion<typeof Actions>;

export type State = ItemWishlistData;

const initialState = {
  itemLoading: false,
  favoritesItemList: [],
  sysError: false,
  skusLoading: true,
  allL2s: {},
  images: {
    main: {},
    chip: {}
  }
};

export default (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case GET_FAVORITES_ITEM_SUCCESS: {
      const obj = { ...action.payload };
      let arr = [];
      arr = Object.keys(obj).map((k) => {
        if (obj[k] === true) {
          return k;
        }
        return "";
      });
      arr = arr.filter(Boolean); //Remove the false value
      return {
        ...state,
        favoritesItemList: [...arr],
        itemLoading: false
      };
    }
    case GET_FAVORITES_ITEM_LOADING: {
      return {
        ...state,
        itemLoading: action.payload
      };
    }
    case INIT_FAVORITES_ITEM: {
      return {
        ...state,
        favoritesItemList: []
      };
    }
    case FAVORITES_ITEM_POST_SUCCESS: {
      const itemId = action.payload;
      const newItemId = state.favoritesItemList.filter(
        (item) => item === `${itemId}`
      );
      const itemIds = newItemId.length
        ? state.favoritesItemList
        : [...state.favoritesItemList, itemId];
      return {
        ...state,
        itemLoading: false,
        favoritesItemList: [...itemIds]
      };
    }
    case FAVORITES_ITEM_DELETE_SUCCESS: {
      const itemId = action.payload;
      const itemIds = [...state.favoritesItemList];
      if (itemIds.includes(itemId)) {
        itemIds.splice(itemIds.indexOf(itemId), 1);
      }
      return {
        ...state,
        itemLoading: false,
        favoritesItemList: [...itemIds]
      };
    }
    case FETCH_PRODUCT_SKU_KEY_LOADING:
      return {
        ...state,
        skusLoading: action.payload
      };
    case FETCH_PRODUCT_SKU_KEY_SUCCESS:
      return {
        ...state,
        allL2s: action.payload
      };
    case FETCH_PRODUCT_SKU_IMAGES_KEY_SUCCESS:
      return {
        ...state,
        images: action.payload
      };
    case GET_WISHLIST_SUCCESS: {
      const newWishlist = [...action.payload];
      const newArr = newWishlist.map((list) => list.l2Id);
      return {
        ...state,
        favoritesItemList: [...newArr]
      };
    }
    case FETCH_PRODUCT_SKU_KEY_FAIL:
      return {
        ...state,
        sysError: action.payload
      };
    default:
      return state;
  }
};
