import store from "@redux/store";
import { COUNTRY, COUNTRY_CONFIG } from "@type/index";

export const footAndInches: COUNTRY_CONFIG<boolean> = {
  [COUNTRY.JP]: false,
  [COUNTRY.US]: true,
  [COUNTRY.UK]: true,
  [COUNTRY.FR]: false,
  [COUNTRY.DE]: false,
  [COUNTRY.ES]: false,
  [COUNTRY.IT]: false,
  [COUNTRY.DK]: false,
  [COUNTRY.SE]: false,
  [COUNTRY.NL]: false,
  [COUNTRY.BE]: false,
  [COUNTRY.FI]: false,
  [COUNTRY.GR]: false,
  [COUNTRY.IE]: false,
  [COUNTRY.LU]: false,
  [COUNTRY.AT]: false,
  [COUNTRY.PL]: false,
  [COUNTRY.PT]: false,
  [COUNTRY.SI]: false,
  [COUNTRY.SK]: false,
  [COUNTRY.LT]: false,
  [COUNTRY.LV]: false,
  [COUNTRY.EE]: false,
  [COUNTRY.CZ]: false,
  [COUNTRY.HU]: false,
  [COUNTRY.RO]: false,
  [COUNTRY.BG]: false,
  [COUNTRY.HR]: false,
  [COUNTRY.KR]: false
};

// inch conversion
export const heightFootFromTotalInch = (totalInch: number) =>
  Math.floor(totalInch / 12);
export const heightInchFromTotalInch = (totalInch: number) =>
  Math.floor(totalInch % 12);
export const heightFootInchFromTotalInch = (totalInch: number) => {
  const foot = heightFootFromTotalInch(totalInch);
  const inch = heightInchFromTotalInch(totalInch);
  return `${foot}′ ${inch}″`;
};

export const userHeight = (height: number): string => {
  const config = store.getState().config;
  const country = config.region as COUNTRY;
  if (footAndInches[country])
    return height === 0 ? "-′-″" : heightFootInchFromTotalInch(height);
  else return height === 0 ? "-" : height?.toString() ?? "-";
};
