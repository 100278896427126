import { isMobile } from "react-device-detect";
import { initializeApp } from "firebase/app";
import {
  fetchAndActivate,
  getRemoteConfig,
  getString
} from "firebase/remote-config";
import { getAnalytics, setUserProperties } from "firebase/analytics";
import {
  FETCH_TIMEOUT_MILLIS,
  MINIMUM_FETCH_INTERVAL,
  SB_ENV
} from "@config/common";
import {
  DeviceType,
  FirebaseGenderType,
  FirebaseWishType,
  GenderObjType,
  Media,
  WishObjType
} from "@type/index";
import store from "../../redux/store";

// Firebase project configuration
const firebaseConfig = (brand: string, country: string) => ({
  apiKey: SB_ENV(brand, country).firebaseApiKey,
  projectId: SB_ENV(brand, country).firebaseProjectId,
  appId: SB_ENV(brand, country).firebaseAppId,
  measurementId: SB_ENV(brand, country).firebaseMeasurementId
});

// Firebase get remote config timeout
function waitWithTimeout(promise: any, timeoutMessage = "timeout") {
  let timer: NodeJS.Timeout;
  const timeoutPromise = new Promise((_, reject) => {
    timer = setTimeout(() => reject(timeoutMessage), FETCH_TIMEOUT_MILLIS);
  });
  return Promise.race([timeoutPromise, promise]).finally(() =>
    clearTimeout(timer)
  );
}

export const getFirebaseConfig = async () => {
  const { region, brand_name, language } = store.getState().config;
  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig(brand_name, region));
  const config = store.getState().config;
  try {
    const analytics = getAnalytics();
    setUserProperties(analytics, {
      language: language, // ja, en, fr
      platform: config.isApp ? Media.APP : Media.WEB, // web, app
      device: isMobile ? DeviceType.SP : DeviceType.PC, // SP, PC
      environment: process.env.REACT_APP_ENV // staging, staging-canary, production, production-canary
    });
    // Initialize Remote Config and get a reference to the service
    const remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings.fetchTimeoutMillis = FETCH_TIMEOUT_MILLIS; // TODO not work
    remoteConfig.settings.minimumFetchIntervalMillis = MINIMUM_FETCH_INTERVAL;
    await waitWithTimeout(fetchAndActivate(remoteConfig));
    const sb_tab_gender: string = getString(
      remoteConfig,
      "stylingbook_sb_tab_gender"
    );
    const sh_tab_gender: string = getString(
      remoteConfig,
      "stylingbook_sh_tab_gender"
    );
    const sb_wish: string = getString(remoteConfig, "stylingbook_sb_wish");
    const sh_wish: string = getString(remoteConfig, "stylingbook_sh_wish");
    // Get gender tab info
    let genderByBrand: FirebaseGenderType | undefined;
    try {
      const _sb: GenderObjType = JSON.parse(sb_tab_gender);
      const _sh: GenderObjType = JSON.parse(sh_tab_gender);
      genderByBrand = {
        sb: _sb,
        sh: _sh
      };
    } catch (e) {
      genderByBrand = undefined;
    }
    // Get wish icon and wish count info
    let wishByBrand: FirebaseWishType | undefined;
    try {
      const _sb: WishObjType = JSON.parse(sb_wish);
      const _sh: WishObjType = JSON.parse(sh_wish);
      wishByBrand = {
        sb: _sb,
        sh: _sh
      };
    } catch (e) {
      wishByBrand = undefined;
    }
    return {
      web_tab_gender: genderByBrand,
      web_wish: wishByBrand
    };
  } catch (e) {
    return {
      web_tab_gender: undefined,
      web_wish: undefined
    };
  }
};
