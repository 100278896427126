import {
  BRAND_LIST,
  GABrandList,
  GATrackEventType,
  PageViewBaseOption
} from "@type/index";

export const isClient = typeof window !== "undefined";

let firstPageView = true;
export function checkPreviousPageExists() {
  if (!isClient) {
    return false;
  }

  if (!firstPageView) {
    return true;
  }
  firstPageView = false;

  if (
    window.performance
      .getEntriesByType("navigation")
      .map((nav: any) => nav.type)
      .includes("reload")
  ) {
    return true;
  }

  return !!(
    document.referrer &&
    document.referrer.includes(new URL(window.location.href).hostname)
  );
}

/*
  GU brand must be capitalized for Google Analytics,
  while the internal BRAND_LIST is lowercase "gu"
*/
export const transformBrandForGoogleAnalytics = (
  brand: string
): GABrandList => {
  switch (brand) {
    case BRAND_LIST.UQ:
      return GABrandList.UQ;
    case BRAND_LIST.GU:
      return GABrandList.GU;
    case BRAND_LIST.PL:
      return GABrandList.PL;
    default:
      return GABrandList.UQ;
  }
};

/**
 * sendPageViewForWave2 passes data into window.dataLayer and run callback functions which are injected by native apps if needed.
 * It is called for tracking page view events and virtual page view events.
 *
 * @param data
 */
export const sendPageViewForWave2 = (data: any) => {
  if (!isClient) {
    return;
  }
  if (!window.dataLayer) {
    return;
  }

  if (
    window.UQCustomerAppAndroid &&
    window.UQCustomerAppAndroid.receiveAnalyticsData
  ) {
    // Android App
    window.UQCustomerAppAndroid.receiveAnalyticsData(JSON.stringify(data));
  } else if (
    window.webkit &&
    window.webkit.messageHandlers.receiveAnalyticsData
  ) {
    // iOS App
    window.webkit.messageHandlers.receiveAnalyticsData.postMessage(data);
  }
  // Web Browser and Native App
  window.dataLayer.push(data);
};

export const trackPageView = (baseOption: PageViewBaseOption) => {
  sendPageViewForWave2({
    ...baseOption
  });
};

/**
 * Track an event such as clicking event on GoogleAnalytics
 * via sending data to dataLayer of window object.
 *
 * @param data - Analytics data
 */
export const sendTrackEvent = (data: GATrackEventType): void => {
  if (!isClient) {
    return;
  }
  if (!window.dataLayer) {
    return;
  }

  const eventData = {
    ...data,
    event: data.event || "trackEvent"
  };

  if (
    window.UQCustomerAppAndroid &&
    window.UQCustomerAppAndroid.receiveAnalyticsData
  ) {
    // Android App
    window.UQCustomerAppAndroid.receiveAnalyticsData(JSON.stringify(eventData));
  } else if (
    window.webkit &&
    window.webkit.messageHandlers.receiveAnalyticsData
  ) {
    // iOS App
    window.webkit.messageHandlers.receiveAnalyticsData.postMessage(eventData);
  }
  // Web Browser and Native App
  window.dataLayer.push(data);
};
