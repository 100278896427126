import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import reducer from "../reducer";
import mySaga from "../saga";

interface WindowType extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
}

declare const window: WindowType;

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

export const makeStore = () => store;

sagaMiddleware.run(mySaga);

export default store;
