import { ActionsUnion, createAction } from "@redux/helpers";
import {
  DelFavoritesStylingPayload,
  FavoritesStylingData,
  FavoritesStylingDataType,
  FavoritesStylingPayload,
  PostFavoritesStylingPayload,
  StylingWishCountPayload,
  StylingWishlistData
} from "@type/index";

export const GET_FAVORITES_STYLING =
  "[favorites styling] get favorites styling request";
const GET_FAVORITES_STYLING_SUCCESS =
  "[favorites styling] get favorites styling success";
const GET_FAVORITES_STYLING_LOADING =
  "[favorites styling] get favorites styling loading";
export const POST_FAVORITES_STYLING =
  "[favorites styling] post favorites styling request";
const FAVORITES_STYLING_POST_SUCCESS =
  "[favorites styling] favorites styling post success";
export const DELETE_FAVORITES_STYLING =
  "[favorites styling] del favorites styling request";
const FAVORITES_STYLING_DELETE_SUCCESS =
  "[favorites styling] favorites styling delete success";
const INIT_FAVORITES_STYLING = "[favorites styling] init favorites styling";
export const GET_FAVORITES_STYLING_WISH_COUNT =
  "[favorites styling] get favorites styling wish count";

export const Actions = {
  getFavoritesStyling: (payload: FavoritesStylingPayload) =>
    createAction(GET_FAVORITES_STYLING, payload),
  getFavoritesStylingSuccess: (payload: FavoritesStylingDataType) =>
    createAction(GET_FAVORITES_STYLING_SUCCESS, payload),
  getFavoritesStylingLoading: (payload: boolean) =>
    createAction(GET_FAVORITES_STYLING_LOADING, payload),
  postFavoritesStyling: (payload: PostFavoritesStylingPayload) =>
    createAction(POST_FAVORITES_STYLING, payload),
  postFavoritesStylingSuccess: (payload: { id: string; type: string }) =>
    createAction(FAVORITES_STYLING_POST_SUCCESS, payload),
  delFavoritesStyling: (payload: DelFavoritesStylingPayload) =>
    createAction(DELETE_FAVORITES_STYLING, payload),
  delFavoritesStylingSuccess: (payload: { id: string; type: string }) =>
    createAction(FAVORITES_STYLING_DELETE_SUCCESS, payload),
  initFavoritesStyling: () => createAction(INIT_FAVORITES_STYLING),
  getStylingWishCount: (payload: StylingWishCountPayload) =>
    createAction(GET_FAVORITES_STYLING_WISH_COUNT, payload)
};

type ActionType = ActionsUnion<typeof Actions>;

export type State = StylingWishlistData;

const initialState = {
  stylingLoading: true,
  stylingSBWishlist: [],
  stylingSHWishlist: []
};

export default (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case GET_FAVORITES_STYLING_SUCCESS: {
      let _sbList: FavoritesStylingData = [];
      let _shList: FavoritesStylingData = [];
      if (action.payload.type === "sb") {
        const set = new Set([
          ...state.stylingSBWishlist,
          ...action.payload.list
        ]);
        const arr = Array.from(set);
        _sbList = [...arr];
      } else {
        const set = new Set([
          ...state.stylingSHWishlist,
          ...action.payload.list
        ]);
        const arr = Array.from(set);
        _shList = [...arr];
      }
      if (action.payload.fromDetail) {
        const req = action.payload.query || [];
        const res = [...action.payload.list];
        const un_wish = req.filter((value) => !res.includes(value));
        if (action.payload.type === "sb") {
          const current = [..._sbList];
          _sbList = current.filter((value) => !un_wish.includes(value));
        } else {
          const current = [..._shList];
          _shList = current.filter((value) => !un_wish.includes(value));
        }
      }
      return {
        ...state,
        stylingSBWishlist: [..._sbList],
        stylingSHWishlist: [..._shList],
        stylingLoading: false
      };
    }
    case GET_FAVORITES_STYLING_LOADING:
      return {
        ...state,
        stylingLoading: action.payload
      };
    case INIT_FAVORITES_STYLING:
      return {
        ...state,
        stylingSBWishlist: [],
        stylingSHWishlist: []
      };
    case FAVORITES_STYLING_POST_SUCCESS: {
      const styleId = action.payload.id;
      let _sbIds: FavoritesStylingData = [];
      let _shIds: FavoritesStylingData = [];
      if (action.payload.type === "sb") {
        const newStyleId = state.stylingSBWishlist.filter(
          (item) => item.toString() === `${styleId.toString()}`
        );
        _sbIds = newStyleId.length
          ? state.stylingSBWishlist
          : [...state.stylingSBWishlist, styleId.toString()];
      } else {
        const newStyleId = state.stylingSHWishlist.filter(
          (item) => item.toString() === `${styleId.toString()}`
        );
        _shIds = newStyleId.length
          ? state.stylingSHWishlist
          : [...state.stylingSHWishlist, styleId.toString()];
      }
      return {
        ...state,
        stylingLoading: false,
        stylingSBWishlist: [..._sbIds],
        stylingSHWishlist: [..._shIds]
      };
    }
    case FAVORITES_STYLING_DELETE_SUCCESS: {
      const styleId = action.payload.id;
      let _sbIds: FavoritesStylingData = [];
      let _shIds: FavoritesStylingData = [];
      if (action.payload.type === "sb") {
        _sbIds = [...state.stylingSBWishlist];
        if (_sbIds.includes(styleId.toString())) {
          _sbIds.splice(_sbIds.indexOf(styleId.toString()), 1);
        }
      } else {
        _shIds = [...state.stylingSHWishlist];
        if (_shIds.includes(styleId.toString())) {
          _shIds.splice(_shIds.indexOf(styleId.toString()), 1);
        }
      }
      return {
        ...state,
        stylingLoading: false,
        stylingSBWishlist: [..._sbIds],
        stylingSHWishlist: [..._shIds]
      };
    }
    default:
      return state;
  }
};
