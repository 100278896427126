import {
  GenderOptionType,
  ImageInfo,
  ProductDetailType,
  ProductModels,
  RelatedStylingStylehintUserInfoType,
  SBNormalImages,
  SHNormalImages,
  StylingType
} from "@type/index";

export interface CarouselListType extends ImageInfo {
  productTagsList: Array<NewProductDetailType>;
  wishCount: number;
}

export type RelatedListType = {
  styleId: string;
  imageUrl: string;
  userInfo?: RelatedStylingStylehintUserInfoType;
  modelHeight?: number;
};

export interface NewProductDetailType extends ProductDetailType {
  center: [number, number];
  position: "left" | "right" | "top" | "bottom";
  gender: string;
}

export const handleReloadStylehintCarouseList = (
  arr: Array<SHNormalImages>,
  id: string
): Array<ImageInfo> => {
  let newArr: Array<ImageInfo> = [];
  if (arr.length > 0) {
    const index = arr.findIndex(
      (a: SHNormalImages) => a.outfitId?.toString() === id
    );
    if (index !== -1) {
      arr.splice(index, 1);
    }
    newArr = arr.map((item: SHNormalImages) => {
      const obj: ImageInfo = {
        styleId: item.outfitId?.toString() || "",
        styleImageUrl: item.styleImageUrl || ""
      };
      return obj;
    });
  }
  return newArr;
};

export const handleReloadOfficialCarouseList = (
  arr: Array<SBNormalImages>,
  id: string
): Array<ImageInfo> => {
  let newArr: Array<ImageInfo> = [];
  if (arr.length > 0) {
    const index = arr.findIndex(
      (a: SBNormalImages) => a.styleId.toString() === id
    );
    if (index !== -1) {
      arr.splice(index, 1);
    }
    newArr = arr.map((item: SBNormalImages) => {
      const obj: ImageInfo = {
        styleId: item.styleId.toString() || "",
        styleImageUrl: item.imageUrl || ""
      };
      return obj;
    });
  }
  return newArr;
};

// handle item tags
export const handleItemTags = (
  box: Array<number>,
  imageWidth: number,
  imageHeight: number
) => {
  const x1 = box[0];
  const y1 = box[1];
  const x2 = box[2];
  const y2 = box[3];
  // The x,y coordinates start from the upper left corner
  const center_x = Math.abs(x1 - x2) / 2 + Math.min(x1, x2);
  const center_y = Math.abs(y1 - y2) / 2 + Math.min(y1, y2);
  const tagLength = 168 + 8; // tagWidth + spacing
  const tagHeight = 66;
  const pointWidth = 19.2;
  const arrowWidth = 32;
  let position: "left" | "right" | "top" | "bottom" = "right";
  if (imageWidth > 0 && imageHeight > 0) {
    const rightSafeWidth =
      imageWidth - imageWidth * center_x - arrowWidth - pointWidth;
    const leftSafeWidth = imageWidth * center_x - arrowWidth;
    const isBottomSafe =
      imageHeight - imageHeight * center_y - pointWidth >
      (tagHeight - pointWidth) / 2;
    const isTopSafe = imageHeight * center_y > (tagHeight - pointWidth) / 2;
    // see if you can put it left or right
    if (
      (center_y * 100 >= 50 && isBottomSafe) ||
      (center_y * 100 < 50 && isTopSafe)
    ) {
      if (rightSafeWidth > tagLength) {
        position = "right";
      } else if (leftSafeWidth > tagLength) {
        position = "left";
      } else if (center_y * 100 >= 50) {
        position = "top";
      } else {
        position = "bottom";
      }
    }
    // next, decide whether to top or bottom
    else if (center_y * 100 >= 50) {
      position = "top";
    } else {
      position = "bottom";
    }
  }
  return {
    x: center_x * 100,
    y: center_y * 100,
    position: position
  };
};

export const handleModelsList = (
  models: Array<ProductModels>,
  type: string,
  currentTab: string
): ProductModels | undefined => {
  let model: ProductModels | undefined;
  if (models.length > 0) {
    if (
      type === StylingType.official &&
      models.length > 1 &&
      currentTab.length > 0
    ) {
      model = models.find((m: ProductModels) => m.positionId === currentTab);
    } else {
      model = models[0];
    }
  }
  return model;
};

// skip "inactive" type products
export const isActiveProduct = (
  p: NewProductDetailType | ProductDetailType
): boolean => !(p.active !== undefined && !p.active);

export const handleGenderCodeToStr = (
  genderList: Array<GenderOptionType>,
  code: number
): string => {
  const data = genderList.find((item) => item.code === code);
  let genderValue = "women";
  if (data) {
    genderValue = data.key;
  }
  return genderValue;
};

export const productListUnique = (arr: Array<ProductDetailType>) => {
  let returnArr = [];
  const obj: any = {};
  returnArr = arr.reduce(
    (cur: Array<ProductDetailType>, next: ProductDetailType) => {
      if (!obj[next.l2Id]) {
        obj[next.l2Id] = true;
        cur.push(next);
      }
      return cur;
    },
    []
  );
  return returnArr;
};

export const handleCarouselList = (
  arr: Array<ImageInfo>
): Array<CarouselListType> => {
  let newArr: Array<CarouselListType> = [];
  if (arr.length > 0) {
    newArr = JSON.parse(JSON.stringify(arr));
    newArr.map((item: CarouselListType) => {
      item.productTagsList = [];
      item.wishCount = 0;
      return item;
    });
  }
  return newArr;
};

export const updateCarouselList = (
  id: string,
  arr: Array<CarouselListType>,
  tagList: Array<NewProductDetailType>,
  count: number
): Array<CarouselListType> => {
  let newArr: Array<CarouselListType> = [];
  if (arr.length > 0) {
    newArr = JSON.parse(JSON.stringify(arr));
    newArr.map((item: CarouselListType) => {
      if (item.styleId.toString() === id.toString()) {
        item.productTagsList = tagList || [];
        item.wishCount = count;
      }
      return item;
    });
  }
  return newArr;
};

export const hasProductForMutiModel = (models: Array<ProductModels>): boolean =>
  models.some((model: ProductModels) => {
    if (model.products && model.products.length > 0) {
      return true;
    } else {
      return false;
    }
  });
