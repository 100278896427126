import { call, put, takeEvery } from "redux-saga/effects";
import { CmsContentActions, GET_CMS_BANNER } from "@redux/reducer";
import { CMSPayloadType, CMSBannerResponse } from "@type/index";
import request from "@util/net/request";
import axios, { AxiosResponse } from "axios";

export function* getBannerSaga(action: { payload: CMSPayloadType }) {
  const payload: CMSPayloadType = action.payload;
  try {
    const res: AxiosResponse<CMSBannerResponse> = yield call(
      request.get,
      "/cms",
      {
        params: {
          device: payload.device,
          path: payload.path
        }
      }
    );
    if (
      res.data.status === "ok" &&
      res.data.result.body &&
      res.data.result.body.length > 0
    ) {
      yield put(
        CmsContentActions.setCmsBanner({
          content: res.data.result.body,
          path: payload.path
        })
      );
    } else {
      yield put(
        CmsContentActions.setCmsBanner({
          content: [],
          path: payload.path
        })
      );
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      yield put(
        CmsContentActions.setCmsBanner({
          content: [],
          path: payload.path
        })
      );
    }
  }
}

export function* watchLoadBannerSaga() {
  yield takeEvery<any>(GET_CMS_BANNER, getBannerSaga);
}
