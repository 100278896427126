import React, { useRef } from "react";
import { ButtonFloating } from "@common/DynamicUikit";

const GoTop = () => {
  const btnRef = useRef(null);
  // const showRef = useRef(false);
  // const click = () => {
  //   $("html,body").animate({ scrollTop: 0 }, 800);
  // };

  // const handleScroll = () => {
  //   if (
  //     window.pageYOffset > 2600 &&
  //     !showRef.current &&
  //     btnRef &&
  //     btnRef.current
  //   ) {
  //     showRef.current = true;
  //     // $(btnRef.current).stop().fadeIn();
  //   } else if (
  //     window.pageYOffset < 2600 &&
  //     showRef.current &&
  //     btnRef &&
  //     btnRef.current
  //   ) {
  //     showRef.current = false;
  //     // $(btnRef.current).stop().fadeOut();
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <div ref={btnRef} style={{ display: "none" }}>
      <ButtonFloating
        ariaLabel="Top"
        element="button"
        icon="chevron-up"
        // onClick={click}
      >
        Top
      </ButtonFloating>
    </div>
  );
};

export default GoTop;
