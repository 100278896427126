import store from "@redux/store";
import { BRAND_LIST } from "@type/index";
import { GUUikit } from "./gu";
import { PLUikit } from "./pl";
import { UQUikit } from "./uq";

const getCurrentComponent = () => {
  const { brand_name } = store.getState().config;
  switch (brand_name) {
    case BRAND_LIST.UQ:
      return UQUikit;
    case BRAND_LIST.GU:
      return GUUikit;
    case BRAND_LIST.PL:
      return PLUikit;
    default:
      return UQUikit;
  }
};

const Accordion = getCurrentComponent().Accordion;
const Body = getCurrentComponent().Body;
const Breadcrumb = getCurrentComponent().Breadcrumb;
const BreadcrumbGroup = getCurrentComponent().BreadcrumbGroup;
const Button = getCurrentComponent().Button;
const ButtonFloating = getCurrentComponent().ButtonFloating;
const ButtonIcon = getCurrentComponent().ButtonIcon;
const ButtonLayout = getCurrentComponent().ButtonLayout;
const ButtonToggle = getCurrentComponent().ButtonToggle;
const ButtonToggleGroup = getCurrentComponent().ButtonToggleGroup;
const Caption = getCurrentComponent().Caption;
const Carousel = getCurrentComponent().Carousel;
const Checkbox = getCurrentComponent().Checkbox;
const CheckboxGroup = getCurrentComponent().CheckboxGroup;
const CmsContentsCard = getCurrentComponent().CmsContentsCard;
const CmsImage = getCurrentComponent().CmsImage;
const CmsLink = getCurrentComponent().CmsLink;
const CollectionList = getCurrentComponent().CollectionList;
const ContentAlignment = getCurrentComponent().ContentAlignment;
const Display = getCurrentComponent().Display;
const Drawer = getCurrentComponent().Drawer;
const FilterLayout = getCurrentComponent().FilterLayout;
const FlagText = getCurrentComponent().FlagText;
const Footer = getCurrentComponent().Footer;
const GutterContainer = getCurrentComponent().GutterContainer;
const HeaderPage = getCurrentComponent().HeaderPage;
const Image = getCurrentComponent().Image;
const ImageEnhancements = getCurrentComponent().ImageEnhancements;
const ImageOverlay = getCurrentComponent().ImageOverlay;
const ItemTag = getCurrentComponent().ItemTag;
const ItemTags = getCurrentComponent().ItemTags;
const Keyline = getCurrentComponent().Keyline;
const Layout = getCurrentComponent().Layout;
const LayoutWrapper = getCurrentComponent().LayoutWrapper;
const LinkCell = getCurrentComponent().LinkCell;
const LinkImage = getCurrentComponent().LinkImage;
const LinkNavigation = getCurrentComponent().LinkNavigation;
const LinkNavigationGroup = getCurrentComponent().LinkNavigationGroup;
const LinkText = getCurrentComponent().LinkText;
const LoadingIndicator = getCurrentComponent().LoadingIndicator;
const MediaObject = getCurrentComponent().MediaObject;
const Modal = getCurrentComponent().Modal;
const NavigationHeader = getCurrentComponent().NavigationHeader;
const Price = getCurrentComponent().Price;
const Radio = getCurrentComponent().Radio;
const RadioGroup = getCurrentComponent().RadioGroup;
const Spacer = getCurrentComponent().Spacer;
const StickyContainer = getCurrentComponent().StickyContainer;
const StylingGrid = getCurrentComponent().StylingGrid;
const StyleUserInfo = getCurrentComponent().StyleUserInfo;
const Tab = getCurrentComponent().Tab;
const TabGroup = getCurrentComponent().TabGroup;
const TagFilter = getCurrentComponent().TagFilter;
const TagFilterGroup = getCurrentComponent().TagFilterGroup;
const TemplateFullGutter = getCurrentComponent().TemplateFullGutter;
const TemplateILP = getCurrentComponent().TemplateILP;
const TemplateMediaInformation = getCurrentComponent().TemplateMediaInformation;
const Tile = getCurrentComponent().Tile;
const Title = getCurrentComponent().Title;
const UtilityBar = getCurrentComponent().UtilityBar;
const CollectionListHorizontal = getCurrentComponent().CollectionListHorizontal;
const ProductTile = getCurrentComponent().ProductTile;
const Chip = getCurrentComponent().Chip;
const ChipGroup = getCurrentComponent().ChipGroup;

export {
  Accordion,
  Body,
  Breadcrumb,
  BreadcrumbGroup,
  Button,
  ButtonFloating,
  ButtonIcon,
  ButtonLayout,
  ButtonToggle,
  ButtonToggleGroup,
  Caption,
  Carousel,
  Checkbox,
  CheckboxGroup,
  CmsContentsCard,
  CmsImage,
  CmsLink,
  CollectionList,
  ContentAlignment,
  Display,
  Drawer,
  FilterLayout,
  FlagText,
  Footer,
  GutterContainer,
  HeaderPage,
  Image,
  ImageEnhancements,
  ImageOverlay,
  ItemTag,
  ItemTags,
  Keyline,
  Layout,
  LayoutWrapper,
  LinkCell,
  LinkImage,
  LinkNavigation,
  LinkNavigationGroup,
  LinkText,
  LoadingIndicator,
  MediaObject,
  Modal,
  NavigationHeader,
  Price,
  Radio,
  RadioGroup,
  Spacer,
  StickyContainer,
  StylingGrid,
  StyleUserInfo,
  Tab,
  TabGroup,
  TagFilter,
  TagFilterGroup,
  TemplateFullGutter,
  TemplateILP,
  TemplateMediaInformation,
  Tile,
  Title,
  UtilityBar,
  CollectionListHorizontal,
  ProductTile,
  Chip,
  ChipGroup
};
