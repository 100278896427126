import React, { createContext, useState } from "react";

export const yScrollContext = createContext({
  yScroll: 0,
  setYscroll: (prevState: number) => prevState
});

export const YScroll = ({ children }: any) => {
  const [yScroll, setYscroll] = useState(0);
  const value = { yScroll, setYscroll };

  return (
    <yScrollContext.Provider value={value as any}>
      {children}
    </yScrollContext.Provider>
  );
};
