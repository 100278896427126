import { call, put, takeLatest } from "redux-saga/effects";
import request from "@util/net/request";
import { AxiosResponse } from "axios";
import { GET_STYLING_FILTERS, StylingFilterActions } from "@redux/reducer";
import { FiltersPayload, FiltersResponse } from "@type/index";

export function* getStylingFiltersSaga(action: { payload: FiltersPayload }) {
  try {
    const payload: FiltersPayload = action.payload;
    yield put(StylingFilterActions.getStylingFiltersLoading(true));
    const res: AxiosResponse<FiltersResponse> = yield call(
      request.get,
      "/styles/filters",
      {
        params: {
          ...payload
        }
      }
    );
    yield put(StylingFilterActions.getStylingFiltersSuccess(res.data.result));
  } catch (error) {
    yield put(StylingFilterActions.getStylingFiltersLoading(false));
  }
}

export function* watchLoadStylingFiltersSaga() {
  yield takeLatest<any>(GET_STYLING_FILTERS, getStylingFiltersSaga);
}
