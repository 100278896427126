import { call, put, takeLatest } from "redux-saga/effects";
import request from "@util/net/request";
import { AxiosResponse } from "axios";
import {
  GET_OFFICIAL_CAROUSEL_LIST,
  GET_RELATED_STYLING_OFFICIAL,
  RelatedStylingOfficialActions
} from "@redux/reducer";
import { OfficialListPayload, OfficialListResponse } from "@type/index";

export function* getRelatedStylingOfficialSaga(action: {
  payload: OfficialListPayload;
}) {
  try {
    const payload: OfficialListPayload = action.payload;
    yield put(
      RelatedStylingOfficialActions.getRelatedStylingOfficialLoading(true)
    );
    const res: AxiosResponse<OfficialListResponse> = yield call(
      request.get,
      "/styles/images/sb/normal",
      {
        params: {
          ...payload
        }
      }
    );
    yield put(
      RelatedStylingOfficialActions.getRelatedStylingOfficialSuccess(
        res.data.result
      )
    );
  } catch (error) {
    yield put(
      RelatedStylingOfficialActions.getRelatedStylingOfficialLoading(false)
    );
  }
}

export function* getCarouselStylingOfficialSaga(action: {
  payload: OfficialListPayload;
}) {
  try {
    const payload: OfficialListPayload = action.payload;
    yield put(
      RelatedStylingOfficialActions.getOfficialCarouselListLoading(true)
    );
    const res: AxiosResponse<OfficialListResponse> = yield call(
      request.get,
      "/styles/images/sb/normal",
      {
        params: {
          ...payload
        }
      }
    );
    yield put(
      RelatedStylingOfficialActions.getOfficialCarouselListSuccess(
        res.data.result
      )
    );
  } catch (error) {
    yield put(
      RelatedStylingOfficialActions.getOfficialCarouselListLoading(false)
    );
  }
}

export function* watchLoadRelatedStylingOfficialSaga() {
  yield takeLatest<any>(
    GET_RELATED_STYLING_OFFICIAL,
    getRelatedStylingOfficialSaga
  );
  yield takeLatest<any>(
    GET_OFFICIAL_CAROUSEL_LIST,
    getCarouselStylingOfficialSaga
  );
}
