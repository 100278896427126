import { Cookies } from "react-cookie";
import {
  BRAND_LIST,
  BRAND_NAME_LIST,
  COUNTRY,
  LANGUAGE,
  UserTypeEnum,
  UserTypeTypes,
  ImageRatio
} from "@type/index";
import { SB_ENV } from "@config/common";
import store from "../redux/store";
import { CurrencyFormatter } from "./currency";
import { convertRegionName } from "./country";

export const isNotEmptyStr = (s: unknown): boolean => {
  if (typeof s === "string" && s.length > 0) {
    return true;
  }
  return false;
};

export const isNotEmptyObj = (s: any): boolean => {
  if (s === undefined || s === null) {
    return false;
  }
  const count = Object.keys(s).length;
  if (count > 0) {
    return true;
  }
  return false;
};

// Thousand separator
export const toThousands = (num: number | null): string => {
  if (num === null) {
    return "";
  }
  const result = [];
  let counter = 0;
  const _num = num.toString();
  const numArr = _num.split("."); // dividing decimal point
  const numStrs = numArr[0].toString().split(""); // convert to a character array
  for (let i = numStrs.length - 1; i >= 0; i -= 1) {
    counter += 1;
    result.unshift(numStrs[i]);
    if (!(counter % 3) && i !== 0) {
      result.unshift(",");
    }
  }
  if (numArr[1]) {
    return result.join("").concat(`.${numArr[1]}`);
  } else {
    return result.join("");
  }
};

function formatDecimal(num: number, decimal: number) {
  let newNum = num.toString();
  const index = newNum.indexOf(".");
  if (index !== -1) {
    newNum = newNum.substring(0, decimal + index + 1);
    newNum = parseFloat(newNum).toFixed(decimal);
  } else {
    newNum = toThousands(Number(newNum));
  }
  return newNum;
}

export const formatTotalNum = (lang: string, num: number) => {
  const lang_other = [
    { range: 1, symbol: "", value: 1 },
    { range: 1e4, symbol: "K", value: 1e3 },
    { range: 1e6, symbol: "M", value: 1e6 },
    { range: 1e9, symbol: "B", value: 1e9 }
  ];
  const lang_ja = [
    { range: 1, symbol: "", value: 1 },
    { range: 1e4, symbol: "万", value: 1e4 },
    { range: 1e8, symbol: "億", value: 1e8 }
  ];
  let si = lang_ja;
  if (lang !== "ja") {
    si = lang_other;
  }
  let i;
  for (i = si.length - 1; i > 0; i -= 1) {
    if (num >= si[i].range) {
      break;
    }
  }
  return formatDecimal(num / si[i].value, 1) + si[i].symbol;
};

// Prevents events from bubbling
export const preventBubbling = (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
) => {
  e.stopPropagation();
  e.preventDefault();
};

// Get the position where the string appears
export const findStrIndex = (str: string, cha: string, num: number) => {
  let x = 0;
  for (let i = 0; i < num; i += 1) {
    x = str.indexOf(cha, x + 1);
  }
  return x;
};

// Store link by brand region language
export const getStoreLinkUrl = (
  domain: string,
  region: string,
  language: string,
  storeId: number
): string => `${domain}/${region}/${language}/detail/${storeId}`;

// Handle store link
export const handleStoreLink = (
  id: number | undefined,
  storeRegion: string
): string | undefined => {
  if (id !== 0 && !id) {
    return undefined;
  } else {
    const { brand_name, region, language } = store.getState().config;
    if (
      storeRegion &&
      storeRegion.toLocaleUpperCase() !== region.toLocaleUpperCase()
    ) {
      return undefined;
    } else {
      const domain = SB_ENV(brand_name, region).storeDomain;
      return getStoreLinkUrl(domain, region, language, id);
    }
  }
};

export function getIsLoggedIn(): boolean {
  const cookies = new Cookies();
  return cookies.get("isLoggedIn") === "true";
}

// Capitalize the first letter
export const titleCase = (str: string): string => {
  let newStr = "";
  if (str && str.length > 0) {
    newStr = str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
  }
  return newStr;
};

// Get brand name
export const getBrandName = (): string => {
  const { brand_name } = store.getState().config;
  switch (brand_name) {
    case BRAND_LIST.UQ:
      return titleCase(BRAND_NAME_LIST.UQ);
    case BRAND_LIST.PL:
      return BRAND_NAME_LIST.PL.toUpperCase();
    case BRAND_LIST.GU:
      return BRAND_NAME_LIST.GU.toUpperCase();
    default:
      return titleCase(BRAND_NAME_LIST.UQ);
  }
};

// Gender mapping
const genderMapper: { [key: string]: string } = {
  MEN: "text_men",
  WOMEN: "text_women",
  KIDS: "text_kids",
  BABY: "text_baby",
  UNISEX: "text_unisex"
};

// Convert gender enum to LID
export const localizeGenderName = (genderCategory: string | undefined) => {
  if (!genderCategory) return "";
  return genderMapper[genderCategory] || "";
};

/**
 * Function checks the size of device and returns if its a mobile
 * or not, for IPad it will be false
 * Function uses the same width as DS breakpoints
 */
export const isSP = () => window && window.innerWidth < 600;

// Get About App URL
export const getAboutAppURL = (region: string, language: string): string => {
  const _region = region.includes("eu-") ? region.split("-")[1] : region;
  return `${process.env.REACT_APP_STYLEHINT_DOMAIN}/${_region}/${language}/about_app`;
};

/**
 * @param priceValue - a price value
 */
export const formatPrice = (priceValue: number): string => {
  const { region, language } = store.getState().config;
  const price = new CurrencyFormatter(
    convertRegionName(region) as COUNTRY,
    language as LANGUAGE
  );
  return price.format(priceValue);
};

// For GU, GA value should be KIDS·BABY
export const gaGenderValue = (gender: string, isUpper?: boolean): string => {
  const { brand_name } = store.getState().config;
  if (brand_name === BRAND_LIST.GU && gender.toLowerCase() === "kids") {
    return isUpper ? "KIDS・BABY" : "kids・baby";
  }
  return isUpper ? gender.toUpperCase() : gender.toLowerCase();
};

// StyleHint styling detail -> item size, returns placeholder string for "No Control"
export const getSizeForItemInfo = (size?: string) => {
  const placeholder = "-";
  if (!size || size === "No Control") {
    return placeholder;
  }
  return size;
};

// Should display size label on StyleHint detail page item area
export const isDisplaySizeLabel = (size?: string): boolean =>
  getSizeForItemInfo(size) !== "-";

// UserType is Staff
export const isStaff = (type: UserTypeTypes): boolean =>
  type.toUpperCase() === UserTypeEnum.STAFF;

// Export Discount Format
export const formatDiscount = (discountValue: string | number): string =>
  discountValue ? `-${discountValue}%` : "";

// Is Phase for ECRenewal
export const getEcRenewalCompatibility = (): boolean =>
  process.env.REACT_APP_EC_RENEWAL_COMPATIBILITY === "3";

// Item Image Ratio for ECRenewal
export const getEcRenewalImageRatio = (): ImageRatio => {
  if (process.env.REACT_APP_EC_RENEWAL_RATIO === "3x4") {
    return "3x4";
  } else if (process.env.REACT_APP_EC_RENEWAL_RATIO === "1x1") {
    return "1x1";
  } else {
    return "3x4";
  }
};
