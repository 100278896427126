import React, { useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbGroup,
  HeaderPage,
  TemplateFullGutter
} from "@common/DynamicUikit";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { CombinedReducersType, ConfigActions } from "@redux/reducer";
import { StylingType } from "@type/index";
import { NotFound, SystemError } from "../../common";

type IProps = {
  status: number;
};

const ErrorPage = (props: IProps) => {
  const { status } = props;
  const { t } = useTranslation();
  const config = useSelector((store: CombinedReducersType) => store.config);
  const dispatch = useDispatch();

  useEffect(() => {
    if (status === 503) {
      dispatch(ConfigActions.setShowLogin(true));
    }
  }, [dispatch, status]);

  const navigationPath = (
    <BreadcrumbGroup className="fr-ec-mb-spacing-05">
      <Breadcrumb element="a" href={config.basePath}>
        {t("text_breadcrumbs_top")}
      </Breadcrumb>
      <Breadcrumb element="a" href="">
        {t("text_styling_book_header_title")}
      </Breadcrumb>
    </BreadcrumbGroup>
  );

  const mainContent = (
    <>
      <HeaderPage
        title={t("text_styling_book_header_title")}
        className="fr-ec-mb-spacing-05"
      />
      {status === 404 ? (
        <NotFound
          text={t("text_official_styling_top_page_link")}
          type={StylingType.official}
        />
      ) : (
        <SystemError />
      )}
    </>
  );

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{status === 404 ? t("text_404") : t("text_error_title")}</title>
        <meta
          name="description"
          content={
            status === 404 ? t("text_404") : t("text_503_error_description")
          }
        />
      </Helmet>
      <TemplateFullGutter
        mainContent={mainContent}
        navigationPath={navigationPath}
      />
    </>
  );
};
export default ErrorPage;
