import { ActionsUnion, createAction } from "@redux/helpers";
import {
  LargeOfficialStylingPayload,
  OfficialListData,
  OfficialListPayload,
  OfficialListType,
  SBlargeImagesData,
  StylesInfo,
  StylingSortEnum
} from "@type/index";

export const GET_STYLING_OFFICIAL_LIST =
  "[styling list] get styling official list request";
const GET_STYLING_OFFICIAL_LIST_SUCCESS =
  "[styling list] get styling official list success";
const GET_STYLING_OFFICIAL_LIST_LOADING =
  "[styling list] get styling official list loading";
export const GET_STYLING_OFFICIAL_LARGE_IMAGE_LIST =
  "[styling list] get styling official large image list request";
const GET_STYLING_OFFICIAL_LARGE_IMAGE_LIST_SUCCESS =
  "[styling list] get styling official large image list success";
const GET_STYLING_OFFICIAL_LARGE_IMAGE_LIST_LOADING =
  "[styling list] get styling official large image list loading";
const INIT_OFFICIAL_LISTS = "[styling list] init styling official all lists";
const SET_OFFICIAL_LIST_ERROR_CODE =
  "[styling list] set styling official lists error code";
const SET_SHOW_FILTER_PANEL = "[styling list] set show or hide filter panel";
const SET_SB_STYLING_LIST =
  "[styling list] set official styling list contain favorites";
const SET_OFFICIAL_CURRENT_SORT =
  "[styling list] set styling official current sort";

export const Actions = {
  getStylingOfficialList: (payload: OfficialListPayload) =>
    createAction(GET_STYLING_OFFICIAL_LIST, payload),
  getStylingOfficialListSuccess: (payload: OfficialListType) =>
    createAction(GET_STYLING_OFFICIAL_LIST_SUCCESS, payload),
  getStylingOfficialListLoading: (payload: boolean) =>
    createAction(GET_STYLING_OFFICIAL_LIST_LOADING, payload),
  getOfficialLargeImageList: (payload: LargeOfficialStylingPayload) =>
    createAction(GET_STYLING_OFFICIAL_LARGE_IMAGE_LIST, payload),
  getOfficialLargeImageListSuccess: (payload: SBlargeImagesData) =>
    createAction(GET_STYLING_OFFICIAL_LARGE_IMAGE_LIST_SUCCESS, payload),
  getOfficialLargeImageListLoading: (payload: boolean) =>
    createAction(GET_STYLING_OFFICIAL_LARGE_IMAGE_LIST_LOADING, payload),
  initOfficialLists: (payload?: boolean) =>
    createAction(INIT_OFFICIAL_LISTS, payload), // default payload = false -> init all data, payload = true -> only init hasMore
  setOfficialListErrorCode: (payload: string) =>
    createAction(SET_OFFICIAL_LIST_ERROR_CODE, payload),
  setShowFilterPanel: (payload: boolean) =>
    createAction(SET_SHOW_FILTER_PANEL, payload),
  setSBStylingList: (payload: Array<StylesInfo>) =>
    createAction(SET_SB_STYLING_LIST, payload),
  setOfficialCurrentSort: (payload: StylingSortEnum) =>
    createAction(SET_OFFICIAL_CURRENT_SORT, payload)
};

type ActionType = ActionsUnion<typeof Actions>;

export type State = OfficialListData;

const initialState = {
  loading: true,
  official_list: {
    styles: [],
    pagination: {
      count: 0,
      total: 0,
      offset: 0
    }
  },
  large_loading: true,
  official_large_list: [],
  hasMore: false,
  current_list: [],
  noResult: false,
  errorCode: "",
  showFilterPanel: false,
  SB_list: [],
  currentSort: StylingSortEnum.latest,
  hasClickSort: false
};

export default (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case GET_STYLING_OFFICIAL_LIST_SUCCESS: {
      const _total = action.payload.pagination.total || 0;
      const _count =
        action.payload.pagination.count || action.payload.styles.length;
      const _offset = action.payload.pagination.offset || 0;
      return {
        ...state,
        official_list: {
          pagination: { ...action.payload.pagination },
          styles:
            action.payload.pagination.offset === 0
              ? [...action.payload.styles]
              : [...state.official_list.styles, ...action.payload.styles]
        },
        hasMore: _count + _offset < _total,
        loading: false,
        current_list: [...action.payload.styles],
        noResult: !action.payload.pagination.total
      };
    }
    case GET_STYLING_OFFICIAL_LIST_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case GET_STYLING_OFFICIAL_LARGE_IMAGE_LIST_SUCCESS:
      return {
        ...state,
        official_large_list: [...action.payload.styles],
        large_loading: false
      };
    case GET_STYLING_OFFICIAL_LARGE_IMAGE_LIST_LOADING:
      return {
        ...state,
        large_loading: action.payload
      };
    case INIT_OFFICIAL_LISTS: {
      if (action.payload) {
        return {
          ...state,
          hasMore: false,
          noResult: false
        };
      } else {
        const obj = { ...initialState, showFilterPanel: state.showFilterPanel };
        return {
          ...state,
          ...obj
        };
      }
    }
    case SET_OFFICIAL_LIST_ERROR_CODE:
      return {
        ...state,
        errorCode: action.payload,
        loading: false
      };
    case SET_SHOW_FILTER_PANEL:
      return {
        ...state,
        showFilterPanel: action.payload
      };
    case SET_SB_STYLING_LIST:
      return {
        ...state,
        SB_list: [...action.payload]
      };
    case SET_OFFICIAL_CURRENT_SORT:
      return {
        ...state,
        currentSort: action.payload,
        hasClickSort: true
      };
    default:
      return state;
  }
};
