import { combineReducers } from "redux";

import ConfigReducer, {
  Actions as ConfigActions,
  State as ConfigStateType,
  SET_USER_AGENT
} from "./config";

// Cms Content
import CmsContentReducer, {
  Actions as CmsContentActions,
  State as CmsContentStateType,
  GET_CMS_BANNER
} from "./cmsContent";

// Stying Filters
import StylingFilterReducer, {
  Actions as StylingFilterActions,
  State as StylingFilterStateType,
  GET_STYLING_FILTERS
} from "./filters";

// Stylehint Styling
import StylehintListReducer, {
  Actions as StylehintListActions,
  State as StylehintListStateType,
  GET_STYLING_STYLEHINT_LIST,
  GET_STYLING_STYLEHINT_LARGE_IMAGE_LIST
} from "./stylehintList";

// Official Styling
import OfficialListReducer, {
  Actions as OfficialListActions,
  State as OfficialListStateType,
  GET_STYLING_OFFICIAL_LIST,
  GET_STYLING_OFFICIAL_LARGE_IMAGE_LIST
} from "./officialList";

// Detail Styling
import DetailStylingReducer, {
  Actions as DetailStylingActions,
  State as DetailStylingStateType,
  GET_DETAIL_STYLING
} from "./detailStyling";

// Favorites Styling
import FavoritesStylingReducer, {
  Actions as FavoritesStylingActions,
  State as FavoritesStylingStateType,
  GET_FAVORITES_STYLING,
  POST_FAVORITES_STYLING,
  DELETE_FAVORITES_STYLING,
  GET_FAVORITES_STYLING_WISH_COUNT
} from "./favoritesStyling";

// Favorites Item
import FavoritesItemReducer, {
  Actions as FavoritesItemActions,
  State as FavoritesItemStateType,
  GET_FAVORITES_ITEM,
  POST_FAVORITES_ITEM,
  DELETE_FAVORITES_ITEM,
  FETCH_PRODUCT_SKU_KEY,
  FETCH_PRODUCT_L2S_WITH_IMAGE
} from "./favoritesItem";

// User Profile
import UserProfileReducer, {
  Actions as UserProfileActions,
  State as UserProfileStateType,
  GET_USER_PROFILE
} from "./userProfile";

// Related Styling Stylehint
import RelatedStylingStylehintReducer, {
  Actions as RelatedStylingStylehintActions,
  State as RelatedStylingStylehintStateType,
  GET_RELATED_STYLING_STYLEHINT,
  GET_STYLEHINT_CAROUSEL_LIST
} from "./relatedStylingStylehint";

// Related Styling Official
import RelatedStylingOfficialReducer, {
  Actions as RelatedStylingOfficialActions,
  State as RelatedStylingOfficialStateType,
  GET_RELATED_STYLING_OFFICIAL,
  GET_OFFICIAL_CAROUSEL_LIST
} from "./relatedStylingOfficial";

export interface CombinedReducersType {
  config: ConfigStateType;
  cmsContent: CmsContentStateType;
  stylingFilters: StylingFilterStateType;
  stylehintList: StylehintListStateType;
  officialList: OfficialListStateType;
  detailStyling: DetailStylingStateType;
  favoritesStyling: FavoritesStylingStateType;
  favoritesItem: FavoritesItemStateType;
  userProfile: UserProfileStateType;
  relatedStylingStylehint: RelatedStylingStylehintStateType;
  relatedStylingOfficial: RelatedStylingOfficialStateType;
}

const rootReducer = combineReducers<CombinedReducersType>({
  config: ConfigReducer,
  cmsContent: CmsContentReducer,
  stylingFilters: StylingFilterReducer,
  stylehintList: StylehintListReducer,
  officialList: OfficialListReducer,
  detailStyling: DetailStylingReducer,
  favoritesStyling: FavoritesStylingReducer,
  favoritesItem: FavoritesItemReducer,
  userProfile: UserProfileReducer,
  relatedStylingStylehint: RelatedStylingStylehintReducer,
  relatedStylingOfficial: RelatedStylingOfficialReducer
});

export default rootReducer;

export {
  ConfigActions,
  SET_USER_AGENT,
  // Cms Content
  CmsContentActions,
  GET_CMS_BANNER,
  // Styling Filters
  StylingFilterActions,
  GET_STYLING_FILTERS,
  // Stylehint Styling
  StylehintListActions,
  GET_STYLING_STYLEHINT_LIST,
  GET_STYLING_STYLEHINT_LARGE_IMAGE_LIST,
  // Official Styling
  OfficialListActions,
  GET_STYLING_OFFICIAL_LIST,
  GET_STYLING_OFFICIAL_LARGE_IMAGE_LIST,
  // Detail Styling
  DetailStylingActions,
  GET_DETAIL_STYLING,
  // Favorites Styling
  FavoritesStylingActions,
  GET_FAVORITES_STYLING,
  POST_FAVORITES_STYLING,
  DELETE_FAVORITES_STYLING,
  GET_FAVORITES_STYLING_WISH_COUNT,
  // Favorites Item
  FavoritesItemActions,
  GET_FAVORITES_ITEM,
  POST_FAVORITES_ITEM,
  DELETE_FAVORITES_ITEM,
  FETCH_PRODUCT_SKU_KEY,
  FETCH_PRODUCT_L2S_WITH_IMAGE,
  // User Profile
  GET_USER_PROFILE,
  UserProfileActions,
  // Related Styling Stylehint
  RelatedStylingStylehintActions,
  GET_RELATED_STYLING_STYLEHINT,
  GET_STYLEHINT_CAROUSEL_LIST,
  // Related Styling Official
  RelatedStylingOfficialActions,
  GET_RELATED_STYLING_OFFICIAL,
  GET_OFFICIAL_CAROUSEL_LIST
};
