export const executeOnceAtATime = (fn: any) => {
  let isSagaExecuting = false;
  return function* (...rest: any) {
    if (!isSagaExecuting) {
      isSagaExecuting = true;
      yield* fn(...rest);
      isSagaExecuting = false;
    }
  };
};
