import React from "react";
import { Button, ButtonLayout, Modal, Body } from "@common/DynamicUikit";
import { useDispatch, useSelector } from "react-redux";
import { CombinedReducersType, ConfigActions } from "@redux/reducer";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { BRAND_LIST } from "@type/index";
import { getEcRenewalCompatibility } from "@util/helpers";

type IProps = {
  clickClose?: () => void;
};

const LoginModal = (props: IProps): any => {
  const { clickClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [, setCookie] = useCookies();
  const { region, language, brand_name } = useSelector(
    (store: CombinedReducersType) => store.config
  );

  const jumpDomain = (brand: string): string => {
    switch (brand) {
      case BRAND_LIST.GU:
        return window.location.origin.replace("gu-global", "uniqlo");
      default:
        return window.location.origin;
    }
  };

  const handlerJumpLogin = () => {
    setCookie("loginRedirectUri", `${location.pathname}${location.search}`, {
      path: `/${region}`
    });
    const _url = `${jumpDomain(
      brand_name
    )}/${region}/api/commerce/v5/${language}/redirect?from=${
      window.location.href
    }&to=${
      window.location.origin
    }/${region}/api/commerce/v5/${language}/connect`;
    window.location.href = _url;
  };

  const handlerClose = () => {
    if (clickClose) {
      clickClose();
    }
    dispatch(ConfigActions.setShowLogin(false));
  };

  return (
    <Modal
      closeLabel={t("text_a11y_close")}
      closeTitle={t("text_a11y_close")}
      errors={[]}
      id="sm-1"
      labelElement="h5"
      onBackdropClick={undefined}
      onRequestClose={handlerClose}
      title={t("text_login_required")}
      titleId="modal-title"
      trapFocus
      variant="small"
      isOpen
      footer={
        <ButtonLayout reverseOnVertical={false} variant="fluid">
          <Button
            element="button"
            isSmall={false}
            variant="primary"
            width="fullWidth"
            data-testid="login-button"
            onClick={handlerJumpLogin}
            ecRenewal={getEcRenewalCompatibility()}
          >
            {t("text_log_in")}
          </Button>
          <Button
            element="button"
            isSmall={false}
            variant="secondary"
            width="fullWidth"
            onClick={handlerClose}
            data-testid="close-button"
            ecRenewal={getEcRenewalCompatibility()}
          >
            {t("text_app_update_dialog_cancel")}
          </Button>
        </ButtonLayout>
      }
      hideCloseIconLabel={getEcRenewalCompatibility()}
    >
      <Body element="p" variant="standard">
        {t("text_login_request")}
      </Body>
    </Modal>
  );
};

export default LoginModal;
