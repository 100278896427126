import { call, put, takeLatest } from "redux-saga/effects";
import request from "@util/net/request";
import axios, { AxiosResponse } from "axios";
import {
  GET_STYLING_OFFICIAL_LARGE_IMAGE_LIST,
  GET_STYLING_OFFICIAL_LIST,
  OfficialListActions
} from "@redux/reducer";
import {
  LargeOfficialStylingPayload,
  LargeOfficialStylingResponse,
  OfficialListPayload,
  OfficialListResponse
} from "@type/index";

export function* loadOfficialListSaga(action: {
  payload: OfficialListPayload;
}) {
  const payload: OfficialListPayload = action.payload;
  try {
    yield put(OfficialListActions.setOfficialListErrorCode(""));
    yield put(OfficialListActions.getStylingOfficialListLoading(true));
    const res: AxiosResponse<OfficialListResponse> = yield call(
      request.get,
      "/styles/images/sb/normal",
      {
        params: { ...payload }
      }
    );
    if (res.data.status === "nok") {
      yield put(OfficialListActions.setOfficialListErrorCode("503"));
    } else {
      yield put(
        OfficialListActions.getStylingOfficialListSuccess(res.data.result)
      );
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      yield put(OfficialListActions.setOfficialListErrorCode("503"));
    }
  }
}

export function* loadOfficialLargeListSaga(action: {
  payload: LargeOfficialStylingPayload;
}) {
  try {
    const payload: LargeOfficialStylingPayload = action.payload;
    yield put(OfficialListActions.getOfficialLargeImageListLoading(true));
    const res: AxiosResponse<LargeOfficialStylingResponse> = yield call(
      request.get,
      "/styles/images/sb/large",
      {
        params: { ...payload }
      }
    );
    yield put(
      OfficialListActions.getOfficialLargeImageListSuccess(res.data.result)
    );
  } catch (error) {
    yield put(OfficialListActions.getOfficialLargeImageListLoading(false));
  }
}

export function* watchLoadOfficialListSaga() {
  yield takeLatest<any>(GET_STYLING_OFFICIAL_LIST, loadOfficialListSaga);
  yield takeLatest<any>(
    GET_STYLING_OFFICIAL_LARGE_IMAGE_LIST,
    loadOfficialLargeListSaga
  );
}
