import { FlagComponent } from "@type/index";

export enum FLAG_TYPE {
  PRICE = "price",
  PRODUCT = "product"
}

enum FLAG_COLOR_CONFIG {
  RED = "promotional",
  GREY = "placeholder",
  BLACK = "standard"
}

enum FLAG_PRIORITY {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
  FIFTH = 5,
  SIXTH = 6,
  SEVENTH = 7,
  EIGHTH = 8,
  NINTH = 9,
  TENTH = 10,
  ELEVENTH = 11,
  TWELFTH = 12,
  THIRTEENTH = 13,
  FOURTEENTH = 14,
  FIFTEENTH = 15
}

export type flagColorType =
  | "promotional"
  | "placeholder"
  | "primary-dark"
  | "secondary"
  | undefined;

type priceColorType = "promotional" | "standard" | undefined;

type flagConfigObj = {
  priority: number;
  flag_color: flagColorType;
  price_color: priceColorType;
};

type newObjType = {
  price_flag?: [
    {
      name: string;
      color: flagColorType;
    }
  ];
  product_flag?: {
    name: string;
    color: flagColorType;
  };
  price_color: priceColorType;
};

/**
 * refer to "All about display flags" excel (sheet 1-Type) Group0 and Group 1
 * show price flags priority
 * ex: flag code: priority
 */
const priceFlagConfig: { [key: string]: flagConfigObj } = {
  specialPrice: {
    priority: FLAG_PRIORITY.FIRST,
    flag_color: FLAG_COLOR_CONFIG.RED,
    price_color: FLAG_COLOR_CONFIG.RED
  },
  multibuy: {
    priority: FLAG_PRIORITY.SECOND,
    flag_color: FLAG_COLOR_CONFIG.RED,
    price_color: FLAG_COLOR_CONFIG.BLACK
  },
  appmemberLimited: {
    priority: FLAG_PRIORITY.THIRD,
    flag_color: FLAG_COLOR_CONFIG.RED,
    price_color: FLAG_COLOR_CONFIG.RED
  },
  onlineLimited: {
    priority: FLAG_PRIORITY.FOURTH,
    flag_color: FLAG_COLOR_CONFIG.RED,
    price_color: FLAG_COLOR_CONFIG.RED
  },
  limitedOffer: {
    priority: FLAG_PRIORITY.FIFTH,
    flag_color: FLAG_COLOR_CONFIG.RED,
    price_color: FLAG_COLOR_CONFIG.RED
  },
  newPrice: {
    priority: FLAG_PRIORITY.SIXTH,
    flag_color: FLAG_COLOR_CONFIG.GREY,
    price_color: FLAG_COLOR_CONFIG.BLACK
  },
  discount: {
    priority: FLAG_PRIORITY.SEVENTH,
    flag_color: FLAG_COLOR_CONFIG.RED,
    price_color: FLAG_COLOR_CONFIG.RED
  },
  colorSizeLimitedPrice: {
    priority: FLAG_PRIORITY.EIGHTH,
    flag_color: FLAG_COLOR_CONFIG.RED,
    price_color: FLAG_COLOR_CONFIG.RED
  }
};

export const handleFlagsSort = (
  priceFlag: Array<FlagComponent>,
  productFlag: Array<FlagComponent>,
  t: any,
  promotionText?: string
) => {
  const newPriceFlag = JSON.parse(JSON.stringify(priceFlag));
  const newProductFlag = JSON.parse(JSON.stringify(productFlag));
  const obj: newObjType = {
    price_flag: undefined,
    product_flag: undefined,
    price_color: "standard"
  };
  if (newPriceFlag.length > 0) {
    obj.price_flag = newPriceFlag.map((p: FlagComponent) => {
      const name =
        p.nameWording && p.nameWording?.flagWithTime
          ? t(p.nameWording.flagWithTime, {
              date: p.nameWording.substitutions?.date,
              month: p.nameWording.substitutions?.month,
              flagName: p.nameWording.substitutions?.flagName,
              startDate: p.nameWording.substitutions?.startDate
            })
          : p.name;
      const priceData = {
        name: promotionText && p.code === "specialPrice" ? promotionText : name,
        color: priceFlagConfig[p.code]?.flag_color || FLAG_COLOR_CONFIG.RED
      };
      if (
        priceFlagConfig[p.code] &&
        priceFlagConfig[p.code].price_color === FLAG_COLOR_CONFIG.RED
      ) {
        obj.price_color = priceFlagConfig[p.code].price_color;
      }
      return priceData;
    });
  }
  if (newProductFlag.length > 0) {
    obj.product_flag = {
      name: newProductFlag
        .map((p: FlagComponent) => {
          const name =
            p.nameWording && p.nameWording?.flagWithTime
              ? t(p.nameWording.flagWithTime, {
                  date: p.nameWording.substitutions?.date,
                  month: p.nameWording.substitutions?.month,
                  flagName: p.nameWording.substitutions?.flagName,
                  startDate: p.nameWording.substitutions?.startDate
                })
              : p.name;
          return name;
        })
        .toString()
        .split(",")
        .join(", "),
      color: "placeholder"
    };
  }
  return obj;
};
