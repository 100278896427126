import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import request from "@util/net/request";
import { executeOnceAtATime } from "@util/net/index";
import {
  FavoritesStylingActions,
  GET_FAVORITES_STYLING,
  POST_FAVORITES_STYLING,
  DELETE_FAVORITES_STYLING,
  ConfigActions,
  DetailStylingActions,
  GET_FAVORITES_STYLING_WISH_COUNT
} from "@redux/reducer";
import {
  DelFavoritesStylingPayload,
  FavoritesStylingCountResponse,
  FavoritesStylingDataType,
  FavoritesStylingPayload,
  FavoritesStylingResponse,
  OperateFavoritesResponse,
  PostFavoritesStylingPayload,
  StylingAbbrType,
  StylingWishCountPayload
} from "@type/index";
import {
  addFavoriteStyles,
  deleteFavoriteStyles,
  isGUNativeApp,
  searchFavoriteStyles
} from "@util/nativeApp";

// get favorites styling list
export function* getFavoritesStylingSaga(action: {
  payload: FavoritesStylingPayload;
}) {
  try {
    const payload: FavoritesStylingPayload = action.payload;
    yield put(FavoritesStylingActions.getFavoritesStylingLoading(true));
    if (isGUNativeApp()) {
      if (payload.ids !== "") {
        const styles = payload.ids.split(",");
        try {
          const { stylingIds }: { stylingIds: string[] } = yield call(
            searchFavoriteStyles,
            styles,
            payload.type as StylingAbbrType
          );
          if (stylingIds) {
            const _res: FavoritesStylingDataType = {
              list: stylingIds,
              type: payload.type || "sb",
              fromDetail: payload.fromDetail,
              query: styles
            };
            yield put(FavoritesStylingActions.getFavoritesStylingSuccess(_res));
          }
        } catch (e) {
          yield put(FavoritesStylingActions.getFavoritesStylingLoading(false));
        }
      }
    } else {
      const res: AxiosResponse<FavoritesStylingResponse> = yield call(
        request.get,
        "/favorites/styles/search",
        {
          params: {
            ids: payload.ids,
            type: payload.type
          }
        }
      );
      if (res.data.status === "ok") {
        const _res: FavoritesStylingDataType = {
          list: res.data.result,
          type: payload.type || "sb",
          fromDetail: payload.fromDetail,
          query: payload.ids.split(",")
        };
        yield put(FavoritesStylingActions.getFavoritesStylingSuccess(_res));
      } else {
        yield put(FavoritesStylingActions.getFavoritesStylingLoading(false));
      }
    }
  } catch (error) {
    yield put(FavoritesStylingActions.getFavoritesStylingLoading(false));
  }
}

// add favorites styling
export function* postFavoritesStylingSaga(action: {
  payload: PostFavoritesStylingPayload;
}) {
  try {
    const payload: PostFavoritesStylingPayload = action.payload;
    yield put(FavoritesStylingActions.getFavoritesStylingLoading(true));
    if (isGUNativeApp()) {
      try {
        const { status }: { status: boolean } = yield call(
          addFavoriteStyles,
          payload.body[0].styleId,
          payload.type as StylingAbbrType
        );
        if (status) {
          yield put(
            FavoritesStylingActions.postFavoritesStylingSuccess({
              id: payload.body[0].styleId,
              type: payload.type || "sb"
            })
          );
          if (payload.isCarouselImg) {
            yield put(
              DetailStylingActions.setCarouselImgWishCount({ type: "post" })
            );
          }
        }
      } catch (e) {
        yield put(FavoritesStylingActions.getFavoritesStylingLoading(false));
      }
    } else {
      const res: AxiosResponse<OperateFavoritesResponse> = yield call(
        request.post,
        "/favorites/styles",
        payload.body,
        {
          params: {
            type: payload.type
          }
        }
      );
      if (res.data.status === "ok") {
        yield put(
          FavoritesStylingActions.postFavoritesStylingSuccess({
            id: payload.body[0].styleId,
            type: payload.type || "sb"
          })
        );
        if (payload.isCarouselImg) {
          yield put(
            DetailStylingActions.setCarouselImgWishCount({ type: "post" })
          );
        }
      }
    }
    yield put(FavoritesStylingActions.getFavoritesStylingLoading(false));
  } catch (error) {
    yield put(FavoritesStylingActions.getFavoritesStylingLoading(false));
    if (!axios.isCancel(error)) {
      yield put(ConfigActions.setShowWishIconError(true));
    }
  }
}

// del favorites styling
export function* deleteFavoritesStylingSaga(action: {
  payload: DelFavoritesStylingPayload;
}) {
  try {
    const payload: DelFavoritesStylingPayload = action.payload;
    yield put(FavoritesStylingActions.getFavoritesStylingLoading(true));
    if (isGUNativeApp()) {
      try {
        const { status }: { status: boolean } = yield call(
          deleteFavoriteStyles,
          payload.styleId,
          payload.type as StylingAbbrType
        );
        if (status) {
          yield put(
            FavoritesStylingActions.delFavoritesStylingSuccess({
              id: payload.styleId,
              type: payload.type || "sb"
            })
          );
          if (payload.isCarouselImg) {
            yield put(
              DetailStylingActions.setCarouselImgWishCount({ type: "del" })
            );
          }
        }
      } catch (e) {
        yield put(FavoritesStylingActions.getFavoritesStylingLoading(false));
      }
    } else {
      const res: AxiosResponse<OperateFavoritesResponse> = yield call(
        request.del,
        `/favorites/styles/${payload.styleId}`,
        {
          params: {
            type: payload.type
          }
        }
      );
      if (res.data.status === "ok") {
        yield put(
          FavoritesStylingActions.delFavoritesStylingSuccess({
            id: payload.styleId,
            type: payload.type || "sb"
          })
        );
        if (payload.isCarouselImg) {
          yield put(
            DetailStylingActions.setCarouselImgWishCount({ type: "del" })
          );
        }
      }
    }
    yield put(FavoritesStylingActions.getFavoritesStylingLoading(false));
  } catch (error) {
    yield put(FavoritesStylingActions.getFavoritesStylingLoading(false));
    if (!axios.isCancel(error)) {
      yield put(ConfigActions.setShowWishIconError(true));
    }
  }
}

// get carousel styling wish count
export function* getStylingWishCountSaga(action: {
  payload: StylingWishCountPayload;
}) {
  try {
    const payload: StylingWishCountPayload = action.payload;
    yield put(DetailStylingActions.getCarouselImgWishCountLoading(true));
    const resWishCount: AxiosResponse<FavoritesStylingCountResponse> =
      yield call(request.get, `/favorites/styles/${payload.styleId}/count`, {
        params: {
          type: payload.type
        }
      });
    if (resWishCount.data.status === "ok") {
      yield put(
        DetailStylingActions.initCarouselImgWishCount(
          resWishCount.data.result.count
        )
      );
    } else {
      yield put(DetailStylingActions.initCarouselImgWishCount(0));
    }
  } catch (error) {
    yield put(DetailStylingActions.initCarouselImgWishCount(0));
  }
}

export const addWishlistStylingBookStyling = executeOnceAtATime(
  postFavoritesStylingSaga
);
export const delWishlistStylingBookStyling = executeOnceAtATime(
  deleteFavoritesStylingSaga
);

export function* watchLoadFavoritesStylingSaga() {
  yield takeEvery<any>(GET_FAVORITES_STYLING, getFavoritesStylingSaga);
  yield takeLatest<any>(POST_FAVORITES_STYLING, addWishlistStylingBookStyling);
  yield takeLatest<any>(
    DELETE_FAVORITES_STYLING,
    delWishlistStylingBookStyling
  );
  yield takeLatest<any>(
    GET_FAVORITES_STYLING_WISH_COUNT,
    getStylingWishCountSaga
  );
}
