import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ConfigActions } from "../../redux/reducer";
import store from "../../redux/store";

const allPendingRequestsRecord: AbortController[] = [];

const filterUrlCanBeInterceptor = (config: any) => {
  if (
    (config.method && config.method === "post") ||
    config.method === "delete"
  ) {
    return true;
  } else {
    return false;
  }
};

export const reqHandler = (config: AxiosRequestConfig) => {
  const controller = new AbortController();
  config.signal = controller.signal;
  allPendingRequestsRecord.push(controller);
  return config;
};

export const reqErrHandler = (error: AxiosError) => Promise.reject(error);

export const resHandler = (response: AxiosResponse<any>) => {
  // not login show login modal
  // wish error show wish modal
  if (
    response &&
    response.config &&
    filterUrlCanBeInterceptor(response.config) &&
    response.data &&
    response.data.status === "nok"
  ) {
    if (response.data.error && response.data.error.httpStatusCode === 401) {
      store.dispatch(ConfigActions.setShowLogin(true));
    } else {
      store.dispatch(ConfigActions.setShowWishIconError(true));
    }
  }
  return response;
};

// TODO handler server error logic
export const resErrHandler = (error: AxiosError) => Promise.reject(error);

const removeAllPendingRequestsRecord = () => {
  if (allPendingRequestsRecord) {
    allPendingRequestsRecord.forEach((controller) => {
      controller.abort();
    });
  }
  // Remove all Records
  allPendingRequestsRecord.splice(0);
};

// To cancel all requests
export const getConfirmation = () => {
  removeAllPendingRequestsRecord();
};
