import React from "react";
import { Body, Button, Modal } from "@common/DynamicUikit";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ConfigActions } from "@redux/reducer";
import { getEcRenewalCompatibility } from "@util/helpers";

type IProps = {
  clickClose?: () => void;
};

const ErrorModal = (props: IProps) => {
  const { clickClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handlerClose = () => {
    if (clickClose) {
      clickClose();
    }
    dispatch(ConfigActions.setShowWishIconError(false));
  };

  const handlerCloseButton = () => {
    handlerClose();
    window.location.reload();
  };

  return (
    <Modal
      closeLabel={t("text_a11y_close")}
      closeTitle={t("text_a11y_close")}
      errors={[]}
      id="wish-error-modal"
      labelElement="h5"
      onBackdropClick={undefined}
      onRequestClose={handlerClose}
      title={t("text_system_error")}
      titleId="wish-error-title"
      trapFocus
      variant="small"
      isOpen
      footer={
        <Button
          element="button"
          variant="primary"
          width="fullWidth"
          onClick={handlerCloseButton}
          ecRenewal={getEcRenewalCompatibility()}
        >
          {t("text_ok")}
        </Button>
      }
      hideCloseIconLabel={getEcRenewalCompatibility()}
    >
      <Body element="p">{t("text_error_description")}</Body>
    </Modal>
  );
};

export default ErrorModal;
