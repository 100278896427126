import { call, put, takeLatest } from "redux-saga/effects";
import request from "@util/net/request";
import { AxiosResponse } from "axios";
import {
  GET_RELATED_STYLING_STYLEHINT,
  GET_STYLEHINT_CAROUSEL_LIST,
  RelatedStylingStylehintActions
} from "@redux/reducer";
import {
  RelatedStylingStylehintPayload,
  RelatedStylingStylehintResponse,
  StylehintListPayload,
  StylehintListResponse
} from "@type/index";

export function* getRelatedStylingStylehintSaga(action: {
  payload: RelatedStylingStylehintPayload;
}) {
  try {
    const payload: RelatedStylingStylehintPayload = action.payload;
    yield put(
      RelatedStylingStylehintActions.getRelatedStylingStylehintLoading(true)
    );
    const res: AxiosResponse<RelatedStylingStylehintResponse> = yield call(
      request.get,
      "/styles/products/sh",
      {
        params: {
          ...payload
        }
      }
    );
    yield put(
      RelatedStylingStylehintActions.getRelatedStylingStylehintSuccess(
        res.data.result
      )
    );
  } catch (error) {
    yield put(
      RelatedStylingStylehintActions.getRelatedStylingStylehintLoading(false)
    );
  }
}

export function* getStylehintCarouselListSaga(action: {
  payload: StylehintListPayload;
}) {
  try {
    const payload: StylehintListPayload = action.payload;
    yield put(
      RelatedStylingStylehintActions.getStylehintCarouselListLoading(true)
    );
    const res: AxiosResponse<StylehintListResponse> = yield call(
      request.get,
      "/styles/images/sh/normal",
      {
        params: { ...payload }
      }
    );
    yield put(
      RelatedStylingStylehintActions.getStylehintCarouselListSuccess(
        res.data.result
      )
    );
  } catch (error) {
    yield put(
      RelatedStylingStylehintActions.getStylehintCarouselListLoading(false)
    );
  }
}

export function* watchLoadRelatedStylingStylehintSaga() {
  yield takeLatest<any>(
    GET_RELATED_STYLING_STYLEHINT,
    getRelatedStylingStylehintSaga
  );
  yield takeLatest<any>(
    GET_STYLEHINT_CAROUSEL_LIST,
    getStylehintCarouselListSaga
  );
}
