import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Body,
  Button,
  ButtonLayout,
  ButtonToggle,
  ButtonToggleGroup,
  FlagText,
  Image,
  Keyline,
  LoadingIndicator,
  MediaObject,
  Modal,
  Spacer,
  StyleUserInfo,
  Chip,
  ProductTile,
  ChipGroup
} from "@common/DynamicUikit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CombinedReducersType, FavoritesItemActions } from "@redux/reducer";
import {
  ItemWishListMainData,
  L2,
  ProductDetailType,
  UserInfoType
} from "@type/index";
import {
  sendTrackEvent,
  transformBrandForGoogleAnalytics
} from "@util/analytics/utils";
import { TRACK_EVENT } from "@config/common";
import {
  getEcRenewalCompatibility,
  getEcRenewalImageRatio,
  getSizeForItemInfo,
  isNotEmptyObj,
  isStaff,
  localizeGenderName
} from "@util/helpers";
import { userHeight } from "@util/height";
import { isGUNativeApp } from "@util/nativeApp";

interface ProductInfoType extends ProductDetailType {
  center: [number, number];
  position: "left" | "right" | "top" | "bottom";
  gender: string;
}

type IProps = {
  closePopup: () => void;
  stylingId: string;
  productInfo: ProductInfoType;
  userInfo: UserInfoType | undefined;
  modelHeight: number;
  modelWearingColor?: string | null;
};

const FavoritesPopup = (props: IProps) => {
  const { t } = useTranslation();
  const {
    closePopup,
    stylingId,
    productInfo,
    userInfo,
    modelHeight,
    modelWearingColor
  } = props;
  const dispatch = useDispatch();
  const config = useSelector((store: CombinedReducersType) => store.config);
  const favoritesItemData = useSelector(
    (store: CombinedReducersType) => store.favoritesItem
  );
  const [colorCode, setColorCode] = useState<string>("");

  useEffect(() => {
    if (productInfo.productId && productInfo.priceGroup) {
      if (isGUNativeApp()) {
        // To get SKU details without being logged in
        dispatch(
          FavoritesItemActions.fetchProductL2sWithImage({
            productId: productInfo.productId,
            priceGroup: productInfo.priceGroup
          })
        );
      } else {
        dispatch(
          FavoritesItemActions.fetchProductSKUs({
            productId: productInfo.productId,
            priceGroup: productInfo.priceGroup
          })
        );
      }
    }
  }, [dispatch, productInfo.priceGroup, productInfo.productId]);

  useEffect(() => {
    if (productInfo.color && productInfo.color.code) {
      setColorCode(productInfo.color.code);
    }
  }, [productInfo.color]);

  const handleClose = () => {
    closePopup();
    dispatch(FavoritesItemActions.fetchProductSKUsFail(false));
    dispatch(FavoritesItemActions.initFavoritesItem());
  };

  useEffect(() => {
    if (config.showLogin) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.showLogin]);

  const onWishlist = useMemo(
    () => (size: L2) => {
      if (!size.l2Id || !productInfo.productId || !productInfo.priceGroup) {
        return;
      }
      sendTrackEvent({
        brand: transformBrandForGoogleAnalytics(config.brand_name),
        event: TRACK_EVENT,
        eventAction: `Styling_${stylingId}`,
        eventCategory: "sb_wishlist_item",
        eventLabel: size.l2Id
      });
      if (size.isWishlisted) {
        dispatch(
          FavoritesItemActions.delFavoritesItem({
            id: size.l2Id,
            isGUNativeApp: isGUNativeApp()
          })
        );
      } else {
        dispatch(
          FavoritesItemActions.postFavoritesItem({
            body: [
              {
                l2Id: size.l2Id,
                isRepresentative: false
              }
            ],
            isGUNativeApp: isGUNativeApp()
          })
        );
      }
    },
    [
      config.brand_name,
      dispatch,
      productInfo.priceGroup,
      productInfo.productId,
      stylingId
    ]
  );

  const [selectedColor, setSelectedColor] = useState(modelWearingColor);

  const filteredSKUs = useMemo(() => {
    // Skip hidden color and SKU if there are more than one SKU
    // for length == 1 case, we only check showFlag of color
    // for length > 1, we check both color and size
    const colorGroups = JSON.parse(JSON.stringify(favoritesItemData.allL2s));
    Object.keys(colorGroups).forEach((colorId) => {
      if (
        colorGroups[colorId].length === 1 &&
        !colorGroups[colorId][0].color?.display.showFlag
      ) {
        delete colorGroups[colorId];
      } else if (colorGroups[colorId].length > 1) {
        colorGroups[colorId] = colorGroups[colorId].filter(
          (sku: L2) =>
            sku.color?.display?.showFlag && sku.size?.display?.showFlag
        );
        if (!colorGroups[colorId].length) {
          delete colorGroups[colorId];
        }
      }
    });
    return colorGroups;
  }, [favoritesItemData.allL2s]);

  const processedAllL2s = useMemo(() => {
    if (getEcRenewalCompatibility()) {
      const l2s: typeof filteredSKUs = {};
      Object.keys(filteredSKUs).forEach((color) => {
        l2s[color] = filteredSKUs[color].map((size: L2) => ({
          ...size,
          isWishlisted: favoritesItemData.favoritesItemList.some(
            (l2Id) => l2Id === size.l2Id
          )
        }));
      });
      return l2s;
    } else {
      const l2s: typeof filteredSKUs = {};
      Object.keys(filteredSKUs).forEach((color) => {
        l2s[color] = filteredSKUs[color].map((size: L2) => ({
          ...size,
          isWishlisted: favoritesItemData.favoritesItemList.some(
            (l2Id) => l2Id === size.l2Id
          )
        }));
      });
      // The favorite modals are sorted by color number, with the color worn at the top of the list
      let obj: typeof filteredSKUs = {};
      if (!favoritesItemData.skusLoading && isNotEmptyObj(l2s)) {
        if (colorCode && l2s[colorCode]) {
          obj = {
            [colorCode]: [],
            ...l2s
          };
        } else {
          obj = { ...l2s };
        }
      }
      return obj;
    }
  }, [
    colorCode,
    favoritesItemData.favoritesItemList,
    favoritesItemData.skusLoading,
    filteredSKUs
  ]);

  const SKUList = useMemo(
    () =>
      Object.keys(processedAllL2s).map((color) => (
        <Spacer element="div" variant="spacing-05" key={color}>
          <MediaObject
            media={
              <Image
                alt="Trench coat"
                ratio={getEcRenewalImageRatio()}
                src={processedAllL2s[color][0].imageUrl || ""}
                noImage={{
                  imageSize: "large",
                  imageType: "product",
                  noImageLabel: t("text_no_image")
                }}
              />
            }
            mediaPosition="left"
            smallContainer="horizontal"
            variation="large"
            className="fr-ec-mb-spacing-03"
          >
            <FlagText element="p" color="primary-dark" variant="large">
              {t("text_color")}: {processedAllL2s[color][0].color?.displayCode}{" "}
              {processedAllL2s[color][0].color?.name}
            </FlagText>
          </MediaObject>
          <ButtonToggleGroup
            columnCount={processedAllL2s[color].length > 1 ? 2 : 1}
          >
            {processedAllL2s[color].map((size: L2) => (
              <ButtonToggle
                key={size.l2Id}
                icon="heart"
                onClick={() => {
                  if (!favoritesItemData.itemLoading) {
                    onWishlist(size);
                  }
                }}
                text={
                  size.pld?.display?.showFlag
                    ? `${size.size?.name} ${t(
                        "text_size_multiplication_sign"
                      )} ${size.pld?.name}`
                    : size.size?.name
                }
                variant="labelWithIcon"
                isToggled={!!size.isWishlisted}
              />
            ))}
          </ButtonToggleGroup>
        </Spacer>
      )),
    [favoritesItemData.itemLoading, onWishlist, processedAllL2s, t]
  );

  const chipImages: { [color: string]: string } = favoritesItemData.images
    ? favoritesItemData.images.chip
    : {};
  const mainImages: {
    [color: string]: ItemWishListMainData;
  } = favoritesItemData.images ? favoritesItemData.images.main : {};

  const getModalFooter = () => (
    <Spacer className="fr-ec-mt-spacing-02" element="div" variant="spacing-05">
      <ButtonLayout>
        <Button
          element="button"
          variant="primary"
          onClick={handleClose}
          ecRenewal={getEcRenewalCompatibility()}
        >
          {t("text_close")}
        </Button>
      </ButtonLayout>
    </Spacer>
  );

  const getPhase3ModalContent = useCallback(() => {
    const colorChipsContent = Object.keys(processedAllL2s).map((colorId) => (
      <Chip
        label={processedAllL2s[colorId][0].color?.name}
        image={
          chipImages[processedAllL2s[colorId][0]?.color?.displayCode] || ""
        }
        variant="default"
        type="radio"
        name="colorDisplay"
        ecRenewal
        value={colorId}
        key={colorId}
        onChange={() => setSelectedColor(colorId)}
        selected={colorId === selectedColor}
      />
    ));

    return (
      <>
        {selectedColor && processedAllL2s[selectedColor] && (
          <ProductTile
            variant="horizontal-small"
            name=""
            noImageLabel={t("text_no_image")}
            ecRenewal
            favoriteButtonHidden
            media={
              <Image
                src={
                  mainImages[
                    processedAllL2s[selectedColor][0]?.color?.displayCode
                  ]?.image || ""
                }
                alt={processedAllL2s[selectedColor][0].color?.name}
                noImage={{
                  imageSize: "default",
                  imageType: "product",
                  noImageLabel: t("text_no_image")
                }}
                ratio={getEcRenewalImageRatio()}
              />
            }
            informationContent={
              <FlagText element="p" color="primary-dark" variant="large">
                {`${t("text_color")}: ${
                  processedAllL2s[selectedColor][0].color?.displayCode
                } ${processedAllL2s[selectedColor][0].color?.name}`}
              </FlagText>
            }
            className="fr-ec-mb-spacing-04"
          />
        )}
        <ChipGroup
          id="favModalColorDisplay"
          ecRenewal
          variant="productSelector"
          className="fr-ec-mb-spacing-04"
          swipeable={{ sm: false, md: false, lg: false }}
        >
          {colorChipsContent}
        </ChipGroup>
        {selectedColor && processedAllL2s[selectedColor] && (
          <Spacer variant="spacing-02" element="div">
            <ButtonToggleGroup columnCount={2}>
              {processedAllL2s[selectedColor].map((size: any) => (
                <ButtonToggle
                  key={size.l2Id}
                  icon="heart"
                  onClick={() => onWishlist(size)}
                  text={
                    size.pld?.display?.showFlag
                      ? `${size.size?.name}${t(
                          "text_size_multiplication_sign"
                        )}${size.pld?.name}`
                      : size.size?.name
                  }
                  variant="labelWithIcon"
                  isToggled={!!size.isWishlisted}
                />
              ))}
            </ButtonToggleGroup>
          </Spacer>
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processedAllL2s, onWishlist, selectedColor, chipImages, mainImages]);

  const getModalBody = () => {
    if (favoritesItemData.skusLoading) {
      return <LoadingIndicator variant="normal" />;
    }
    if (
      favoritesItemData.sysError ||
      (getEcRenewalCompatibility() && Object.keys(chipImages).length === 0) ||
      Object.keys(processedAllL2s).length === 0
    ) {
      return (
        <>
          <Body className="fr-ec-mb-spacing-05" element="p">
            {t("text_error_description")}
          </Body>
        </>
      );
    } else {
      return (
        <>
          {userInfo && (
            <>
              {userInfo.isFromInstagram ? (
                <StyleUserInfo
                  isBadge={false}
                  userName={userInfo.name}
                  variant="small"
                  storeInfo={t("text_wearing_size_code01", {
                    size_code: `${t(
                      localizeGenderName(productInfo.genderCategory)
                    )} ${getSizeForItemInfo(productInfo.size)}`
                  })}
                  className="fr-ec-mb-spacing-05"
                />
              ) : (
                <StyleUserInfo
                  isBadge={isStaff(userInfo.userType)}
                  userName={userInfo.name}
                  variant="small"
                  storeInfo={t("text_wearing_size_code01", {
                    size_code: `${t(
                      localizeGenderName(productInfo.genderCategory)
                    )} ${getSizeForItemInfo(productInfo.size)}`
                  })}
                  className="fr-ec-mb-spacing-05"
                  modelHeight={userHeight(modelHeight)}
                  heightUnit={t("text_style_model_height")}
                  profileImage={
                    <Image
                      alt={t("text_styling_book_stylehint_user_id_alt_tag", {
                        userName: `${userInfo.name}${
                          isStaff(userInfo.userType) && userInfo.storeName
                            ? `、${userInfo.storeName}`
                            : ""
                        }`
                      })}
                      noImage={{ imageSize: "smallest", imageType: "user" }}
                      ratio="1x1"
                      src={userInfo.image ? userInfo.image : ""}
                    />
                  }
                />
              )}
              <Keyline className="fr-ec-mb-spacing-05" />
            </>
          )}
          {getEcRenewalCompatibility() ? getPhase3ModalContent() : SKUList}
        </>
      );
    }
  };

  return (
    <Modal
      closeLabel={t("text_a11y_close")}
      closeTitle={t("text_close")}
      isOpen={isGUNativeApp() ? true : !config.showLogin}
      onRequestClose={handleClose}
      labelElement="h5"
      title={t("text_a11y_favorites_add")}
      variant="large"
      hideCloseIconLabel={getEcRenewalCompatibility()}
      footer={getModalFooter()}
    >
      {getModalBody()}
    </Modal>
  );
};

export default FavoritesPopup;
