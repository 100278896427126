import { lazy } from "react";
import store from "@redux/store";
import { BRAND_LIST } from "@type/index";

const DynamicComponent = lazy(() => {
  const { brand_name } = store.getState().config;
  switch (brand_name) {
    case BRAND_LIST.UQ:
      return import("../UQLayout");
    case BRAND_LIST.GU:
      return import("../GULayout");
    case BRAND_LIST.PL:
      return import("../PLLayout");
    default:
      return import("../UQLayout");
  }
});

export default DynamicComponent;
