import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CombinedReducersType, CmsContentActions } from "@redux/reducer";
import CMSComponents from "@util/cms";
import { parseComponent } from "@util/cms/parser";

type IProps = {
  type: string;
};

const StyleHintBanner = (props: IProps) => {
  const { type } = props;
  const dispatch = useDispatch();
  const cmsContent = useSelector(
    (store: CombinedReducersType) => store.cmsContent
  );

  useEffect(() => {
    let _path = "";
    if (type === "top") {
      // top page
      _path = "/stylingbook/stylehint";
    } else if (type === "detail") {
      // detail page
      _path = "/styledetail";
    }
    // fetch banner api
    dispatch(
      CmsContentActions.getCmsBanner({
        path: _path,
        device: "RS"
      })
    );
  }, [dispatch, type]);

  const renderCmsContent = () => {
    const styleDetail =
      cmsContent[type === "top" ? "/stylingbook/stylehint" : "/styledetail"];
    let cmsComponentMarkup = null;
    if (styleDetail?.length) {
      cmsComponentMarkup = parseComponent(styleDetail, CMSComponents);
    }
    return <>{cmsComponentMarkup}</>;
  };

  return <>{renderCmsContent()}</>;
};

export default StyleHintBanner;
