import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import store from "@redux/store";
import {
  BUILD_VERSION,
  GU_UIKIT_VERSION,
  LOCALISATION_VERSION,
  PL_UIKIT_VERSION,
  UQ_UIKIT_VERSION
} from "@config/common";
import { CustomRouter } from "@common/CustomRouter";
import { YScroll } from "@common/YScroll";
import { DynamicComponent, RedirectTop } from "@common/index";
import App from "./component/App";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <DynamicComponent>
        <CustomRouter>
          <YScroll>
            <Routes>
              <Route
                path="/:region/:language/stylingbook"
                element={<RedirectTop />}
              />
              <Route
                path="/:region/:language/stylingbook/:type/*"
                element={<App />}
              />
            </Routes>
          </YScroll>
        </CustomRouter>
      </DynamicComponent>
    </HelmetProvider>
  </Provider>
);

(window as any).__BUILD_VERSION__ = BUILD_VERSION;
(window as any).__LOCALISATION_VERSION__ = LOCALISATION_VERSION;
(window as any).__UQ_UIKIT_VERSION__ = UQ_UIKIT_VERSION;
(window as any).__PL_UIKIT_VERSION__ = PL_UIKIT_VERSION;
(window as any).__GU_UIKIT_VERSION__ = GU_UIKIT_VERSION;
