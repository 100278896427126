import { OptionsType } from "./stylingConfig";

export type OptionsData = {
  [key: string]: Array<OptionsType>;
};

const CmHeightOptions: OptionsData = {
  women: [
    {
      key: "0",
      value: "",
      lid: "text_styling_book_staff_customer_filter_all"
    },
    {
      key: "001150",
      value: "001150",
      max: "150",
      lid: "text_styling_book_filter_height_under"
    },
    {
      key: "150155",
      value: "150155",
      min: "150",
      max: "155"
    },
    {
      key: "155160",
      value: "155160",
      min: "155",
      max: "160"
    },
    {
      key: "160165",
      value: "160165",
      min: "160",
      max: "165"
    },
    {
      key: "165170",
      value: "165170",
      min: "165",
      max: "170"
    },
    {
      key: "170175",
      value: "170175",
      min: "170",
      max: "175"
    },
    {
      key: "175300",
      value: "175300",
      min: "175",
      lid: "text_styling_book_filter_height_over"
    }
  ],
  men: [
    {
      key: "0",
      value: "",
      lid: "text_styling_book_staff_customer_filter_all"
    },
    {
      key: "001160",
      value: "001160",
      max: "160",
      lid: "text_styling_book_filter_height_under"
    },
    {
      key: "160165",
      value: "160165",
      min: "160",
      max: "165"
    },
    {
      key: "165170",
      value: "165170",
      min: "165",
      max: "170"
    },
    {
      key: "170175",
      value: "170175",
      min: "170",
      max: "175"
    },
    {
      key: "175180",
      value: "175180",
      min: "175",
      max: "180"
    },
    {
      key: "180185",
      value: "180185",
      min: "180",
      max: "185"
    },
    {
      key: "185300",
      value: "185300",
      min: "185",
      lid: "text_styling_book_filter_height_over"
    }
  ],
  kids: [
    {
      key: "0",
      value: "",
      lid: "text_styling_book_staff_customer_filter_all"
    },
    {
      key: "001100",
      value: "001100",
      max: "100",
      lid: "text_styling_book_filter_height_under"
    },
    {
      key: "100110",
      value: "100110",
      min: "100",
      max: "110"
    },
    {
      key: "110120",
      value: "110120",
      min: "110",
      max: "120"
    },
    {
      key: "120130",
      value: "120130",
      min: "120",
      max: "130"
    },
    {
      key: "130140",
      value: "130140",
      min: "130",
      max: "140"
    },
    {
      key: "140150",
      value: "140150",
      min: "140",
      max: "150"
    },
    {
      key: "150160",
      value: "150160",
      min: "150",
      max: "160"
    },
    {
      key: "160300",
      value: "160300",
      min: "160",
      lid: "text_styling_book_filter_height_over"
    }
  ],
  baby: [
    {
      key: "0",
      value: "",
      lid: "text_styling_book_staff_customer_filter_all"
    },
    {
      key: "6070",
      value: "6070",
      min: "60",
      max: "70"
    },
    {
      key: "7080",
      value: "7080",
      min: "70",
      max: "80"
    },
    {
      key: "8090",
      value: "8090",
      min: "80",
      max: "90"
    },
    {
      key: "90100",
      value: "90100",
      min: "90",
      max: "100"
    },
    {
      key: "100110",
      value: "100110",
      min: "100",
      max: "110"
    },
    {
      key: "110300",
      value: "110300",
      min: "110",
      lid: "text_styling_book_filter_height_over"
    }
  ]
};

const InchHeightOptions: OptionsData = {
  women: [
    {
      key: "0",
      value: "",
      lid: "text_styling_book_staff_customer_filter_all"
    },
    {
      key: "0160",
      value: "0160",
      max: "60",
      lid: "text_styling_book_filter_height_under"
    },
    {
      key: "6062",
      value: "6062",
      min: "60",
      max: "62"
    },
    {
      key: "6264",
      value: "6264",
      min: "62",
      max: "64"
    },
    {
      key: "6466",
      value: "6466",
      min: "64",
      max: "66"
    },
    {
      key: "6668",
      value: "6668",
      min: "66",
      max: "68"
    },
    {
      key: "6870",
      value: "6870",
      min: "68",
      max: "70"
    },
    {
      key: "7072",
      value: "7072",
      min: "70",
      max: "72"
    },
    {
      key: "7274",
      value: "7274",
      min: "72",
      max: "74"
    },
    {
      key: "7476",
      value: "7476",
      min: "74",
      max: "76"
    },
    {
      key: "7678",
      value: "7678",
      min: "76",
      max: "78"
    },
    {
      key: "7880",
      value: "7880",
      min: "78",
      max: "80"
    },
    {
      key: "80118",
      value: "80118",
      min: "80",
      lid: "text_styling_book_filter_height_over"
    }
  ],
  men: [
    {
      key: "0",
      value: "",
      lid: "text_styling_book_staff_customer_filter_all"
    },
    {
      key: "0160",
      value: "0160",
      max: "60",
      lid: "text_styling_book_filter_height_under"
    },
    {
      key: "6062",
      value: "6062",
      min: "60",
      max: "62"
    },
    {
      key: "6264",
      value: "6264",
      min: "62",
      max: "64"
    },
    {
      key: "6466",
      value: "6466",
      min: "64",
      max: "66"
    },
    {
      key: "6668",
      value: "6668",
      min: "66",
      max: "68"
    },
    {
      key: "6870",
      value: "6870",
      min: "68",
      max: "70"
    },
    {
      key: "7072",
      value: "7072",
      min: "70",
      max: "72"
    },
    {
      key: "7274",
      value: "7274",
      min: "72",
      max: "74"
    },
    {
      key: "7476",
      value: "7476",
      min: "74",
      max: "76"
    },
    {
      key: "7678",
      value: "7678",
      min: "76",
      max: "78"
    },
    {
      key: "7880",
      value: "7880",
      min: "78",
      max: "80"
    },
    {
      key: "80118",
      value: "80118",
      min: "80",
      lid: "text_styling_book_filter_height_over"
    }
  ],
  kids: [
    {
      key: "0",
      value: "",
      lid: "text_styling_book_staff_customer_filter_all"
    },
    {
      key: "0122",
      value: "0122",
      max: "22",
      lid: "text_styling_book_filter_height_under"
    },
    {
      key: "2226",
      value: "2226",
      min: "22",
      max: "26"
    },
    {
      key: "2630",
      value: "2630",
      min: "26",
      max: "30"
    },
    {
      key: "3034",
      value: "3034",
      min: "30",
      max: "34"
    },
    {
      key: "3438",
      value: "3438",
      min: "34",
      max: "38"
    },
    {
      key: "3842",
      value: "3842",
      min: "38",
      max: "42"
    },
    {
      key: "4246",
      value: "4246",
      min: "42",
      max: "46"
    },
    {
      key: "4650",
      value: "4650",
      min: "46",
      max: "50"
    },
    {
      key: "5054",
      value: "5054",
      min: "50",
      max: "54"
    },
    {
      key: "5458",
      value: "5458",
      min: "54",
      max: "58"
    },
    {
      key: "5862",
      value: "5862",
      min: "58",
      max: "62"
    },
    {
      key: "62118",
      value: "62118",
      min: "62",
      lid: "text_styling_book_filter_height_over"
    }
  ],
  baby: [
    {
      key: "0",
      value: "",
      lid: "text_styling_book_staff_customer_filter_all"
    },
    {
      key: "0122",
      value: "0122",
      max: "22",
      lid: "text_styling_book_filter_height_under"
    },
    {
      key: "2226",
      value: "2226",
      min: "22",
      max: "26"
    },
    {
      key: "2630",
      value: "2630",
      min: "26",
      max: "30"
    },
    {
      key: "3034",
      value: "3034",
      min: "30",
      max: "34"
    },
    {
      key: "3438",
      value: "3438",
      min: "34",
      max: "38"
    },
    {
      key: "3842",
      value: "3842",
      min: "38",
      max: "42"
    },
    {
      key: "4246",
      value: "4246",
      min: "42",
      max: "46"
    },
    {
      key: "4650",
      value: "4650",
      min: "46",
      max: "50"
    },
    {
      key: "5054",
      value: "5054",
      min: "50",
      max: "54"
    },
    {
      key: "5458",
      value: "5458",
      min: "54",
      max: "58"
    },
    {
      key: "5862",
      value: "5862",
      min: "58",
      max: "62"
    },
    {
      key: "62118",
      value: "62118",
      min: "62",
      lid: "text_styling_book_filter_height_over"
    }
  ]
};

export const HeightOptions: { [key: string]: OptionsData } = {
  "ja-jp": { ...CmHeightOptions },
  "en-us": { ...InchHeightOptions },
  "en-uk": { ...InchHeightOptions },
  "en-eu": { ...CmHeightOptions },
  "ko-kr": { ...CmHeightOptions }
};
