import axios from "axios";
import axiosRetry from "axios-retry";
import { FETCH_TIMEOUT_MILLIS, SB_ENV } from "@config/common";
import {
  reqErrHandler,
  reqHandler,
  resErrHandler,
  resHandler
} from "@util/net/interceptors";
import store from "../../redux/store";

const relativePath = () => {
  const { region, language } = store.getState().config;
  return `/${region}/api/commerce/v5/${language}`;
};

const relativeConfig = (config?: any) => {
  const { region, brand_name } = store.getState().config;
  return {
    ...config,
    headers: { "x-fr-clientid": SB_ENV(brand_name, region).clientId }
  };
};

const instance = axios.create({
  timeout: FETCH_TIMEOUT_MILLIS
});

axiosRetry(instance, {
  retries: 2,
  retryDelay: (retryCount) => retryCount * 1000
});

instance.interceptors.request.use(reqHandler, reqErrHandler);
instance.interceptors.response.use(resHandler, resErrHandler);

const get = async (url: string, config?: any) => {
  const response = await instance.get(
    `${relativePath()}${url}`,
    relativeConfig(config)
  );
  return response;
};

const post = async (url: string, payload?: any, config?: any) => {
  const response = await instance.post(
    `${relativePath()}${url}`,
    payload,
    relativeConfig(config)
  );
  return response;
};

const del = async (url: string, config?: any) => {
  const response = await instance.delete(
    `${relativePath()}${url}`,
    relativeConfig(config)
  );
  return response;
};

export default {
  get,
  post,
  del
};
