import { ActionsUnion, createAction } from "@redux/helpers";
import {
  DetailFromPageEnum,
  DetailStylingData,
  DetailStylingPayload,
  ImageInfo,
  StylingDetailType
} from "@type/index";

export const GET_DETAIL_STYLING = "[detail styling] get detail styling request";
const GET_DETAIL_STYLING_SUCCESS =
  "[detail styling] get detail styling success";
const GET_DETAIL_STYLING_LOADING =
  "[detail styling] get detail styling loading";
const SET_CAROUSEL_LIST = "[detail styling] set detail page carousel list";
const INIT_CAROUSEL_LIST = "[detail styling] init detail page carousel list";
const SET_FROM_PAGE_NAME = "[detail styling] set from page name";
const INIT_DETAIL_STYLING = "[detail styling] init detail styling";
const SET_PREVIOUS_DETAIL_STYLING =
  "[detail styling] set previous detail styling data";
const SET_ERROR_CODE = "[detail styling] set error code";
const SET_CAROUSEL_IMG_WISH_COUNT =
  "[detail styling] set carousel styling wish count";
const INIT_CAROUSEL_IMG_WISH_COUNT =
  "[detail styling] init carousel styling wish count";
const GET_CAROUSEL_IMG_WISH_COUNT_LOADING =
  "[detail styling] get carousel styling wish count loading";

type CarouselDataType = {
  list: Array<ImageInfo>;
  index: number;
};

export const Actions = {
  getDetailStyling: (payload: DetailStylingPayload) =>
    createAction(GET_DETAIL_STYLING, payload),
  getDetailStylingSuccess: (payload: StylingDetailType) =>
    createAction(GET_DETAIL_STYLING_SUCCESS, payload),
  getDetailStylingLoading: (payload: boolean) =>
    createAction(GET_DETAIL_STYLING_LOADING, payload),
  setCarouselList: (paylaod: CarouselDataType) =>
    createAction(SET_CAROUSEL_LIST, paylaod),
  initCarouselList: () => createAction(INIT_CAROUSEL_LIST),
  setFromPageName: (paylaod: string) =>
    createAction(SET_FROM_PAGE_NAME, paylaod),
  initDetailStyling: () => createAction(INIT_DETAIL_STYLING),
  setPreviousDetailStyling: (payload: string) =>
    createAction(SET_PREVIOUS_DETAIL_STYLING, payload),
  setDetailStylingErrorCode: (payload: string) =>
    createAction(SET_ERROR_CODE, payload),
  setCarouselImgWishCount: (payload: { type: string }) =>
    createAction(SET_CAROUSEL_IMG_WISH_COUNT, payload),
  initCarouselImgWishCount: (payload: number) =>
    createAction(INIT_CAROUSEL_IMG_WISH_COUNT, payload),
  getCarouselImgWishCountLoading: (payload: boolean) =>
    createAction(GET_CAROUSEL_IMG_WISH_COUNT_LOADING, payload)
};

type ActionType = ActionsUnion<typeof Actions>;

export type State = DetailStylingData;

const initialState = {
  detailInfo: {
    styleImageUrl: "",
    hashtags: [],
    models: []
  },
  loading: true,
  carouselList: [],
  carouselIndex: 0,
  fromPage: "",
  previousProductIds: "",
  errorCode: "",
  likesCount: 0,
  likesCountLoading: true
};

export default (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case GET_DETAIL_STYLING_SUCCESS:
      return {
        ...state,
        detailInfo: {
          ...action.payload
        },
        loading: false
      };
    case GET_DETAIL_STYLING_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SET_CAROUSEL_LIST:
      return {
        ...state,
        fromPage: DetailFromPageEnum.top,
        carouselList: [...action.payload.list],
        carouselIndex: action.payload.index,
        loading: true
      };
    case INIT_CAROUSEL_LIST:
      return {
        ...state,
        carouselList: [],
        carouselIndex: 0
      };
    case SET_FROM_PAGE_NAME:
      return {
        ...state,
        fromPage: action.payload
      };
    case INIT_DETAIL_STYLING:
      return {
        ...state,
        detailInfo: {
          styleImageUrl: "",
          hashtags: [],
          models: []
        },
        errorCode: "",
        likesCount: 0,
        likesCountLoading: true
      };
    case SET_PREVIOUS_DETAIL_STYLING:
      return {
        ...state,
        previousProductIds: action.payload
      };
    case SET_ERROR_CODE:
      return {
        ...state,
        errorCode: action.payload,
        loading: false
      };
    case SET_CAROUSEL_IMG_WISH_COUNT: {
      const { type } = action.payload;
      let count = state.likesCount;
      if (type === "post") {
        count += 1;
      } else if (count > 0) {
        count -= 1;
      }
      return {
        ...state,
        likesCount: count
      };
    }
    case INIT_CAROUSEL_IMG_WISH_COUNT:
      return {
        ...state,
        likesCount: action.payload,
        likesCountLoading: false
      };
    case GET_CAROUSEL_IMG_WISH_COUNT_LOADING:
      return {
        ...state,
        likesCountLoading: action.payload
      };
    default:
      return state;
  }
};
