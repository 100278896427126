import React from "react";
import { Body, GutterContainer, LinkText, Spacer } from "@common/DynamicUikit";
import { useTranslation } from "react-i18next";
import { getEcRenewalCompatibility } from "@util/helpers";

type IProps = {
  handleClick: () => void;
};

const NoResults = (props: IProps) => {
  const { handleClick } = props;
  const { t } = useTranslation();
  return (
    <GutterContainer style={{ height: "100vh" }}>
      <Spacer element="div" variant="spacing-05" />
      <Body element="p" className="fr-ec-mb-spacing-04">
        {t("text_styling_book_no_results")}
      </Body>
      <LinkText
        element="button"
        onClick={handleClick}
        ecRenewal={getEcRenewalCompatibility()}
      >
        {t("text_styling_book_clear_all_filters")}
      </LinkText>
    </GutterContainer>
  );
};
export default NoResults;
