import { CmsContentsCard, CmsImage, CmsLink } from "@common/DynamicUikit";
import { ComponentType } from "react";

export type CMSExportsType = {
  CmsContentsCard: ComponentType<any>;
  CmsImage: ComponentType<any>;
  CmsLink: ComponentType<any>;
};

const CMSComponentsExport: CMSExportsType = {
  CmsContentsCard,
  CmsImage,
  CmsLink
};

export default CMSComponentsExport;
