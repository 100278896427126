import { call, put, takeLatest } from "redux-saga/effects";
import { DetailStylingActions, GET_DETAIL_STYLING } from "@redux/reducer";
import { DetailStylingPayload, StylingDetailResponse } from "@type/index";
import request from "@util/net/request";
import axios, { AxiosResponse } from "axios";

export function* getDetailStylingSaga(action: {
  payload: DetailStylingPayload;
}) {
  try {
    const payload: DetailStylingPayload = action.payload;
    yield put(DetailStylingActions.setDetailStylingErrorCode(""));
    yield put(DetailStylingActions.getDetailStylingLoading(true));
    const res: AxiosResponse<StylingDetailResponse> = yield call(
      request.get,
      `/styles/${payload.styleId}/details`,
      {
        params: {
          type: payload.type,
          imageRatio: payload.imageRatio
        }
      }
    );
    if (res.data.status === "ok") {
      if (res.data.result.styleImageUrl === "") {
        yield put(DetailStylingActions.setDetailStylingErrorCode("404"));
      } else {
        yield put(
          DetailStylingActions.getDetailStylingSuccess(res.data.result)
        );
        if (payload.type === "sh") {
          yield put(
            DetailStylingActions.initCarouselImgWishCount(
              res.data.result.last31DaysLikes || 0
            )
          );
        }
      }
    } else if (
      res.data.status === "nok" &&
      res.data.error &&
      res.data.error.httpStatusCode === 404
    ) {
      yield put(DetailStylingActions.setDetailStylingErrorCode("404"));
    } else {
      yield put(DetailStylingActions.setDetailStylingErrorCode("503"));
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      yield put(DetailStylingActions.setDetailStylingErrorCode("503"));
    }
  }
}

export function* watchLoadDetailStylingSaga() {
  yield takeLatest<any>(GET_DETAIL_STYLING, getDetailStylingSaga);
}
