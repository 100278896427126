import React from "react";
import { Display, GutterContainer, LinkText } from "@common/DynamicUikit";
import { useTranslation } from "react-i18next";
import { generatePath, useParams } from "react-router-dom";
import { RouterUrlType } from "@type/index";

type IProps = {
  text: string;
  type: string;
};

const NotFound = (props: IProps) => {
  const { t } = useTranslation();
  const params = useParams();

  return (
    <GutterContainer>
      <Display element="p" variant="display3" className="fr-ec-mb-spacing-04">
        {t("text_404")}
      </Display>
      <LinkText
        element="a"
        href={generatePath(
          `/:region/:language/stylingbook/${props.type}/women`,
          {
            ...params
          } as RouterUrlType
        )}
        variant="standalone-secondary"
      >
        {props.text}
      </LinkText>
    </GutterContainer>
  );
};
export default NotFound;
