import { call, put, takeLatest } from "redux-saga/effects";
import request from "@util/net/request";
import axios, { AxiosResponse } from "axios";
import {
  GET_STYLING_STYLEHINT_LARGE_IMAGE_LIST,
  GET_STYLING_STYLEHINT_LIST,
  StylehintListActions
} from "@redux/reducer";
import {
  LargeStylehintStylingPayload,
  LargeStylehintStylingResponse,
  STYLING_SORT_BY,
  StylehintListPayload,
  StylehintListResponse
} from "@type/index";

export function* loadStylehintListSaga(action: {
  payload: StylehintListPayload;
}) {
  try {
    const payload: StylehintListPayload = action.payload;
    if (payload.order === STYLING_SORT_BY.PUBLISHED_AT_DESC) {
      payload.sortByPriority = true;
    }
    yield put(StylehintListActions.setStylehintListErrorCode(""));
    yield put(StylehintListActions.getStylingStylehintListLoading(true));
    const res: AxiosResponse<StylehintListResponse> = yield call(
      request.get,
      "/styles/images/sh/normal",
      {
        params: { ...payload }
      }
    );
    if (res.data.status === "nok") {
      yield put(StylehintListActions.setStylehintListErrorCode("503"));
    } else {
      yield put(
        StylehintListActions.getStylingStylehintListSuccess(res.data.result)
      );
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      yield put(StylehintListActions.setStylehintListErrorCode("503"));
    }
  }
}

export function* loadStylehintLargeListSaga(action: {
  payload: LargeStylehintStylingPayload;
}) {
  try {
    const payload: LargeStylehintStylingPayload = action.payload;
    yield put(StylehintListActions.getStylehintLargeImageListLoading(true));
    const res: AxiosResponse<LargeStylehintStylingResponse> = yield call(
      request.get,
      "/styles/images/sh/large",
      {
        params: { ...payload }
      }
    );
    yield put(
      StylehintListActions.getStylehintLargeImageListSuccess(res.data.result)
    );
  } catch (error) {
    yield put(StylehintListActions.getStylehintLargeImageListLoading(false));
  }
}

export function* watchLoadStylingListSaga() {
  yield takeLatest<any>(GET_STYLING_STYLEHINT_LIST, loadStylehintListSaga);
  yield takeLatest<any>(
    GET_STYLING_STYLEHINT_LARGE_IMAGE_LIST,
    loadStylehintLargeListSaga
  );
}
