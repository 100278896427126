import { ActionsUnion, createAction } from "@redux/helpers";
import {
  CMSComponentSchema,
  CMSPayloadType,
  CMSSuccessType
} from "@type/index";

export const GET_CMS_BANNER = "[top page] get cms banner";
export const SET_CMS_BANNER = "[top page] set cms banner";

export const Actions = {
  getCmsBanner: (payload: CMSPayloadType) =>
    createAction(GET_CMS_BANNER, payload),
  setCmsBanner: (payload: CMSSuccessType) =>
    createAction(SET_CMS_BANNER, payload)
};

type ActionType = ActionsUnion<typeof Actions>;

export type State = {
  [key: string]: Array<CMSComponentSchema>;
};

const intialState: State = {};

export default (state: State = intialState, action: ActionType): State => {
  switch (action.type) {
    case SET_CMS_BANNER: {
      const { content, path } = action.payload;
      return {
        ...state,
        [path]: content
      };
    }
    default:
      return state;
  }
};
