import { COUNTRY, HrefLangListType } from "@type/index";
import { convertRegionName } from "@util/country";
import { findStrIndex } from "@util/helpers";
import store from "../../redux/store";

export const getHrefLangList = (endPath: string): HrefLangListType[] => {
  const { region, language } = store.getState().config;
  const _index = findStrIndex(endPath, "/", 2);
  const _path = endPath.slice(_index + 1);
  let hrefLang: HrefLangListType[] = [];
  switch (region) {
    case COUNTRY.FR:
      hrefLang = [
        { hrefLang: "fr-fr", alternateHref: ["fr", "fr", _path].join("/") },
        { hrefLang: "en-fr", alternateHref: ["fr", "en", _path].join("/") }
      ];
      break;
    case COUNTRY.DE:
      hrefLang = [
        { hrefLang: "de-de", alternateHref: ["de", "de", _path].join("/") },
        { hrefLang: "en-de", alternateHref: ["de", "en", _path].join("/") }
      ];
      break;
    case COUNTRY.ES:
      hrefLang = [
        { hrefLang: "es-es", alternateHref: ["es", "es", _path].join("/") },
        { hrefLang: "en-es", alternateHref: ["es", "en", _path].join("/") }
      ];
      break;
    case COUNTRY.IT:
      hrefLang = [
        { hrefLang: "it-it", alternateHref: ["it", "it", _path].join("/") },
        { hrefLang: "en-it", alternateHref: ["it", "en", _path].join("/") }
      ];
      break;
    case COUNTRY.NL:
      hrefLang = [
        { hrefLang: "nl-nl", alternateHref: ["nl", "nl", _path].join("/") },
        { hrefLang: "en-nl", alternateHref: ["nl", "en", _path].join("/") }
      ];
      break;
    case COUNTRY.BE:
      hrefLang = [
        { hrefLang: "fr-be", alternateHref: ["be", "fr", _path].join("/") },
        { hrefLang: "nl-be", alternateHref: ["be", "nl", _path].join("/") },
        { hrefLang: "en-be", alternateHref: ["be", "en", _path].join("/") }
      ];
      break;
    default:
      hrefLang = [
        {
          hrefLang: `${language}-${convertRegionName(region)}`,
          alternateHref: [region, language, _path].join("/")
        }
      ];
      break;
  }
  return hrefLang;
};
