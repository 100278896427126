import { call, put, takeLatest } from "redux-saga/effects";
import { UserProfileActions, GET_USER_PROFILE } from "@redux/reducer";
import {
  STYLING_SORT_BY,
  UserProfilePayload,
  UserProfileResponse
} from "@type/index";
import request from "@util/net/request";
import axios, { AxiosResponse } from "axios";

export function* getUserProfileSaga(action: { payload: UserProfilePayload }) {
  try {
    const payload: UserProfilePayload = action.payload;
    yield put(UserProfileActions.setUserProfileNotFound(""));
    yield put(UserProfileActions.getUserProfileLoading(true));
    const res: AxiosResponse<UserProfileResponse> = yield call(
      request.get,
      `/styles/users/${payload.userId}`,
      {
        params: {
          offset: payload.offset,
          limit: payload.limit,
          order: STYLING_SORT_BY.PUBLISHED_AT_DESC
        }
      }
    );
    if (res.data.status === "nok") {
      yield put(UserProfileActions.setUserProfileNotFound("503"));
    } else if (res.data.result.userInfo?.name === "") {
      yield put(UserProfileActions.setUserProfileNotFound("404"));
    } else {
      yield put(UserProfileActions.getUserProfileSuccess(res.data.result));
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      yield put(UserProfileActions.setUserProfileNotFound("503"));
    }
  }
}

export function* watchLoadUserProfileSaga() {
  yield takeLatest<any>(GET_USER_PROFILE, getUserProfileSaga);
}
