import { ActionsUnion, createAction } from "@redux/helpers";
import {
  StylehintListData,
  StylehintListPayload,
  LargeStylehintStylingPayload,
  SHlargeImages,
  StylehintListType,
  StylingSortEnum,
  StylesInfo
} from "@type/index";

export const GET_STYLING_STYLEHINT_LIST =
  "[styling list] get styling stylehint list request";
const GET_STYLING_STYLEHINT_LIST_SUCCESS =
  "[styling list] get styling stylehint list success";
const GET_STYLING_STYLEHINT_LIST_LOADING =
  "[styling list] get styling stylehint list loading";
export const GET_STYLING_STYLEHINT_LARGE_IMAGE_LIST =
  "[styling list] get styling stylehint large image list request";
const GET_STYLING_STYLEHINT_LARGE_IMAGE_LIST_SUCCESS =
  "[styling list] get styling stylehint large image list success";
const GET_STYLING_STYLEHINT_LARGE_IMAGE_LIST_LOADING =
  "[styling list] get styling stylehint large image list loading";
const INIT_STYLEHINT_LISTS = "[styling list] init styling stylehint all lists";
const SET_STYLEHINT_LIST_ERROR_CODE =
  "[styling list] set styling stylehint lists error code";
const SET_STYLEHINT_CURRENT_SORT =
  "[styling list] set styling stylehint current sort";
const SET_SH_STYLING_LIST =
  "[styling list] set stylehint styling list contain favorites";

export const Actions = {
  getStylingStylehintList: (payload: StylehintListPayload) =>
    createAction(GET_STYLING_STYLEHINT_LIST, payload),
  getStylingStylehintListSuccess: (payload: StylehintListType) =>
    createAction(GET_STYLING_STYLEHINT_LIST_SUCCESS, payload),
  getStylingStylehintListLoading: (payload: boolean) =>
    createAction(GET_STYLING_STYLEHINT_LIST_LOADING, payload),
  getStylehintLargeImageList: (payload: LargeStylehintStylingPayload) =>
    createAction(GET_STYLING_STYLEHINT_LARGE_IMAGE_LIST, payload),
  getStylehintLargeImageListSuccess: (payload: Array<SHlargeImages>) =>
    createAction(GET_STYLING_STYLEHINT_LARGE_IMAGE_LIST_SUCCESS, payload),
  getStylehintLargeImageListLoading: (payload: boolean) =>
    createAction(GET_STYLING_STYLEHINT_LARGE_IMAGE_LIST_LOADING, payload),
  initStylehintLists: (payload?: boolean) =>
    createAction(INIT_STYLEHINT_LISTS, payload), // default payload = false -> init all data, payload = true -> only init hasMore
  setStylehintListErrorCode: (payload: string) =>
    createAction(SET_STYLEHINT_LIST_ERROR_CODE, payload),
  setStylehintCurrentSort: (payload: StylingSortEnum) =>
    createAction(SET_STYLEHINT_CURRENT_SORT, payload),
  setSHStylingList: (payload: Array<StylesInfo>) =>
    createAction(SET_SH_STYLING_LIST, payload)
};

type ActionType = ActionsUnion<typeof Actions>;

export type State = StylehintListData;

const initialState = {
  loading: true,
  stylehint_list: {
    pagination: {
      count: 0,
      total: 0,
      offset: 0
    },
    images: []
  },
  large_loading: true,
  stylehint_large_list: [],
  hasMore: false,
  current_list: [],
  noResult: false,
  errorCode: "",
  currentSort: StylingSortEnum.latest,
  hasClickSort: false,
  SH_list: []
};

export default (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case GET_STYLING_STYLEHINT_LIST_SUCCESS: {
      const _total = action.payload.pagination.total || 0;
      const _count = action.payload.pagination.count || 0;
      const _offset = action.payload.pagination.offset || 0;

      return {
        ...state,
        stylehint_list: {
          pagination: { ...action.payload.pagination },
          images:
            action.payload.pagination.offset === 0
              ? [...action.payload.images]
              : [...state.stylehint_list.images, ...action.payload.images]
        },
        hasMore: _count + _offset < _total,
        loading: false,
        current_list: [...action.payload.images],
        noResult: !action.payload.pagination.total
      };
    }
    case GET_STYLING_STYLEHINT_LIST_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case GET_STYLING_STYLEHINT_LARGE_IMAGE_LIST_SUCCESS:
      return {
        ...state,
        stylehint_large_list: [...action.payload],
        large_loading: false
      };
    case GET_STYLING_STYLEHINT_LARGE_IMAGE_LIST_LOADING:
      return {
        ...state,
        large_loading: action.payload
      };
    case INIT_STYLEHINT_LISTS: {
      if (action.payload) {
        return {
          ...state,
          hasMore: false,
          noResult: false
        };
      } else {
        return {
          ...state,
          ...initialState
        };
      }
    }
    case SET_STYLEHINT_LIST_ERROR_CODE:
      return {
        ...state,
        errorCode: action.payload,
        loading: false
      };
    case SET_STYLEHINT_CURRENT_SORT:
      return {
        ...state,
        currentSort: action.payload,
        hasClickSort: true
      };
    case SET_SH_STYLING_LIST:
      return {
        ...state,
        SH_list: [...action.payload]
      };
    default:
      return state;
  }
};
