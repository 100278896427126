import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
  Link,
  useNavigationType
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CombinedReducersType,
  ConfigActions,
  DetailStylingActions,
  FavoritesStylingActions,
  OfficialListActions,
  StylehintListActions,
  StylingFilterActions
} from "@redux/reducer";
import InfiniteScroll from "react-infinite-scroll-component";
import { Helmet } from "react-helmet-async";
import {
  Accordion,
  Body,
  Breadcrumb,
  BreadcrumbGroup,
  ButtonToggle,
  Checkbox,
  CheckboxGroup,
  ContentAlignment,
  FilterLayout,
  Image,
  ImageEnhancements,
  ImageOverlay,
  Layout,
  LayoutWrapper,
  LinkNavigation,
  LinkNavigationGroup,
  Modal,
  RadioGroup,
  Radio,
  Spacer,
  StylingGrid,
  Tab,
  TabGroup,
  TagFilter,
  TagFilterGroup,
  TemplateILP,
  Tile,
  Title,
  UtilityBar,
  ButtonLayout,
  Button,
  LoadingIndicator,
  GutterContainer,
  HeaderPage,
  Caption,
  StickyContainer
} from "@common/DynamicUikit";
import {
  COUNTRY,
  GenderOptionType,
  PageType,
  ProductColors,
  RouterUrlType,
  SBlargeImages,
  SBNormalImages,
  SHlargeImages,
  SHNormalImages,
  StyleGenderEnum,
  StylehintListPayload,
  StylesInfo,
  StylingSortEnum,
  StylingType
} from "@type/index";
import { SB_ENV, TRACK_EVENT, TRACK_EVENT_SEARCH } from "@config/common";
import {
  formatTotalNum,
  gaGenderValue,
  getEcRenewalCompatibility,
  getIsLoggedIn,
  isNotEmptyObj,
  preventBubbling
} from "@util/helpers";
import { getHrefLangList } from "@util/hreflang";
import {
  sendTrackEvent,
  trackPageView,
  transformBrandForGoogleAnalytics
} from "@util/analytics/utils";
import { getPageViewBaseOption } from "@util/analytics";
import { isEUMinorCountry, isGuKids } from "@util/country";
import { isGUNativeApp } from "@util/nativeApp";
import {
  NoResults,
  LoginModal,
  SystemError,
  ErrorModal,
  GoTop,
  StyleHintBanner
} from "../../common";
import {
  AccordionObjType,
  accordionOfficialObj,
  accordionStylehintObj,
  colorOptions,
  ColorOptionType,
  defaultOrderRouterParam,
  FilterOptionType,
  filterRouteList,
  filterRouteObj,
  FilterRouteObjType,
  FilterRouteType,
  FilterRouteTypes,
  FilterTagType,
  FilterType,
  findCategory,
  findGender,
  fuseIsFavoriteFlag,
  getGenderFromUrl,
  getOfficialGa,
  getOfficialList,
  getOfficialListParams,
  getStylehintGa,
  getStylehintList,
  getStylehintListParams,
  handleHasFilters,
  handleHeightLabel,
  handleOrderRouterParam,
  isShowWishIcon,
  mapCategoryKeyToName,
  mapCategoryListToOptions,
  mapCoordinationListToOptions,
  mapFeatureListToOptions,
  mapHashtagListToOptions,
  mapOfficialList,
  mapStylehintList,
  OptionsType,
  postOrDeleteFavoritesStyling,
  RouteParamType,
  routerQuery,
  SelectedData,
  useDebounce
} from "./stylingConfig";
import { HeightOptions } from "./heightOptions";

const TopStyling = () => {
  const dispatch = useDispatch();
  const config = useSelector((store: CombinedReducersType) => store.config);
  const filtersData = useSelector(
    (state: CombinedReducersType) => state.stylingFilters
  );
  const stylehintListData = useSelector(
    (state: CombinedReducersType) => state.stylehintList
  );
  const officialListData = useSelector(
    (state: CombinedReducersType) => state.officialList
  );
  const favoritesSBStylingList = useSelector(
    (store: CombinedReducersType) => store.favoritesStyling.stylingSBWishlist
  );
  const favoritesSHStylingList = useSelector(
    (store: CombinedReducersType) => store.favoritesStyling.stylingSHWishlist
  );
  const favoritesStylingLoading = useSelector(
    (store: CombinedReducersType) => store.favoritesStyling.stylingLoading
  );
  const { type = "" } = useParams();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const useType = useNavigationType();
  const { t } = useTranslation();
  const [gender, setGender] = useState<GenderOptionType>({
    key: "women",
    value: StyleGenderEnum.WOMEN,
    code: 2,
    lid: "text_styling_book_meta_women",
    displayLid: "text_women"
  });
  const [genderLID, setGenderLID] = useState<string>("");
  const [isToggled, setIsToggled] = useState(false); // filter button toggle - sp and tab modal open
  const [featureOptions, setFeatureOptions] = useState<Array<FilterOptionType>>(
    []
  ); // official filter - feature options
  const [coordinationOptions, setCoordinationOptions] = useState<
    Array<FilterOptionType>
  >([]); // official filter - coordination options
  const [hashtagOptions, setHashtagOptions] = useState<Array<FilterOptionType>>(
    []
  ); // stylehint filter - hashtag options
  const [modelOptions, setModelOptions] = useState<Array<OptionsType>>([]); // stylehint filter - model options
  const [heightOptions, setHeightOptions] = useState<Array<OptionsType>>([]); // stylehint filter - height options
  const [categoryOptions, setCategoryOptions] = useState<
    Array<FilterOptionType>
  >([]);
  const categoryTrees = useRef<{
    classes: Array<FilterOptionType>;
    categories: Array<FilterOptionType>;
    subcategories: Array<FilterOptionType>;
  }>({
    classes: [],
    categories: [],
    subcategories: []
  });
  const [categoryTreeLevel, setCategoryTreeLevel] = useState(1);
  const [categoryKey, setCategoryKey] = useState<Array<number>>([]);
  const [expandedAccordionOfficial, setExpandedAccordionOfficial] =
    useState<AccordionObjType>(accordionOfficialObj);
  const [expandedAccordionStylehint, setExpandedAccordionStylehint] =
    useState<AccordionObjType>(accordionStylehintObj);
  const [selectedOfficialFilter, setSelectedOfficialFilter] = useState<
    Array<FilterTagType>
  >([]);
  const [selectedStylehintFilter, setSelectedStylehintFilter] = useState<
    Array<FilterTagType>
  >([]);
  const [currentRouteFilter, setCurrentRouteFilter] =
    useState<FilterRouteObjType>(filterRouteObj);
  const [filterTagData, setFilterTagData] =
    useState<FilterRouteObjType>(filterRouteObj);
  const [largeStylehintImages, setLargeStylehintImages] = useState<
    Array<SHlargeImages>
  >([]); // stylehint large images
  const [largeOfficialImages, setLargeOfficialImages] = useState<
    Array<SBlargeImages>
  >([]); // official large images
  const [stylingList, setStylingList] = useState<Array<StylesInfo>>([]); // official and stylehint normal images
  const currentStylehintParams = useRef<StylehintListPayload>({});
  const [hasChangeFilter, setHasChangeFilter] = useState<boolean>(false);
  const [hasChangeSort, setHasChangeSort] = useState<boolean>(false);
  const [initExpandObj, setInitExpandObj] = useState<AccordionObjType>({});
  const metaTitle = useRef("");
  const metaDescription = useRef("");
  const currentQuery = useRef("");
  const hasReqOfficialNormal = useRef(false);
  const hasReqStylehintNormal = useRef(false);
  const isStillOnTopPage = useRef(false); // Record multiple fallbacks on the same page - in order to fix goback logic
  const genderList = useRef<Array<GenderOptionType>>([]);
  const hasClickFilterModal = useRef(false); // in order to fix safari click filter not go top
  const hasFetchFilter = useRef(false); // in order to fix the request is not triggered when the route is unchanged
  const isShowSystemError = useRef<boolean>(false); // show system error -> 503 page
  const isClickLargeImage = useRef<boolean>(false); // whether click large image(true) or click filter checkbox(false)

  // init gender list from firebase
  useEffect(() => {
    genderList.current =
      type === StylingType.official
        ? config.sb_gender_list
        : config.sh_gender_list;
  }, [config.sb_gender_list, config.sh_gender_list, type]);

  // get styling filters
  const getStylingFiltersList = (id: string, genderKey: string) => {
    let hasData = false;
    if (id === StylingType.official) {
      hasData =
        filtersData.featureList.length > 0 ||
        filtersData.categoryList.length > 0 ||
        filtersData.coordinationList.length > 0;
    } else {
      hasData =
        filtersData.hashtagList.length > 0 ||
        filtersData.categoryList.length > 0;
    }
    if (!hasData || isStillOnTopPage.current) {
      dispatch(
        StylingFilterActions.getStylingFilters({
          type: id === StylingType.official ? "sb" : "sh",
          departmentId: isGuKids(config.brand_name, genderKey.toString()),
          rootId:
            id === StylingType.official
              ? SB_ENV(config.brand_name, config.region).sbRootId
              : SB_ENV(config.brand_name, config.region).shRootId
        })
      );
    }
  };

  // get styling large images list
  const getStylingLargeList = (id: string, obj: GenderOptionType) => {
    if (id === StylingType.stylehint) {
      if (
        stylehintListData.stylehint_large_list.length === 0 ||
        isStillOnTopPage.current
      ) {
        dispatch(
          StylehintListActions.getStylehintLargeImageList({
            genderCode: isGuKids(config.brand_name, "", obj.code.toString())
          })
        );
      }
    } else if (
      officialListData.official_large_list.length === 0 ||
      isStillOnTopPage.current
    ) {
      dispatch(
        OfficialListActions.getOfficialLargeImageList({
          departmentIds: isGuKids(config.brand_name, obj.key.toString())
        })
      );
    }
  };

  // init large images list
  useEffect(() => {
    if (type === StylingType.stylehint) {
      setLargeStylehintImages([...stylehintListData.stylehint_large_list]);
    } else {
      setLargeOfficialImages([...officialListData.official_large_list]);
    }
  }, [
    officialListData.official_large_list,
    stylehintListData.stylehint_large_list,
    type
  ]);

  // init styling normal images list
  useEffect(() => {
    if (
      type === StylingType.stylehint &&
      !stylehintListData.loading &&
      !stylehintListData.large_loading
    ) {
      const list = [
        ...mapStylehintList(stylehintListData.stylehint_list.images)
      ];
      setStylingList([...fuseIsFavoriteFlag(list, favoritesSHStylingList)]);
    } else if (
      type === StylingType.official &&
      !officialListData.loading &&
      !officialListData.large_loading
    ) {
      const list = [...mapOfficialList(officialListData.official_list.styles)];
      setStylingList([...fuseIsFavoriteFlag(list, favoritesSBStylingList)]);
    }
  }, [
    favoritesSBStylingList,
    favoritesSHStylingList,
    officialListData.large_loading,
    officialListData.loading,
    officialListData.official_list.styles,
    stylehintListData.large_loading,
    stylehintListData.loading,
    stylehintListData.stylehint_list.images,
    type
  ]);

  // update official styling list on store
  useEffect(() => {
    if (type === StylingType.official) {
      const list = [...mapOfficialList(officialListData.official_list.styles)];
      const arr = [...fuseIsFavoriteFlag(list, favoritesSBStylingList)];
      dispatch(OfficialListActions.setSBStylingList(arr));
    } else {
      const list = [
        ...mapStylehintList(stylehintListData.stylehint_list.images)
      ];
      const arr = [...fuseIsFavoriteFlag(list, favoritesSHStylingList)];
      dispatch(StylehintListActions.setSHStylingList(arr));
    }
  }, [
    dispatch,
    favoritesSBStylingList,
    favoritesSHStylingList,
    officialListData.official_list.styles,
    stylehintListData.stylehint_list.images,
    type
  ]);

  // error handling - official normal system error
  useEffect(() => {
    if (type === StylingType.official && officialListData.errorCode === "503") {
      setStylingList([...officialListData.SB_list]);
    }
  }, [officialListData.SB_list, officialListData.errorCode, type]);

  // error handling - stylehint normal system error
  useEffect(() => {
    if (
      type === StylingType.stylehint &&
      stylehintListData.errorCode === "503"
    ) {
      setStylingList([...stylehintListData.SH_list]);
    }
  }, [stylehintListData.SH_list, stylehintListData.errorCode, type]);

  // get favorites official styling list
  useEffect(() => {
    if (
      type === StylingType.official &&
      officialListData.current_list.length > 0 &&
      hasReqOfficialNormal.current
    ) {
      let arr: Array<string> = [];
      arr = officialListData.current_list.map(
        (item: SBNormalImages) => item.styleId
      );
      dispatch(
        FavoritesStylingActions.getFavoritesStyling({
          ids: arr.join(","),
          type: "sb"
        })
      );
    }
  }, [dispatch, officialListData.current_list, type]);

  // get favorites stylehint styling list
  useEffect(() => {
    if (
      type === StylingType.stylehint &&
      stylehintListData.current_list.length > 0 &&
      hasReqStylehintNormal.current
    ) {
      let arr: Array<string> = [];
      arr = stylehintListData.current_list.map(
        (item: SHNormalImages) => item.outfitId?.toString() || ""
      );
      dispatch(
        FavoritesStylingActions.getFavoritesStyling({
          ids: arr.join(","),
          type: "sh"
        })
      );
    }
  }, [dispatch, params.region, stylehintListData.current_list, type]);

  // init category options
  useEffect(() => {
    let arr: Array<FilterOptionType> = [];
    categoryTrees.current.classes = mapCategoryListToOptions(
      filtersData.categoryList
    );
    if (categoryKey[0]) {
      const obj = findCategory(
        categoryTrees.current.classes,
        categoryKey[0].toString()
      );
      if (!!obj && obj.children && obj.children.length > 0) {
        categoryTrees.current.categories = mapCategoryListToOptions(
          obj.children
        );
      } else {
        categoryTrees.current.categories = [
          {
            key: "0",
            value: t("text_filter_clear_all_category")
          }
        ];
      }
    }
    if (categoryKey[1]) {
      const obj = findCategory(
        categoryTrees.current.categories,
        categoryKey[1].toString()
      );
      if (!!obj && obj.children && obj.children.length > 0) {
        categoryTrees.current.subcategories = mapCategoryListToOptions(
          obj.children
        );
      } else {
        categoryTrees.current.subcategories = [
          {
            key: "0",
            value: t("text_filter_clear_all_category")
          }
        ];
      }
    }
    if (categoryTreeLevel === 1) {
      arr = categoryTrees.current.classes;
    } else if (categoryTreeLevel === 2) {
      arr = categoryTrees.current.categories;
    } else if (categoryTreeLevel === 3) {
      arr = categoryTrees.current.subcategories;
    } else {
      arr.push({
        key: "0",
        value: t("text_filter_clear_all_category")
      });
    }
    setCategoryOptions([...arr]);
  }, [categoryKey, categoryTreeLevel, filtersData.categoryList, t]);

  // init official filter - feature options
  useEffect(() => {
    if (filtersData.featureList.length > 0) {
      setFeatureOptions(mapFeatureListToOptions(filtersData.featureList));
    } else {
      setFeatureOptions([]);
    }
  }, [filtersData.featureList]);

  // init official filter - coordination options
  useEffect(() => {
    if (filtersData.coordinationList.length > 0) {
      setCoordinationOptions(
        mapCoordinationListToOptions(filtersData.coordinationList.slice(0, 10))
      );
    } else {
      setCoordinationOptions([]);
    }
  }, [filtersData.coordinationList]);

  // init stylehint filter - hashtag options
  useEffect(() => {
    if (filtersData.hashtagList.length > 0) {
      setHashtagOptions(
        mapHashtagListToOptions(filtersData.hashtagList.slice(0, 10))
      );
    } else {
      setHashtagOptions([]);
    }
  }, [filtersData.hashtagList]);

  // init stylehint filter - model options
  useEffect(() => {
    setModelOptions([
      {
        key: "0",
        value: t("text_styling_book_staff_customer_filter_all")
      },
      {
        key: "stylehint_user",
        value: t("text_styling_book_staff_customer_filter_customer_styling")
      },
      {
        key: "staffstyling",
        value: t("text_styling_book_staff_customer_filter_staff_styling")
      }
    ]);
  }, [t]);

  // init stylehint filter - height options
  useEffect(() => {
    const obj =
      HeightOptions[`${params.language}-${params.region}`] ||
      HeightOptions["en-eu"];
    if (obj && isNotEmptyObj(obj)) {
      setHeightOptions(obj[gender.key]);
    }
  }, [gender.key, params.language, params.region]);

  // fetch filter data and large data
  const fetchFilterAndLargeData = () => {
    const str = getGenderFromUrl(location.pathname);
    const obj = findGender(genderList.current, str);
    if (obj) {
      // init gender
      setGender(obj);
      setGenderLID(obj.lid);
      // get styling large list
      getStylingLargeList(type, obj);
    }
    // get styling filters list
    getStylingFiltersList(type, str);
  };

  // init current gender and get filter list and large list
  useEffect(() => {
    if (config.is_loading) {
      return;
    }
    fetchFilterAndLargeData();
    hasFetchFilter.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, config.is_loading]);

  useEffect(() => {
    if (filtersData.isReloadFilters) {
      if (!hasFetchFilter.current) {
        fetchFilterAndLargeData();
        hasFetchFilter.current = false;
      }
      dispatch(StylingFilterActions.setIsReloadFilters(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersData.isReloadFilters]);

  // init
  useEffect(() => {
    hasFetchFilter.current = false;
    if (config.is_loading) {
      return;
    }
    if (
      hasChangeFilter ||
      hasChangeSort ||
      (useType === "POP" && isStillOnTopPage.current)
    ) {
      setStylingList([]);
      initOnlyNormalLists();
    }
    const str = getGenderFromUrl(location.pathname);
    const newObj = JSON.parse(JSON.stringify(filterRouteObj));
    let newExpandObj = JSON.parse(
      JSON.stringify(
        type === StylingType.official
          ? expandedAccordionOfficial
          : expandedAccordionStylehint
      )
    );
    if (!handleHasFilters(location.search)) {
      newExpandObj = JSON.parse(
        JSON.stringify(
          type === StylingType.official
            ? accordionOfficialObj
            : accordionStylehintObj
        )
      );
    } else {
      filterRouteList.map((item: FilterRouteTypes) => {
        const searchData = searchParams.get(item);
        const filters = FilterRouteType[item];
        if (searchData !== null) {
          const arr = searchData.split(",");
          newObj[filters].routeValue = arr;
          newExpandObj[filters] = true;
          if (!officialListData.showFilterPanel) {
            dispatch(OfficialListActions.setShowFilterPanel(true));
          }
          if (item === RouteParamType.category) {
            setCategoryTreeLevel(arr.length + 1);
            setCategoryKey([...arr.map((a) => Number(a))]);
          }
        }
        return newObj;
      });
    }
    if (!hasChangeFilter) {
      setInitExpandObj(newExpandObj);
    } else if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
    if (isStillOnTopPage.current && typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
    setCurrentRouteFilter(newObj);
    currentQuery.current = routerQuery(type, newObj);
    // get init styling list
    if (type === StylingType.official) {
      const _order = searchParams.get("order") || "";
      if (
        hasChangeFilter ||
        officialListData.official_list.styles.length === 0 ||
        isStillOnTopPage.current
      ) {
        getOfficialList(dispatch, {
          ...getOfficialListParams(
            str,
            defaultOrderRouterParam(type, _order),
            newObj
          )
        });
        hasReqOfficialNormal.current = true;
      }
    } else {
      const _order = searchParams.get("order") || "";
      currentStylehintParams.current = getStylehintListParams(
        str,
        defaultOrderRouterParam(type, _order),
        newObj,
        params
      );
      if (
        hasChangeFilter ||
        stylehintListData.stylehint_list.images.length === 0 ||
        isStillOnTopPage.current
      ) {
        getStylehintList(dispatch, {
          ...currentStylehintParams.current
        });
        hasReqStylehintNormal.current = true;
      }
    }
    if (useType === "POP" && !handleHasFilters(location.search)) {
      setHasChangeFilter(false);
      setHasChangeSort(false);
    }
    isStillOnTopPage.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, config.is_loading]);

  // set order router url
  useEffect(() => {
    const orderData = searchParams.get("order");
    let value = StylingSortEnum.latest;
    if (orderData && orderData.length > 0) {
      value = defaultOrderRouterParam(type, orderData);
      if (type === StylingType.stylehint) {
        dispatch(StylehintListActions.setStylehintCurrentSort(value));
      } else {
        dispatch(OfficialListActions.setOfficialCurrentSort(value));
      }
    }
  }, [dispatch, searchParams, type]);

  useEffect(() => {
    if (!hasChangeFilter && !filtersData.loading) {
      expandAccordion(initExpandObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersData.loading, hasChangeFilter, initExpandObj]);

  // init current filters value (tag value and route value)
  const getOptionsValue = useCallback(
    (id: string, arr: Array<string>): Array<string> => {
      let newArr: Array<string> = [];
      let options: any[] = [];
      switch (id) {
        case FilterType.colors:
          options = colorOptions;
          break;
        case FilterType.coordination:
          options = coordinationOptions;
          break;
        case FilterType.feature:
          options = featureOptions;
          break;
        case FilterType.hashtag:
          options = hashtagOptions;
          break;
        case FilterType.height:
          options = heightOptions;
          break;
        case FilterType.model:
          options = modelOptions;
          break;
        default:
          break;
      }
      newArr = arr.map((a: string) => {
        const obj = options.find(
          (o: any) =>
            o.key.toString().toLowerCase() === a.toString().toLowerCase()
        );
        let value = obj ? obj.value : null;
        if (id === FilterType.height && obj) {
          value = handleHeightLabel(obj, t);
        }
        if (id === FilterType.hashtag && !obj) {
          value = a;
          const newHashtag: FilterOptionType = {
            key: a,
            value: a
          };
          setHashtagOptions([newHashtag, ...hashtagOptions]);
        }
        return value;
      });
      return newArr;
    },
    [
      coordinationOptions,
      featureOptions,
      hashtagOptions,
      heightOptions,
      modelOptions,
      t
    ]
  );
  useEffect(() => {
    const newObj = JSON.parse(JSON.stringify(currentRouteFilter));
    Object.keys(newObj).forEach((key) => {
      const arr = newObj[key].routeValue;
      let newArr: Array<string> = [];
      if (arr.length > 0) {
        newArr = getOptionsValue(key, arr);
      }
      newObj[key].tagValue =
        key === FilterType.category
          ? mapCategoryKeyToName(filtersData.categoryList, arr)
          : newArr;
      newObj[key].routeValue = arr;
    });
    setFilterTagData({ ...newObj });
    handleChangeFilter(newObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRouteFilter, getOptionsValue]);

  // handle meta title
  const handleMetaTitle = useCallback(
    (filterArr: FilterTagType[]) => {
      if (genderLID) {
        const obj = {
          title: ""
        };
        // non-filter
        if (filterArr.length === 0) {
          obj.title = t(
            type === StylingType.official
              ? "text_styling_book_official_gender_meta_title"
              : "text_styling_book_stylehint_gender_meta_title",
            {
              gender: t(genderLID),
              uqPostfix: t("text_meta_title_postfix")
            }
          );
        }
        // filtered by
        else {
          let str = "";
          let arr = filterArr.map((f: FilterTagType) => f.value?.toString());
          str = arr.toString();
          arr = str.split(",");
          str = arr.join("、");
          if (type === StylingType.official) {
            obj.title = t(
              "text_styling_book_official_gender_filter_meta_title",
              {
                filterOptions: str,
                gender: t(genderLID),
                uqPostfix: t("text_meta_title_postfix")
              }
            );
          } else {
            obj.title = t("text_styling_book_stylehint_filter_meta_title", {
              filterOptions: str,
              gender: t(genderLID),
              uqPostfix: t("text_meta_title_postfix")
            });
          }
        }
        return obj;
      } else {
        return null;
      }
    },
    [genderLID, t, type]
  );

  // handel meta desc
  const handleMetaDesc = useCallback(
    (filterArr: FilterTagType[]) => {
      if (genderLID) {
        const obj = {
          description: ""
        };
        // non-filter
        if (filterArr.length === 0) {
          obj.description =
            type === StylingType.official
              ? t("text_styling_book_official_gender_meta_description", {
                  brand: t("text_styling_book_meta_title_brand"),
                  gender: t(genderLID)
                })
              : t("text_styling_book_stylehint_gender_meta_description", {
                  brand: t("text_styling_book_meta_title_brand"),
                  gender: t(genderLID),
                  count: stylehintListData.stylehint_list.pagination.total
                });
        }
        // filtered by
        else {
          let str = "";
          let arr = filterArr.map((f: FilterTagType) => f.value?.toString());
          str = arr.toString();
          arr = str.split(",");
          str = arr.join("、");
          if (type === StylingType.official) {
            obj.description = t(
              "text_styling_book_official_gender_filter_meta_description",
              {
                brand: t("text_styling_book_meta_title_brand"),
                filterOptions: str,
                gender: t(genderLID)
              }
            );
          } else {
            obj.description = t(
              "text_styling_book_stylehint_filter_meta_description",
              {
                brand: t("text_styling_book_meta_title_brand"),
                filterOptions: str,
                searchCount: stylehintListData.stylehint_list.pagination.total,
                gender: t(genderLID)
              }
            );
          }
        }
        return obj;
      } else {
        return null;
      }
    },
    [genderLID, stylehintListData.stylehint_list.pagination.total, t, type]
  );

  // ga - click filter ga and pv | SEO - meta title
  useDeepCompareEffect(() => {
    const filterArr = JSON.parse(
      JSON.stringify(
        type === StylingType.official
          ? selectedOfficialFilter
          : selectedStylehintFilter
      )
    );
    let newTemp = [];
    let flag = false;
    if (filterArr.length > 0) {
      newTemp = filterArr.map((obj: FilterTagType) =>
        Object.values(obj).includes(null)
      );
      flag = newTemp.every((item: boolean) => item === false);
    }
    // add meta data and trackPageView
    if (
      (newTemp.length > 0 && flag) ||
      (!handleHasFilters(location.search) && newTemp.length === 0)
    ) {
      const obj = handleMetaTitle(filterArr);
      if (obj) {
        metaTitle.current = obj.title;
        trackPageView(
          getPageViewBaseOption(PageType.StylingBook, "", obj.title)
        );
      }
    }
    // ga - trackEventSearch
    if (newTemp.length > 0 && flag && !isClickLargeImage.current) {
      handleFilterGa(currentRouteFilter);
    }
    isClickLargeImage.current = false;
  }, [selectedOfficialFilter, selectedStylehintFilter, type, genderLID]);

  // SEO - meta description
  useDeepCompareEffect(() => {
    const loadingData =
      type === StylingType.official
        ? officialListData.loading
        : stylehintListData.loading;
    if (!loadingData && !isShowSystemError.current) {
      const filterArr = JSON.parse(
        JSON.stringify(
          type === StylingType.official
            ? selectedOfficialFilter
            : selectedStylehintFilter
        )
      );
      let newTemp = [];
      let flag = false;
      if (filterArr.length > 0) {
        newTemp = filterArr.map((obj: FilterTagType) =>
          Object.values(obj).includes(null)
        );
        flag = newTemp.every((item: boolean) => item === false);
      }
      if (
        (newTemp.length > 0 && flag) ||
        (!handleHasFilters(location.search) && newTemp.length === 0)
      ) {
        // add meta desc
        const obj = handleMetaDesc(filterArr);
        if (obj) {
          metaDescription.current = obj.description;
        }
      }
    }
  }, [
    selectedOfficialFilter,
    selectedStylehintFilter,
    type,
    genderLID,
    stylehintListData.loading,
    officialListData.loading
  ]);

  // SEO - add hreflang
  const hrefLangList = useMemo(
    () => getHrefLangList(location.pathname),
    [location.pathname]
  );

  // SEO - add script
  const breadcrumbJson = useMemo(() => {
    const _json = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: t("text_breadcrumbs_top"),
          item: `${window.location.origin}/${params.region}/${params.language}`
        },
        {
          "@type": "ListItem",
          position: 2,
          name: `${t("text_styling_book_header_title")} ${t(
            type === StylingType.official
              ? "text_styling_book_official_styling"
              : "text_styling_book_stylehint"
          )}`
        }
      ]
    };
    return JSON.stringify(_json);
  }, [params.language, params.region, t, type]);

  // SEO - add noindex
  const isNoResult = useMemo(
    () =>
      (type === StylingType.official && officialListData.noResult) ||
      (type === StylingType.stylehint && stylehintListData.noResult),
    [officialListData.noResult, stylehintListData.noResult, type]
  );

  // change list tab (official or stylehint)
  const handleChangeListTab = (e: StylingType) => {
    // ga
    sendTrackEvent({
      brand: transformBrandForGoogleAnalytics(config.brand_name),
      event: TRACK_EVENT,
      eventAction: "tab",
      eventCategory:
        e === StylingType.official
          ? "sb_OfficialStyling_click"
          : "sb_StyleHint_click",
      eventLabel:
        e === StylingType.official ? "top" : t("text_styling_book_sort_latest")
    });
    initAccordionState();
    handleClearAllFilters();
    initAllLists();
    const url = generatePath(
      `/:region/:language/stylingbook/${e}/${gender.key}`,
      {
        ...params
      } as RouterUrlType
    );
    navigate(url);
  };

  // change gender tab (women、men、kids or baby)
  const handleChangeGenderTab = (e: string) => {
    // ga
    sendTrackEvent({
      brand: transformBrandForGoogleAnalytics(config.brand_name),
      event: TRACK_EVENT,
      eventAction: "gender",
      eventCategory:
        type === StylingType.official
          ? "sb_OfficialStyling_click"
          : "sb_StyleHint_click",
      eventLabel: gaGenderValue(e, true)
    });
    initAccordionState();
    handleClearAllFilters();
    initAllLists();
    setGenderLID("");
    const url = generatePath(`/:region/:language/stylingbook/${type}/${e}`, {
      ...params
    } as RouterUrlType);
    navigate(url);
  };

  // handle click filter icon button
  const onToggleFilter = () => {
    if (handleFilterCount() > 0) {
      dispatch(OfficialListActions.setShowFilterPanel(true));
    } else {
      dispatch(
        OfficialListActions.setShowFilterPanel(
          !officialListData.showFilterPanel
        )
      );
    }
    hasClickFilterModal.current = false;
    setIsToggled(!isToggled);
  };

  // handle click sort icon button
  const handleSortChange = (
    event: React.KeyboardEvent<HTMLLIElement> | React.MouseEvent<HTMLLIElement>,
    value: string
  ) => {
    event.preventDefault();
    initOnlyNormalLists();
    setStylingList([]);
    setHasChangeSort(true);
    setHasChangeFilter(false);
    // change sort url
    handleRouterUrl(currentRouteFilter, handleOrderRouterParam(type, value));
    // ga
    sendTrackEvent({
      brand: transformBrandForGoogleAnalytics(config.brand_name),
      event: TRACK_EVENT,
      eventAction:
        type === StylingType.official ? "OfficialStyling" : "StyleHint",
      eventCategory: "sb_sort",
      eventLabel:
        value === StylingSortEnum.latest
          ? t("text_styling_book_sort_latest")
          : t("text_styling_book_sort_popular")
    });
    trackPageView(
      getPageViewBaseOption(PageType.StylingBook, "", window.document.title)
    );
    // go top
    window.scrollTo(0, 0);
  };

  // handle expanded accordion
  const expandAccordion = (obj: AccordionObjType) => {
    if (type === StylingType.official) {
      setExpandedAccordionOfficial({
        ...expandedAccordionOfficial,
        ...obj
      });
    } else {
      setExpandedAccordionStylehint({
        ...expandedAccordionStylehint,
        ...obj
      });
    }
  };

  // handle expanded or close accordion
  const handleExpandAccordion = (id: FilterType) => {
    if (type === StylingType.official) {
      setExpandedAccordionOfficial({
        ...expandedAccordionOfficial,
        [id]: !expandedAccordionOfficial[id]
      });
    } else {
      setExpandedAccordionStylehint({
        ...expandedAccordionStylehint,
        [id]: !expandedAccordionStylehint[id]
      });
    }
  };

  // handle router url
  const handleRouterUrl = (obj: FilterRouteObjType, sortValue?: string) => {
    setStylingList([]);
    if (!sortValue) {
      setHasChangeFilter(true);
      setHasChangeSort(false);
    }
    hasClickFilterModal.current = true;
    let url = `/:region/:language/stylingbook/:type/${gender.key}?`;
    let newUrl = "";
    if (type === StylingType.official) {
      if (obj[FilterType.colors].routeValue.length > 0) {
        url += `${RouteParamType.colors}=${obj[FilterType.colors].routeValue}&`;
      }
      if (obj[FilterType.category].routeValue.length > 0) {
        url += `${RouteParamType.category}=${
          obj[FilterType.category].routeValue
        }&`;
      }
      if (obj[FilterType.feature].routeValue.length > 0) {
        url += `${RouteParamType.feature}=${
          obj[FilterType.feature].routeValue
        }&`;
      }
      if (obj[FilterType.coordination].routeValue.length > 0) {
        url += `${RouteParamType.coordination}=${
          obj[FilterType.coordination].routeValue
        }&`;
      }
    } else {
      if (obj[FilterType.colors].routeValue.length > 0) {
        url += `${RouteParamType.colors}=${obj[FilterType.colors].routeValue}&`;
      }
      if (obj[FilterType.category].routeValue.length > 0) {
        url += `${RouteParamType.category}=${
          obj[FilterType.category].routeValue
        }&`;
      }
      if (obj[FilterType.hashtag].routeValue.length > 0) {
        url += `${RouteParamType.hashtag}=${
          obj[FilterType.hashtag].routeValue
        }&`;
      }
      if (obj[FilterType.height].routeValue.length > 0) {
        url += `${RouteParamType.height}=${obj[FilterType.height].routeValue}&`;
      }
      if (obj[FilterType.model].routeValue.length > 0) {
        url += `${RouteParamType.model}=${obj[FilterType.model].routeValue}&`;
      }
    }
    // sort router order => GP4.0-3.0
    const _sortValue = handleOrderRouterParam(
      type,
      type === StylingType.stylehint
        ? stylehintListData.currentSort
        : officialListData.currentSort
    );
    const _newSortValue =
      sortValue && sortValue.length > 0 ? sortValue : _sortValue;
    const isChangeSort =
      (sortValue && sortValue.length > 0) ||
      (type === StylingType.stylehint && stylehintListData.hasClickSort) ||
      (type === StylingType.official && officialListData.hasClickSort);
    if (isChangeSort) {
      url += `${RouteParamType.order}=${_newSortValue}&`;
    }
    url = url.substring(0, url.lastIndexOf("&"));
    newUrl = generatePath(url, {
      ...params
    });
    navigate(newUrl);
  };

  // handle change filter
  // official: feature, category, coordination, colors
  // stylehint: model, height, hashtag, category, colors
  const handleChangeFilter = (routeObj: FilterRouteObjType) => {
    let selected: Array<SelectedData> = [];
    if (type === StylingType.official) {
      selected = [
        {
          type: FilterType.feature,
          value: [...routeObj[FilterType.feature].tagValue]
        },
        {
          type: FilterType.category,
          value: [...routeObj[FilterType.category].tagValue]
        },
        {
          type: FilterType.coordination,
          value: [...routeObj[FilterType.coordination].tagValue]
        },
        {
          type: FilterType.colors,
          value: [...routeObj[FilterType.colors].tagValue]
        }
      ];
    } else {
      selected = [
        {
          type: FilterType.model,
          value: [...routeObj[FilterType.model].tagValue]
        },
        {
          type: FilterType.height,
          value: [...routeObj[FilterType.height].tagValue]
        },
        {
          type: FilterType.hashtag,
          value: [...routeObj[FilterType.hashtag].tagValue]
        },
        {
          type: FilterType.category,
          value: [...routeObj[FilterType.category].tagValue]
        },
        {
          type: FilterType.colors,
          value: [...routeObj[FilterType.colors].tagValue]
        }
      ];
    }
    const newArr: Array<FilterTagType> = [];
    selected.map((a) => {
      if (a.value.length > 0) {
        return a.value.map((props, index) => {
          const obj = {
            key: a.type,
            value: props,
            index: index
          };
          newArr.push(obj);
          return a;
        });
      }
      return a;
    });
    if (type === StylingType.official) {
      setSelectedOfficialFilter([...newArr]);
    } else {
      setSelectedStylehintFilter([...newArr]);
    }
  };

  // ga - trackEventSearch
  const handleFilterGa = (data: FilterRouteObjType) => {
    let obj = {
      brand: transformBrandForGoogleAnalytics(config.brand_name),
      event: TRACK_EVENT_SEARCH,
      eventAction:
        type === StylingType.official ? "OfficialStyling" : "StyleHint",
      eventCategory: "sb_filter",
      eventLabel:
        type === StylingType.official
          ? "OfficialStyling_filter"
          : "StyleHint_filter"
    };
    const filterList = Object.keys(data);
    const newArr: Array<{ key: string; value: Array<string> }> = filterList.map(
      (item: string) => {
        const arr = data[item].routeValue;
        const param = {
          key: item,
          value:
            item === FilterType.category
              ? mapCategoryKeyToName(filtersData.categoryList, arr)
              : getOptionsValue(item, arr)
        };
        return param;
      }
    );
    if (type === StylingType.official) {
      const newObj = getOfficialGa(newArr);
      obj = { ...obj, ...newObj };
    } else {
      const newObj = getStylehintGa(newArr, t);
      obj = { ...obj, ...newObj };
    }
    // ga - trackEventSearch
    sendTrackEvent({ ...obj });
  };

  // handle checked or unchecked checkbox
  const handleCheckedCheckbox = (
    id: FilterType,
    item: FilterOptionType | ColorOptionType
  ): boolean => {
    if (id === FilterType.colors) {
      return filterTagData[id].tagValue.includes(item.value);
    } else {
      return filterTagData[id].routeValue.includes(item.key);
    }
  };

  // handle change checkbox
  const handleCheckboxChange = (
    id: FilterType,
    item: FilterOptionType | ColorOptionType
  ) => {
    const newRouteObj = JSON.parse(JSON.stringify(currentRouteFilter));
    const newData = JSON.parse(JSON.stringify(filterTagData));
    const data =
      id === FilterType.colors ? newData[id].tagValue : newData[id].routeValue;
    const index =
      id === FilterType.colors
        ? data.indexOf(item.value)
        : data.indexOf(item.key);
    const isIncludes =
      id === FilterType.colors
        ? data.includes(item.value)
        : data.includes(item.key);
    if (isIncludes) {
      newRouteObj[id].routeValue.splice(index, 1);
    } else if (id === FilterType.colors) {
      newRouteObj[id].routeValue.push(item.key.toUpperCase());
    } else {
      newRouteObj[id].routeValue.push(item.key);
    }
    handleRouterUrl(newRouteObj);
  };

  // handle checked or unchecked radio (only stylehint)
  const handleCheckedRadio = (id: FilterType, item: OptionsType): boolean => {
    if (filterTagData[id].tagValue.length === 0 && item.key === "0") {
      return true;
    } else {
      let value = item.value;
      if (id === FilterType.height) {
        value = handleHeightLabel(item, t);
      }
      return filterTagData[id].tagValue.includes(value);
    }
  };

  // handle switch radio (only stylehint)
  const handleRadioChange = (id: FilterType, item: OptionsType) => {
    const newRouteObj = JSON.parse(JSON.stringify(currentRouteFilter));
    newRouteObj[id] = { ...newRouteObj[id], tagValue: [], routeValue: [] };
    if (item.key !== "0") {
      newRouteObj[id].routeValue.push(item.key);
      handleRouterUrl(newRouteObj);
    } else {
      const newData = {
        ...newRouteObj,
        [id]: { tagValue: [], routeValue: [] }
      };
      handleRouterUrl(newData);
    }
  };

  // handle click link navigation
  const handleClickLink = (id: FilterType, item: FilterOptionType) => {
    const newRouteObj = JSON.parse(JSON.stringify(currentRouteFilter));
    // click clear all category
    if (categoryOptions.length === 1 && categoryOptions[0].key === "0") {
      newRouteObj[id] = { ...newRouteObj[id], tagValue: [], routeValue: [] };
      setCategoryTreeLevel(1);
      setCategoryKey([]);
    } else {
      newRouteObj[id].routeValue.push(item.key);
      setCategoryTreeLevel((level) => level + 1);
      setCategoryKey(() => [...categoryKey, Number(item.key)]);
    }
    handleRouterUrl(newRouteObj);
  };

  // handle click delete tag filter
  const handleClickDeleteTag = (
    id: FilterType,
    item: string,
    index: number
  ) => {
    const newRouteObj = JSON.parse(JSON.stringify(currentRouteFilter));
    if (id === FilterType.category) {
      newRouteObj[id].routeValue.splice(index);
      setCategoryTreeLevel(index + 1);
      const arr = [...categoryKey];
      arr.splice(index);
      setCategoryKey([...arr]);
    } else {
      newRouteObj[id].routeValue.splice(index, 1);
    }
    handleRouterUrl(newRouteObj);
  };

  // init accordion state
  const initAccordionState = () => {
    if (type === StylingType.official) {
      setExpandedAccordionOfficial(accordionOfficialObj);
    } else {
      setExpandedAccordionStylehint(accordionStylehintObj);
    }
    setHasChangeSort(false);
    setHasChangeFilter(false);
  };

  // init large styling list, normal stying list
  const initAllLists = () => {
    setStylingList([]);
    setSelectedOfficialFilter([]);
    setSelectedStylehintFilter([]);
    dispatch(FavoritesStylingActions.initFavoritesStyling());
    dispatch(StylingFilterActions.initStylingFilters());
    dispatch(OfficialListActions.initOfficialLists());
    dispatch(StylehintListActions.initStylehintLists());
  };

  // init large styling list, normal stying list
  const initOnlyNormalLists = () => {
    dispatch(OfficialListActions.initOfficialLists(true));
    dispatch(StylehintListActions.initStylehintLists(true));
    dispatch(FavoritesStylingActions.initFavoritesStyling());
  };

  // handle clear all filters
  const handleClearAllFilters = (isClickClearAll?: boolean) => {
    setCategoryTreeLevel(1);
    setCategoryKey([]);
    if (isClickClearAll) {
      const newRouteObj = JSON.parse(JSON.stringify(filterRouteObj));
      handleRouterUrl(newRouteObj);
    }
  };

  // handle selected filter count
  const handleFilterCount = (): number => {
    if (type === StylingType.official) {
      return selectedOfficialFilter.length;
    } else {
      return selectedStylehintFilter.length;
    }
  };

  // handle selected filter lists
  const handleFilterTagsArr = (): Array<FilterTagType> => {
    if (type === StylingType.official) {
      return selectedOfficialFilter;
    } else {
      return selectedStylehintFilter;
    }
  };

  // handle wish list selected or unselected
  const handleWishList = (styleId: string) => {
    if (!getIsLoggedIn() && !isGUNativeApp()) {
      dispatch(ConfigActions.setShowLogin(true));
    } else {
      const _type = type === StylingType.official ? "sb" : "sh";
      const _list =
        type === StylingType.official
          ? favoritesSBStylingList
          : favoritesSHStylingList;
      postOrDeleteFavoritesStyling(dispatch, _list, styleId, _type, false);
    }
  };

  // handle click wish icon
  const handleClickFavoriteStyling = useDebounce((styleId: string) => {
    handleWishList(styleId);
    // ga
    sendTrackEvent({
      brand: transformBrandForGoogleAnalytics(config.brand_name),
      event: TRACK_EVENT,
      eventAction:
        type === StylingType.official
          ? "officialstyling_img_list"
          : "stylehint_img_list",
      eventCategory: "sb_wishlist_style",
      eventLabel: styleId
    });
  }, 200);

  // click go to detail page
  const goToDetailPageUrl = (styleId: string): string => {
    const url = generatePath(
      `/:region/:language/stylingbook/:type/${styleId}`,
      {
        ...params
      } as RouterUrlType
    );
    return url;
  };

  // handle click normal image styling
  const clickStyling = (id: string, index: number) => {
    // ga
    sendTrackEvent({
      brand: transformBrandForGoogleAnalytics(config.brand_name),
      event: TRACK_EVENT,
      eventAction:
        type === StylingType.official
          ? `${gaGenderValue(gender.key)}_${id}`
          : `StyleHint_${id}`,
      eventCategory:
        type === StylingType.official
          ? "sb_OfficialStyling_img"
          : "sb_StyleHint_click",
      eventLabel: id
    });

    // save normal list according to detail page carousel list
    dispatch(
      DetailStylingActions.setCarouselList({
        list: [...stylingList],
        index: index
      })
    );
  };

  // handle click large image styling
  const handleClickLargeImage = (
    imageType: FilterType,
    item: FilterOptionType,
    gaLabel: string
  ) => {
    // ga
    sendTrackEvent({
      brand: transformBrandForGoogleAnalytics(config.brand_name),
      event: TRACK_EVENT,
      eventAction: `${gaGenderValue(gender.key)}_${gaLabel}`,
      eventCategory:
        type === StylingType.official
          ? "sb_OfficialStyling_img"
          : "sb_StyleHint_click",
      eventLabel: gaLabel
    });
    if (!officialListData.showFilterPanel) {
      dispatch(OfficialListActions.setShowFilterPanel(true));
    }
    isClickLargeImage.current = true;
    expandAccordion({ [imageType]: true });
    handleCheckboxChange(imageType, item);
  };

  // handle list total number
  const handleListTotalNum = () => {
    let num = 0;
    if (type === StylingType.official) {
      num = officialListData.official_list.pagination.total || 0;
    } else {
      num = stylehintListData.stylehint_list.pagination.total || 0;
    }
    return formatTotalNum(params.language || "ja", num);
  };

  // Breadcrumb area
  const navigationPath = (
    <BreadcrumbGroup
      className={getEcRenewalCompatibility() ? "" : "fr-ec-mb-spacing-05"}
    >
      <Breadcrumb element="a" href={config.basePath}>
        {t("text_breadcrumbs_top")}
      </Breadcrumb>
      <Breadcrumb element="a" href="women">
        {params.type === StylingType.official
          ? `${t("text_styling_book_header_title")} ${t(
              "text_styling_book_official_styling"
            )}`
          : `${t("text_styling_book_header_title")} ${t(
              "text_styling_book_stylehint"
            )}`}
      </Breadcrumb>
    </BreadcrumbGroup>
  );

  // Accordion checkbox components
  const AccordionWithCheckBox = (
    options: Array<FilterOptionType>,
    id: FilterType,
    label: string,
    isExpanded: boolean
  ) => (
    <Accordion
      id={`accordion-${id}`}
      element="div"
      label={label}
      labelElement="h1"
      variant="dynamic"
      expanded={isExpanded}
      onExpand={() => handleExpandAccordion(id)}
      onClose={() => handleExpandAccordion(id)}
    >
      <CheckboxGroup id={id}>
        {options.length > 0 ? (
          options.map((item: FilterOptionType, index: number) => (
            <Checkbox
              key={item.key}
              id={`Checkbox-${item.key}-${index}`}
              label={
                id === FilterType.hashtag || id === FilterType.feature
                  ? `#${item.value}`
                  : item.value
              }
              checked={handleCheckedCheckbox(id, item)}
              onChange={() => handleCheckboxChange(id, item)}
            />
          ))
        ) : (
          <></>
        )}
      </CheckboxGroup>
    </Accordion>
  );

  // Accordion link navigation components
  const AccordionWithLink = (
    options: Array<FilterOptionType>,
    id: FilterType,
    label: string,
    isExpanded: boolean
  ) => (
    <Accordion
      id={`accordion-${id}`}
      element="div"
      label={label}
      labelElement="h1"
      variant="dynamic"
      expanded={isExpanded}
      onExpand={() => handleExpandAccordion(id)}
      onClose={() => handleExpandAccordion(id)}
    >
      <LinkNavigationGroup labelId={id} indented>
        {options.length > 0 ? (
          options.map((item: FilterOptionType) => (
            <LinkNavigation
              key={item.key}
              element="button"
              onClick={() => handleClickLink(id, item)}
            >
              {item.value}
            </LinkNavigation>
          ))
        ) : (
          <></>
        )}
      </LinkNavigationGroup>
    </Accordion>
  );

  // Accordion checkbox color components
  const AccordionCheckBoxWithColor = (
    options: Array<ColorOptionType>,
    id: FilterType,
    label: string,
    isExpanded: boolean,
    isSP?: boolean
  ) => (
    <Accordion
      className={isSP ? "" : "fr-ec-mb-spacing-08"}
      id={`accordion-${id}`}
      element="div"
      label={label}
      labelElement="h1"
      variant="dynamic"
      expanded={isExpanded}
      onExpand={() => handleExpandAccordion(id)}
      onClose={() => handleExpandAccordion(id)}
    >
      <CheckboxGroup id={id} columnCount={isSP ? 2 : 1}>
        {options.length > 0 ? (
          options.map((item: ColorOptionType) => (
            <Checkbox
              key={item.key}
              id={`Checkbox-${item.key}`}
              label={item.value}
              color={item.key as ProductColors}
              checked={handleCheckedCheckbox(id, item)}
              onChange={() => handleCheckboxChange(id, item)}
            />
          ))
        ) : (
          <></>
        )}
      </CheckboxGroup>
    </Accordion>
  );

  // Accordion radio components
  const AccordionWithRadio = (
    options: OptionsType[],
    id: FilterType,
    label: string,
    isExpanded: boolean
  ) => (
    <Accordion
      id={`accordion-${id}`}
      element="div"
      label={label}
      labelElement="h1"
      variant="dynamic"
      expanded={isExpanded}
      onExpand={() => handleExpandAccordion(id)}
      onClose={() => handleExpandAccordion(id)}
    >
      <RadioGroup id={id}>
        {options.length > 0 ? (
          options.map((item: OptionsType) => (
            <Radio
              key={`${id}-${item.key}`}
              id={`Radio-${id}-${item.key}`}
              label={
                id === FilterType.height
                  ? handleHeightLabel(item, t)
                  : item.value
              }
              name={`radio-group-${id}`}
              checked={handleCheckedRadio(id, item)}
              onChange={() => handleRadioChange(id, item)}
            />
          ))
        ) : (
          <></>
        )}
      </RadioGroup>
    </Accordion>
  );

  // error handling - filter or sort error
  const handleFilterOrSortReqError = () =>
    (type === StylingType.official &&
      officialListData.errorCode === "503" &&
      (hasChangeFilter || hasChangeSort)) ||
    (type === StylingType.stylehint &&
      stylehintListData.errorCode === "503" &&
      (hasChangeFilter || hasChangeSort));

  const utilityBar = () => (
    <StickyContainer stickySection="utilityBar">
      <UtilityBar
        ecRenewal={getEcRenewalCompatibility()}
        resultDetailsSection={
          <Body variant="standard" element="span">
            {t("text_styling_book_search_results_number", {
              total: handleTopPageListLoading() ? "-" : handleListTotalNum()
            })}
          </Body>
        }
        collapseFilterLabel={t("text_filter")}
        toggleFilter={officialListData.showFilterPanel}
        onToggleFilter={onToggleFilter}
        filterBadgeCount={
          handleFilterCount() > 0 ? handleFilterCount() : undefined
        }
        handleChangeSort={handleSortChange}
        currentSortValue={
          type === StylingType.stylehint
            ? stylehintListData.currentSort
            : officialListData.currentSort
        }
        sortOptions={[
          {
            label: t("text_styling_book_sort_latest"),
            value: StylingSortEnum.latest
          },
          {
            label: t("text_styling_book_sort_popular"),
            value: StylingSortEnum.popular
          }
        ]}
        toggleSort
        sortLabel={t("text_sort")}
      />
    </StickyContainer>
  );

  // pc filter menu
  const FilterPanelContent = (isSP?: boolean) => {
    let filterTags = [];
    if (!filtersData.loading) {
      filterTags = JSON.parse(JSON.stringify(handleFilterTagsArr()));
    }
    return (
      <>
        <div className={filterTags.length > 0 ? "fr-ec-mb-spacing-05" : ""}>
          <Spacer element="div" variant="spacing-05" />
          {filterTags.length > 0 ? (
            <>
              <ContentAlignment
                direction="row"
                className="fr-ec-mb-spacing-04"
                element="div"
                alignment="center"
                justifyContent="space-between"
              >
                <Title variant="standard" element="h1">
                  {t("text_active_filters")}
                </Title>
              </ContentAlignment>
              <TagFilterGroup
                clearLabel={t("text_styling_book_clear_all_filters")}
                onClear={() => handleClearAllFilters(true)}
                ecRenewal={getEcRenewalCompatibility()}
              >
                {filterTags.map(
                  (props: FilterTagType) =>
                    props.value && (
                      <TagFilter
                        key={`${props.key}-${props.value}-${props.index}`}
                        value={
                          props.key === FilterType.hashtag ||
                          props.key === FilterType.feature
                            ? `#${props.value}`
                            : props.value
                        }
                        onClick={() =>
                          handleClickDeleteTag(
                            props.key,
                            props.value,
                            props.index
                          )
                        }
                      />
                    )
                )}
              </TagFilterGroup>
            </>
          ) : null}
        </div>
        {type === StylingType.official ? (
          <>
            {featureOptions.length > 0 &&
              AccordionWithCheckBox(
                featureOptions.slice(0, 10),
                FilterType.feature,
                t("text_styling_book_search_by_feature"),
                expandedAccordionOfficial.feature
              )}
            {categoryOptions.length > 0 &&
              AccordionWithLink(
                categoryOptions,
                FilterType.category,
                t("text_styling_book_search_by_category"),
                expandedAccordionOfficial.category
              )}
            {coordinationOptions.length > 0 &&
              AccordionWithCheckBox(
                coordinationOptions,
                FilterType.coordination,
                t("text_styling_book_search_by_coordinate"),
                expandedAccordionOfficial.coordination
              )}
            {AccordionCheckBoxWithColor(
              colorOptions,
              FilterType.colors,
              t("text_styling_book_search_by_color"),
              expandedAccordionOfficial.colors,
              isSP
            )}
          </>
        ) : (
          <>
            {AccordionWithRadio(
              modelOptions,
              FilterType.model,
              t("text_styling_book_staff_customer_filter_title"),
              expandedAccordionStylehint.model
            )}
            {AccordionWithRadio(
              heightOptions,
              FilterType.height,
              t("text_styling_book_search_by_height"),
              expandedAccordionStylehint.height
            )}
            {hashtagOptions.length > 0 &&
              AccordionWithCheckBox(
                hashtagOptions,
                FilterType.hashtag,
                t("text_styling_book_search_by_hashtag"),
                expandedAccordionStylehint.hashtag
              )}
            {categoryOptions.length > 0 &&
              AccordionWithLink(
                categoryOptions,
                FilterType.category,
                t("text_styling_book_search_by_category"),
                expandedAccordionStylehint.category
              )}
            {AccordionCheckBoxWithColor(
              colorOptions,
              FilterType.colors,
              t("text_styling_book_search_by_color"),
              expandedAccordionStylehint.colors,
              isSP
            )}
          </>
        )}
      </>
    );
  };

  // close modal event
  const handleCloseModal = () => {
    if (hasClickFilterModal.current && typeof window !== "undefined") {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }
    setIsToggled(!isToggled);
  };

  // sp and tab filter menu
  const ModalComponent = () => (
    <Modal
      variant="filter"
      title={t("text_filter")}
      closeLabel={t("text_a11y_close")}
      onRequestClose={handleCloseModal}
      isOpen={isToggled}
      trapFocus
      footer={
        <ButtonLayout variant="fluid">
          <Button
            element="button"
            variant="primary"
            onClick={handleCloseModal}
            ecRenewal={getEcRenewalCompatibility()}
          >
            {t("text_apply_search_filter")}
          </Button>
        </ButtonLayout>
      }
      itemCount={t("text_styling_book_search_results_number", {
        total: handleTopPageListLoading() ? "-" : handleListTotalNum()
      })}
      hideCloseIconLabel={getEcRenewalCompatibility()}
    >
      {FilterPanelContent(true)}
    </Modal>
  );

  // Official styling large images => feature list
  const imagePlusTextItems = () =>
    largeOfficialImages.map((item: SBlargeImages) => (
      <Tile
        element="button"
        onClick={() =>
          handleClickLargeImage(
            FilterType.feature,
            {
              key: item.featureId || "",
              value: item.featureId || ""
            },
            item.featureId
          )
        }
      >
        <ImageEnhancements
          padding={false}
          bottomCenterContent={
            <GutterContainer>
              <Title
                className="fr-ec-mb-spacing-02"
                element="h2"
                variant="large"
              >
                {item.title}
              </Title>
              <Body element="p">{item.description}</Body>
            </GutterContainer>
          }
        >
          <Image
            ratio="3x4"
            src={`${item.imageUrl}_r-600-800` || ""}
            alt={t("text_styling_book_stylehint_feature_alt_tag", {
              feature: item.title,
              gender: t(gender.lid)
            })}
          />
        </ImageEnhancements>
      </Tile>
    ));

  // Stylehint styling large images => hashtag list
  const hashtagItems = () =>
    largeStylehintImages.map((item: SHlargeImages) => (
      <ImageOverlay
        key={item.outfitId}
        element="button"
        textAlign="center"
        children={
          <Image
            ratio="3x4"
            src={`${item.imageUrl}_r-600-800` || ""}
            alt={t("text_styling_book_stylehint_hashtag_alt_tag", {
              hashtag: item.hashtag,
              gender: t(gender.lid)
            })}
          />
        }
        content={`#${item.hashtag}`}
        onClick={() =>
          handleClickLargeImage(
            FilterType.hashtag,
            {
              key: item.hashtag || "",
              value: item.hashtag || ""
            },
            item.outfitId ? item.outfitId.toString() : ""
          )
        }
      />
    ));

  // Large images area
  const handleShowSpecialItems = () => {
    if (handleFilterCount() === 0) {
      return type === StylingType.official
        ? imagePlusTextItems()
        : hashtagItems();
    } else {
      return undefined;
    }
  };

  // handle top page list loading
  const handleTopPageListLoading = (): boolean =>
    (type === StylingType.official &&
      (officialListData.loading || officialListData.large_loading)) ||
    (type === StylingType.stylehint &&
      (stylehintListData.loading || stylehintListData.large_loading));

  // handle no result
  const handleNoResult = (): boolean =>
    (type === StylingType.official && officialListData.noResult) ||
    (type === StylingType.stylehint && stylehintListData.noResult);

  // FilterLayout pageContent area
  const GridItems = (): any => {
    if (handleNoResult()) {
      return (
        <>
          {isMobile && type !== StylingType.official && (
            <StyleHintBanner type="top" />
          )}
          <NoResults handleClick={() => handleClearAllFilters(true)} />
        </>
      );
    } else {
      return (
        <>
          {handleFilterOrSortReqError() && (
            <>
              <GutterContainer>
                <Caption color="error" element="p">
                  {hasChangeFilter &&
                    t("text_error_failed_update_filter_criteria")}
                  {hasChangeSort && t("text_error_failed_sort_product")}
                </Caption>
              </GutterContainer>
              <Spacer element="div" variant="spacing-04" />
            </>
          )}
          {isMobile && type !== StylingType.official && (
            <StyleHintBanner type="top" />
          )}
          <StylingGrid
            variant={
              type === StylingType.official ? "imagePlusText" : "hashTag"
            }
            specialItems={handleShowSpecialItems()}
            gridItems={stylingList.map((item: StylesInfo, index: number) => (
              <Link
                to={goToDetailPageUrl(item.styleId)}
                onClick={() => clickStyling(item.styleId, index)}
              >
                <Tile element="button" key={item.styleId}>
                  <ImageEnhancements
                    topRightContent={
                      isShowWishIcon(type) ? (
                        <ButtonToggle
                          icon="heart"
                          variant="icon"
                          onClick={(e: any) => {
                            preventBubbling(e);
                            if (!favoritesStylingLoading) {
                              handleClickFavoriteStyling(item.styleId);
                            }
                          }}
                          isToggled={item.isFavorite}
                        />
                      ) : undefined
                    }
                    padding={false}
                  >
                    <Image
                      ratio="3x4"
                      src={`${item.styleImageUrl}_r-600-800`}
                      alt={t(
                        type === StylingType.official
                          ? "text_styling_book_official_styling_id_alt_tag"
                          : "text_styling_book_stylehint_styling_id_alt_tag",
                        {
                          brand: t("text_styling_book_meta_title_brand"),
                          styleId: item.styleId
                        }
                      )}
                      loading="lazy"
                    />
                  </ImageEnhancements>
                </Tile>
              </Link>
            ))}
            className={handleTopPageListLoading() ? "" : "fr-ec-mb-spacing-07"}
          />
          {handleTopPageListLoading() && <LoadingIndicator variant="normal" />}
        </>
      );
    }
  };

  const genderTabContent = () => (
    <TabGroup
      genderTypography
      sticky={false}
      variant="small"
      defaultIndex={genderList.current.findIndex(
        (value) => value.key === getGenderFromUrl(location.pathname)
      )}
    >
      {genderList.current.map((item: GenderOptionType) => (
        <Tab
          key={item.key}
          label={t(item.displayLid)}
          textTransform="all-caps"
          onClick={
            item.key !== getGenderFromUrl(location.pathname)
              ? () => handleChangeGenderTab(item.key.toString())
              : undefined
          }
        >
          <FilterLayout
            filterPanel={FilterPanelContent()}
            utilityBarContent={utilityBar()}
            pageContent={GridItems()}
            showFilterPanel={officialListData.showFilterPanel}
            stickyFilterPanelOnLarge={false}
          />
        </Tab>
      ))}
    </TabGroup>
  );

  const getMoreData = () => {
    const newRouteObj = JSON.parse(JSON.stringify(currentRouteFilter));
    // get more list
    if (type === StylingType.official) {
      const _offset = officialListData.official_list.pagination.offset || 0;
      const _params = {
        ...getOfficialListParams(
          gender.key,
          officialListData.currentSort,
          newRouteObj
        )
      };
      _params.offset = _offset + 50;
      getOfficialList(dispatch, _params);
      hasReqOfficialNormal.current = true;
    } else {
      currentStylehintParams.current = getStylehintListParams(
        gender.key,
        stylehintListData.currentSort,
        newRouteObj,
        params,
        currentStylehintParams.current
      );
      const _offset = stylehintListData.stylehint_list.pagination.offset || 0;
      currentStylehintParams.current.offset = _offset + 50;
      getStylehintList(dispatch, {
        ...currentStylehintParams.current
      });
      hasReqStylehintNormal.current = true;
    }
  };

  // UQKR & UQNL & UQBE & EU minor regions only have officialstyling
  const mainContent =
    params.region === COUNTRY.NL ||
    params.region === COUNTRY.BE ||
    (params.region && isEUMinorCountry(params.region)) ? (
      <>
        <HeaderPage
          className="fr-ec-mb-spacing-06"
          title={t("text_styling_book_official_styling_page_title")}
        />
        {genderTabContent()}
      </>
    ) : (
      <TabGroup
        genderTypography={false}
        sticky={false}
        variant="standard-boxed"
        className="fr-ec-mb-spacing-05"
        defaultIndex={type === StylingType.official ? 0 : 1}
      >
        <Tab
          id="official"
          label={t("text_styling_book_official_styling")}
          onClick={
            type !== StylingType.official
              ? () => handleChangeListTab(StylingType.official)
              : undefined
          }
          labelElement="h1"
        >
          {config.is_loading ? (
            <LoadingIndicator variant="normal" />
          ) : (
            genderTabContent()
          )}
        </Tab>
        <Tab
          id="stylehint"
          label={t("text_styling_book_stylehint")}
          labelImage="https://asset.uniqlo.com/g/icons/style-hint.svg"
          onClick={
            type !== StylingType.stylehint
              ? () => handleChangeListTab(StylingType.stylehint)
              : undefined
          }
          labelElement="h1"
        >
          {config.is_loading ? (
            <LoadingIndicator variant="normal" />
          ) : (
            genderTabContent()
          )}
        </Tab>
      </TabGroup>
    );

  // handle render main content or render error component
  const handleRenderMainContent = () => {
    if (
      (type === StylingType.official &&
        officialListData.errorCode === "503" &&
        !hasChangeFilter &&
        !hasChangeSort) ||
      (type === StylingType.stylehint &&
        stylehintListData.errorCode === "503" &&
        !hasChangeFilter &&
        !hasChangeSort)
    ) {
      isShowSystemError.current = true;
      metaTitle.current = t("text_error_title");
      metaDescription.current = t("text_503_error_description");
      return (
        <>
          <HeaderPage
            className="fr-ec-mb-spacing-05"
            title={t("text_styling_book_header_title")}
          />
          <SystemError />
          <Spacer element="div" variant="spacing-08" />
        </>
      );
    } else {
      isShowSystemError.current = false;
      return mainContent;
    }
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{metaTitle.current}</title>
        <meta name="description" content={metaDescription.current} />
        <meta
          name="robots"
          content={
            isNoResult || isShowSystemError.current
              ? "noindex,nofollow"
              : "index,follow"
          }
        />
        {!isShowSystemError.current && (
          <>
            {hrefLangList.map((item) => (
              <link
                key={item.hrefLang}
                rel="alternate"
                hrefLang={item.hrefLang}
                href={`${window.location.origin}/${item.alternateHref}${
                  currentQuery.current.length > 0
                    ? `?${currentQuery.current}`
                    : ""
                }`}
              />
            ))}
            <link
              rel="canonical"
              href={`${window.location.origin}${location.pathname}${
                currentQuery.current.length > 0
                  ? `?${currentQuery.current}`
                  : ""
              }`}
            />
            <script type="application/ld+json" id="breadcrumb">
              {breadcrumbJson}
            </script>
          </>
        )}
      </Helmet>
      <InfiniteScroll
        dataLength={stylingList.length}
        next={getMoreData}
        hasMore={
          type === StylingType.official
            ? officialListData.hasMore
            : stylehintListData.hasMore
        }
        loader
      >
        <TemplateILP
          navigationPath={!config.isApp ? navigationPath : undefined}
          mainContent={handleRenderMainContent()}
        />
        {/* fix ios14 safari scroll bug */}
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "1px"
          }}
        />
      </InfiniteScroll>
      <LayoutWrapper
        element="section"
        className="uq-ec-template-ilp-modal-wrapper"
      >
        <Layout
          colSpan={{ sm: 4, md: 12, lg: 0 }}
          visible={{ sm: true, md: true, lg: false }}
        >
          {isToggled && ModalComponent()}
        </Layout>
      </LayoutWrapper>
      <GoTop />
      {config.showLogin && <LoginModal />}
      {config.showWishIconError && <ErrorModal />}
    </>
  );
};

export default TopStyling;
