import { ActionsUnion, createAction } from "@redux/helpers";
import {
  UserProfileData,
  UserProfilePayload,
  UserProfileResponseData
} from "@type/index";

export const INIT_USER_PROFILE = "[user profile] init user profile";
export const GET_USER_PROFILE = "[user profile] get user profile request";
const GET_USER_PROFILE_SUCCESS = "[user profile] get user profile success";
const GET_USER_PROFILE_LOADING = "[user profile] get user profile loading";
const SET_USER_PROFILE_NOFOUND = "[user profile] set user profile not found";

export const Actions = {
  initUserProfile: () => createAction(INIT_USER_PROFILE),
  getUserProfile: (payload: UserProfilePayload) =>
    createAction(GET_USER_PROFILE, payload),
  getUserProfileSuccess: (payload: UserProfileResponseData) =>
    createAction(GET_USER_PROFILE_SUCCESS, payload),
  getUserProfileLoading: (payload: boolean) =>
    createAction(GET_USER_PROFILE_LOADING, payload),
  setUserProfileNotFound: (payload: string) =>
    createAction(SET_USER_PROFILE_NOFOUND, payload)
};

type ActionType = ActionsUnion<typeof Actions>;

export type State = UserProfileData;

const initialState = {
  pagination: {
    count: 0,
    total: 0,
    offset: 0
  },
  limit: 50,
  hasMore: false,
  errorCode: "",
  loading: true,
  current_list: []
};

export default (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case INIT_USER_PROFILE:
      return {
        ...initialState
      };
    case GET_USER_PROFILE_SUCCESS: {
      const _total = action.payload.pagination.total || 0;
      const _count = action.payload.pagination.count || 0;
      const _offset = action.payload.pagination.offset || 0;
      const _styles = action.payload.styles;
      let _list: Array<string> = [];
      if (_styles && _styles.length > 0) {
        _list = _styles.map((s) => s.id.toString());
      }
      return {
        ...state,
        userInfo: action.payload.userInfo,
        pagination: { ...action.payload.pagination },
        styles: [...(state.styles || []), ...(action.payload.styles || [])],
        hasMore: _count + _offset < _total,
        errorCode: "",
        loading: false,
        current_list: _list
      };
    }
    case GET_USER_PROFILE_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SET_USER_PROFILE_NOFOUND:
      return {
        ...state,
        errorCode: action.payload,
        hasMore: false,
        loading: false
      };
    default:
      return state;
  }
};
