import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import request from "@util/net/request";
import {
  FavoritesItemActions,
  GET_FAVORITES_ITEM,
  POST_FAVORITES_ITEM,
  DELETE_FAVORITES_ITEM,
  ConfigActions,
  FETCH_PRODUCT_SKU_KEY,
  FETCH_PRODUCT_L2S_WITH_IMAGE
} from "@redux/reducer";
import {
  DelFavoritesItemPayload,
  FavoritesItemPayload,
  L2,
  NewPostFavoritesItemPayload,
  OperateFavoritesResponse,
  ProductSkusPayload,
  ProductSkusResponse
} from "@type/index";
import store from "@redux/store";
import {
  addorRemoveNativeAppFavoriteItems,
  searchNativeFavoriteItems
} from "@util/nativeApp";
import { INativeItemTypes } from "@type/mobileApp";
import { getEcRenewalImageRatio } from "@util/helpers";

// get favorites item list
export function* getFavoritesItemSaga(action: {
  payload: FavoritesItemPayload;
}) {
  try {
    const payload: FavoritesItemPayload = action.payload;
    yield put(FavoritesItemActions.getFavoritesItemLoading(true));
    if (payload.isGUNativeApp) {
      try {
        const { skuIds: wishlistSKUs }: { skuIds: INativeItemTypes["items"] } =
          yield call(searchNativeFavoriteItems, {
            items: payload.l2Ids
          });
        const updatedWishlist = wishlistSKUs.map(
          ({ l2Id, isRepresentative }) => ({
            l2Id,
            display: true, // TODO: check how we can get the correct value for this
            isRepresentative: isRepresentative ?? false
          })
        );
        yield put(FavoritesItemActions.getWishlistSuccess(updatedWishlist));
        yield put(FavoritesItemActions.getFavoritesItemLoading(false));
      } catch (e) {
        yield put(FavoritesItemActions.getFavoritesItemLoading(false));
      }
    }
    // else {
    //   const res: AxiosResponse<FavoritesItemResponse> = yield call(
    //     request.get,
    //     "/favorites/search",
    //     {
    //       params: {
    //         ids: payload.ids
    //       }
    //     }
    //   );
    //   yield put(FavoritesItemActions.getFavoritesItemSuccess(res.data.result));
    // }
  } catch (error) {
    yield put(FavoritesItemActions.getFavoritesItemLoading(false));
  }
}

// add favorites item
export function* postFavoritesItemSaga(action: {
  payload: NewPostFavoritesItemPayload;
}) {
  try {
    const payload: NewPostFavoritesItemPayload = action.payload;
    yield put(FavoritesItemActions.getFavoritesItemLoading(true));
    if (payload.isGUNativeApp) {
      try {
        const { status }: { status: boolean } = yield call(
          addorRemoveNativeAppFavoriteItems,
          {
            isFavourite: true,
            items: payload.body
          }
        );
        if (status) {
          yield put(
            FavoritesItemActions.postFavoritesItemSuccess(payload.body[0].l2Id)
          );
        }
        yield put(FavoritesItemActions.getFavoritesItemLoading(false));
      } catch (e) {
        yield put(FavoritesItemActions.getFavoritesItemLoading(false));
      }
    } else {
      const res: AxiosResponse<OperateFavoritesResponse> = yield call(
        request.post,
        "/favorites",
        payload.body
      );
      if (res.data.status === "ok") {
        yield put(
          FavoritesItemActions.postFavoritesItemSuccess(payload.body[0].l2Id)
        );
      }
      yield put(FavoritesItemActions.getFavoritesItemLoading(false));
    }
  } catch (error) {
    yield put(FavoritesItemActions.getFavoritesItemLoading(false));
    if (!axios.isCancel(error)) {
      yield put(ConfigActions.setShowWishIconError(true));
    }
  }
}

// del favorites item
export function* deleteFavoritesItemSaga(action: {
  payload: DelFavoritesItemPayload;
}) {
  try {
    const payload: DelFavoritesItemPayload = action.payload;
    yield put(FavoritesItemActions.getFavoritesItemLoading(true));
    if (payload.isGUNativeApp) {
      try {
        const { status }: { status: boolean } = yield call(
          addorRemoveNativeAppFavoriteItems,
          {
            isFavourite: false,
            items: [{ l2Id: payload.id }]
          }
        );
        if (status) {
          yield put(FavoritesItemActions.delFavoritesItemSuccess(payload.id));
        }
        yield put(FavoritesItemActions.getFavoritesItemLoading(false));
      } catch (e) {
        yield put(FavoritesItemActions.getFavoritesItemLoading(false));
      }
    } else {
      const res: AxiosResponse<OperateFavoritesResponse> = yield call(
        request.del,
        "/favorites",
        {
          params: {
            id: payload.id
          }
        }
      );
      if (res.data.status === "ok") {
        yield put(FavoritesItemActions.delFavoritesItemSuccess(payload.id));
      }
      yield put(FavoritesItemActions.getFavoritesItemLoading(false));
    }
  } catch (error) {
    yield put(FavoritesItemActions.getFavoritesItemLoading(false));
    if (!axios.isCancel(error)) {
      yield put(ConfigActions.setShowWishIconError(true));
    }
  }
}

// fetch product skus
export function* fetchProductSKUsSaga(action: { payload: ProductSkusPayload }) {
  try {
    const { productId, priceGroup }: ProductSkusPayload = action.payload;
    yield put(FavoritesItemActions.fetchProductSKUsLoading(true));
    const _url = `/products/${productId}/price-groups/${priceGroup}/favorites?allL2s=true&imageRatio=${getEcRenewalImageRatio()}`;
    const res: AxiosResponse<ProductSkusResponse> = yield call(
      request.get,
      _url
    );
    if (res.data.status === "ok" && res.data.result) {
      if (res.data.result.l2s) {
        const colorGroups: { [key: string]: L2[] } = {};
        res.data.result.l2s.forEach((l2) => {
          if (!colorGroups[l2.color?.code || "all"]) {
            colorGroups[l2.color?.code || "all"] = [];
          }
          colorGroups[l2.color?.code || "all"].push(l2);
        });
        yield put(FavoritesItemActions.fetchProductSKUsSuccess(colorGroups));
        if (res.data.result.images) {
          yield put(
            FavoritesItemActions.fetchProductSKUsImagesSuccess(
              res.data.result.images
            )
          );
        }
      }
      if (res.data.result.wishlist) {
        const updatedWishlist = res.data.result.wishlist.map((wishlist) => ({
          ...wishlist,
          priceGroup
        }));
        yield put(FavoritesItemActions.getWishlistSuccess(updatedWishlist));
      }
      yield put(FavoritesItemActions.fetchProductSKUsLoading(false));
      yield put(FavoritesItemActions.fetchProductSKUsFail(false));
    } else if (res.data.error && res.data.error.httpStatusCode === 401) {
      yield put(FavoritesItemActions.fetchProductSKUsLoading(false));
      yield put(FavoritesItemActions.fetchProductSKUsFail(false));
      store.dispatch(ConfigActions.setShowLogin(true));
    } else {
      yield put(FavoritesItemActions.fetchProductSKUsLoading(false));
      yield put(FavoritesItemActions.fetchProductSKUsFail(true));
    }
  } catch (error) {
    yield put(FavoritesItemActions.fetchProductSKUsLoading(false));
    yield put(FavoritesItemActions.fetchProductSKUsFail(true));
  }
}

// For GU APP -> fetch product skus data
// API to return all l2s of a product with CSP and image details
export function* fetchProductL2sWithImageSaga(action: {
  payload: ProductSkusPayload;
}) {
  try {
    const { productId, priceGroup }: ProductSkusPayload = action.payload;
    yield put(FavoritesItemActions.fetchProductSKUsLoading(true));
    const res: AxiosResponse<ProductSkusResponse> = yield call(
      request.get,
      `/products/${productId}/price-groups/${priceGroup}/l2s-with-images`
    );
    if (res.data.status === "ok" && res.data.result) {
      if (res.data.result.l2s) {
        const colorGroups: { [key: string]: L2[] } = {};
        res.data.result.l2s.forEach((l2) => {
          if (!colorGroups[l2.color?.code || "all"]) {
            colorGroups[l2.color?.code || "all"] = [];
          }
          const updatedL2 = {
            ...l2,
            isWishlisted: false // wishlist value will be fetched from mobileapp.js
          };
          colorGroups[l2.color?.code || "all"].push(updatedL2);
        });
        yield put(FavoritesItemActions.fetchProductSKUsSuccess(colorGroups));
        // get product wish list
        const l2Ids =
          (res.data.result.l2s.map(({ l2Id }) => l2Id) as string[]) ?? [];
        yield put(
          FavoritesItemActions.getFavoritesItem({
            l2Ids: l2Ids,
            isGUNativeApp: true
          })
        );
      }
      yield put(FavoritesItemActions.fetchProductSKUsLoading(false));
      yield put(FavoritesItemActions.fetchProductSKUsFail(false));
    } else {
      yield put(FavoritesItemActions.fetchProductSKUsLoading(false));
      yield put(FavoritesItemActions.fetchProductSKUsFail(true));
    }
  } catch (error) {
    yield put(FavoritesItemActions.fetchProductSKUsLoading(false));
    yield put(FavoritesItemActions.fetchProductSKUsFail(true));
  }
}

export function* watchLoadFavoritesItemSaga() {
  yield takeLatest<any>(GET_FAVORITES_ITEM, getFavoritesItemSaga);
  yield takeEvery<any>(POST_FAVORITES_ITEM, postFavoritesItemSaga);
  yield takeEvery<any>(DELETE_FAVORITES_ITEM, deleteFavoritesItemSaga);
  yield takeLatest<any>(FETCH_PRODUCT_SKU_KEY, fetchProductSKUsSaga);
  yield takeLatest<any>(
    FETCH_PRODUCT_L2S_WITH_IMAGE,
    fetchProductL2sWithImageSaga
  );
}
