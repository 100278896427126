import { COUNTRY, LANGUAGE } from "@type/index";

export class CurrencyFormatter {
  country: COUNTRY;
  language: LANGUAGE;
  constructor(country: COUNTRY, language: LANGUAGE) {
    this.country = country;
    this.language = language;
  }

  // This function inserts a given separator to value
  static addSeparator(value: string, separator: string, by: number): string {
    return value.replace(/./g, (char, index, all) =>
      index && (all.length - index) % by === 0 ? separator + char : char
    );
  }

  formatInteger(integer: string) {
    return CurrencyFormatter.addSeparator(integer, this.seperatorSymbol(), 3);
  }

  decimalSymbol() {
    switch (`${this.language}-${this.country}`) {
      case `${LANGUAGE.EN}-${COUNTRY.US}`:
      case `${LANGUAGE.EN}-${COUNTRY.SE}`:
      case `${LANGUAGE.EN}-${COUNTRY.UK}`:
      case `${LANGUAGE.EN}-${COUNTRY.DK}`:
        return ".";
      case `${LANGUAGE.FR}-${COUNTRY.FR}`:
      case `${LANGUAGE.EN}-${COUNTRY.FR}`:
      case `${LANGUAGE.EN}-${COUNTRY.FI}`:
      case `${LANGUAGE.EN}-${COUNTRY.GR}`:
      case `${LANGUAGE.ES}-${COUNTRY.ES}`:
      case `${LANGUAGE.EN}-${COUNTRY.ES}`:
      case `${LANGUAGE.EN}-${COUNTRY.IT}`:
      case `${LANGUAGE.IT}-${COUNTRY.IT}`:
      case `${LANGUAGE.EN}-${COUNTRY.AT}`:
      case `${LANGUAGE.DE}-${COUNTRY.DE}`:
      case `${LANGUAGE.EN}-${COUNTRY.DE}`:
      case `${LANGUAGE.FR}-${COUNTRY.BE}`:
      case `${LANGUAGE.NL}-${COUNTRY.BE}`:
      case `${LANGUAGE.EN}-${COUNTRY.LU}`:
      case `${LANGUAGE.EN}-${COUNTRY.RO}`:
      case `${LANGUAGE.EN}-${COUNTRY.BG}`:
      case `${LANGUAGE.EN}-${COUNTRY.IE}`:
      case `${LANGUAGE.EN}-${COUNTRY.PT}`:
      case `${LANGUAGE.EN}-${COUNTRY.BE}`:
      case `${LANGUAGE.EN}-${COUNTRY.NL}`:
      case `${LANGUAGE.NL}-${COUNTRY.NL}`:
      case `${LANGUAGE.EN}-${COUNTRY.LV}`:
      case `${LANGUAGE.EN}-${COUNTRY.PL}`:
      case `${LANGUAGE.EN}-${COUNTRY.EE}`:
      case `${LANGUAGE.EN}-${COUNTRY.SI}`:
      case `${LANGUAGE.EN}-${COUNTRY.HU}`:
      case `${LANGUAGE.EN}-${COUNTRY.CZ}`:
      case `${LANGUAGE.EN}-${COUNTRY.SK}`:
      case `${LANGUAGE.EN}-${COUNTRY.LT}`:
      case `${LANGUAGE.EN}-${COUNTRY.HR}`:
        return ",";
      default:
        return "";
    }
  }
  seperatorSymbol() {
    switch (`${this.language}-${this.country}`) {
      case `${LANGUAGE.EN}-${COUNTRY.US}`:
      case `${LANGUAGE.JA}-${COUNTRY.JP}`:
      case `${LANGUAGE.EN}-${COUNTRY.SE}`:
      case `${LANGUAGE.EN}-${COUNTRY.UK}`:
      case `${LANGUAGE.EN}-${COUNTRY.DK}`:
        return ",";
      case `${LANGUAGE.FR}-${COUNTRY.FR}`:
      case `${LANGUAGE.EN}-${COUNTRY.FR}`:
      case `${LANGUAGE.EN}-${COUNTRY.FI}`:
      case `${LANGUAGE.EN}-${COUNTRY.GR}`:
      case `${LANGUAGE.ES}-${COUNTRY.ES}`:
      case `${LANGUAGE.EN}-${COUNTRY.ES}`:
      case `${LANGUAGE.IT}-${COUNTRY.IT}`:
      case `${LANGUAGE.EN}-${COUNTRY.IT}`:
      case `${LANGUAGE.EN}-${COUNTRY.AT}`:
      case `${LANGUAGE.DE}-${COUNTRY.DE}`:
      case `${LANGUAGE.EN}-${COUNTRY.DE}`:
      case `${LANGUAGE.NL}-${COUNTRY.BE}`:
      case `${LANGUAGE.FR}-${COUNTRY.BE}`:
      case `${LANGUAGE.EN}-${COUNTRY.LU}`:
      case `${LANGUAGE.EN}-${COUNTRY.RO}`:
      case `${LANGUAGE.EN}-${COUNTRY.BG}`:
      case `${LANGUAGE.EN}-${COUNTRY.IE}`:
      case `${LANGUAGE.EN}-${COUNTRY.PT}`:
      case `${LANGUAGE.EN}-${COUNTRY.BE}`:
      case `${LANGUAGE.EN}-${COUNTRY.NL}`:
      case `${LANGUAGE.NL}-${COUNTRY.NL}`:
      case `${LANGUAGE.EN}-${COUNTRY.LV}`:
      case `${LANGUAGE.EN}-${COUNTRY.PL}`:
      case `${LANGUAGE.EN}-${COUNTRY.EE}`:
      case `${LANGUAGE.EN}-${COUNTRY.SI}`:
      case `${LANGUAGE.EN}-${COUNTRY.HU}`:
      case `${LANGUAGE.EN}-${COUNTRY.CZ}`:
      case `${LANGUAGE.EN}-${COUNTRY.SK}`:
      case `${LANGUAGE.EN}-${COUNTRY.LT}`:
      case `${LANGUAGE.EN}-${COUNTRY.HR}`:
        return ".";
      default:
        return ",";
    }
  }
  discardDecimalIfZero() {
    switch (`${this.language}-${this.country}`) {
      case `${LANGUAGE.EN}-${COUNTRY.US}`:
        return false;
      case `${LANGUAGE.EN}-${COUNTRY.UK}`:
      case `${LANGUAGE.EN}-${COUNTRY.DK}`:
      case `${LANGUAGE.FR}-${COUNTRY.FR}`:
      case `${LANGUAGE.EN}-${COUNTRY.FR}`:
      case `${LANGUAGE.EN}-${COUNTRY.SE}`:
      case `${LANGUAGE.EN}-${COUNTRY.FI}`:
      case `${LANGUAGE.EN}-${COUNTRY.GR}`:
      case `${LANGUAGE.ES}-${COUNTRY.ES}`:
      case `${LANGUAGE.EN}-${COUNTRY.ES}`:
      case `${LANGUAGE.IT}-${COUNTRY.IT}`:
      case `${LANGUAGE.EN}-${COUNTRY.IT}`:
      case `${LANGUAGE.EN}-${COUNTRY.AT}`:
      case `${LANGUAGE.DE}-${COUNTRY.DE}`:
      case `${LANGUAGE.EN}-${COUNTRY.DE}`:
      case `${LANGUAGE.FR}-${COUNTRY.BE}`:
      case `${LANGUAGE.NL}-${COUNTRY.BE}`:
      case `${LANGUAGE.EN}-${COUNTRY.LU}`:
      case `${LANGUAGE.EN}-${COUNTRY.RO}`:
      case `${LANGUAGE.EN}-${COUNTRY.BG}`:
      case `${LANGUAGE.EN}-${COUNTRY.IE}`:
      case `${LANGUAGE.EN}-${COUNTRY.PT}`:
      case `${LANGUAGE.EN}-${COUNTRY.BE}`:
      case `${LANGUAGE.EN}-${COUNTRY.NL}`:
      case `${LANGUAGE.NL}-${COUNTRY.NL}`:
      case `${LANGUAGE.EN}-${COUNTRY.LV}`:
      case `${LANGUAGE.EN}-${COUNTRY.PL}`:
      case `${LANGUAGE.EN}-${COUNTRY.EE}`:
      case `${LANGUAGE.EN}-${COUNTRY.SI}`:
      case `${LANGUAGE.EN}-${COUNTRY.HU}`:
      case `${LANGUAGE.EN}-${COUNTRY.CZ}`:
      case `${LANGUAGE.EN}-${COUNTRY.SK}`:
      case `${LANGUAGE.EN}-${COUNTRY.LT}`:
      case `${LANGUAGE.EN}-${COUNTRY.HR}`:
        return true;
      default:
        return false;
    }
  }
  formatDecimal(symbol: string, decimal: string) {
    if (Number.parseInt(decimal, 10) === 0 && symbol) {
      return this.discardDecimalIfZero() ? "" : `${symbol}${decimal}`;
    }
    return symbol ? `${symbol}${decimal}` : "";
  }
  // Return proper formatted currency value
  format(value: number | string) {
    let valueToString = "";
    let negativeSymbol = "";

    if (typeof value === "number") {
      if (value < 0) {
        valueToString = String(-value);
        negativeSymbol = "-";
      } else {
        valueToString = String(value);
      }
    } else if (typeof value === "string") {
      if (value[0] === "-") {
        valueToString = value.slice(1);
        negativeSymbol = "-";
      } else {
        valueToString = value;
      }
    }

    // parseFloat expects string as its argument
    const parsedPrice = Number.parseFloat(valueToString).toFixed(2);
    const [integer, decimal] = parsedPrice.split(".");
    const symbol = this.decimalSymbol();
    const formattedInteger = this.formatInteger(integer);
    const formattedDecimal = this.formatDecimal(symbol, decimal);
    return `${negativeSymbol}${formattedInteger}${formattedDecimal}`;
  }
}
