import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  generatePath,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import i18next from "i18next";
import { getUA } from "react-device-detect";
import { Action } from "history";
import { useDispatch, useSelector } from "react-redux";
import { CombinedReducersType, ConfigActions } from "@redux/reducer";
import {
  BRAND_LIST,
  COUNTRY,
  GenderObjType,
  RouterUrlType,
  StylingType,
  WishObjType
} from "@type/index";
import {
  FR_UserAgent,
  guAndroidPattern,
  guIOSPattern,
  UQ_JP_JA_Android_UserAgent,
  UQ_JP_JA_Ios_UserAgent,
  UQ_US_EN_Android_UserAgent,
  UQ_US_EN_Ios_UserAgent
} from "@config/common";
import { getFirebaseConfig } from "@util/firebase";
import { getConfirmation } from "@util/net/interceptors";
import { convertRegionNameForWording, isEUMinorCountry } from "@util/country";
import { yScrollContext } from "@common/YScroll";
import { history } from "@common/CustomRouter";
import TopStyling from "./TopStyling";
import DetailStyling from "./DetailStyling";
import PersonalPage from "./PersonalPage";
import ErrorPage from "./ErrorPage";
import { PublicHeader, PublicFooter } from "../common";

const App = () => {
  const dispatch = useDispatch();
  const { language, region, type } = useParams();
  const params = useParams();
  const navigate = useNavigate();
  const config = useSelector((store: CombinedReducersType) => store.config);
  const { yScroll, setYscroll } = useContext(yScrollContext);
  const uLocation = useLocation();
  const [currHist, setCurrHist] = useState<{
    action: Action;
    location: any;
    currentKey: string;
  }>({
    action: "",
    location: {},
    currentKey: ""
  } as any);

  // UQKR & UQNL & UQBE & EU minor regions only have officialstyling -> stylehint should redirect to official
  useEffect(() => {
    if (
      (region === COUNTRY.NL ||
        region === COUNTRY.BE ||
        (region && isEUMinorCountry(region))) &&
      type === StylingType.stylehint
    ) {
      const url = generatePath(
        "/:region/:language/stylingbook/officialstyling/women",
        {
          ...params
        } as RouterUrlType
      );
      navigate(url, { replace: true });
    }
  }, [navigate, params, region, type]);

  useEffect(() => {
    sessionStorage.setItem("key", uLocation.key);
  }, [uLocation]);

  useEffect(() => {
    const scrolled = () => setYscroll(window.scrollY);
    window.addEventListener("scroll", scrolled);
    return () => {
      window.removeEventListener("scroll", scrolled);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      sessionStorage.setItem("yvalue", JSON.stringify(yScroll));
    },
    [yScroll]
  );

  useEffect(
    () =>
      history.listen(({ location, action }: any) => {
        let _location;
        if (action === "POP") {
          const fwdKey = sessionStorage.getItem("key");
          const yscroll = sessionStorage.getItem("yvalue");
          _location = {
            ...location,
            state: {
              forward: {
                key: fwdKey,
                yscroll: yscroll
              },
              ...location.state
            }
          };
        }
        setCurrHist({
          ...currHist,
          action: action,
          location: {
            ..._location
          },
          currentKey: location.key
        });
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useLayoutEffect(() => {
    const { action, location, currentKey } = currHist;
    if (action && action === "POP") {
      // eslint-disable-next-line no-prototype-builtins
      if (location.state.hasOwnProperty("forward")) {
        if (currentKey === location.key) {
          setTimeout(() => {
            window.scrollTo(0, parseFloat(location.state.forward.yscroll));
          }, 100);
        }
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [currHist]);

  // set current userAgent
  // set current region and language
  useEffect(() => {
    i18next.changeLanguage(
      `${language}-${convertRegionNameForWording(region || "")}`
    );
    if (
      getUA.indexOf(UQ_JP_JA_Ios_UserAgent) > -1 ||
      getUA.indexOf(UQ_JP_JA_Android_UserAgent) > -1 ||
      getUA.indexOf(UQ_US_EN_Ios_UserAgent) > -1 ||
      getUA.indexOf(UQ_US_EN_Android_UserAgent) > -1 ||
      getUA.indexOf(FR_UserAgent) > -1 ||
      guIOSPattern.test(getUA) ||
      guAndroidPattern.test(getUA)
    ) {
      dispatch(ConfigActions.setIsApp(true));
    }
  }, [dispatch, language, region]);

  useEffect(() => {
    if (config.showSlide) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [config.showSlide]);

  // Get gender data from Firebase
  useEffect(() => {
    const getFirebase = async () => {
      const firebaseConfig = await getFirebaseConfig();
      // PLST only show women & men both official and stylehint
      // UNIQLO default show all gender
      const default_value_gender: GenderObjType =
        config.brand_name === BRAND_LIST.PL
          ? {
              women: true,
              men: true,
              kids: false,
              baby: false
            }
          : {
              women: true,
              men: true,
              kids: true,
              baby: true
            };
      const default_value_sb_wish: WishObjType = { icon: true, count: true };
      const default_value_sh_wish: WishObjType = {
        icon: true,
        count: true
      };
      let sb_gender = default_value_gender;
      let sh_gender = default_value_gender;
      let sb_wish = default_value_sb_wish;
      let sh_wish = default_value_sh_wish;
      if (firebaseConfig !== null) {
        const genderByFirebase = firebaseConfig.web_tab_gender;
        const wishByFirebase = firebaseConfig.web_wish;
        if (genderByFirebase !== undefined) {
          sb_gender = genderByFirebase.sb;
          sh_gender = genderByFirebase.sh;
        }
        if (wishByFirebase !== undefined) {
          sb_wish = wishByFirebase.sb;
          sh_wish = wishByFirebase.sh;
        }
      }
      dispatch(
        ConfigActions.setFirebase({
          sb_tab_gender: sb_gender,
          sh_tab_gender: sh_gender,
          sb_wish: sb_wish,
          sh_wish: sh_wish
        })
      );
    };
    getFirebase();
  }, [config.brand_name, dispatch, region]);

  // Cancel all pending requests when change router url
  useEffect(
    () => () => {
      getConfirmation();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.pathname]
  );

  const elementRouteGender = (gender: keyof GenderObjType) => {
    const obj =
      type === StylingType.official
        ? config.firebaseData.sb_tab_gender
        : config.firebaseData.sh_tab_gender;
    if (obj[gender]) {
      return <TopStyling />;
    } else {
      return <Navigate replace to="women" />;
    }
  };

  return (
    <>
      <PublicHeader />
      {type === StylingType.official || type === StylingType.stylehint ? (
        <main>
          <Routes>
            <Route path="women" element={elementRouteGender("women")} />
            <Route path="men" element={elementRouteGender("men")} />
            <Route path="kids" element={elementRouteGender("kids")} />
            <Route path="baby" element={elementRouteGender("baby")} />
            <Route path=":stylingId" element={<DetailStyling />} />
            {type === StylingType.stylehint && (
              <Route path="user/:authorId" element={<PersonalPage />} />
            )}
            <Route path="*" element={<Navigate replace to="women" />} />
          </Routes>
        </main>
      ) : (
        <ErrorPage status={404} />
      )}
      <PublicFooter />
    </>
  );
};

export default App;
