import { CountryEnv, LANGUAGE } from "@type/index";

const _countryEnv: CountryEnv = {
  clientId: process.env.REACT_APP_CLIENT_ID_KR_UQ || "uq.kr.new-styling-book",
  sbRootId: process.env.REACT_APP_FILTERS_SB_ROOT_ID_KR_UQ || 0,
  shRootId: process.env.REACT_APP_FILTERS_SH_ROOT_ID_KR_UQ || 0,
  firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY_KR_UQ || "",
  firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_KR_UQ || "",
  firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID_KR_UQ || "",
  firebaseMeasurementId:
    process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_KR_UQ || "",
  storeDomain: process.env.REACT_APP_STORE_DOMAIN_UQ || "",
  faqUrl: "https://faq-kr.uniqlo.com/",
  showAboutApp: true,
  showDualPrice: false,
  languages: [LANGUAGE.KO]
};

export default _countryEnv;
