import { BRAND_LIST, COUNTRY, StyleGenderEnum } from "@type/index";

// IS EU minor country -> which region name start with `eu-`
export const isEUMinorCountry = (country: string): boolean =>
  country.includes("eu-");

// IS EU Rollout
export const isEUCountry = (country: string): boolean =>
  [
    COUNTRY.UK,
    COUNTRY.FR,
    COUNTRY.DE,
    COUNTRY.ES,
    COUNTRY.IT,
    COUNTRY.DK,
    COUNTRY.SE,
    COUNTRY.NL,
    COUNTRY.BE,
    COUNTRY.FI,
    COUNTRY.GR,
    COUNTRY.IE,
    COUNTRY.LU,
    COUNTRY.AT,
    COUNTRY.PL,
    COUNTRY.PT,
    COUNTRY.SI,
    COUNTRY.SK,
    COUNTRY.LT,
    COUNTRY.LV,
    COUNTRY.EE,
    COUNTRY.CZ,
    COUNTRY.HU,
    COUNTRY.RO,
    COUNTRY.BG,
    COUNTRY.HR
  ].includes(convertEUMinorRegionName(country));

// Convert EU minor country name
export const convertEUMinorRegionName = (country: string): COUNTRY => {
  if (isEUMinorCountry(country)) {
    return country.slice(3) as COUNTRY;
  }
  return country as COUNTRY;
};

// For request BFF, the region name need to meet requirements
export const convertRegionName = (country: string): string => {
  if (country === COUNTRY.UK) {
    return "uk";
  }
  if (isEUMinorCountry(country)) {
    return country.slice(3);
  }
  return country;
};

// For get wording
export const convertRegionNameForWording = (country: string): string => {
  if (isEUMinorCountry(country)) {
    return "EU";
  }
  return country;
};

export const isUniqlo = (brand: string) => brand === BRAND_LIST.UQ;
export const isGu = (brand: string) => brand === BRAND_LIST.GU;
export const isPlst = (brand: string) => brand === BRAND_LIST.PL;

// For GU, should show kids & baby
export const isGuKids = (
  brand: string,
  gender?: string,
  genderCode?: string
) => {
  if (gender) {
    return isGu(brand) &&
      gender.toLocaleLowerCase() === StyleGenderEnum.KIDS.toLocaleLowerCase()
      ? "kids,baby"
      : gender;
  } else {
    return isGu(brand) && genderCode === "3" ? "3,4" : genderCode;
  }
};
