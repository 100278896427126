import { ActionsUnion, createAction } from "@redux/helpers";
import {
  FiltersPayload,
  FiltersResponseType,
  StylingFiltersData
} from "@type/index";

export const GET_STYLING_FILTERS =
  "[styling filter] get styling filter request";
const GET_STYLING_FILTERS_SUCCESS =
  "[styling filter] get styling filter success";
const GET_STYLING_FILTERS_LOADING =
  "[styling filter] get styling filter loading";
const INIT_STYLING_FILTERS = "[styling filter] init styling filters";
const SET_IS_RELOAD_FILTERS = "[styling filter] set reload styling filters";

export const Actions = {
  getStylingFilters: (payload: FiltersPayload) =>
    createAction(GET_STYLING_FILTERS, payload),
  getStylingFiltersSuccess: (payload: FiltersResponseType) =>
    createAction(GET_STYLING_FILTERS_SUCCESS, payload),
  getStylingFiltersLoading: (payload: boolean) =>
    createAction(GET_STYLING_FILTERS_LOADING, payload),
  initStylingFilters: () => createAction(INIT_STYLING_FILTERS),
  setIsReloadFilters: (payload: boolean) =>
    createAction(SET_IS_RELOAD_FILTERS, payload)
};

type ActionType = ActionsUnion<typeof Actions>;

export type State = StylingFiltersData;

const initialState = {
  loading: true,
  categoryList: [],
  coordinationList: [],
  featureList: [],
  hashtagList: [],
  isReloadFilters: false
};

export default (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case GET_STYLING_FILTERS_SUCCESS:
      return {
        ...state,
        categoryList: [...(action.payload.styleCategories || [])],
        coordinationList: [...(action.payload.tagCategories || [])],
        featureList: [...(action.payload.features || [])],
        hashtagList: [...(action.payload.hashtags || [])],
        loading: false
      };
    case GET_STYLING_FILTERS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case INIT_STYLING_FILTERS:
      return {
        ...state,
        ...initialState
      };
    case SET_IS_RELOAD_FILTERS:
      return {
        ...state,
        isReloadFilters: action.payload
      };
    default:
      return state;
  }
};
