import { all, fork } from "redux-saga/effects";
import { watchLoadStylingFiltersSaga } from "./filters";
import { watchLoadStylingListSaga } from "./stylehintList";
import { watchLoadOfficialListSaga } from "./officialList";
import { watchLoadDetailStylingSaga } from "./detailStyling";
import { watchLoadFavoritesStylingSaga } from "./favoritesStyling";
import { watchLoadFavoritesItemSaga } from "./favoritesItem";
import { watchLoadUserProfileSaga } from "./userProfile";
import { watchLoadRelatedStylingStylehintSaga } from "./relatedStylingStylehint";
import { watchLoadRelatedStylingOfficialSaga } from "./relatedStylingOfficial";
import { watchLoadBannerSaga } from "./cmsContent";

export default function* rootSaga(): Generator {
  yield all([fork(watchLoadStylingFiltersSaga)]);
  yield all([fork(watchLoadStylingListSaga)]);
  yield all([fork(watchLoadOfficialListSaga)]);
  yield all([fork(watchLoadDetailStylingSaga)]);
  yield all([fork(watchLoadFavoritesStylingSaga)]);
  yield all([fork(watchLoadFavoritesItemSaga)]);
  yield all([fork(watchLoadUserProfileSaga)]);
  yield all([fork(watchLoadRelatedStylingStylehintSaga)]);
  yield all([fork(watchLoadRelatedStylingOfficialSaga)]);
  yield all([fork(watchLoadBannerSaga)]);
}
