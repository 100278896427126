import { ActionsUnion, createAction } from "@redux/helpers";
import {
  OfficialListPayload,
  OfficialListType,
  RelatedStylingOfficialData
} from "@type/index";

export const GET_RELATED_STYLING_OFFICIAL =
  "[related styling official] get related styling official request";
const GET_RELATED_STYLING_OFFICIAL_SUCCESS =
  "[related styling official] get related styling official success";
const GET_RELATED_STYLING_OFFICIAL_LOADING =
  "[related styling official] get related styling official loading";
const INIT_RELATED_STYLING_OFFICIAL =
  "[related styling official] init related styling official data";
export const GET_OFFICIAL_CAROUSEL_LIST =
  "[carousel styling official] get official carousel list request";
const GET_OFFICIAL_CAROUSEL_LIST_SUCCESS =
  "[carousel styling official] get official carousel list success";
const GET_OFFICIAL_CAROUSEL_LIST_LOADING =
  "[carousel styling official] get official carousel list loading";

export const Actions = {
  getRelatedStylingOfficial: (payload: OfficialListPayload) =>
    createAction(GET_RELATED_STYLING_OFFICIAL, payload),
  getRelatedStylingOfficialSuccess: (payload: OfficialListType) =>
    createAction(GET_RELATED_STYLING_OFFICIAL_SUCCESS, payload),
  getRelatedStylingOfficialLoading: (payload: boolean) =>
    createAction(GET_RELATED_STYLING_OFFICIAL_LOADING, payload),
  initRelatedStylingOfficial: () => createAction(INIT_RELATED_STYLING_OFFICIAL),
  getOfficialCarouselList: (payload: OfficialListPayload) =>
    createAction(GET_OFFICIAL_CAROUSEL_LIST, payload),
  getOfficialCarouselListSuccess: (payload: OfficialListType) =>
    createAction(GET_OFFICIAL_CAROUSEL_LIST_SUCCESS, payload),
  getOfficialCarouselListLoading: (payload: boolean) =>
    createAction(GET_OFFICIAL_CAROUSEL_LIST_LOADING, payload)
};

type ActionType = ActionsUnion<typeof Actions>;

export type State = RelatedStylingOfficialData;

const initialState = {
  styles: [],
  loading: true,
  carousel_styles: [],
  carousel_loading: true
};

export default (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case GET_RELATED_STYLING_OFFICIAL_SUCCESS:
      return {
        ...state,
        styles: [...(action.payload.styles || [])],
        loading: false
      };
    case GET_RELATED_STYLING_OFFICIAL_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case INIT_RELATED_STYLING_OFFICIAL:
      return {
        ...state,
        ...initialState
      };
    case GET_OFFICIAL_CAROUSEL_LIST_SUCCESS:
      return {
        ...state,
        carousel_styles: [...(action.payload.styles || [])],
        carousel_loading: false
      };
    case GET_OFFICIAL_CAROUSEL_LIST_LOADING:
      return {
        ...state,
        carousel_loading: action.payload
      };
    default:
      return state;
  }
};
