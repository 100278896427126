import { BRAND_LIST, StylingAbbrType } from "@type/index";
import { INativeItemTypes, mobileAppType } from "@type/mobileApp";

export enum NativeAppType {
  IOS = "ios",
  ANDROID = "android",
  MOBILE = "mobile"
}

export const checkIsNativeApp = (fromParameter: string): boolean =>
  fromParameter === "android" || fromParameter === "ios";

declare const GU_MOBILEAPP: mobileAppType;
declare const GU_MOBILEAPP_PRESENT: boolean;
declare let MOBILEAPP: mobileAppType;

// in milliseconds
const retryInterval = 100;
// retries will finish in ((retryInterval * maxRetries) / 1000) seconds.
const maxRetries = 50;

const linkage = {
  baseUrl: {
    uq: "uniqloapp://api",
    gu: "gu-japan://api",
    pl: ""
  }
};

let retryCount = 0;
let timerId: number;
let nativeAppClientInstance: mobileAppType;
let pendingCalls: Array<any> = [];

/**
 * Gets called when the mobile app client was found in a global variable.
 * This function does a one time initialisation of the mobileApp global instance.
 */
export function initNativeAppClient(brand: BRAND_LIST) {
  const nativeAppClient = MOBILEAPP.MobileAppApiClient({
    baseUrl: linkage.baseUrl[brand],
    iframeId: self === window.top ? null : "some-iframe-id"
  });

  nativeAppClientInstance = MOBILEAPP.MobileAppApi(nativeAppClient);
  if (pendingCalls.length > 0) {
    pendingCalls.forEach((pendingCall: Array<Function>) => {
      pendingCall[0]();
    });
    pendingCalls = [];
  }
}

/**
 * Waits for GU_MOBILEAPP global variable to become available
 * and then initialises the mobile app module variable
 */
export function waitAndLoad(brand: BRAND_LIST = BRAND_LIST.GU) {
  const isGuMobileAppLoaded = typeof GU_MOBILEAPP !== "undefined";

  if (retryCount < maxRetries && !isGuMobileAppLoaded) {
    retryCount += 1;
  } else {
    if (isGuMobileAppLoaded) {
      window.MOBILEAPP = GU_MOBILEAPP;
      initNativeAppClient(brand);
    } else if (pendingCalls.length > 0) {
      pendingCalls.forEach((pendingCall: Array<Function>) => {
        pendingCall[1]();
      });
      pendingCalls = [];
    }
    window.clearTimeout(timerId);
  }
}

export function getNativeAppClientInstance(): mobileAppType {
  return nativeAppClientInstance;
}

// native app detection if userAgent is GU app
export function isGUNativeApp(): boolean {
  return typeof GU_MOBILEAPP_PRESENT !== "undefined" && GU_MOBILEAPP_PRESENT;
}

export function setTimerId() {
  if (isGUNativeApp()) {
    timerId = window.setInterval(waitAndLoad, retryInterval);
  }
}

/**
 * Notify the GUAPP with vibration
 */

export function executeVibration() {
  let waitForInit = Promise.resolve();
  if (!nativeAppClientInstance) {
    waitForInit = new Promise((res: () => void, rej: () => void) => {
      pendingCalls.push([res, rej]);
    });
  }
  waitForInit.then(() => {
    nativeAppClientInstance.executeVibration({ type: "tapFeedback" });
  });
}

/**
 * Setting provided from GU App
 * to add/remove product to/from wishlist
 */
export function addorRemoveNativeAppFavoriteItems({
  isFavourite,
  items
}: INativeItemTypes): Promise<{ status: boolean }> {
  return new Promise((resolve, reject) => {
    let waitForInit: Promise<void | {}> = Promise.resolve();
    if (!nativeAppClientInstance) {
      waitForInit = new Promise((res, rej) => {
        pendingCalls.push([res, rej]);
      });
    }
    const nativeAppFunction = isFavourite
      ? "addFavoriteItems"
      : "deleteFavoriteItems";

    waitForInit
      .then(() => {
        if (!nativeAppClientInstance[nativeAppFunction]) {
          reject(`[mobileapp.js] ${nativeAppFunction} function not found.`);
        }
        nativeAppClientInstance[nativeAppFunction](
          { items },
          ({
            status,
            errors
          }: {
            status: Number;
            errors: { detail: String }[];
          }) => {
            if (errors) {
              reject(
                `[mobileapp.js] No result received from ${nativeAppFunction}: ${errors.map(
                  (error: { detail: String }) => error.detail
                )}`
              );
            }
            if (status !== 200) {
              reject(`Error status: ${status} from ${nativeAppFunction}`);
            }
            resolve({ status: Boolean(status) });

            if (isFavourite) {
              executeVibration();
            }
          }
        );
      })
      .catch((e) => {
        reject(
          `[mobileapp.js] Error in helpers/nativeApp:${nativeAppFunction} ${e.message}`
        );
      });
  });
}

/**
 * Setting provided from GU App
 * to search favourite items from wishlist
 */
export function searchNativeFavoriteItems({
  items
}: {
  items: string[];
}): Promise<{ skuIds: INativeItemTypes["items"] }> {
  return new Promise((resolve, reject) => {
    let waitForInit: Promise<void | {}> = Promise.resolve();
    if (!nativeAppClientInstance) {
      waitForInit = new Promise((res, rej) => {
        pendingCalls.push([res, rej]);
      });
    }
    waitForInit
      .then(() => {
        if (!nativeAppClientInstance.searchFavoriteItems) {
          reject("[mobileapp.js] searchFavoriteItems function not found.");
        }
        nativeAppClientInstance.searchFavoriteItems(
          { items },
          ({
            result,
            status,
            errors
          }: {
            result: INativeItemTypes["items"];
            status: Number;
            errors: { detail: String }[];
          }) => {
            if (errors) {
              reject(
                `[mobileapp.js] No result received from searchFavoriteItems: ${errors.map(
                  (error: { detail: String }) => error.detail
                )}`
              );
            }
            if (status !== 200) {
              reject(`Error status: ${status} from searchFavoriteItems`);
            }
            if (!result) {
              throw new Error("No result received from searchFavoriteItems");
            }
            resolve({
              skuIds: result.map(({ l2Id, isRepresentative }) => ({
                l2Id,
                isRepresentative
              }))
            });
          }
        );
      })
      .catch((e) => {
        reject(
          `[mobileapp.js] Error in helpers/nativeApp:searchFavoriteItems ${e.message}`
        );
      });
  });
}

export const isNativeAppByUserAgent = (userAgent: string) => {
  const devicePattern =
    /FRNativeApp(\/uq-ios|\/uq-android|\/pl-android|\/pl-ios)|gu-app/i;
  return devicePattern.test(userAgent);
};

export const getNativeAppType = () => {
  const iOSPattern = /FRNativeApp(\/uq-ios|\/pl-ios)|(iOS Application gu-app)/i;
  const androidPattern =
    /FRNativeApp(\/uq-android|\/pl-android)|(Android Application gu-app)/i;
  const userAgent = navigator.userAgent;

  if (iOSPattern.test(userAgent)) {
    return NativeAppType.IOS;
  } else if (androidPattern.test(userAgent)) {
    return NativeAppType.ANDROID;
  }
  return NativeAppType.MOBILE;
};

/**
 * Search Favourite Styles in GUApp
 */
interface searchFavoriteOfficialStylesResponseType {
  status: number;
  count: number;
  result: { styleId: string; timeStamp: number; createTimestamp: number }[];
  errors?: { pointer?: string; detail: string };
}
export function searchFavoriteStyles(
  styles: string[],
  type: string
): Promise<{
  stylingIds: string[];
}> {
  return new Promise((resolve, reject) => {
    let waitForInit: Promise<void | {}> = Promise.resolve();
    if (!nativeAppClientInstance) {
      waitForInit = new Promise((res, rej) => {
        pendingCalls.push([res, rej]);
      });
    }
    waitForInit
      .then(() => {
        let nativeAppFunction:
          | "searchFavoriteOfficialStyles"
          | "searchFavoriteUserStyles" = "searchFavoriteOfficialStyles";
        if (type === StylingAbbrType.SH) {
          nativeAppFunction = "searchFavoriteUserStyles";
        }
        if (!nativeAppClientInstance[nativeAppFunction]) {
          reject(`${nativeAppFunction} function not found`);
        }
        nativeAppClientInstance[nativeAppFunction](
          { styles, sortOrder: "desc" },
          ({
            status,
            result,
            errors
          }: searchFavoriteOfficialStylesResponseType) => {
            if (!result) {
              reject(`No result received from ${nativeAppFunction}`);
            }
            if (errors) {
              reject(
                `Error detail from ${nativeAppFunction}: ${errors.detail}`
              );
            }
            if (status !== 200) {
              reject(`Error status: ${status} from ${nativeAppFunction}`);
            }
            resolve({ stylingIds: result.map(({ styleId }) => styleId) });
          }
        );
      })
      .catch(() => {
        reject({});
      });
  });
}

/**
 * Add Favourite Styles in GUApp
 */

interface addFavoriteStylesResponseType {
  status: number;
  errors?: { pointer?: string; detail: string }[];
}
export function addFavoriteStyles(
  styleId: string,
  type: StylingAbbrType.SH | StylingAbbrType.SB
): Promise<{
  status: boolean;
}> {
  return new Promise((resolve, reject) => {
    let waitForInit: Promise<void | {}> = Promise.resolve();
    if (!nativeAppClientInstance) {
      waitForInit = new Promise((res, rej) => {
        pendingCalls.push([res, rej]);
      });
    }

    waitForInit
      .then(() => {
        let nativeAppFunction:
          | "addFavoriteOfficialStyles"
          | "addFavoriteUserStyles" = "addFavoriteOfficialStyles";
        if (type === StylingAbbrType.SH) {
          nativeAppFunction = "addFavoriteUserStyles";
        }
        if (!nativeAppClientInstance[nativeAppFunction]) {
          reject(`${nativeAppFunction} function not found`);
        }
        const styles = [{ styleId }];
        nativeAppClientInstance[nativeAppFunction](
          { styles },
          ({ status, errors }: addFavoriteStylesResponseType) => {
            if (status !== 200) {
              reject(`Error status: ${status} from ${nativeAppFunction}`);
            }
            if (errors) {
              reject(
                `Error detail from ${nativeAppFunction}: ${errors
                  .map((error: { detail: String }) => error.detail)
                  .toString()}`
              );
            }
            resolve({ status: Boolean(status) });
          }
        );

        executeVibration();
      })
      .catch(() => {
        reject({});
      });
  });
}

/**
 * Delete Favourite Styles in GUApp
 */
interface deleteFavoriteStylesResponseType {
  status: number;
  errors?: { pointer?: string; detail: string }[];
}
export function deleteFavoriteStyles(
  styleId: string,
  type: string
): Promise<{
  status: boolean;
}> {
  return new Promise((resolve, reject) => {
    let waitForInit: Promise<void | {}> = Promise.resolve();
    if (!nativeAppClientInstance) {
      waitForInit = new Promise((res, rej) => {
        pendingCalls.push([res, rej]);
      });
    }

    waitForInit
      .then(() => {
        let nativeAppFunction:
          | "deleteFavoriteOfficialStyles"
          | "deleteFavoriteUserStyles" = "deleteFavoriteOfficialStyles";
        if (type === StylingAbbrType.SH) {
          nativeAppFunction = "deleteFavoriteUserStyles";
        }
        if (!nativeAppClientInstance[nativeAppFunction]) {
          reject(`${nativeAppFunction} function not found`);
        }
        const styles = [{ styleId }];
        nativeAppClientInstance[nativeAppFunction](
          { styles },
          ({ status, errors }: deleteFavoriteStylesResponseType) => {
            if (status !== 200) {
              reject(`Error status: ${status} from ${nativeAppFunction}`);
            }
            if (errors) {
              reject(
                `Error detail from ${nativeAppFunction}: ${errors
                  .map((error: { detail: String }) => error.detail)
                  .toString()}`
              );
            }
            resolve({ status: Boolean(status) });
          }
        );
      })
      .catch(() => {
        reject({});
      });
  });
}

setTimerId();
