export enum StylingType {
  official = "officialstyling",
  stylehint = "stylehint"
}

export enum StylingAbbrType {
  SH = "sh",
  SB = "sb"
}

export enum SiteType {
  Mobile = "mobile",
  Desktop = "desktop",
  Responsive = "responsive"
}

export enum Media {
  APP = "app",
  WEB = "web"
}

export enum DeviceType {
  PC = "PC",
  SP = "SP",
  RS = "RS" // * Responsive
}

export enum PageType {
  StylingBook = "stylingbook"
}

export enum BRAND_LIST {
  UQ = "uq",
  GU = "gu",
  PL = "pl"
}

export enum GABrandList {
  UQ = "uq",
  GU = "GU",
  PL = "PL"
}

export enum BRAND_NAME_LIST {
  UQ = "uniqlo",
  GU = "gu",
  PL = "plst"
}

export enum COUNTRY {
  JP = "jp",
  US = "us",
  KR = "kr",
  UK = "uk",
  SE = "se",
  DE = "de",
  DK = "dk",
  FR = "fr",
  ES = "es",
  IT = "it",
  AT = "at",
  FI = "fi",
  GR = "gr",
  PT = "pt",
  BE = "be",
  NL = "nl",
  LV = "lv",
  PL = "pl",
  EE = "ee",
  SI = "si",
  HU = "hu",
  CZ = "cz",
  SK = "sk",
  LT = "lt",
  HR = "hr",
  LU = "lu",
  RO = "ro",
  IE = "ie",
  BG = "bg"
}

export enum LANGUAGE {
  EN = "en",
  JA = "ja",
  KO = "ko",
  FR = "fr",
  DE = "de",
  ES = "es",
  NL = "nl",
  IT = "it"
}

export type COUNTRY_CONFIG<T> = { [key in COUNTRY]: T };
export type COUNTRY_CONFIG_WITH_LANG<T> = {
  [key in COUNTRY]: { [L in LANGUAGE]?: T };
};

export type RouterUrlType = {
  region: string;
  language: string;
  type: string;
};

export type Maybe<T> = T | null | undefined;

export enum StylingSortEnum {
  latest = "latest", // 新着
  popular = "popular" // 人气
}

export enum SortRouterParamEnum {
  published = "published",
  likes = "likes"
}

export enum StyleGenderEnum {
  WOMEN = "WOMEN",
  MEN = "MEN",
  KIDS = "KIDS",
  BABY = "BABY"
}
export type StyleGenderType = 1 | 2 | 3 | 4;

export type DepartmentIdEnum = "men" | "women" | "kids" | "baby";

export enum UserTypeEnum {
  CONSUMER = "CONSUMER",
  OFFICIAL = "OFFICIAL",
  STAFF = "STAFF",
  KOL = "KOL",
  KOL_BADGE = "KOL_BADGE",
  STYLE_HINTER = "STYLE_HINTER"
}
export type UserTypeTypes = keyof typeof UserTypeEnum;

export const UserTypeMap: Record<UserTypeEnum, number> = {
  [UserTypeEnum.CONSUMER]: 0,
  [UserTypeEnum.OFFICIAL]: 1,
  [UserTypeEnum.STAFF]: 2,
  [UserTypeEnum.KOL]: 3,
  [UserTypeEnum.KOL_BADGE]: 4,
  [UserTypeEnum.STYLE_HINTER]: 5
};

export type FlagColorEnum = "normal" | "discount";

export enum StockStatusEnum {
  IN_STOCK = "IN_STOCK",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  COLOR_OUT_OF_STOCK = "COLOR_OUT_OF_STOCK"
}
export type StockStatusType = keyof typeof StockStatusEnum;

export enum STYLING_SORT_BY {
  PUBLISHED_AT_DESC = "published_at:desc",
  SH_LIKES_7DAYS_DESC = "sh_likes_7days:desc",
  SH_LIKES_31DAYS_DESC = "sh_likes_31days:desc",
  EC_LIKES_TOTAL_DESC = "ec_likes_total:desc",
  EC_LIKES_31DAYS_DESC = "ec_likes_31days:desc",
  DISPLAY_START_AT_DESC = "display_start_at:desc"
}

export type ProductColors =
  | "white"
  | "gray"
  | "black"
  | "pink"
  | "red"
  | "orange"
  | "beige"
  | "brown"
  | "yellow"
  | "green"
  | "blue"
  | "purple";

export enum ImageType {
  styles = "styles",
  items = "items",
  carousel = "carousel"
}

export interface ErrorResponse {
  id?: string;
  code: number;
  message?: string;
  details: Array<{ [key: string]: string }>;
  httpStatusCode?: number;
}

/**
 * Styling Filters Type
 */
export interface FiltersPayload {
  departmentId?: string; // department Id
  type: string; // Styling Book (sb) or StyleHint (sh)
  httpFailure?: string;
  rootId: string | number;
}

export interface FiltersResponse {
  status: string;
  result: FiltersResponseType;
}

export interface HashtagsType {
  hashtag: string;
}

export interface FiltersResponseType {
  styleCategories?: Array<StyleCategoriesTreeType>;
  tagCategories?: Array<TagCategoriesType>;
  features?: Array<FeaturesType>;
  hashtags?: Array<HashtagsType>;
}

export interface StylingFiltersData {
  loading: boolean;
  featureList: Array<FeaturesType>;
  coordinationList: Array<TagCategoriesType>;
  hashtagList: Array<HashtagsType>;
  categoryList: Array<StyleCategoriesTreeType>;
  isReloadFilters: boolean;
}

export interface StyleCategoriesType {
  id?: number;
  name?: string;
  localizedName?: string; // Style category name in regional language
  styleCount?: number; // Number of approved outfits for given StyleHint category
}
export interface StyleCategoriesTreeType extends StyleCategoriesType {
  styleCategories?: Array<StyleCategoriesType>;
}

export interface TagCategoriesType {
  totalStyles?: number; // The count of the styles which are tagged with current tag
  code?: string; // The identiter of the tag
  name?: string; // The name of the tag
  displayCode?: string; // The tag display code
}

export interface FeaturesType {
  id?: string; // Represents abstract description name
  title?: string; // The title  describes the feature
}

/**
 * Stylehint Styling Type
 */
export interface LargeStylehintStylingPayload {
  genderCode?: string; // 1 = MEN, 2 = WOMEN, 3 = KIDS,  4 = BABY
  httpFailure?: string;
}

export interface LargeStylehintStylingResponse {
  status: string;
  result: Array<SHlargeImages>;
}

export interface SHlargeImages {
  order?: number;
  hashtag?: string;
  outfitId?: number;
  imageUrl?: string;
  description?: string;
  createdAt?: number;
  updatedAt?: number;
  styleGender?: StyleGenderType; // 1 = MEN, 2 = WOMEN, 3 = KIDS,  4 = BABY
}

export type StylehintListPayload = {
  departmentIds?: string;
  hashTags?: string;
  categoryIds?: string;
  userType?: string; // 0 = consumer, 1 = official, 2 = staff, 3 = KOL, 4 = KOL(Badge), 5 = StyleHinter
  colors?: string; // Available values : white, gray, blue, purple, black, pink, red, orange, beige, brown, yellow, green
  minModelHeight?: number;
  maxModelHeight?: number;
  unit?: string; // Unit of model height. If maxModelHeight and minModelHeight is not present, this parameter will be ignored.
  order?: string;
  limit?: number; // default value is 10. Max value is 50.
  offset?: number;
  httpFailure?: string;
  productId?: string;
  sortByPriority?: boolean; // priority flag
};

export interface StylehintListResponse {
  status: string;
  result: StylehintListType;
}

export interface StylehintListData {
  loading: boolean;
  stylehint_list: StylehintListType;
  large_loading: boolean;
  stylehint_large_list: Array<SHlargeImages>;
  hasMore: boolean;
  current_list: Array<SHNormalImages>;
  noResult: boolean;
  errorCode: string;
  currentSort: StylingSortEnum;
  hasClickSort: boolean;
  SH_list: Array<StylesInfo>;
}

export interface StylehintListType {
  pagination: Pagination;
  images: Array<SHNormalImages>;
}

export interface Pagination {
  count?: number;
  total?: number;
  offset?: number;
}

export interface SHNormalImages {
  outfitId?: number;
  styleImageUrl?: string;
  isFromInstagram?: boolean;
  originalSourceUrl?: string;
  departmentId?: DepartmentIdEnum;
  totalLikes?: number;
  last7DaysLikes?: number;
}

/**
 * Official Styling Type
 */
export interface LargeOfficialStylingPayload {
  httpFailure?: string;
  featureIds?: string;
  departmentIds?: string;
}

export interface LargeOfficialStylingResponse {
  status: string;
  result: SBlargeImagesData;
}

export interface SBlargeImagesData {
  styles: Array<SBlargeImages>;
}

export interface SBlargeImages {
  featureId: string;
  imageUrl?: string;
  title: string;
  description: string;
}

export interface OfficialListPayload {
  httpFailure?: string;
  tagCodes?: string; // tagCodes
  limit?: number; // Default value is 10. Max value is 50.
  order?: string;
  offset?: number; // A number of offset for pagination, default value is 0
  categoryIds?: string; // categoryIds Ids
  departmentIds?: string; // department Ids.
  featureIds?: string; // feature Ids
  colors?: string; // Available values : white, gray, blue, purple, black, pink, red, orange, beige, brown, yellow, green
  productIds?: string; // Identifier of Product.
}

export interface OfficialListResponse {
  status: string;
  result: OfficialListType;
}

export interface OfficialListType {
  styles: Array<SBNormalImages>;
  pagination: Pagination;
}

export interface SBNormalImages {
  styleId: string;
  imageUrl: string;
}

export interface OfficialListData {
  loading: boolean;
  official_list: OfficialListType;
  large_loading: boolean;
  official_large_list: Array<SBlargeImages>;
  hasMore: boolean;
  current_list: Array<SBNormalImages>;
  noResult: boolean;
  errorCode: string;
  showFilterPanel: boolean;
  SB_list: Array<StylesInfo>;
  currentSort: StylingSortEnum;
  hasClickSort: boolean;
}

/**
 * Styling Detail Type
 */
export interface DetailStylingPayload {
  styleId: string;
  httpFailure?: string;
  type: string; // Styling Book (sb) or StyleHint (sh)
  imageRatio?: string; // select the aspect ratio of images by 1x1 or 3x4
}

export interface StylingDetailResponse {
  status: string;
  result: StylingDetailType;
  error?: ErrorResponse;
}

export interface UserInfoBase {
  image?: string;
  name: string;
  height: number;
  isFromInstagram: boolean;
  userType: UserTypeTypes;
}

export interface SHStoreInfo {
  storeName: string;
  brand: string;
  region: string;
  storeId?: number;
}

export interface UserInfoOther {
  validatedDate: number; // Date on which style was validated. It will be 10 digit unix timestamp
  comment: string;
  id?: number; // Author ID in UGC
}

export interface FlagComponent {
  code: string; // Identifiers for the flags
  id: number;
  name: string; // Localized name of the product's flags
  flagColor: FlagColorEnum;
  nameWording?: {
    flagWithTime: string; // Localization ID of flag test with time. Clients should use this field instead of name if its present.
    substitutions?: {
      date?: string;
      month?: string;
      flagName?: string;
      startDate?: string;
    };
  };
}

export interface FlagsType {
  productFlags: Array<FlagComponent>;
  priceFlags: Array<FlagComponent>;
}

export interface CurrencyType {
  code: string;
  symbol: string;
}

export interface PriceType {
  base: {
    currency: CurrencyType;
    value: number;
  };
  promo: {
    currency: CurrencyType;
    value: number;
  };
  isDualPrice?: boolean;
  discountPercentage?: Maybe<string | number>;
}

export type GenderCategory = "MEN" | "WOMEN" | "KIDS" | "BABY" | "UNISEX";

export interface ProductDetailType {
  l2Id: string; // Product identifier
  productName: string; // Name of the product in PDP page
  productId: string; // productId of the product that is related to the style
  priceGroup: string; // priceGroup of the product that is related to the style
  box?: Array<number>;
  flags: FlagsType;
  stockStatus?: StockStatusType;
  price: PriceType;
  genderCategory?: GenderCategory;
  pdpImageUrl: string; // This will be url of product image
  color: {
    name: string; // Localized name
    code: string; // example: AAA00
    displayCode: string; // example: 00
  };
  active?: boolean; // Deprecated on GP4.0-3.0
  size: string; // Size of the product used by StyleHint user
  promotionText?: string; // king promotion text
}

export interface ProductModels {
  positionId?: string; // model position
  positionName?: string; // position of the model in style image
  products: Array<ProductDetailType>;
}

export type UserInfoType = UserInfoBase & SHStoreInfo & UserInfoOther;
export interface StylingDetailType {
  last7DaysLikes?: number; // Likes received in last 7 days
  totalLikes?: number; // Total likes recieved
  userInfo?: UserInfoType;
  styleImageUrl: string; // Link to style image
  publishedAt?: number; // Date on which outfit was approved
  hashtags?: Array<string>; // Hashtags added by user for the outfit
  modelHeight?: number; // height of the model
  gender?: number; // 1:Men, 2:Women, 3:Kids, 4:Baby, 5:Prefer Not to Say. Gender type the dress is made for. This is only present for StyleHint
  models: Array<ProductModels>;
  count?: string; // For display wish count
  last31DaysLikes?: number; // Likes received in last 31 days
  postTag?: string; // To identify the nature of outfit post, for normal outfits this field will not be present
}

export interface ImageInfo {
  styleId: string;
  styleImageUrl: string;
}

export interface StylesInfo extends ImageInfo {
  isFavorite: boolean;
  likeCounts?: number;
}

export enum DetailFromPageEnum {
  top = "top",
  related = "related",
  changeId = "changeId",
  changeTab = "changeTab"
}
export interface DetailStylingData {
  detailInfo: StylingDetailType;
  loading: boolean;
  carouselList: Array<ImageInfo>;
  carouselIndex: number;
  fromPage: string;
  previousProductIds: string;
  errorCode: string;
  likesCount: number;
  likesCountLoading: boolean;
}

/**
 * Related Styling StyleHint Type
 */
export interface RelatedStylingStylehintPayload {
  productIds: string;
  departmentIds?: string;
  order?: string;
  userType?: string; // 0 = Consumer 1 = Official 2 = Staff 3 = KOL 4 = KOL(Badge) 5 = StyleHinter
  limit?: number; // default value is 10. Max value is 25.
  offset?: number;
  httpFailure?: string;
}

export interface RelatedStylingStylehintResponse {
  status: string;
  result: RelatedStylingStylehintObj;
}

export interface RelatedStylingStylehintObj {
  styles?: Array<RelatedStylingStylehintType>;
}

export type RelatedStylingStylehintUserInfoType = UserInfoBase &
  SHStoreInfo & { id?: number };
export interface RelatedStylingStylehintType {
  last7DaysLikes?: number; // Likes received in last 7 days
  totalLikes?: number; // Total likes recieved
  id: number; // UGC ID of the outfit
  image?: string; // Link to style image
  userInfo?: RelatedStylingStylehintUserInfoType;
  gender?: string; // Gender type the outfit is made for
  modelHeight?: number; // Height of the model
}

export interface RelatedStylingStylehintData {
  styles: Array<RelatedStylingStylehintType>;
  loading: boolean;
  carousel_styles: Array<SHNormalImages>;
  carousel_loading: boolean;
}

/**
 * Related Styling Official Type
 */
export interface RelatedStylingOfficialData {
  styles: Array<SBNormalImages>;
  loading: boolean;
  carousel_styles: Array<SBNormalImages>;
  carousel_loading: boolean;
}

/**
 * Favorites Styling Type
 */
export interface FavoritesStylingPayload {
  ids: string;
  httpFailure?: string; // default: false
  type?: string; // sb|sh. Default value : sb
  fromDetail?: boolean; // whether from detail page
}

export interface FavoritesStylingCountResponse {
  result: {
    count: number;
  };
  status: string;
}

export type FavoritesStylingData = Array<string>;

export interface FavoritesStylingResponse {
  result: FavoritesStylingData;
  status: string;
}

export interface FavoritesStylingDataType {
  list: FavoritesStylingData;
  type: string; // sb|sh
  fromDetail?: boolean; // whether from detail page
  query?: Array<string>; // request payload
}

export type PostFavoritesStylingPayload = {
  type?: string; // sb|sh. Default value : sb
  body: [{ styleId: string }];
  isCarouselImg?: boolean; // only carousel img display wish count
};

export type OperateFavoritesResponse = { status: string };

export type DelFavoritesStylingPayload = {
  styleId: string;
  httpFailure?: string; // default: false
  type?: string; // sb|sh. Default value : sb
  isCarouselImg?: boolean; // only carousel img display wish count
};

export type StylingWishCountPayload = {
  styleId: string;
  type: string; // sb|sh. Default value : sb
};

/**
 * Favorites Item Type
 */
export interface FavoritesItemPayload {
  httpFailure?: string; // default: false
  l2Ids: string[];
  isGUNativeApp?: boolean;
}

export interface FavoritesItemData {
  [key: string]: boolean;
}

export interface FavoritesItemResponse {
  result: FavoritesItemData;
  status: string;
}

export type PostFavoritesItemPayload = [
  { l2Id: string; isRepresentative: boolean }
];

export type NewPostFavoritesItemPayload = {
  body: PostFavoritesItemPayload;
  isGUNativeApp: boolean;
};

export type DelFavoritesItemPayload = {
  httpFailure?: string; // default: false
  id: string;
  ids?: string;
  isGUNativeApp?: boolean;
};

export interface StylingWishlistData {
  stylingLoading: boolean;
  stylingSBWishlist: FavoritesStylingData;
  stylingSHWishlist: FavoritesStylingData;
}

export interface ItemWishListMainData {
  image: string;
}

export interface ItemWishListImagesData {
  main: {
    [color: string]: ItemWishListMainData;
  };
  chip: {
    [color: string]: string;
  };
}

export interface ItemWishlistData {
  itemLoading: boolean;
  favoritesItemList: Array<string>;
  sysError: boolean;
  skusLoading: boolean;
  allL2s: {
    [color: string]: L2[];
  };
  images?: ItemWishListImagesData;
}

export interface ProductSkusPayload {
  productId: string;
  priceGroup: string;
}

export interface ProductSkusResponse {
  status: string;
  result: ProductSkusData;
  error?: ErrorResponse;
}

export interface ProductSkusData {
  wishlist?: Array<WishlistL2ItemType>;
  l2s?: Array<L2>;
  images?: ItemWishListImagesData;
}

export type WishlistL2ItemType = {
  l2Id: string;
  productId?: string;
  display: boolean;
  isRepresentative: boolean;
};

export type L2 = {
  l2Id: string;
  color: SizeColorType;
  size: SizeColorType;
  pld: PatternLengthDimensionFrontend;
  isWishlisted: boolean;
  imageUrl: string;
};

export type SizeColorType = {
  name: string;
  code: string;
  displayCode: string;
  filterCode: string;
  display: Display;
};

export type PatternLengthDimensionFrontend = {
  name: string;
  code: string;
  displayCode: string;
  filterCode: string;
  display: Display;
};

export type Display = { showFlag: boolean; chipType: number };

/**
 * User Profile Type
 */
export type UserProfileResponse = {
  result: UserProfileResponseData;
  status: string;
};

export type OtherUserInfoType = {
  id?: number;
  bio?: string;
  storeOrgCode?: string;
};

export type UserProfileResponseData = {
  pagination: Pagination;
  userInfo?: UserInfoBase & SHStoreInfo & OtherUserInfoType;
  styles?: Array<UserProfileStyles>;
};
export interface UserProfileData extends UserProfileResponseData {
  limit: number;
  hasMore: boolean;
  errorCode: string;
  loading: boolean;
  current_list: Array<string>;
}

export type UserProfileStyles = {
  last7DaysLikes: number;
  totalLikes: number;
  id: number;
  originalSourceUrl: string;
  isFromInstagram?: boolean;
  styleImageUrl: string;
  modelHeight: number;
};

export type UserProfilePayload = {
  limit: number;
  offset: number;
  userId: number;
};

/**
 * Firebase Gender Type
 */
export type GenderObjType = {
  women: boolean;
  men: boolean;
  kids: boolean;
  baby: boolean;
};

export type FirebaseGenderType = {
  sb: GenderObjType;
  sh: GenderObjType;
};

export interface GenderOptionType {
  key: string;
  value: string;
  code: number;
  lid: string;
  displayLid: string;
}

export type FirebaseDataType = {
  sb_tab_gender: GenderObjType;
  sh_tab_gender: GenderObjType;
  sb_wish_icon: boolean;
  sb_wish_count: boolean;
  sh_wish_icon: boolean;
  sh_wish_count: boolean;
};

/**
 * Firebase Wish Type
 */
export type WishObjType = {
  icon: boolean;
  count: boolean;
};

export type FirebaseWishType = {
  sb: WishObjType;
  sh: WishObjType;
};

/**
 * PageView
 */
export type PageViewBaseOption = {
  event: string;
  pageUrlPath: string;
  pageTitle: string;
  previousPageExists: boolean;
  brand: GABrandList;
  siteType: SiteType;
  pageType: PageType;
  subID: string;
  loginState: boolean;
};

/**
 * TrackEvent
 */
export type GATrackEventType = {
  _clear?: boolean;
  brand?: GABrandList;
  event?: string;
  value?: number;
  eventAction: string;
  eventCategory: string;
  eventLabel: string;
  eventValue?: string;
};

/**
 * Country Env
 */
// export type CountryEnv = Partial<EnvType>;
export type CountryEnv = {
  clientId: string;
  sbRootId: string | number;
  shRootId: string | number;
  firebaseApiKey: string;
  firebaseProjectId: string;
  firebaseAppId: string;
  firebaseMeasurementId: string;
  storeDomain: string;
  faqUrl: string;
  showAboutApp: boolean;
  showDualPrice: boolean;
  languages: Array<LANGUAGE>;
};

export interface HrefLangListType {
  hrefLang: string;
  alternateHref: string;
}

export type CMSPayloadType = {
  path: string;
  device: string;
};

export type CMSLoadingPayloadType = {
  loading: boolean;
};

export type CMSComponentSchema = {
  _type: string;
  [prop: string]: any;
};

export type CMSSuccessType = {
  content: Array<CMSComponentSchema>;
  path: string;
};

export interface CMSBannerResponse {
  status: string;
  result: {
    body: Array<CMSComponentSchema>;
  };
  error?: ErrorResponse;
}

export declare const IMAGE_RATIO_1X1 = "1x1";
export declare const IMAGE_RATIO_16X9 = "16x9";
export declare const IMAGE_RATIO_3X4 = "3x4";
export declare const IMAGE_RATIO_4X3 = "4x3";
export declare const IMAGE_RATIO_2X1 = "2x1";
export declare const IMAGE_RATIO_6X1 = "6x1";
export declare const IMAGE_RATIO_3X1 = "3x1";
export declare const IMAGE_RATIO_5X2 = "5x2";
export declare const IMAGE_FULL_WIDTH = "full-width";
export type ImageRatio =
  | typeof IMAGE_RATIO_1X1
  | typeof IMAGE_RATIO_16X9
  | typeof IMAGE_RATIO_3X4
  | typeof IMAGE_RATIO_4X3
  | typeof IMAGE_RATIO_3X1
  | typeof IMAGE_RATIO_6X1
  | typeof IMAGE_RATIO_5X2
  | typeof IMAGE_RATIO_2X1
  | typeof IMAGE_FULL_WIDTH;
