import { PageType, PageViewBaseOption, SiteType } from "@type/index";
import store from "@redux/store";
import {
  checkPreviousPageExists,
  transformBrandForGoogleAnalytics
} from "./utils";

const { brand_name } = store.getState().config;

export const EVENT = "trackPageView";

export const getPageViewBaseOption = (
  pageType: PageType,
  subId: string,
  pageTitle: string,
  extraData?: Record<string, unknown>
): PageViewBaseOption => ({
  event: EVENT,
  pageUrlPath: `${window.location.pathname}${window.location.search}${window.location.hash}`,
  pageTitle: pageTitle,
  previousPageExists: checkPreviousPageExists(),
  brand: transformBrandForGoogleAnalytics(brand_name),
  siteType: SiteType.Responsive,
  pageType: pageType,
  subID: subId,
  loginState: subId.length > 0,
  ...(extraData && extraData)
});
