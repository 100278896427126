import { useCallback, useEffect, useRef } from "react";
import {
  BRAND_LIST,
  COUNTRY,
  FeaturesType,
  GenderOptionType,
  HashtagsType,
  ImageInfo,
  LANGUAGE,
  OfficialListPayload,
  SBNormalImages,
  SHNormalImages,
  StyleCategoriesTreeType,
  StylehintListPayload,
  StylingSortEnum,
  StylingType,
  StylesInfo,
  TagCategoriesType,
  ProductColors,
  STYLING_SORT_BY,
  UserTypeMap,
  UserTypeEnum
} from "@type/index";
import { isNotEmptyStr } from "@util/helpers";
import { userHeight } from "@util/height";
import { isGuKids } from "@util/country";
import {
  FavoritesStylingActions,
  OfficialListActions,
  StylehintListActions,
  StylingFilterActions
} from "@redux/reducer";
import { isGUNativeApp } from "@util/nativeApp";
import { FR_UserAgent } from "@config/common";
import { getUA } from "react-device-detect";
import { HeightOptions } from "./heightOptions";
import store from "../../../redux/store";

export enum FilterType {
  feature = "feature",
  category = "category",
  coordination = "coordination",
  colors = "colors",
  model = "model",
  height = "height",
  hashtag = "hashtag"
}

export enum RouteParamType {
  feature = "featureId",
  category = "catId",
  coordination = "coordinate",
  colors = "colorId",
  model = "model",
  height = "height",
  hashtag = "hashtag",
  order = "order"
}

export enum FilterRouteType {
  featureId = FilterType.feature,
  catId = FilterType.category,
  coordinate = FilterType.coordination,
  colorId = FilterType.colors,
  model = FilterType.model,
  height = FilterType.height,
  hashtag = FilterType.hashtag
}
export type FilterRouteTypes = keyof typeof FilterRouteType;

export interface FilterOptionType {
  key: string;
  value: string;
  code?: number | string;
  children?: Array<any>;
}

export type AccordionObjType = {
  [key: string]: boolean;
};

export type RouteValueType = {
  tagValue: Array<string>;
  routeValue: Array<string>;
};

export type FilterRouteObjType = {
  [key: string]: RouteValueType;
};

export interface SelectedData {
  type: FilterType;
  value: Array<string>;
}

export interface FilterTagType {
  key: FilterType;
  value: string;
  index: number;
}

export const filterRouteList: Array<FilterRouteTypes> = [
  RouteParamType.feature,
  RouteParamType.category,
  RouteParamType.coordination,
  RouteParamType.colors,
  RouteParamType.model,
  RouteParamType.height,
  RouteParamType.hashtag
];

export const accordionOfficialObj = {
  [FilterType.feature]: false,
  [FilterType.category]: false,
  [FilterType.coordination]: false,
  [FilterType.colors]: false
};

export const accordionStylehintObj = {
  [FilterType.model]: true,
  [FilterType.height]: true,
  [FilterType.hashtag]: false,
  [FilterType.category]: false,
  [FilterType.colors]: false
};

export const filterRouteObj = {
  [FilterType.feature]: {
    tagValue: [],
    routeValue: []
  },
  [FilterType.coordination]: {
    tagValue: [],
    routeValue: []
  },
  [FilterType.model]: {
    tagValue: [],
    routeValue: []
  },
  [FilterType.height]: {
    tagValue: [],
    routeValue: []
  },
  [FilterType.hashtag]: {
    tagValue: [],
    routeValue: []
  },
  [FilterType.category]: {
    tagValue: [],
    routeValue: []
  },
  [FilterType.colors]: {
    tagValue: [],
    routeValue: []
  }
};

export type ColorOptionType = {
  key: ProductColors; // white, gray, black, pink, red, orange, beige, brown, yellow, green, blue, purple
  value: string;
};
export const colorOptions: Array<ColorOptionType> = [
  { key: "white", value: "White" },
  { key: "gray", value: "Gray" },
  { key: "black", value: "Black" },
  { key: "pink", value: "Pink" },
  { key: "red", value: "Red" },
  { key: "orange", value: "Orange" },
  { key: "beige", value: "Beige" },
  { key: "brown", value: "Brown" },
  { key: "yellow", value: "Yellow" },
  { key: "green", value: "Green" },
  { key: "blue", value: "Blue" },
  { key: "purple", value: "Purple" }
];

export type OptionsType = {
  key: string;
  value: string;
  code?: string;
  min?: string;
  max?: string;
  lid?: string;
};

// debounce func
export const useDebounce = (fn: any, delay: number, dep = []) => {
  const { current } = useRef({ fn, timer: null });
  useEffect(() => {
    current.fn = fn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fn]);

  return useCallback(function f(this: unknown, ...args: any[]) {
    if (current.timer) {
      clearTimeout(current.timer);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    current.timer = setTimeout(() => {
      current.fn.call(this, ...args);
    }, delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dep);
};

export const mapHashtagListToOptions = (
  arr: Array<HashtagsType>
): Array<FilterOptionType> => {
  let newArr: Array<FilterOptionType> = [];
  if (arr.length > 0) {
    newArr = arr.map((item: HashtagsType) => {
      const newItem: FilterOptionType = {
        key: item.hashtag,
        value: item.hashtag
      };
      return newItem;
    });
  }
  return newArr;
};

export const mapFeatureListToOptions = (
  arr: Array<FeaturesType>
): Array<FilterOptionType> => {
  let newArr: Array<FilterOptionType> = [];
  if (arr.length > 0) {
    newArr = arr.map((item: FeaturesType) => {
      const newItem: FilterOptionType = {
        key: item.id ? item.id.toString() : "",
        value: item.title ? item.title.toString() : ""
      };
      return newItem;
    });
  }
  return newArr;
};

export const mapCoordinationListToOptions = (
  arr: Array<TagCategoriesType>
): Array<FilterOptionType> => {
  let newArr: Array<FilterOptionType> = [];
  if (arr.length > 0) {
    newArr = arr.map((item: TagCategoriesType) => {
      const newItem: FilterOptionType = {
        key: item.code ? item.code.toString() : "",
        value: item.name ? item.name.toString() : "",
        code: item.name ? item.name.toString() : ""
      };
      return newItem;
    });
  }
  return newArr;
};

export const mapCategoryListToOptions = (
  arr: Array<StyleCategoriesTreeType>
): Array<FilterOptionType> => {
  let newArr: Array<FilterOptionType> = [];
  if (arr.length > 0) {
    newArr = arr.map((item: StyleCategoriesTreeType) => {
      const newItem: FilterOptionType = {
        key: item.id ? item.id.toString() : "",
        value: item.localizedName ? item.localizedName.toString() : "",
        code: item.name ? item.name.toString() : "",
        children: item.styleCategories || []
      };
      return newItem;
    });
  }
  return newArr;
};

export const findCategory = (
  arr: Array<FilterOptionType>,
  str: string
): FilterOptionType | undefined =>
  arr.find((item: FilterOptionType) => item.key.toString() === str);

export const mapCategoryKeyToName = (
  lists: Array<StyleCategoriesTreeType>,
  arr: Array<string>
) => {
  const nameArr: Array<string> = [];
  const getName = (
    list: Array<StyleCategoriesTreeType>,
    index: number
  ): Array<string> => {
    const obj = list.find(
      (item: StyleCategoriesTreeType) => item.id === Number(arr[index])
    );
    if (obj) {
      nameArr.push(obj.localizedName || "");
      if (obj.styleCategories && obj.styleCategories.length > 0) {
        getName(obj.styleCategories, index + 1);
      }
      return nameArr;
    }
    return nameArr;
  };
  return getName(lists, 0);
};

export const mapStylehintList = (arr: Array<SHNormalImages>) => {
  let newArr: Array<ImageInfo> = [];
  if (arr.length > 0) {
    newArr = arr.map((item: SHNormalImages) => {
      const obj: ImageInfo = {
        styleId: item.outfitId?.toString() || "",
        styleImageUrl: item.styleImageUrl || ""
      };
      return obj;
    });
  }
  return newArr;
};

export const mapOfficialList = (arr: Array<SBNormalImages>) => {
  let newArr: Array<ImageInfo> = [];
  if (arr.length > 0) {
    newArr = arr.map((item: SBNormalImages) => {
      const obj: ImageInfo = {
        styleId: item.styleId || "",
        styleImageUrl: item.imageUrl || ""
      };
      return obj;
    });
  }
  return newArr;
};

export const fuseIsFavoriteFlag = (
  arr: Array<ImageInfo>,
  wishlistedStyles: Array<string>
) => {
  let newArr: Array<StylesInfo> = [];
  if (arr.length > 0) {
    newArr = arr.map((item: ImageInfo) => {
      const obj: StylesInfo = {
        ...item,
        isFavorite:
          Boolean(
            wishlistedStyles &&
              wishlistedStyles.length > 0 &&
              wishlistedStyles.find(
                (w: string) => w.toString() === item.styleId.toString()
              )
          ) || false
      };
      return obj;
    });
  }
  return newArr;
};

export const getGenderFromUrl = (str: string): string => {
  const lastChar = str.charAt(str.length - 1);
  if (lastChar === "/") {
    str = str.slice(0, str.length - 1);
  }
  return str.substring(str.lastIndexOf("/") + 1);
};

export const findGender = (
  genderList: Array<GenderOptionType>,
  str: string
): GenderOptionType | undefined =>
  genderList.find((item: GenderOptionType) => item.key === str);

// handle height label
export const handleHeightLabel = (item: OptionsType, t: any): string => {
  const { language } = store.getState().config;
  let str = "";
  const min = isNotEmptyStr(item.min);
  const max = isNotEmptyStr(item.max);
  const displayMin = userHeight(Number(item.min));
  const displayMax = userHeight(Number(item.max));
  switch (language) {
    case LANGUAGE.JA:
    case LANGUAGE.KO: {
      if (min && !max) {
        str = `${displayMin}${t("text_style_model_height")}${t(`${item.lid}`)}`;
      } else if (!min && max) {
        str = `${displayMax}${t("text_style_model_height")}${t(`${item.lid}`)}`;
      } else if (min && max) {
        str = `${displayMin}${t("text_style_model_height")} - ${displayMax}${t(
          "text_style_model_height"
        )}`;
      } else {
        str = t(`${item.lid}`);
      }
      break;
    }
    default: {
      if (min && !max) {
        str = `${t(`${item.lid}`)} ${displayMin}${t(
          "text_style_model_height"
        )}`;
      } else if (!min && max) {
        str = `${t(`${item.lid}`)} ${displayMax}${t(
          "text_style_model_height"
        )}`;
      } else if (min && max) {
        str = `${displayMin}${t("text_style_model_height")} - ${displayMax}${t(
          "text_style_model_height"
        )}`;
      } else {
        str = t(`${item.lid}`);
      }
      break;
    }
  }
  return str;
};

enum StylehintParamsEnum {
  category = "categoryIds",
  colors = "colors",
  hashtag = "hashTags",
  minHeight = "minModelHeight",
  maxHeight = "maxModelHeight",
  model = "userType"
}

// stylehint_user: Customer / KOL / KOL(badge) / StyleHinter
export const stylehintUser = [
  UserTypeMap[UserTypeEnum.CONSUMER],
  UserTypeMap[UserTypeEnum.KOL],
  UserTypeMap[UserTypeEnum.KOL_BADGE],
  UserTypeMap[UserTypeEnum.STYLE_HINTER]
].toString();

export const staffUser = UserTypeMap[UserTypeEnum.STAFF].toString();

export const getStylehintListParams = (
  gender: string,
  sort: string,
  data?: FilterRouteObjType | undefined,
  params?: any,
  currentParams?: StylehintListPayload
) => {
  const config = store.getState().config;
  const _gender = isGuKids(config.brand_name, gender);
  let obj: StylehintListPayload = {
    [StylehintParamsEnum.category]: undefined,
    [StylehintParamsEnum.colors]: undefined,
    [StylehintParamsEnum.hashtag]: undefined,
    [StylehintParamsEnum.minHeight]: undefined,
    [StylehintParamsEnum.maxHeight]: undefined,
    unit:
      config.region === COUNTRY.US || config.region === COUNTRY.UK
        ? "inch"
        : "cm"
  };
  if (currentParams) {
    obj = { ...currentParams };
  }
  if (data) {
    Object.keys(data).forEach((key: string) => {
      if (data[key].routeValue.length > 0) {
        switch (key) {
          case FilterType.category:
            obj[StylehintParamsEnum.category] =
              data[key].routeValue[data[key].routeValue.length - 1];
            break;
          case FilterType.colors: {
            const newColor = data[key].routeValue.map((c) =>
              c.toString().toLowerCase()
            );
            obj[StylehintParamsEnum.colors] = newColor.join(",");
            break;
          }
          case FilterType.hashtag:
            obj[StylehintParamsEnum.hashtag] = data[key].routeValue.join(",");
            break;
          case FilterType.height: {
            const heights =
              HeightOptions[`${params.language}-${params.region}`] ||
              HeightOptions["en-eu"];
            const heightList = heights ? heights[gender] : [];
            const newHeight = heightList.find(
              (o: OptionsType) => o.key === data[key].routeValue[0]
            );
            if (newHeight && newHeight.min && newHeight.max) {
              obj[StylehintParamsEnum.minHeight] = Number(newHeight.min);
              obj[StylehintParamsEnum.maxHeight] = Number(newHeight.max);
            } else if (newHeight && !newHeight.min && newHeight.max) {
              delete obj[StylehintParamsEnum.minHeight];
              obj[StylehintParamsEnum.maxHeight] = Number(newHeight.max);
            } else if (newHeight && newHeight.min && !newHeight.max) {
              delete obj[StylehintParamsEnum.maxHeight];
              obj[StylehintParamsEnum.minHeight] = Number(newHeight.min);
            }
            break;
          }
          case FilterType.model:
            obj[StylehintParamsEnum.model] =
              data[key].routeValue[0] === "stylehint_user"
                ? stylehintUser
                : staffUser;
            break;
          default:
            break;
        }
      } else {
        switch (key) {
          case FilterType.category:
            obj[StylehintParamsEnum.category] = undefined;
            break;
          case FilterType.colors:
            obj[StylehintParamsEnum.colors] = undefined;
            break;
          case FilterType.hashtag:
            obj[StylehintParamsEnum.hashtag] = undefined;
            break;
          case FilterType.height: {
            obj[StylehintParamsEnum.minHeight] = undefined;
            obj[StylehintParamsEnum.maxHeight] = undefined;
            break;
          }
          case FilterType.model:
            break;
          default:
            break;
        }
      }
    });
  }
  const requestParam = {
    offset: 0,
    limit: 50,
    ...obj,
    departmentIds: _gender,
    order:
      sort === StylingSortEnum.latest
        ? STYLING_SORT_BY.PUBLISHED_AT_DESC
        : STYLING_SORT_BY.EC_LIKES_31DAYS_DESC
  };
  return requestParam;
};

// get stylehint styling normal images list
export const getStylehintList = (
  dispatch: any,
  query?: StylehintListPayload
) => {
  dispatch(
    StylehintListActions.getStylingStylehintList({
      offset: 0,
      limit: 50,
      ...query
    })
  );
};

enum OfficialParamsEnum {
  category = "categoryIds",
  colors = "colors",
  feature = "featureIds",
  coordination = "tagCodes"
}

export const getOfficialListParams = (
  gender: string,
  sort: string,
  data?: FilterRouteObjType | undefined
) => {
  const { brand_name } = store.getState().config;
  const _gender = isGuKids(brand_name, gender);
  const obj: OfficialListPayload = {
    [OfficialParamsEnum.category]: undefined,
    [OfficialParamsEnum.colors]: undefined,
    [OfficialParamsEnum.feature]: undefined,
    [OfficialParamsEnum.coordination]: undefined
  };
  if (data) {
    Object.keys(data).forEach((key: string) => {
      if (data[key].routeValue.length > 0) {
        switch (key) {
          case FilterType.category:
            obj[OfficialParamsEnum.category] =
              data[key].routeValue[data[key].routeValue.length - 1];
            break;
          case FilterType.colors: {
            const newColor = data[key].routeValue.map((c) =>
              c.toString().toLowerCase()
            );
            obj[OfficialParamsEnum.colors] = newColor.join(",");
            break;
          }
          case FilterType.feature:
            obj[OfficialParamsEnum.feature] = data[key].routeValue.join(",");
            break;
          case FilterType.coordination:
            obj[OfficialParamsEnum.coordination] =
              data[key].routeValue.join(",");
            break;
          default:
            break;
        }
      }
    });
  }
  const requestParam = {
    offset: 0,
    limit: 50,
    ...obj,
    departmentIds: _gender,
    order:
      sort === StylingSortEnum.latest
        ? STYLING_SORT_BY.DISPLAY_START_AT_DESC
        : STYLING_SORT_BY.EC_LIKES_TOTAL_DESC
  };
  return requestParam;
};

// get official styling normal images list
export const getOfficialList = (dispatch: any, query?: OfficialListPayload) => {
  dispatch(
    OfficialListActions.getStylingOfficialList({
      offset: 0,
      limit: 50,
      ...query
    })
  );
};

export const postOrDeleteFavoritesStyling = (
  dispatch: any,
  list: Array<string>,
  id: string,
  type: string,
  isCarouselImg: boolean
) => {
  const arr = [...list];
  if (arr.includes(id.toString())) {
    dispatch(
      FavoritesStylingActions.delFavoritesStyling({
        styleId: id,
        type: type,
        isCarouselImg
      })
    );
  } else {
    dispatch(
      FavoritesStylingActions.postFavoritesStyling({
        body: [{ styleId: id.toString() }],
        type: type,
        isCarouselImg
      })
    );
  }
};

type OfficialGAObjType = {
  featureFilter: string | null;
  categoryFilter: string | null;
  coordinationFilter: string | null;
  colorFilter: string | null;
};
export const getOfficialGa = (
  arr: Array<{ key: string; value: Array<string> }>
): OfficialGAObjType => {
  const obj: OfficialGAObjType = {
    featureFilter: null,
    categoryFilter: null,
    coordinationFilter: null,
    colorFilter: null
  };
  arr.map((item: { key: string; value: Array<string> }) => {
    const data = item.value;
    switch (item.key) {
      case FilterType.feature:
        obj.featureFilter = data.length > 0 ? data.join(",") : null;
        break;
      case FilterType.category:
        obj.categoryFilter = data.length > 0 ? data.join(",") : null;
        break;
      case FilterType.coordination:
        obj.coordinationFilter = data.length > 0 ? data.join(",") : null;
        break;
      case FilterType.colors: {
        obj.colorFilter = data.length > 0 ? data.join(",").toLowerCase() : null;
        break;
      }
      default:
        break;
    }
    return obj;
  });
  return obj;
};

type StylehintGAObjType = {
  staffCustomerFilter: string | null;
  heightFilter: string | null;
  hashtagFilter: string | null;
  categoryFilter: string | null;
  colorFilter: string | null;
};
export const getStylehintGa = (
  arr: Array<{ key: string; value: Array<string> }>,
  t: any
): StylehintGAObjType => {
  const obj: StylehintGAObjType = {
    staffCustomerFilter: t("text_show_all"),
    heightFilter: t("text_show_all"),
    hashtagFilter: null,
    categoryFilter: null,
    colorFilter: null
  };
  arr.map((item: { key: string; value: Array<string> }) => {
    const data = item.value;
    switch (item.key) {
      case FilterType.model:
        obj.staffCustomerFilter = data[0] || t("text_show_all");
        break;
      case FilterType.height:
        obj.heightFilter = data[0] || t("text_show_all");
        break;
      case FilterType.hashtag:
        obj.hashtagFilter = data.length > 0 ? data.join(",") : null;
        break;
      case FilterType.category:
        obj.categoryFilter = data.length > 0 ? data.join(",") : null;
        break;
      case FilterType.colors: {
        obj.colorFilter = data.length > 0 ? data.join(",").toLowerCase() : null;
        break;
      }
      default:
        break;
    }
    return obj;
  });
  return obj;
};

export const initTopPageAllData = (dispatch: any) => {
  if (typeof window !== "undefined") {
    window.scrollTo(0, 0);
  }
  dispatch(StylingFilterActions.initStylingFilters());
  dispatch(OfficialListActions.initOfficialLists());
  dispatch(StylehintListActions.initStylehintLists());
  dispatch(StylingFilterActions.setIsReloadFilters(true));
};

// get parameters from router
export const routerQuery = (type: string, obj: FilterRouteObjType): string => {
  const strList: string[] = [];
  let str = "";
  if (type === StylingType.official) {
    if (obj[FilterType.colors].routeValue.length > 0) {
      strList.push(
        `${RouteParamType.colors}=${obj[FilterType.colors].routeValue}`
      );
    }
    if (obj[FilterType.category].routeValue.length > 0) {
      strList.push(
        `${RouteParamType.category}=${obj[FilterType.category].routeValue}`
      );
    }
    if (obj[FilterType.feature].routeValue.length > 0) {
      strList.push(
        `${RouteParamType.feature}=${obj[FilterType.feature].routeValue}`
      );
    }
    if (obj[FilterType.coordination].routeValue.length > 0) {
      strList.push(
        `${RouteParamType.coordination}=${
          obj[FilterType.coordination].routeValue
        }`
      );
    }
  } else {
    if (obj[FilterType.colors].routeValue.length > 0) {
      strList.push(
        `${RouteParamType.colors}=${obj[FilterType.colors].routeValue}`
      );
    }
    if (obj[FilterType.category].routeValue.length > 0) {
      strList.push(
        `${RouteParamType.category}=${obj[FilterType.category].routeValue}`
      );
    }
    if (obj[FilterType.hashtag].routeValue.length > 0) {
      strList.push(
        `${RouteParamType.hashtag}=${obj[FilterType.hashtag].routeValue}`
      );
    }
    if (obj[FilterType.height].routeValue.length > 0) {
      strList.push(
        `${RouteParamType.height}=${obj[FilterType.height].routeValue}`
      );
    }
    if (obj[FilterType.model].routeValue.length > 0) {
      strList.push(
        `${RouteParamType.model}=${obj[FilterType.model].routeValue}`
      );
    }
  }
  if (strList.length === 1) {
    str = strList.join("");
  }
  if (strList.length > 1) {
    str = strList.join("&");
  }
  return str;
};

// handle whether show wish icon
export const isShowWishIcon = (type: string): boolean => {
  const { firebaseData, isApp, brand_name } = store.getState().config;
  if (type === StylingType.official) {
    if (brand_name === BRAND_LIST.GU) {
      if (isGUNativeApp() && getUA.indexOf(FR_UserAgent) > -1) {
        return false;
      }
      return firebaseData.sb_wish_icon;
    }
    return firebaseData.sb_wish_icon && !isApp;
  } else {
    if (brand_name === BRAND_LIST.GU) {
      if (isGUNativeApp() && getUA.indexOf(FR_UserAgent) > -1) {
        return false;
      }
      return firebaseData.sh_wish_icon;
    }
    return firebaseData.sh_wish_icon && !isApp;
  }
};

// handle whether show wish count
export const isShowWishCount = (type: string): boolean => {
  const { firebaseData, isApp, brand_name } = store.getState().config;
  if (type === StylingType.official) {
    if (brand_name === BRAND_LIST.GU) {
      return firebaseData.sb_wish_count;
    }
    return firebaseData.sb_wish_count && !isApp;
  } else {
    if (brand_name === BRAND_LIST.GU) {
      return firebaseData.sh_wish_count;
    }
    return firebaseData.sh_wish_count && !isApp;
  }
};

// handle whether has filters
export const handleHasFilters = (params: string): boolean => {
  if (!params) {
    return false;
  } else if (params && params.includes("?order=")) {
    return false;
  } else {
    return true;
  }
};

// handle router param for order
export const handleOrderRouterParam = (type: string, sort: string): string => {
  let order = "";
  if (type === StylingType.official) {
    order =
      sort === StylingSortEnum.latest
        ? STYLING_SORT_BY.DISPLAY_START_AT_DESC
        : STYLING_SORT_BY.EC_LIKES_TOTAL_DESC;
  } else {
    order =
      sort === StylingSortEnum.latest
        ? STYLING_SORT_BY.PUBLISHED_AT_DESC
        : STYLING_SORT_BY.EC_LIKES_31DAYS_DESC;
  }
  const arr = order.split(":");
  return arr[0];
};

// handle default router param for order
export const defaultOrderRouterParam = (
  type: string,
  str: string
): StylingSortEnum => {
  if (str.length > 0) {
    switch (str) {
      case handleOrderRouterParam(type, StylingSortEnum.latest):
        return StylingSortEnum.latest;
      case handleOrderRouterParam(type, StylingSortEnum.popular):
        return StylingSortEnum.popular;
      default:
        return StylingSortEnum.latest;
    }
  } else {
    return StylingSortEnum.latest;
  }
};
