import {
  Accordion,
  Body,
  Breadcrumb,
  BreadcrumbGroup,
  ButtonToggle,
  Checkbox,
  CheckboxGroup,
  ContentAlignment,
  FilterLayout,
  Image,
  ImageEnhancements,
  ImageOverlay,
  Layout,
  LayoutWrapper,
  LinkNavigation,
  LinkNavigationGroup,
  Modal,
  RadioGroup,
  Radio,
  Spacer,
  StylingGrid,
  Tab,
  TabGroup,
  TagFilter,
  TagFilterGroup,
  TemplateILP,
  Tile,
  Title,
  UtilityBar,
  ButtonLayout,
  Button,
  LoadingIndicator,
  GutterContainer,
  HeaderPage,
  Caption,
  StickyContainer,
  Carousel,
  CollectionList,
  Display,
  FlagText,
  ItemTag,
  ItemTags,
  Keyline,
  MediaObject,
  Price,
  StyleUserInfo,
  TemplateMediaInformation,
  Footer,
  ButtonIcon,
  Drawer,
  LinkCell,
  LinkImage,
  NavigationHeader,
  ButtonFloating,
  LinkText,
  TemplateFullGutter,
  CmsContentsCard,
  CmsLink,
  CmsImage,
  ButtonToggleGroup,
  CollectionListHorizontal,
  ProductTile,
  Chip,
  ChipGroup
} from "pl-global-ec-uikit/components/react";

export const PLUikit = {
  Accordion,
  Body,
  Breadcrumb,
  BreadcrumbGroup,
  Button,
  ButtonFloating,
  ButtonIcon,
  ButtonLayout,
  ButtonToggle,
  Caption,
  Carousel,
  Checkbox,
  CheckboxGroup,
  CmsContentsCard,
  CmsImage,
  CmsLink,
  CollectionList,
  ContentAlignment,
  Display,
  Drawer,
  FilterLayout,
  FlagText,
  Footer,
  GutterContainer,
  HeaderPage,
  Image,
  ImageEnhancements,
  ImageOverlay,
  ItemTag,
  ItemTags,
  Keyline,
  Layout,
  LayoutWrapper,
  LinkCell,
  LinkImage,
  LinkNavigation,
  LinkNavigationGroup,
  LinkText,
  LoadingIndicator,
  MediaObject,
  Modal,
  NavigationHeader,
  Price,
  Radio,
  RadioGroup,
  Spacer,
  StickyContainer,
  StylingGrid,
  StyleUserInfo,
  Tab,
  TabGroup,
  TagFilter,
  TagFilterGroup,
  TemplateFullGutter,
  TemplateILP,
  TemplateMediaInformation,
  Tile,
  Title,
  UtilityBar,
  ButtonToggleGroup,
  CollectionListHorizontal,
  ProductTile,
  Chip,
  ChipGroup
};
