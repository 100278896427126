import React from "react";
import { useTranslation } from "react-i18next";
import { Footer } from "@common/DynamicUikit";

const PublicFooter = () => {
  const { t } = useTranslation();
  return <Footer copyrightRow={t("text_copyright")} />;
};

export default PublicFooter;
