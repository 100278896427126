import React, { useEffect, useMemo, useState } from "react";
import { generatePath, Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Breadcrumb,
  BreadcrumbGroup,
  ButtonToggle,
  GutterContainer,
  HeaderPage,
  Image,
  ImageEnhancements,
  LoadingIndicator,
  StyleUserInfo,
  StylingGrid,
  TemplateFullGutter,
  Tile
} from "@common/DynamicUikit";
import { PageType, RouterUrlType, StylingType } from "@type/index";
import { useDispatch, useSelector } from "react-redux";
import {
  CombinedReducersType,
  ConfigActions,
  FavoritesStylingActions,
  UserProfileActions
} from "@redux/reducer";
import InfiniteScroll from "react-infinite-scroll-component";
import { Helmet } from "react-helmet-async";
import { TRACK_EVENT } from "@config/common";
import { getHrefLangList } from "@util/hreflang";
import {
  sendTrackEvent,
  trackPageView,
  transformBrandForGoogleAnalytics
} from "@util/analytics/utils";
import { getPageViewBaseOption } from "@util/analytics";
import { userHeight } from "@util/height";
import {
  getIsLoggedIn,
  handleStoreLink,
  isStaff,
  preventBubbling
} from "@util/helpers";
import { isGUNativeApp } from "@util/nativeApp";
import {
  NotFound,
  SystemError,
  GoTop,
  LoginModal,
  ErrorModal
} from "../../common";
import {
  initTopPageAllData,
  isShowWishIcon,
  postOrDeleteFavoritesStyling,
  useDebounce
} from "../TopStyling/stylingConfig";

const PersonalPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { authorId = "" } = useParams();
  const params = useParams();
  const location = useLocation();
  const config = useSelector((store: CombinedReducersType) => store.config);
  const userProfile = useSelector(
    (store: CombinedReducersType) => store.userProfile
  );
  const favoritesSHStylingList = useSelector(
    (store: CombinedReducersType) => store.favoritesStyling.stylingSHWishlist
  );
  const favoritesStylingLoading = useSelector(
    (store: CombinedReducersType) => store.favoritesStyling.stylingLoading
  );
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");

  // handle click breadcrumb url
  const breadcrumbStylingbookUrl = useMemo((): string => {
    const url = generatePath("/:region/:language/stylingbook/:type/women", {
      ...params
    } as RouterUrlType);
    return url;
  }, [params]);

  // SEO - add meta data
  useEffect(() => {
    if (!userProfile.loading) {
      let _metaTitle = "";
      let _metaDescription = "";
      if (userProfile.errorCode !== "") {
        if (userProfile.errorCode === "404") {
          setMetaTitle(t("text_404"));
          setMetaDescription(t("text_404"));
        } else {
          setMetaTitle(t("text_error_title"));
          setMetaDescription(t("text_503_error_description"));
        }
      } else {
        // Ins User
        if (userProfile.userInfo?.isFromInstagram) {
          _metaTitle = t("text_styling_book_stylehint_ins_user_meta_title", {
            userName: userProfile.userInfo?.name,
            uqPostfix: t("text_meta_title_postfix")
          });
          _metaDescription = t(
            "text_styling_book_stylehint_ins_user_meta_description",
            {
              brand: t("text_styling_book_meta_title_brand"),
              userName: userProfile.userInfo?.name
            }
          );
        }
        // STAFF User
        else if (userProfile.userInfo?.userType.toUpperCase() === "STAFF") {
          _metaTitle = t("text_styling_book_stylehint_store_user_meta_title", {
            userName: userProfile.userInfo?.name,
            storeName: userProfile.userInfo?.storeName,
            uqPostfix: t("text_meta_title_postfix")
          });
          _metaDescription = t(
            "text_styling_book_stylehint_store_user_meta_description",
            {
              brand: t("text_styling_book_meta_title_brand"),
              storeName: userProfile.userInfo?.storeName,
              staffName: userProfile.userInfo?.name,
              height: userHeight(userProfile.userInfo.height)
            }
          );
        }
        // CONSUMER User
        else {
          _metaTitle = t(
            "text_styling_book_stylehint_customer_user_meta_title",
            {
              userName: userProfile.userInfo?.name,
              height: userHeight(userProfile.userInfo?.height || 0),
              uqPostfix: t("text_meta_title_postfix")
            }
          );
          _metaDescription = t(
            "text_styling_book_stylehint_customer_user_meta_description",
            {
              brand: t("text_styling_book_meta_title_brand"),
              userName: userProfile.userInfo?.name,
              height: userHeight(userProfile.userInfo?.height || 0)
            }
          );
        }
        setMetaTitle(_metaTitle);
        setMetaDescription(_metaDescription);
        trackPageView(
          getPageViewBaseOption(PageType.StylingBook, "", _metaTitle)
        );
      }
    }
  }, [t, userProfile.errorCode, userProfile.loading, userProfile.userInfo]);

  useEffect(
    () => () => {
      dispatch(UserProfileActions.getUserProfileLoading(true));
    },
    [dispatch]
  );

  // get favorites styling list && EUUK base on GP4.0-3.0 (no stylehint wish func)
  useEffect(() => {
    if (userProfile.current_list.length > 0) {
      const arr = userProfile.current_list;
      dispatch(
        FavoritesStylingActions.getFavoritesStyling({
          ids: arr.join(","),
          type: "sh"
        })
      );
    }
  }, [dispatch, params.region, userProfile.current_list]);

  // SEO - add hreflang
  const hrefLangList = useMemo(
    () => getHrefLangList(location.pathname),
    [location.pathname]
  );

  // SEO - add script
  const breadcrumbJson = useMemo(() => {
    const _json = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: t("text_breadcrumbs_top"),
          item: `${window.location.origin}/${params.region}/${params.language}`
        },
        {
          "@type": "ListItem",
          position: 2,
          name: `${t("text_styling_book_header_title")} ${t(
            "text_styling_book_stylehint"
          )}`,
          item: `${window.location.origin}${breadcrumbStylingbookUrl}`
        },
        {
          "@type": "ListItem",
          position: 3,
          name: userProfile.userInfo ? userProfile.userInfo.name : ""
        }
      ]
    };
    return JSON.stringify(_json);
  }, [
    breadcrumbStylingbookUrl,
    params.language,
    params.region,
    t,
    userProfile.userInfo
  ]);

  // SEO - add noindex
  const isNoResult = useMemo(
    () => userProfile.errorCode !== "",
    [userProfile.errorCode]
  );

  useEffect(() => {
    dispatch(UserProfileActions.initUserProfile());
    dispatch(
      UserProfileActions.getUserProfile({
        offset: 0,
        limit: userProfile.limit,
        userId: parseInt(authorId, 10)
      })
    );
  }, [authorId, dispatch, userProfile.limit]);

  const fetchUserProfileData = () => {
    const _offset = userProfile.pagination.offset || 0;
    dispatch(
      UserProfileActions.getUserProfile({
        offset: _offset + 50,
        limit: userProfile.limit,
        userId: parseInt(authorId, 10)
      })
    );
  };

  // detail page url
  const detailPageUrl = (styleId: number): string => {
    const url = generatePath(
      `/:region/:language/stylingbook/:type/${styleId}`,
      {
        ...params
      } as RouterUrlType
    );
    return url;
  };

  // handle wish list selected or unselected
  const handleWishList = (styleId: string) => {
    if (!getIsLoggedIn() && !isGUNativeApp()) {
      dispatch(ConfigActions.setShowLogin(true));
    } else {
      postOrDeleteFavoritesStyling(
        dispatch,
        favoritesSHStylingList,
        styleId,
        "sh",
        false
      );
    }
  };

  // handle click wish icon
  const handleClickWishIcon = useDebounce((styleId: string) => {
    handleWishList(styleId);
    // ga
    sendTrackEvent({
      brand: transformBrandForGoogleAnalytics(config.brand_name),
      event: TRACK_EVENT,
      eventAction: "stylehint_profile",
      eventCategory: "sb_wishlist_style",
      eventLabel: styleId
    });
  }, 200);

  // breadcrumb area
  const navigationPath =
    userProfile.errorCode !== "" ? (
      <BreadcrumbGroup className="fr-ec-mb-spacing-05">
        <Breadcrumb element="a" href={config.basePath}>
          {t("text_breadcrumbs_top")}
        </Breadcrumb>
        <Breadcrumb element="a">
          {`${t("text_styling_book_header_title")} ${t(
            "text_styling_book_stylehint"
          )}`}
        </Breadcrumb>
      </BreadcrumbGroup>
    ) : (
      <BreadcrumbGroup className="fr-ec-mb-spacing-05">
        <Breadcrumb element="a" href={config.basePath}>
          {t("text_breadcrumbs_top")}
        </Breadcrumb>
        <Link
          to={breadcrumbStylingbookUrl}
          onClick={() => {
            initTopPageAllData(dispatch);
          }}
        >
          <Breadcrumb element="button">
            {`${t("text_styling_book_header_title")} ${t(
              "text_styling_book_stylehint"
            )}`}
          </Breadcrumb>
        </Link>
        <Breadcrumb element="a">
          {userProfile.userInfo ? userProfile.userInfo.name : ""}
        </Breadcrumb>
      </BreadcrumbGroup>
    );

  const handleStyling = (id: number) => {
    // ga
    sendTrackEvent({
      brand: transformBrandForGoogleAnalytics(config.brand_name),
      event: TRACK_EVENT,
      eventAction: `StyleHint_${id}`,
      eventCategory: "sb_StyleHint_click",
      eventLabel: id.toString()
    });
  };

  const mainContent = (
    <>
      <InfiniteScroll
        dataLength={userProfile.styles ? userProfile.styles.length : 0}
        next={fetchUserProfileData}
        hasMore={userProfile.hasMore}
        loader
      >
        {userProfile.errorCode === "" ? (
          <>
            <HeaderPage
              title={t("text_styling_book_stylehint_page_title")}
              className="fr-ec-mb-spacing-05"
            />
            {userProfile.userInfo && (
              <GutterContainer className="fr-ec-mb-spacing-05">
                {userProfile.userInfo.isFromInstagram ? (
                  <StyleUserInfo
                    isBadge={false}
                    userName={userProfile.userInfo.name}
                    variant="large"
                  />
                ) : (
                  <StyleUserInfo
                    storeLink={handleStoreLink(
                      userProfile.userInfo.storeId,
                      userProfile.userInfo.region
                    )}
                    storeNameGaLabel={
                      userProfile.userInfo.storeId?.toString() || ""
                    }
                    storeNameGaAction="shop"
                    gaCallback={(
                      element: HTMLElement,
                      clickedItem?: string,
                      gaAction?: string
                    ) => {
                      sendTrackEvent({
                        brand: transformBrandForGoogleAnalytics(
                          config.brand_name
                        ),
                        event: TRACK_EVENT,
                        eventAction: gaAction || "",
                        eventCategory: "sb_StyleHint_click",
                        eventLabel: clickedItem || ""
                      });
                    }}
                    heightUnit={t("text_style_model_height")}
                    isBadge={isStaff(userProfile.userInfo.userType)}
                    modelHeight={userHeight(userProfile.userInfo.height)}
                    profileImage={
                      <Image
                        alt={t("text_styling_book_stylehint_user_id_alt_tag", {
                          userName: `${userProfile.userInfo.name}${
                            isStaff(userProfile.userInfo.userType) &&
                            userProfile.userInfo.storeName
                              ? `、${userProfile.userInfo.storeName}`
                              : ""
                          }`
                        })}
                        noImage={{ imageSize: "smallest", imageType: "user" }}
                        ratio="1x1"
                        src={
                          userProfile.userInfo.image
                            ? userProfile.userInfo.image
                            : ""
                        }
                      />
                    }
                    storeInfo={
                      isStaff(userProfile.userInfo.userType)
                        ? userProfile.userInfo.storeName
                        : undefined
                    }
                    userName={userProfile.userInfo.name}
                    userReview={userProfile.userInfo.bio}
                    variant="large"
                    labelElement="h1"
                  />
                )}
              </GutterContainer>
            )}
            {userProfile.styles && (
              <StylingGrid
                className="fr-ec-mb-spacing-08"
                gridItems={userProfile.styles.map((item) => (
                  <Link
                    to={detailPageUrl(item.id)}
                    key={item.id}
                    onClick={() => handleStyling(item.id)}
                  >
                    <Tile key={item.id} element="button">
                      <ImageEnhancements
                        topRightContent={
                          isShowWishIcon(StylingType.stylehint) ? (
                            <ButtonToggle
                              icon="heart"
                              variant="icon"
                              isToggled={favoritesSHStylingList.includes(
                                item.id.toString()
                              )}
                              onClick={(e) => {
                                preventBubbling(e);
                                if (!favoritesStylingLoading) {
                                  handleClickWishIcon(item.id.toString());
                                }
                              }}
                            />
                          ) : undefined
                        }
                        padding={false}
                      >
                        <Image
                          alt={t(
                            "text_styling_book_stylehint_styling_id_alt_tag",
                            {
                              brand: t("text_styling_book_meta_title_brand"),
                              styleId: item.id
                            }
                          )}
                          ratio="3x4"
                          src={`${item.styleImageUrl}_r-600-800`}
                        />
                      </ImageEnhancements>
                    </Tile>
                  </Link>
                ))}
              />
            )}
            {userProfile.loading && <LoadingIndicator variant="normal" />}
          </>
        ) : (
          <>
            <HeaderPage
              title={t("text_styling_book_header_title")}
              className="fr-ec-mb-spacing-05"
            />
            {userProfile.errorCode === "404" ? (
              <NotFound
                text={t("text_stylehint_top_page_link")}
                type={StylingType.stylehint}
              />
            ) : (
              <SystemError />
            )}
          </>
        )}
      </InfiniteScroll>
    </>
  );

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta
          name="robots"
          content={isNoResult ? "noindex,nofollow" : "index,follow"}
        />
        {!isNoResult && (
          <>
            {hrefLangList.map((item) => (
              <link
                key={item.hrefLang}
                rel="alternate"
                hrefLang={item.hrefLang}
                href={`${window.location.origin}/${item.alternateHref}`}
              />
            ))}
            <link
              rel="canonical"
              href={`${window.location.origin}${location.pathname}`}
            />
            <script type="application/ld+json" id="breadcrumb">
              {breadcrumbJson}
            </script>
          </>
        )}
      </Helmet>
      <TemplateFullGutter
        mainContent={mainContent}
        navigationPath={!config.isApp ? navigationPath : <></>}
      />
      <GoTop />
      {config.showLogin && <LoginModal />}
      {config.showWishIconError && <ErrorModal />}
    </>
  );
};

export default PersonalPage;
