import { COUNTRY, COUNTRY_CONFIG_WITH_LANG, LANGUAGE } from "@type/index";
import { convertRegionName } from "@util/country";
import store from "../../redux/store";

const MILLISECONDS = 1000;
const SEC = 60;
const MINUTE = SEC * MILLISECONDS;

type CountryConfig = Partial<Config>;
type LanguageConfig<T> = Partial<Partial<Record<LANGUAGE, T>>>;
type Config = {
  countryRegion: string;
  /* [COUNTRY, LANGUAGE] configs */
  timeZone: LanguageConfig<{
    defaultTimezone: string;
    offset: {
      [key: string]: number;
    };
  }>;
  timeZoneParentCategory: {
    [key: string]: string;
  };
};

type timeZoneParentCategoryType = {
  [key: string]: string;
};

const timeZoneParentCategory: timeZoneParentCategoryType = {
  EST: "ET",
  EDT: "ET"
};

const defaultConfig = {
  countryRegion: "Asia/Tokyo",
  timeZone: {},
  timeZoneParentCategory: {
    EST: "ET",
    EDT: "ET"
  }
};

type COUNTRY_CONFIG_WITH_REGION = {
  [key in COUNTRY]: CountryConfig;
};
const COUNTRY_CONFIG: COUNTRY_CONFIG_WITH_REGION = {
  [COUNTRY.UK]: {
    countryRegion: "Europe/London",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "GMT",
        offset: {
          GMT: 0,
          BST: 1
        }
      }
    }
  },
  [COUNTRY.US]: {
    countryRegion: "America/New_York",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "EST",
        offset: {
          EST: -5,
          EDT: -4
        }
      }
    }
  },
  [COUNTRY.JP]: {
    countryRegion: "Asia/Tokyo",
    timeZone: {
      [LANGUAGE.JA]: {
        defaultTimezone: "JST",
        offset: {
          JST: 9
        }
      }
    }
  },
  [COUNTRY.FR]: {
    countryRegion: "Europe/Paris",
    timeZone: {
      [LANGUAGE.FR]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      },
      [LANGUAGE.EN]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      }
    }
  },
  [COUNTRY.DE]: {
    countryRegion: "Europe/Berlin",
    timeZone: {
      [LANGUAGE.DE]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      },
      [LANGUAGE.EN]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      }
    }
  },
  [COUNTRY.ES]: {
    countryRegion: "Europe/Madrid",
    timeZone: {
      [LANGUAGE.ES]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      },
      [LANGUAGE.EN]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      }
    }
  },
  [COUNTRY.IT]: {
    countryRegion: "Europe/Rome",
    timeZone: {
      [LANGUAGE.IT]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      },
      [LANGUAGE.EN]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      }
    }
  },
  [COUNTRY.DK]: {
    countryRegion: "Europe/Copenhagen",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      }
    }
  },
  [COUNTRY.SE]: {
    countryRegion: "Asia/Tokyo",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      }
    }
  },
  [COUNTRY.NL]: {
    countryRegion: "Europe/Amsterdam",
    timeZone: {
      [LANGUAGE.NL]: {
        defaultTimezone: "CET", // TODO: eu-rollout -> to be confirmed
        offset: {
          // TODO: eu-rollout -> to be confirmed
          CET: 1,
          CEST: 2
        }
      },
      [LANGUAGE.EN]: {
        defaultTimezone: "", // TODO: eu-rollout -> to be confirmed
        offset: {
          // TODO: eu-rollout -> to be confirmed
        }
      }
    }
  },
  [COUNTRY.BE]: {
    countryRegion: "Europe/Brussels",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      },
      [LANGUAGE.NL]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      },
      [LANGUAGE.FR]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      }
    }
  },
  [COUNTRY.FI]: {
    countryRegion: "Europe/Helsinki",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "EET",
        offset: {
          EET: 2,
          EEST: 3
        }
      }
    }
  },
  [COUNTRY.GR]: {
    countryRegion: "Europe/Athens",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "EET",
        offset: {
          EET: 2,
          EEST: 3
        }
      }
    }
  },
  [COUNTRY.IE]: {
    countryRegion: "Europe/Dublin",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "GMT",
        offset: {
          GMT: 0,
          BST: 1
        }
      }
    }
  },
  [COUNTRY.LU]: {
    countryRegion: "Europe/Luxembourg",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      }
    }
  },
  [COUNTRY.AT]: {
    countryRegion: "Europe/Vienna",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      }
    }
  },
  [COUNTRY.PL]: {
    countryRegion: "Europe/Warsaw",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      }
    }
  },
  [COUNTRY.PT]: {
    countryRegion: "Europe/Lisbon",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "WET",
        offset: {
          WET: 0,
          WEST: 1
        }
      }
    }
  },
  [COUNTRY.SI]: {
    countryRegion: "Europe/Ljubljana",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      }
    }
  },
  [COUNTRY.SK]: {
    countryRegion: "Europe/Bratislava",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      }
    }
  },
  [COUNTRY.LT]: {
    countryRegion: "Europe/Vilnius",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "EET",
        offset: {
          EET: 2,
          EEST: 3
        }
      }
    }
  },
  [COUNTRY.LV]: {
    countryRegion: "Europe/Riga",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "EET",
        offset: {
          EET: 2,
          EEST: 3
        }
      }
    }
  },
  [COUNTRY.EE]: {
    countryRegion: "Europe/Tallinn",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "EET",
        offset: {
          EET: 2,
          EEST: 3
        }
      }
    }
  },
  [COUNTRY.CZ]: {
    countryRegion: "Europe/Prague",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      }
    }
  },
  [COUNTRY.HU]: {
    countryRegion: "Europe/Budapest",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      }
    }
  },
  [COUNTRY.RO]: {
    countryRegion: "Europe/Bucharest",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "EET",
        offset: {
          EET: 2,
          EEST: 3
        }
      }
    }
  },
  [COUNTRY.BG]: {
    countryRegion: "Europe/Sofia",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "EET",
        offset: {
          EET: 2,
          EEST: 3
        }
      }
    }
  },
  [COUNTRY.HR]: {
    countryRegion: "Europe/Zagreb",
    timeZone: {
      [LANGUAGE.EN]: {
        defaultTimezone: "CET",
        offset: {
          CET: 1,
          CEST: 2
        }
      }
    }
  },
  [COUNTRY.KR]: {
    countryRegion: "Asia/Seoul",
    timeZone: {
      [LANGUAGE.KO]: {
        defaultTimezone: "KST",
        offset: {
          KST: 9
        }
      }
    }
  }
};

export const initializeCountryConfig = (_country: COUNTRY) =>
  COUNTRY_CONFIG[_country];

const getTimeZone = (countryConfig: CountryConfig, language: LANGUAGE) =>
  countryConfig.timeZone?.[language] ?? { defaultTimezone: "", offset: {} };

const getCountryRegion = (countryConfig: CountryConfig) =>
  countryConfig.countryRegion ?? defaultConfig.countryRegion;

const toTimeForTimeZone = (
  country: COUNTRY,
  language: LANGUAGE,
  date: Date
): {
  date: number;
  timeZoneName: string;
} => {
  const countryConfig = initializeCountryConfig(country);
  const timestamp: number = date.getTime();
  let intlDate;
  try {
    intlDate =
      window.Intl &&
      window.Intl.DateTimeFormat(
        `${language.toLowerCase()}-${country.toUpperCase()}`,
        {
          timeZone: getCountryRegion(countryConfig),
          timeZoneName: "short"
        }
      )
        .format(timestamp)
        .split(" ");
  } catch (error) {
    // IE11 does not support IANA time zone names (e.g. `ASIA/TOKYO`) - use `getTimeZone` fallback
  }
  const timeZoneName: string =
    intlDate && intlDate.length
      ? intlDate[intlDate.length - 1]
      : getTimeZone(countryConfig, language).defaultTimezone;

  const utc = timestamp + date.getTimezoneOffset() * MINUTE;

  // Add offset (-5/-4) to UTC time to convert to EST/EDT
  const timeZone =
    getTimeZone(countryConfig, language).offset[timeZoneName] || 0;
  const dateWithOffset = utc + SEC * MINUTE * timeZone;
  const timeZoneValidatedName =
    timeZoneParentCategory[timeZoneName] ?? timeZoneName;
  return { date: dateWithOffset, timeZoneName: timeZoneValidatedName };
};

export const dateFormat: COUNTRY_CONFIG_WITH_LANG<string> = {
  [COUNTRY.UK]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.US]: {
    [LANGUAGE.EN]: "<MM>/<DD>/<yyyy>"
  },
  [COUNTRY.JP]: {
    [LANGUAGE.JA]: "<yyyy>/<MM>/<DD>"
  },
  [COUNTRY.FR]: {
    [LANGUAGE.FR]: "<DD>.<MM>.<yyyy>",
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.DE]: {
    [LANGUAGE.DE]: "<dd>.<mm>.<yyyy>",
    [LANGUAGE.EN]: "<dd>.<mm>.<yyyy>"
  },
  [COUNTRY.ES]: {
    [LANGUAGE.ES]: "<dd>/<mm>/<yyyy>",
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.IT]: {
    [LANGUAGE.IT]: "<dd>/<mm>/<yyyy>",
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.DK]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.SE]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.NL]: {
    [LANGUAGE.NL]: "<dd>/<mm>/<yyyy>", // TODO: eu-rollout -> to be confirmed
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.BE]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>",
    [LANGUAGE.NL]: "<dd>/<mm>/<yyyy>",
    [LANGUAGE.FR]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.FI]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.GR]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.IE]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.LU]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.AT]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.PL]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.PT]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.SI]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.SK]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.LT]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.LV]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.EE]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.CZ]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.HU]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.RO]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.BG]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.HR]: {
    [LANGUAGE.EN]: "<dd>/<mm>/<yyyy>"
  },
  [COUNTRY.KR]: {
    [LANGUAGE.KO]: "<yyyy>/<MM>/<DD>"
  }
};

export const getDateFormat = (country: COUNTRY, language: LANGUAGE) =>
  dateFormat[country][language] || "";

export function convertToDatetime(date?: number): number {
  if (date) {
    // Convert from seconds to milliseconds.
    // If the timestamp is 10 digits, it needs to be multiplied by 1000. If the timestamp is 13 digits, it does not need
    return date.toString().length === 10 ? date * 1000 : date;
  }
  return 0;
}

export const formatDate = (time: number): string => {
  const { region, language } = store.getState().config;
  let dateObj = new Date(convertToDatetime(time));
  // takes date format from country configuration
  const _country = convertRegionName(region) as COUNTRY;
  const _language = language as LANGUAGE;
  const newDateObj = toTimeForTimeZone(_country, _language, dateObj).date;
  dateObj = new Date(convertToDatetime(newDateObj));
  const newDate = getDateFormat(_country, _language);
  const date = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();

  const formattedDate = newDate
    .replace("<dd>", `0${date}`.slice(-2))
    .replace("<DD>", `${date}`)
    .replace("<mm>", `0${month}`.slice(-2))
    .replace("<MM>", `${month}`)
    .replace("<yyyy>", `${year}`);
  return formattedDate;
};
