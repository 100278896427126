import { BRAND_LIST, COUNTRY } from "@type/index";
import { convertRegionName } from "@util/country";
import UQUSCountryENV from "../country/UQ/US";
import UQJPCountryENV from "../country/UQ/JP";
import PLJPCountryENV from "../country/PL/JP";
import GUJPCountryENV from "../country/GU/JP";
import UQATCountryENV from "../country/UQ/AT";
import UQBECountryENV from "../country/UQ/BE";
import UQBGCountryENV from "../country/UQ/BG";
import UQCZCountryENV from "../country/UQ/CZ";
import UQDECountryENV from "../country/UQ/DE";
import UQDKCountryENV from "../country/UQ/DK";
import UQEECountryENV from "../country/UQ/EE";
import UQESCountryENV from "../country/UQ/ES";
import UQFICountryENV from "../country/UQ/FI";
import UQFRCountryENV from "../country/UQ/FR";
import UQGRCountryENV from "../country/UQ/GR";
import UQHRCountryENV from "../country/UQ/HR";
import UQHUCountryENV from "../country/UQ/HU";
import UQIECountryENV from "../country/UQ/IE";
import UQITCountryENV from "../country/UQ/IT";
import UQLTCountryENV from "../country/UQ/LT";
import UQLUCountryENV from "../country/UQ/LU";
import UQLVCountryENV from "../country/UQ/LV";
import UQNLCountryENV from "../country/UQ/NL";
import UQPLCountryENV from "../country/UQ/PL";
import UQPTCountryENV from "../country/UQ/PT";
import UQROCountryENV from "../country/UQ/RO";
import UQSECountryENV from "../country/UQ/SE";
import UQSICountryENV from "../country/UQ/SI";
import UQSKCountryENV from "../country/UQ/SK";
import UQUKCountryENV from "../country/UQ/UK";
import UQKRCountryENV from "../country/UQ/KR";

export const REACT_APP_BRAND_NAME = process.env.REACT_APP_BRAND_NAME || "";
export const BUILD_VERSION = process.env.BUILD_VERSION || "";
export const LOCALISATION_VERSION = process.env.LOCALISATION_VERSION || "";
export const UQ_UIKIT_VERSION = process.env.UQ_UIKIT_VERSION || "";
export const PL_UIKIT_VERSION = process.env.PL_UIKIT_VERSION || "";
export const GU_UIKIT_VERSION = process.env.GU_UIKIT_VERSION || "";

// UQ/JP
export const UQ_JP_JA_Ios_UserAgent = "com.uniqlo.catalogue";
export const UQ_JP_JA_Android_UserAgent = "com.uniqlo.ja.catalogue";
// UQ/US
export const UQ_US_EN_Ios_UserAgent = "com.uniqlo.main.us";
export const UQ_US_EN_Android_UserAgent = "com.uniqlo.usa.catalogue";
// Native app
export const FR_UserAgent = "FRNativeApp";
// GU/JP
export const guIOSPattern = /gu ios application/i;
export const guAndroidPattern = /gu android application/i;

export const MINIMUM_FETCH_INTERVAL = 15 * 60 * 1000; // 15m
export const FETCH_TIMEOUT_MILLIS = 15 * 1000; // 15s
export const TRACK_EVENT = "trackEvent";
export const TRACK_EVENT_SEARCH = "trackEventSearch";

export const SB_ENV = (brand: string, region: string): any => {
  switch (`${brand}${convertRegionName(region)}`) {
    case `${BRAND_LIST.UQ}${COUNTRY.JP}`:
      return UQJPCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.US}`:
      return UQUSCountryENV;
    case `${BRAND_LIST.PL}${COUNTRY.JP}`:
      return PLJPCountryENV;
    case `${BRAND_LIST.GU}${COUNTRY.JP}`:
      return GUJPCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.KR}`:
      return UQKRCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.AT}`:
      return UQATCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.BE}`:
      return UQBECountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.BG}`:
      return UQBGCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.CZ}`:
      return UQCZCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.DE}`:
      return UQDECountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.DK}`:
      return UQDKCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.EE}`:
      return UQEECountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.ES}`:
      return UQESCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.FI}`:
      return UQFICountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.FR}`:
      return UQFRCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.GR}`:
      return UQGRCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.HR}`:
      return UQHRCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.HU}`:
      return UQHUCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.IE}`:
      return UQIECountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.IT}`:
      return UQITCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.LT}`:
      return UQLTCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.LU}`:
      return UQLUCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.LV}`:
      return UQLVCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.NL}`:
      return UQNLCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.PL}`:
      return UQPLCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.PT}`:
      return UQPTCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.RO}`:
      return UQROCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.SE}`:
      return UQSECountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.SI}`:
      return UQSICountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.SK}`:
      return UQSKCountryENV;
    case `${BRAND_LIST.UQ}${COUNTRY.UK}`:
      return UQUKCountryENV;
    default:
      return UQJPCountryENV;
  }
};
