import React from "react";
import { useParams, generatePath, Navigate } from "react-router-dom";
import { RouterUrlType } from "@type/index";

const RedirectTop = () => {
  const params = useParams();
  const url = generatePath(
    "/:region/:language/stylingbook/officialstyling/women",
    {
      ...params
    } as RouterUrlType
  );

  return <Navigate replace to={url} />;
};
export default RedirectTop;
