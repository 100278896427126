import { REACT_APP_BRAND_NAME } from "@config/common";
import {
  BRAND_LIST,
  BRAND_NAME_LIST,
  FirebaseDataType,
  GenderOptionType,
  StyleGenderEnum
} from "@type/index";
import { isGu } from "@util/country";
import { ActionsUnion, createAction } from "../../helpers";

export const SET_USER_AGENT = "[userAgent] set userAgent";
export const SET_SHOW_LOGIN = "[showLogin] set showLogin";
export const SET_SHOW_WISH_ICON_ERROR =
  "[showWishIconError] set showWishIconError";
export const SET_SHOW_SLIDE = "[showSlide] set showSlide";
export const SET_IS_APP = "[isApp] set isApp";
export const SET_FIREBASE = "[firebase] set firebase";

export const Actions = {
  setUserAgent: (userAgent: string) => createAction(SET_USER_AGENT, userAgent),
  setShowLogin: (payload: boolean) => createAction(SET_SHOW_LOGIN, payload),
  setShowWishIconError: (payload: boolean) =>
    createAction(SET_SHOW_WISH_ICON_ERROR, payload),
  setShowSlide: (payload: boolean) => createAction(SET_SHOW_SLIDE, payload),
  setIsApp: (payload: boolean) => createAction(SET_IS_APP, payload),
  setFirebase: (payload: any) => createAction(SET_FIREBASE, payload)
};

type ActionType = ActionsUnion<typeof Actions>;

export type State = {
  brand_name: string;
  userAgent: string;
  showLogin: boolean;
  showSlide: boolean;
  isApp: boolean;
  showWishIconError: boolean;
  firebaseData: FirebaseDataType;
  is_loading: boolean;
  sb_gender_list: Array<GenderOptionType>;
  sh_gender_list: Array<GenderOptionType>;
  region: string;
  language: string;
  basePath: string;
};

const default_gender_switch = {
  women: true,
  men: true,
  kids: true,
  baby: true
};

// Intercepted brand from domain
const getBrand = () => {
  if (REACT_APP_BRAND_NAME) {
    return REACT_APP_BRAND_NAME;
  } else {
    const _brand = window.location.hostname;
    if (_brand.includes(BRAND_NAME_LIST.UQ)) {
      return BRAND_LIST.UQ;
    }
    if (_brand.includes(BRAND_NAME_LIST.GU)) {
      return BRAND_LIST.GU;
    }
    if (_brand.includes(BRAND_NAME_LIST.PL)) {
      return BRAND_LIST.PL;
    }
    return BRAND_LIST.UQ;
  }
};

export const default_gender_list = isGu(getBrand())
  ? [
      {
        key: "women",
        value: StyleGenderEnum.WOMEN,
        code: 2,
        lid: "text_styling_book_meta_women",
        displayLid: "text_women"
      },
      {
        key: "men",
        value: StyleGenderEnum.MEN,
        code: 1,
        lid: "text_styling_book_meta_men",
        displayLid: "text_men"
      },
      {
        key: "kids",
        value: StyleGenderEnum.KIDS,
        code: 3,
        lid: "text_styling_book_gender_menu_kids_and_baby",
        displayLid: "text_styling_book_meta_kids_and_baby"
      }
    ]
  : [
      {
        key: "women",
        value: StyleGenderEnum.WOMEN,
        code: 2,
        lid: "text_styling_book_meta_women",
        displayLid: "text_women"
      },
      {
        key: "men",
        value: StyleGenderEnum.MEN,
        code: 1,
        lid: "text_styling_book_meta_men",
        displayLid: "text_men"
      },
      {
        key: "kids",
        value: StyleGenderEnum.KIDS,
        code: 3,
        lid: "text_styling_book_meta_kids",
        displayLid: "text_kids"
      },
      {
        key: "baby",
        value: StyleGenderEnum.BABY,
        code: 4,
        lid: "text_styling_book_meta_baby",
        displayLid: "text_baby"
      }
    ];

// Intercepted region and language from url path
const getRegionAndLanguage = () => {
  const _region = window.location.pathname;
  const _lang = window.location.pathname.slice(1);
  const regex1 = /\/(.+?)\//g;
  const regex2 = /\/(.+?)\//g;
  const region = regex1.exec(_region) || "jp";
  const lang = regex2.exec(_lang) || "ja";
  return {
    region: region[1],
    lang: lang[1]
  };
};

const initialState = {
  brand_name: getBrand(),
  userAgent: "",
  showLogin: false,
  showSlide: false,
  isApp: false,
  showWishIconError: false,
  firebaseData: {
    sb_tab_gender: default_gender_switch,
    sh_tab_gender: default_gender_switch,
    sb_wish_icon: true,
    sb_wish_count: true,
    sh_wish_icon: true,
    sh_wish_count: true
  },
  is_loading: true,
  sb_gender_list: default_gender_list,
  sh_gender_list: default_gender_list,
  region: getRegionAndLanguage().region,
  language: getRegionAndLanguage().lang,
  basePath: `/${getRegionAndLanguage().region}/${getRegionAndLanguage().lang}/`
};

export default (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case SET_USER_AGENT:
      return { ...state, userAgent: action.payload };
    case SET_SHOW_LOGIN:
      return { ...state, showLogin: action.payload };
    case SET_SHOW_WISH_ICON_ERROR:
      return { ...state, showWishIconError: action.payload };
    case SET_SHOW_SLIDE:
      return { ...state, showSlide: action.payload };
    case SET_IS_APP:
      return { ...state, isApp: action.payload };
    case SET_FIREBASE: {
      const sb = action.payload.sb_tab_gender;
      const sh = action.payload.sh_tab_gender;
      const sb_list = JSON.parse(JSON.stringify(state.sb_gender_list));
      const sh_list = JSON.parse(JSON.stringify(state.sh_gender_list));
      // sb gender list
      for (let i = sb_list.length - 1; i >= 0; i -= 1) {
        if (!sb[sb_list[i].key]) {
          sb_list.splice(i, 1);
        }
      }
      // sh gender list
      for (let i = sh_list.length - 1; i >= 0; i -= 1) {
        if (!sh[sh_list[i].key]) {
          sh_list.splice(i, 1);
        }
      }
      return {
        ...state,
        firebaseData: {
          ...action.payload,
          sb_tab_gender: sb,
          sh_tab_gender: sh,
          sb_wish_icon: action.payload.sb_wish.icon,
          sb_wish_count: action.payload.sb_wish.count,
          sh_wish_icon: action.payload.sh_wish.icon,
          sh_wish_count: action.payload.sh_wish.count
        },
        is_loading: false,
        sb_gender_list: sb_list,
        sh_gender_list: sh_list
      };
    }
    default:
      return state;
  }
};
