import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CombinedReducersType } from "@redux/reducer";
import {
  Accordion,
  ButtonIcon,
  Drawer,
  LinkCell,
  LinkImage,
  LinkNavigation,
  LinkNavigationGroup,
  NavigationHeader,
  CollectionListHorizontal,
  Keyline
} from "@common/DynamicUikit";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import {
  sendTrackEvent,
  transformBrandForGoogleAnalytics
} from "@util/analytics/utils";
import {
  getBrandName,
  getAboutAppURL,
  getEcRenewalCompatibility
} from "@util/helpers";
import { TRACK_EVENT, SB_ENV, FR_UserAgent } from "@config/common";
import { BRAND_LIST, COUNTRY, LANGUAGE, RouterUrlType } from "@type/index";
import { isEUMinorCountry, isGu } from "@util/country";
import { getUA } from "react-device-detect";
import { initTopPageAllData } from "../../App/TopStyling/stylingConfig";

const PublicHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const config = useSelector((store: CombinedReducersType) => store.config);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpen = () => {
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleNavigate = (link: string) => {
    initTopPageAllData(dispatch);
    const url = generatePath(`/:region/:language/stylingbook/${link}/women`, {
      ...params
    } as RouterUrlType);
    navigate(url);
    handleClose();
  };

  const UQEn = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="40"
      height="40"
    >
      <title>{t("text_styling_book_go_to_top")}</title>
      <path fill="red" d="M0 0h40v40H0z" />
      <path
        d="M29.48 5.47h2.53v12.64h-2.53zM13.47 13.9a4.21 4.21 0 0 1-8.42 0V5.47h2.53v8.43a1.68 1.68 0 1 0 3.36 0V5.47h2.53zm11.79 20.63h-8.42V21.89h2.53V32h5.89v2.53zm0-16.42h-2.53l-3.36-7.22v7.22h-2.53V5.47h2.53l3.36 7.22V5.47h2.53v12.64zm-16 3.78a4.21 4.21 0 0 0-4.21 4.22v4.21a4.21 4.21 0 0 0 4.21 4.21 4.34 4.34 0 0 0 .82-.07l.86 2.6h2.53l-1.25-3.75a4.2 4.2 0 0 0 1.25-3v-4.2a4.21 4.21 0 0 0-4.21-4.22m1.68 8.43a1.68 1.68 0 1 1-3.36 0v-4.21a1.68 1.68 0 1 1 3.36 0zm19.8-8.43a4.22 4.22 0 0 0-4.22 4.22v4.21a4.21 4.21 0 0 0 8.42 0v-4.21a4.21 4.21 0 0 0-4.21-4.22m1.68 8.43a1.68 1.68 0 0 1-3.37 0v-4.21a1.68 1.68 0 0 1 3.37 0z"
        fill="#fff"
      />
    </svg>
  );

  const UQDouble = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 90 40"
      width="90"
      height="40"
    >
      <title>{t("text_styling_book_go_to_top")}</title>
      <path fill="red" d="M50 0h40v40H50zM0 0h40v40H0z" />
      <g fill="#fff">
        <path d="M79.48 5.47h2.53v12.64h-2.53zM63.47 13.9a4.21 4.21 0 0 1-8.42 0V5.47h2.53v8.43a1.68 1.68 0 1 0 3.36 0V5.47h2.53zm11.79 20.63h-8.42V21.89h2.53V32h5.89v2.53zm0-16.42h-2.53l-3.36-7.22v7.22h-2.53V5.47h2.53l3.36 7.22V5.47h2.53v12.64zm-16 3.78a4.21 4.21 0 0 0-4.21 4.22v4.21a4.21 4.21 0 0 0 4.21 4.21 4.34 4.34 0 0 0 .82-.07l.86 2.6h2.53l-1.25-3.75a4.2 4.2 0 0 0 1.25-3v-4.2a4.21 4.21 0 0 0-4.21-4.22m1.68 8.43a1.68 1.68 0 1 1-3.36 0v-4.21a1.68 1.68 0 1 1 3.36 0zm19.8-8.43a4.22 4.22 0 0 0-4.22 4.22v4.21a4.21 4.21 0 0 0 8.42 0v-4.21a4.21 4.21 0 0 0-4.21-4.22m1.68 8.43a1.68 1.68 0 0 1-3.37 0v-4.21a1.68 1.68 0 0 1 3.37 0zM22.74 15.16H34.1v2.52H22.74zM24 5.47h8.84V8H24zm-9.26 0H7.15V8h5.06v7.16H5.9v2.52h11.36v-2.52h-2.52V5.47zm8 16.84v12.22H34.1V22.31zM31.57 32h-6.31v-7.16h6.31zM7.15 22.31l-1.28 6.12h2.52l.76-3.59h5.07L12.73 32H5.14l-.51 2.53h10.11l2.52-12.22H7.15z" />
      </g>
    </svg>
  );

  // const PLIcon = (
  //   <svg
  //     width={isSP() ? "115" : "201"}
  //     height={isSP() ? "22" : "40"}
  //     viewBox="0 0 115 22"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <title>{t("text_styling_book_go_to_top")}</title>
  //     <path
  //       d="M0 22H21.8823V0H0V22ZM0.484398 0.487384H21.3979V21.513H0.484398V0.487384ZM31.0392 22H52.9215V0H31.0392V22ZM31.5236 0.487384H52.4371V21.513H31.5236V0.487384ZM62.0772 22H83.96V0H62.0772V22ZM62.5616 0.487384H83.4752V21.513H62.5616V0.487384ZM93.1177 22H115V0H93.1177V22ZM93.6021 0.487384H114.516V21.513H93.6021V0.487384ZM11.74 3.88154L6.84365 3.88113V17.9631H8.94474V12.327H11.74C14.0699 12.327 15.9588 10.4463 15.9588 8.10526C15.9588 5.76174 14.0699 3.88154 11.74 3.88154ZM11.74 10.2122H8.94474V5.99055L11.74 5.99095C12.9149 5.99095 13.8565 6.94043 13.8565 8.10526C13.8565 9.2652 12.9149 10.2122 11.74 10.2122ZM41.01 15.8484H46.2686V17.9631H38.906L38.9064 3.88113H41.01V15.8484ZM103.008 5.99055L99.5045 5.99095V3.88154H108.616V5.99095L105.11 5.99055V17.9631H103.008V5.99055ZM75.1073 13.8764C75.1073 12.4191 74.1251 12.0561 72.9125 11.6854C71.2147 11.1829 69.1513 10.3517 69.1513 7.60075C69.1513 5.38896 70.9429 3.64131 73.1425 3.58707C75.1714 3.5369 76.4506 4.89913 76.8477 5.27884L75.4152 6.75201C75.4152 6.75201 74.3689 5.6818 73.1425 5.70179C72.0804 5.71891 71.2512 6.55134 71.2512 7.60075C71.2512 9.15018 72.504 9.40224 73.93 9.82599C75.4724 10.2914 77.2068 11.2616 77.2068 13.8764C77.2068 16.2725 75.2538 18.2645 72.8537 18.2526C70.5299 18.2416 69.1509 16.5303 69.1509 16.5303L70.6179 15.082C70.6179 15.082 71.4695 16.0812 72.8005 16.122C74.0497 16.1604 75.1073 15.115 75.1073 13.8764Z"
  //       fill="black"
  //     />
  //   </svg>
  // );
  const PLIcon = (
    <img
      alt="PLST logo"
      src="https://asset.uniqlo.com/g/icons/logo_plst_v2.svg"
    />
  );

  const GUIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="40"
      height="40"
    >
      <title>{t("text_styling_book_go_to_top")}</title>
      <path fill="#17288b" d="M0 0h40v40H0z" />
      <path
        d="M20.23 18.55h-8.6v2.91h5.64a5.83 5.83 0 1 1-1.79-5.82h3.71a8.92 8.92 0 1 0 1 2.91zm13.95-6.19v9.45a4 4 0 0 1-8 0v-9.45h-2.91v9.45a6.91 6.91 0 0 0 13.82 0v-9.45z"
        fill="#fff100"
      />
    </svg>
  );

  // Handle brand logo
  const handleBrandLogo = (brand: string) => {
    switch (brand) {
      case BRAND_LIST.UQ: {
        if (config.region === COUNTRY.KR) {
          return UQEn;
        } else {
          return UQDouble;
        }
      }
      case BRAND_LIST.PL:
        return PLIcon;
      case BRAND_LIST.GU:
        return GUIcon;
      default:
        return UQDouble;
    }
  };

  const handleSwitchLanguage = (lang: LANGUAGE) => {
    if (lang === config.language) {
      return;
    }

    const pathArr = window.location.pathname.split("/");
    pathArr[2] = lang;
    const url = `${window.location.origin}${pathArr.join("/")}${
      window.location.search
    }`;
    window.location.replace(url);
  };

  return (
    <div>
      <NavigationHeader
        contentArea={[
          <ButtonIcon
            element="button"
            icon="feature-menu"
            label={t("text_menu")}
            onClick={handleOpen}
            variant="dark"
          />
        ]}
        featureImage={[
          <LinkImage element="a" href={`${config.basePath}stylingbook`}>
            <img
              alt="StylingBook logo"
              src="https://asset.uniqlo.com/icons/stylingbook-logo.svg"
            />
          </LinkImage>
        ]}
        linkImageArea={
          isGu(config.brand_name) &&
          config.isApp &&
          getUA.indexOf(FR_UserAgent) <= -1
            ? [
                <LinkImage element="a">
                  {handleBrandLogo(config.brand_name)}
                </LinkImage>
              ]
            : [
                <LinkImage
                  element="a"
                  href={`${config.basePath}`}
                  gaCallback={() => {
                    // ga
                    sendTrackEvent({
                      brand: transformBrandForGoogleAnalytics(
                        config.brand_name
                      ),
                      event: TRACK_EVENT,
                      eventAction: `${getBrandName()} Logo`,
                      eventCategory: "header",
                      eventLabel: `Icon label ${getBrandName()} Logo`
                    });
                  }}
                >
                  {handleBrandLogo(config.brand_name)}
                </LinkImage>
              ]
        }
        sticky
      />
      <Drawer
        closeLabel={getEcRenewalCompatibility() ? " " : t("text_a11y_close")}
        onBackdropClick={handleClose}
        onRequestClose={handleClose}
        position="end"
        title=""
        visible
        isOpen={openDrawer}
      >
        <React.Fragment key="header-drawer">
          <Accordion
            element="fieldset"
            label={t("text_styling_book_header_title")}
            labelElement="legend"
            defaultExpanded
            variant="small"
          >
            {config.region.toString() === COUNTRY.NL ||
            config.region.toString() === COUNTRY.BE ||
            isEUMinorCountry(config.region) ? (
              <LinkNavigationGroup
                indented
                labelId={t("text_styling_book_header_title")}
                variant="vertical"
              >
                <LinkNavigation
                  element="button"
                  onClick={() => handleNavigate("officialstyling")}
                >
                  {t("text_styling_book_official_styling")}
                </LinkNavigation>
              </LinkNavigationGroup>
            ) : (
              <LinkNavigationGroup
                indented
                labelId={t("text_styling_book_header_title")}
                variant="vertical"
              >
                <LinkNavigation
                  element="button"
                  onClick={() => handleNavigate("officialstyling")}
                >
                  {t("text_styling_book_official_styling")}
                </LinkNavigation>
                <LinkNavigation
                  element="button"
                  onClick={() => handleNavigate("stylehint")}
                >
                  {t("text_styling_book_stylehint")}
                </LinkNavigation>
              </LinkNavigationGroup>
            )}
          </Accordion>
          {!config.isApp && (
            <LinkCell
              element="a"
              href={`${config.basePath}wishlist`}
              id="wish list"
              label={t("text_wishlist")}
              variant="small"
            />
          )}
          {!config.isApp && (
            <LinkCell
              element="a"
              href={`${config.basePath}member`}
              id="account"
              label={t("text_membership_info")}
              variant="small"
            />
          )}
          {SB_ENV(config.brand_name, config.region).showAboutApp &&
            !(
              isGu(config.brand_name) &&
              config.isApp &&
              getUA.indexOf(FR_UserAgent) <= -1
            ) && (
              <LinkCell
                element="a"
                href={getAboutAppURL(config.region, config.language)}
                id="stylehint app"
                label={t("text_stylehintapp")}
                variant="small"
              />
            )}
          {SB_ENV(config.brand_name, config.region).languages.length > 1 && (
            <>
              <Keyline className="fr-ec-mb-spacing-04 fr-ec-mt-spacing-04" />
              <CollectionListHorizontal spacingValue="12" divider>
                {SB_ENV(config.brand_name, config.region).languages.map(
                  (lang: LANGUAGE) => (
                    <LinkNavigation
                      variant="TitleSmall"
                      element="button"
                      onClick={() => handleSwitchLanguage(lang)}
                      selected={config.language === lang}
                    >
                      {t(`text_locale_${lang}`)}
                    </LinkNavigation>
                  )
                )}
              </CollectionListHorizontal>
            </>
          )}
        </React.Fragment>
      </Drawer>
    </div>
  );
};

export default PublicHeader;
