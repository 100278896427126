import { ActionsUnion, createAction } from "@redux/helpers";
import {
  RelatedStylingStylehintData,
  RelatedStylingStylehintPayload,
  RelatedStylingStylehintObj,
  StylehintListPayload,
  StylehintListType
} from "@type/index";

export const GET_RELATED_STYLING_STYLEHINT =
  "[related styling stylehint] get related styling stylehint request";
const GET_RELATED_STYLING_STYLEHINT_SUCCESS =
  "[related styling stylehint] get related styling stylehint success";
const GET_RELATED_STYLING_STYLEHINT_LOADING =
  "[related styling stylehint] get related styling stylehint loading";
const INIT_RELATED_STYLING_STYLEHINT =
  "[related styling stylehint] init related styling stylehint data";
export const GET_STYLEHINT_CAROUSEL_LIST =
  "[carousel styling stylehint] get stylehint carousel list request";
const GET_STYLEHINT_CAROUSEL_LIST_SUCCESS =
  "[carousel styling stylehint] get stylehint carousel list success";
const GET_STYLEHINT_CAROUSEL_LIST_LOADING =
  "[carousel styling stylehint] get stylehint carousel list loading";

export const Actions = {
  getRelatedStylingStylehint: (payload: RelatedStylingStylehintPayload) =>
    createAction(GET_RELATED_STYLING_STYLEHINT, payload),
  getRelatedStylingStylehintSuccess: (payload: RelatedStylingStylehintObj) =>
    createAction(GET_RELATED_STYLING_STYLEHINT_SUCCESS, payload),
  getRelatedStylingStylehintLoading: (payload: boolean) =>
    createAction(GET_RELATED_STYLING_STYLEHINT_LOADING, payload),
  initRelatedStylingStylehint: () =>
    createAction(INIT_RELATED_STYLING_STYLEHINT),
  getStylehintCarouselList: (payload: StylehintListPayload) =>
    createAction(GET_STYLEHINT_CAROUSEL_LIST, payload),
  getStylehintCarouselListSuccess: (payload: StylehintListType) =>
    createAction(GET_STYLEHINT_CAROUSEL_LIST_SUCCESS, payload),
  getStylehintCarouselListLoading: (payload: boolean) =>
    createAction(GET_STYLEHINT_CAROUSEL_LIST_LOADING, payload)
};

type ActionType = ActionsUnion<typeof Actions>;

export type State = RelatedStylingStylehintData;

const initialState = {
  styles: [],
  loading: true,
  carousel_styles: [],
  carousel_loading: true
};

export default (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case GET_RELATED_STYLING_STYLEHINT_SUCCESS:
      return {
        ...state,
        styles: [...(action.payload.styles || [])],
        loading: false
      };
    case GET_RELATED_STYLING_STYLEHINT_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case INIT_RELATED_STYLING_STYLEHINT:
      return {
        ...state,
        ...initialState
      };
    case GET_STYLEHINT_CAROUSEL_LIST_SUCCESS:
      return {
        ...state,
        carousel_styles: action.payload.images,
        carousel_loading: false
      };
    case GET_STYLEHINT_CAROUSEL_LIST_LOADING:
      return {
        ...state,
        carousel_loading: action.payload
      };
    default:
      return state;
  }
};
