import React from "react";
import { Body, Display, GutterContainer, LinkText } from "@common/DynamicUikit";
import { useTranslation } from "react-i18next";
import { CombinedReducersType } from "@redux/reducer";
import { useSelector } from "react-redux";
import { SB_ENV } from "@config/common";

const SystemError = () => {
  const { t } = useTranslation();
  const config = useSelector((store: CombinedReducersType) => store.config);

  return (
    <GutterContainer>
      <Display element="p" variant="display3" className="fr-ec-mb-spacing-05">
        {t("text_error_title")}
      </Display>
      <Body element="p" variant="standard">
        {t("text_503_error_description-1")}
      </Body>
      <Body element="p" variant="standard">
        {t("text_503_error_description-2")}
      </Body>
      <Body element="p" variant="standard" className="fr-ec-mb-spacing-06">
        {t("text_503_error_instruction", {
          text_contactus: t("text_contactus")
        })}
      </Body>
      <Body element="p" variant="standard" className="fr-ec-mb-spacing-02">
        <LinkText
          ariaLabel="aria Label"
          element="a"
          href={config.basePath}
          variant="standalone-secondary"
          className="fr-ec-mb-spacing-02"
        >
          {t("text_styling_book_go_to_top")}
        </LinkText>
      </Body>
      <Body element="p" variant="standard">
        <LinkText
          element="a"
          href={SB_ENV(config.brand_name, config.region).faqUrl}
          variant="standalone-secondary"
        >
          {t("text_contactus")}
        </LinkText>
      </Body>
    </GutterContainer>
  );
};
export default SystemError;
