import React, { createElement } from "react";
import { CMSComponentSchema } from "@type/index";

type Props = {
  key?: string;
  [key: string]: any;
  gaCallback?: (param: HTMLElement) => void;
};

function createComponentNode(
  component: CMSComponentSchema,
  index: number,
  base: string,
  CMSComponents: any,
  pageUrl = "",
  translations?: { [key: string]: string },
  CMSProperties?: { [key: string]: string }
) {
  if (component._type) {
    const componentProps: Props = {};
    componentProps.key = `${base}.${index}`;

    Object.keys(component).forEach((key) => {
      const propValue = component[key];

      // To make sure It is array and have component (_type key is available only for component)
      if (Array.from(propValue).length && propValue[0] && propValue[0]._type) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        componentProps[key] = parseComponent(
          propValue,
          CMSComponents,
          componentProps.key,
          pageUrl,
          translations,
          CMSProperties
        );
      } else {
        componentProps[key] = propValue;
      }
    });

    if (
      // Check if gaCallback is already assigned before
      typeof window !== "undefined" &&
      !componentProps.gaCallback &&
      (componentProps.gaCategory ||
        componentProps.gaLabel ||
        componentProps.gaAction)
    ) {
      componentProps.gaCallback = window.gtmEventHandler;
    }

    if (!componentProps.children) {
      delete componentProps.children;
    }

    if (!CMSComponents[component._type]) {
      delete componentProps._type;
    }

    return createElement(
      CMSComponents[component._type] || component._type,
      componentProps
    );
  }

  return null;
}

export const parseComponent = (
  list: Array<CMSComponentSchema>,
  CMSComponents: any,
  base = "0",
  pageUrl = "",
  translations?: { [key: string]: string },
  CMSProperties?: { [key: string]: string },
  wrappedByDiv?: boolean
) => {
  let components = null;
  if (Array.from(list).length) {
    components = list.map((component, index) => {
      try {
        const componentNode = createComponentNode(
          component,
          index,
          base,
          CMSComponents,
          pageUrl,
          translations,
          CMSProperties
        );
        return wrappedByDiv
          ? React.createElement("div", { _id: component._id }, componentNode)
          : componentNode;
      } catch (error) {
        return null;
      }
    });
  }

  return components;
};
