import React, { useEffect, useMemo, useRef, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { getUA, isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import {
  generatePath,
  Link,
  useLocation,
  useNavigate,
  useNavigationType,
  useParams
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CombinedReducersType,
  ConfigActions,
  DetailStylingActions,
  FavoritesStylingActions,
  RelatedStylingOfficialActions,
  RelatedStylingStylehintActions
} from "@redux/reducer";
import { default_gender_list } from "@redux/reducer/config";
import { useTranslation } from "react-i18next";
import {
  Body,
  Breadcrumb,
  BreadcrumbGroup,
  ButtonToggle,
  Caption,
  Carousel,
  CollectionList,
  Display,
  FlagText,
  GutterContainer,
  HeaderPage,
  Image,
  ImageEnhancements,
  ItemTag,
  ItemTags,
  Keyline,
  MediaObject,
  Price,
  StyleUserInfo,
  Tab,
  TabGroup,
  StylingGrid,
  TemplateMediaInformation,
  Tile,
  Title,
  Spacer,
  TagFilterGroup,
  TagFilter,
  LoadingIndicator,
  LayoutWrapper,
  Layout,
  ProductTile
} from "@common/DynamicUikit";
import {
  BRAND_LIST,
  DetailFromPageEnum,
  GenderObjType,
  ImageInfo,
  ImageType,
  PageType,
  RelatedStylingStylehintType,
  RouterUrlType,
  SBNormalImages,
  StylingType,
  STYLING_SORT_BY
} from "@type/index";
import {
  formatDiscount,
  formatPrice,
  getEcRenewalCompatibility,
  getEcRenewalImageRatio,
  getIsLoggedIn,
  getSizeForItemInfo,
  handleStoreLink,
  isDisplaySizeLabel,
  isNotEmptyObj,
  isStaff,
  localizeGenderName,
  preventBubbling
} from "@util/helpers";
import { FR_UserAgent, SB_ENV, TRACK_EVENT } from "@config/common";
import { userHeight } from "@util/height";
import { formatDate } from "@util/date";
import { getHrefLangList } from "@util/hreflang";
import {
  sendTrackEvent,
  trackPageView,
  transformBrandForGoogleAnalytics
} from "@util/analytics/utils";
import { getPageViewBaseOption } from "@util/analytics";
import { isGUNativeApp } from "@util/nativeApp";
import {
  NotFound,
  LoginModal,
  SystemError,
  ErrorModal,
  GoTop,
  FavoritesPopup,
  StyleHintBanner
} from "../../common";
import {
  initTopPageAllData,
  isShowWishCount,
  isShowWishIcon,
  postOrDeleteFavoritesStyling,
  useDebounce
} from "../TopStyling/stylingConfig";
import { flagColorType, handleFlagsSort } from "./flagsConfig";
import {
  CarouselListType,
  handleCarouselList,
  handleGenderCodeToStr,
  handleItemTags,
  handleModelsList,
  handleReloadOfficialCarouseList,
  handleReloadStylehintCarouseList,
  isActiveProduct,
  hasProductForMutiModel,
  NewProductDetailType,
  productListUnique,
  RelatedListType,
  updateCarouselList
} from "./detailConfig";

const DetailStyling = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const useType = useNavigationType();
  const { stylingId = "", type = "" } = useParams();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const config = useSelector((store: CombinedReducersType) => store.config);
  const stylingDetailData = useSelector(
    (store: CombinedReducersType) => store.detailStyling
  );
  const relatedStylehintData = useSelector(
    (store: CombinedReducersType) => store.relatedStylingStylehint
  );
  const relatedOfficialData = useSelector(
    (store: CombinedReducersType) => store.relatedStylingOfficial
  );
  const favoriteSBStylingList = useSelector(
    (store: CombinedReducersType) => store.favoritesStyling.stylingSBWishlist
  );
  const favoriteSHStylingList = useSelector(
    (store: CombinedReducersType) => store.favoritesStyling.stylingSHWishlist
  );
  const favoritesStylingLoading = useSelector(
    (store: CombinedReducersType) => store.favoritesStyling.stylingLoading
  );
  const [currentTab, setCurrentTab] = useState<string>(""); // position tabs current tab
  const [carouselList, setCarouselList] = useState<Array<CarouselListType>>([]);
  const [productList, setProductList] = useState<Array<NewProductDetailType>>(
    []
  ); // product lists
  const [titleList, setTitleList] =
    useState<Array<NewProductDetailType> | null>(null); // product title lists
  const [productIds, setProductIds] = useState<string>(""); // product ids
  const [relatedList, setRelatedList] = useState<Array<RelatedListType>>([]); // related styling lists
  const [relatedStylingIds, setRelatedStylingIds] = useState<string>(""); // related styling ids
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageHeight, setImageHeight] = useState<number>(0);
  const [itemToggleActive, setItemToggleActive] = useState(false); // item tag toggle
  const [stylingGender, setStylingGender] = useState<string>("");
  const previousStylingId = useRef("");
  const [defaultCarouselData, setDefaultCarouselData] =
    useState<CarouselListType>({
      styleId: stylingId,
      styleImageUrl: "",
      productTagsList: [],
      wishCount: 0
    });
  const [isClickRelated, setIsClickRelated] = useState<boolean>(false);
  const metaTitle = useRef("");
  const metaDescription = useRef("");
  const isShowError = useRef<boolean>(false);
  const [isPopupShown, setIsPopupShown] = useState<boolean>(false);
  const [currentItemInfo, setCurrentItemInfo] = useState({
    productInfo: {} as NewProductDetailType,
    styleId: ""
  });

  // handle click breadcrumb url
  const breadcrumbStylingbookUrl = useMemo((): string => {
    const url = generatePath("/:region/:language/stylingbook/:type/women", {
      ...params
    } as RouterUrlType);
    return url;
  }, [params]);

  // SEO - add meta data
  // ga - trackPageView
  useDeepCompareEffect(() => {
    if (titleList !== null) {
      if (titleList.length === 0) {
        if (type === StylingType.official) {
          metaTitle.current = t("text_styling_book_meta_official_styling");
          metaDescription.current = t(
            "text_styling_book_meta_official_styling"
          );
        } else {
          metaTitle.current = t("text_styling_book_meta_stylehint");
          metaDescription.current = t("text_styling_book_meta_stylehint");
        }
      } else {
        const _productName = titleList
          .map((item) => item.productName)
          .slice(0, 2)
          .join("、");
        if (type === StylingType.official) {
          metaTitle.current = t(
            "text_styling_book_official_detail_meta_title",
            {
              officialProducts: _productName,
              uqPostfix: t("text_meta_title_postfix")
            }
          );
          metaDescription.current = t(
            "text_styling_book_official_detail_meta_description",
            {
              brand: t("text_styling_book_meta_title_brand"),
              officialProducts: _productName
            }
          );
        } else {
          metaTitle.current = t(
            "text_styling_book_stylehint_detail_meta_title",
            {
              stylehintProducts: _productName,
              uqPostfix: t("text_meta_title_postfix")
            }
          );
          metaDescription.current = t(
            "text_styling_book_stylehint_detail_meta_description",
            {
              brand: t("text_styling_book_meta_title_brand"),
              stylehintProducts: _productName
            }
          );
        }
      }
      trackPageView(
        getPageViewBaseOption(PageType.StylingBook, "", metaTitle.current)
      );
    }
  }, [type, t, titleList]);

  useEffect(() => {
    window.document.title = metaTitle.current;
    if (document.getElementsByName("description")[0]) {
      document
        .getElementsByName("description")[0]
        .setAttribute("content", metaDescription.current);
    }
  }, [titleList]);

  // SEO - add hreflang
  const hrefLangList = useMemo(
    () => getHrefLangList(location.pathname),
    [location.pathname]
  );

  // SEO - add script
  const breadcrumbJson = useMemo(() => {
    const _json = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: t("text_breadcrumbs_top"),
          item: `${window.location.origin}/${params.region}/${params.language}`
        },
        {
          "@type": "ListItem",
          position: 2,
          name:
            type === StylingType.official
              ? `${t("text_styling_book_header_title")} ${t(
                  "text_styling_book_official_styling"
                )}`
              : `${t("text_styling_book_header_title")} ${t(
                  "text_styling_book_stylehint"
                )}`,
          item: `${window.location.origin}${breadcrumbStylingbookUrl}`
        },
        {
          "@type": "ListItem",
          position: 3,
          name: `Style No.${stylingId}`
        }
      ]
    };
    return JSON.stringify(_json);
  }, [
    breadcrumbStylingbookUrl,
    params.language,
    params.region,
    type,
    stylingId,
    t
  ]);

  useEffect(() => {
    if (!stylingDetailData.fromPage) {
      initTopPageAllData(dispatch);
    }
    return () => {
      initDetailData();
      dispatch(DetailStylingActions.setFromPageName(""));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (useType === "POP") {
      if (previousStylingId.current === stylingId) {
        dispatch(
          DetailStylingActions.getDetailStyling({
            styleId: stylingId,
            type: type === StylingType.official ? "sb" : "sh",
            imageRatio: getEcRenewalImageRatio()
          })
        );
        if (type === StylingType.official) {
          dispatch(
            FavoritesStylingActions.getFavoritesStyling({
              ids: stylingId,
              type: "sb",
              fromDetail: true
            })
          );
        } else {
          dispatch(
            FavoritesStylingActions.getFavoritesStyling({
              ids: stylingId,
              type: "sh",
              fromDetail: true
            })
          );
        }
      }
      initDetailData();
      dispatch(DetailStylingActions.initCarouselList());
      dispatch(DetailStylingActions.setFromPageName(""));
    }
    previousStylingId.current = stylingId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, useType, location.pathname]);

  // get detail styling
  useEffect(() => {
    dispatch(
      DetailStylingActions.getDetailStyling({
        styleId: stylingId,
        type: type === StylingType.official ? "sb" : "sh",
        imageRatio: getEcRenewalImageRatio()
      })
    );
  }, [dispatch, type, stylingId]);

  // init gender
  useEffect(() => {
    if (stylingDetailData.detailInfo.gender) {
      setStylingGender(
        handleGenderCodeToStr(
          default_gender_list,
          stylingDetailData.detailInfo.gender
        )
      );
    } else {
      setStylingGender("women");
    }
  }, [stylingDetailData.detailInfo.gender]);

  // init carousel list - from top page to detail page
  useEffect(() => {
    if (
      stylingDetailData.fromPage === DetailFromPageEnum.top &&
      stylingDetailData.carouselList.length > 0
    ) {
      setCarouselList([...handleCarouselList(stylingDetailData.carouselList)]);
    }
  }, [stylingDetailData.carouselList, stylingDetailData.fromPage]);

  // init carousel list - click related styling to detail page or reload detail page
  // The default first image is the current styling
  useEffect(() => {
    if (
      stylingDetailData.fromPage === "" ||
      stylingDetailData.fromPage === DetailFromPageEnum.related
    ) {
      const tagsArr = productList.filter(
        (item: NewProductDetailType) => item.box && item.box.length > 0
      );
      const obj: CarouselListType = {
        styleId: stylingId,
        styleImageUrl: stylingDetailData.detailInfo.styleImageUrl,
        productTagsList: tagsArr || [],
        wishCount: stylingDetailData.likesCount || 0
      };
      setDefaultCarouselData({ ...obj });
    }
  }, [
    productList,
    stylingDetailData.detailInfo.styleImageUrl,
    stylingDetailData.fromPage,
    stylingDetailData.likesCount,
    stylingId
  ]);

  // init carousel list - click related styling to detail page or reload detail page
  // Get data from normal api
  useEffect(() => {
    if (
      stylingDetailData.fromPage === "" ||
      stylingDetailData.fromPage === DetailFromPageEnum.related
    ) {
      let newArr: Array<ImageInfo> = [];
      if (type === StylingType.official) {
        const arr =
          JSON.parse(JSON.stringify(relatedOfficialData.carousel_styles)) || [];
        if (arr.length > 0) {
          newArr = handleReloadOfficialCarouseList(arr, stylingId);
        }
      } else {
        const arr =
          JSON.parse(JSON.stringify(relatedStylehintData.carousel_styles)) ||
          [];
        if (arr.length > 0) {
          newArr = handleReloadStylehintCarouseList(arr, stylingId);
        }
      }
      const newCarousel = handleCarouselList([...newArr]);
      setCarouselList([defaultCarouselData, ...newCarousel]);
    }
  }, [
    defaultCarouselData,
    relatedOfficialData.carousel_styles,
    type,
    relatedStylehintData.carousel_styles,
    stylingDetailData.fromPage,
    stylingId
  ]);

  // get carousel area width and height
  useEffect(() => {
    if (
      isMobile &&
      type === StylingType.stylehint &&
      !stylingDetailData.loading
    ) {
      setTimeout(() => {
        const image: any = document.querySelector(`.carousel_${stylingId}`);
        if (image) {
          setImageWidth(image.offsetWidth);
          setImageHeight(image.offsetHeight);
        }
      }, 1000);
    }
  }, [type, stylingDetailData.loading, stylingId]);

  // update carousel list
  useEffect(() => {
    if (
      stylingDetailData.fromPage !== "" &&
      stylingDetailData.fromPage !== DetailFromPageEnum.related
    ) {
      const tagsArr = productList.filter(
        (item: NewProductDetailType) => item.box && item.box.length > 0
      );
      const total = stylingDetailData.likesCount || 0;
      if (carouselList.length > 0) {
        const arr = updateCarouselList(stylingId, carouselList, tagsArr, total);
        setCarouselList([...arr]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList, stylingId, stylingDetailData.likesCount]);

  // init products list(in order to calculate center coordinate and tag position)
  useEffect(() => {
    if (!stylingDetailData.loading) {
      if (stylingDetailData.detailInfo.models.length > 0) {
        const model = handleModelsList(
          stylingDetailData.detailInfo.models,
          type,
          currentTab
        );
        if (model && model.products.length > 0) {
          let arr = JSON.parse(JSON.stringify(model.products));
          arr = productListUnique(arr);
          arr.map((item: NewProductDetailType) => {
            if (item.box && item.box.length > 0) {
              const newBox = handleItemTags(item.box, imageWidth, imageHeight);
              item.center = [newBox.x, newBox.y];
              item.position = newBox.position;
            }
            item.gender = t(localizeGenderName(item.genderCategory));
            return item;
          });
          setCurrentTab(model.positionId || "");
          setProductList([...arr]);
          setTitleList([...arr]);
          // init product productId list
          const newArr = arr.filter((item: NewProductDetailType) =>
            isActiveProduct(item)
          );
          if (newArr.length > 0) {
            const ids: Array<string> = newArr.map(
              (item: NewProductDetailType) => item.productId
            );
            const set = new Set(ids);
            setProductIds(Array.from(set).join(","));
          } else {
            setProductIds("");
          }
        } else {
          setTitleList([]);
          setProductList([]);
          setProductIds("");
        }
      } else {
        setCurrentTab("");
        setProductList([]);
        setTitleList([]);
        setProductIds("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentTab,
    imageHeight,
    imageWidth,
    type,
    stylingDetailData.detailInfo.models,
    stylingDetailData.loading
  ]);

  // get related styling list - skip "outdated" type products when requesting related styling
  useEffect(() => {
    if (productIds.length > 0) {
      if (type === StylingType.stylehint) {
        dispatch(
          RelatedStylingStylehintActions.getRelatedStylingStylehint({
            productIds: productIds.split(",").slice(0, 15).toString(),
            order: `${STYLING_SORT_BY.EC_LIKES_31DAYS_DESC},${STYLING_SORT_BY.PUBLISHED_AT_DESC}`,
            departmentIds: stylingGender,
            limit: 15
          })
        );
      } else if (type === StylingType.official) {
        dispatch(
          RelatedStylingOfficialActions.getRelatedStylingOfficial({
            limit: 15,
            productIds: productIds,
            order: STYLING_SORT_BY.DISPLAY_START_AT_DESC,
            departmentIds: stylingGender
          })
        );
      }
    } else {
      // stylehint related styling init
      dispatch(RelatedStylingStylehintActions.initRelatedStylingStylehint());
      // official related styling init
      dispatch(RelatedStylingOfficialActions.initRelatedStylingOfficial());
    }
  }, [dispatch, type, productIds, stylingGender]);

  // init related styling list
  useEffect(() => {
    let newArr: Array<RelatedListType> = [];
    if (type === StylingType.stylehint) {
      const arr = JSON.parse(JSON.stringify(relatedStylehintData.styles)) || [];
      if (arr.length > 0) {
        // Target Stying A should not display on A's own related styling grid
        const index = arr.findIndex(
          (a: RelatedStylingStylehintType) => a.id.toString() === stylingId
        );
        if (index !== -1) {
          arr.splice(index, 1);
        }
        newArr = arr.map((item: RelatedStylingStylehintType) => {
          const obj: RelatedListType = {
            styleId: item.id ? item.id.toString() : "",
            imageUrl: item.image ? item.image.toString() : "",
            userInfo: item.userInfo,
            modelHeight: item.modelHeight
          };
          return obj;
        });
      }
    } else {
      const arr = JSON.parse(JSON.stringify(relatedOfficialData.styles)) || [];
      if (arr.length > 0) {
        // Target Stying A should not display on A's own related styling grid
        const index = arr.findIndex(
          (a: SBNormalImages) => a.styleId.toString() === stylingId
        );
        if (index !== -1) {
          arr.splice(index, 1);
        }
        newArr = arr.map((item: SBNormalImages) => {
          const obj: RelatedListType = {
            styleId: item.styleId ? item.styleId.toString() : "",
            imageUrl: item.imageUrl ? item.imageUrl.toString() : ""
          };
          return obj;
        });
      }
    }
    if (newArr.length > 0) {
      const list = newArr.slice(0, 10);
      const ids = list.map((item: RelatedListType) => item.styleId);
      setRelatedList([...list]);
      setRelatedStylingIds(ids.join(","));
    } else {
      setRelatedList([]);
      setRelatedStylingIds("");
    }
  }, [
    type,
    relatedOfficialData.styles,
    relatedStylehintData.styles,
    stylingId
  ]);

  // get favorites styling list - official carousel image
  useEffect(() => {
    if (type === StylingType.official) {
      // get official carousel styling wish count
      dispatch(
        FavoritesStylingActions.getStylingWishCount({
          styleId: stylingId,
          type: "sb"
        })
      );
      dispatch(
        FavoritesStylingActions.getFavoritesStyling({
          ids: stylingId,
          type: "sb",
          fromDetail: true
        })
      );
    }
  }, [dispatch, type, stylingId]);

  // get favorites styling list - stylehint carousel image
  useEffect(() => {
    if (type === StylingType.stylehint) {
      dispatch(
        FavoritesStylingActions.getFavoritesStyling({
          ids: stylingId,
          type: "sh",
          fromDetail: true
        })
      );
    }
  }, [dispatch, type, stylingId, params.region]);

  // get favorites styling list - official related styling
  useEffect(() => {
    if (type === StylingType.official && relatedStylingIds.length > 0) {
      dispatch(
        FavoritesStylingActions.getFavoritesStyling({
          ids: relatedStylingIds,
          type: "sb",
          fromDetail: true
        })
      );
    }
  }, [dispatch, type, relatedStylingIds]);

  // get favorites styling list - stylehint related styling
  useEffect(() => {
    if (type === StylingType.stylehint && relatedStylingIds.length > 0) {
      dispatch(
        FavoritesStylingActions.getFavoritesStyling({
          ids: relatedStylingIds,
          type: "sh",
          fromDetail: true
        })
      );
    }
  }, [dispatch, type, relatedStylingIds, params.region]);

  // get carousel list data
  // when reload, request the normal api to get carousel list through the current styling product id
  useEffect(() => {
    let ids = "";
    if (stylingDetailData.fromPage === "" && productIds.length > 0) {
      ids = productIds;
    }
    if (ids.length > 0) {
      if (type === StylingType.official) {
        dispatch(
          RelatedStylingOfficialActions.getOfficialCarouselList({
            limit: 50,
            order: STYLING_SORT_BY.DISPLAY_START_AT_DESC,
            productIds: ids,
            departmentIds: stylingGender
          })
        );
      } else {
        dispatch(
          RelatedStylingStylehintActions.getStylehintCarouselList({
            limit: 50,
            order: `${STYLING_SORT_BY.EC_LIKES_31DAYS_DESC},${STYLING_SORT_BY.PUBLISHED_AT_DESC}`,
            productId: ids,
            departmentIds: stylingGender
          })
        );
      }
    }
  }, [dispatch, type, productIds, stylingDetailData.fromPage, stylingGender]);

  // get carousel list data
  // when click related styling, request the normal api to get carousel list through the previous styling product id
  useEffect(() => {
    let ids = "";
    if (
      isClickRelated &&
      stylingDetailData.fromPage === DetailFromPageEnum.related &&
      stylingDetailData.previousProductIds.length > 0
    ) {
      ids = stylingDetailData.previousProductIds;
      setIsClickRelated(false);
    }
    if (ids.length > 0) {
      if (type === StylingType.official) {
        dispatch(
          RelatedStylingOfficialActions.getOfficialCarouselList({
            limit: 50,
            order: STYLING_SORT_BY.DISPLAY_START_AT_DESC,
            productIds: ids,
            departmentIds: stylingGender
          })
        );
      } else {
        dispatch(
          RelatedStylingStylehintActions.getStylehintCarouselList({
            limit: 50,
            order: `${STYLING_SORT_BY.EC_LIKES_31DAYS_DESC},${STYLING_SORT_BY.PUBLISHED_AT_DESC}`,
            productId: ids,
            departmentIds: stylingGender
          })
        );
      }
    }
  }, [
    dispatch,
    isClickRelated,
    type,
    stylingDetailData.fromPage,
    stylingDetailData.previousProductIds,
    stylingGender
  ]);

  // handle tab change event - only official styling
  const handleChangeTab = (e: string) => {
    if (e !== currentTab) {
      setCurrentTab(e);
      dispatch(
        DetailStylingActions.setFromPageName(DetailFromPageEnum.changeTab)
      );
    }
  };

  // handle wish list selected or unselected
  const handleWishList = (
    imageType: ImageType,
    styleId: string,
    product?: NewProductDetailType
  ) => {
    if (!getIsLoggedIn() && !isGUNativeApp()) {
      dispatch(ConfigActions.setShowLogin(true));
    } else if (
      imageType === ImageType.styles ||
      imageType === ImageType.carousel
    ) {
      const _type = type === StylingType.official ? "sb" : "sh";
      const _list =
        type === StylingType.official
          ? favoriteSBStylingList
          : favoriteSHStylingList;
      postOrDeleteFavoritesStyling(
        dispatch,
        _list,
        styleId,
        _type,
        imageType === ImageType.carousel
      );
    } else {
      setIsPopupShown(true);
      setCurrentItemInfo({
        productInfo: product as NewProductDetailType,
        styleId: styleId
      });
    }
  };

  // current styling click favorite icon
  const handleClickWishIcon = useDebounce(
    (imageType: ImageType, styleId: string, product?: NewProductDetailType) => {
      handleWishList(imageType, styleId, product);
      // ga
      if (imageType === ImageType.carousel) {
        sendTrackEvent({
          brand: transformBrandForGoogleAnalytics(config.brand_name),
          event: TRACK_EVENT,
          eventAction:
            type === StylingType.official
              ? "officialstyling_img_detail"
              : "stylehint_img_detail",
          eventCategory: "sb_wishlist_style",
          eventLabel: styleId
        });
      } else if (imageType === ImageType.styles) {
        sendTrackEvent({
          brand: transformBrandForGoogleAnalytics(config.brand_name),
          event: TRACK_EVENT,
          eventAction:
            type === StylingType.official
              ? "officialstyling_recommend"
              : "stylehint_recommend",
          eventCategory: "sb_wishlist_style",
          eventLabel: styleId
        });
      }
    },
    200
  );

  // carousel change
  const handleIndexChange = (i: number) => {
    dispatch(DetailStylingActions.setFromPageName(DetailFromPageEnum.changeId));
    dispatch(DetailStylingActions.getDetailStylingLoading(true));
    initDetailData();
    const data = carouselList[i].styleId;
    const url = generatePath(`/:region/:language/stylingbook/:type/${data}`, {
      ...params
    } as RouterUrlType);
    navigate(url, { replace: true });
  };

  // go to PDP page
  const handlePDPUrl = (p: NewProductDetailType): string => {
    const url = generatePath(
      `/:region/:language/products/${p.productId}/${p.priceGroup}?colorDisplayCode=${p.color.displayCode}`,
      {
        ...params
      } as RouterUrlType
    );
    return url;
  };
  const handleClickPDPLink = (p: NewProductDetailType) => {
    if (isActiveProduct(p)) {
      // ga
      sendTrackEvent({
        brand: transformBrandForGoogleAnalytics(config.brand_name),
        event: TRACK_EVENT,
        eventAction: "Hanger_item",
        eventCategory: "sb_StyleHint_click",
        eventLabel: `${p.productId}_${stylingId}`
      });

      const url = handlePDPUrl(p);
      window.location.href = url;
    }
  };

  // user page url
  const userPageUrl = (user_id: number | undefined): string | undefined => {
    if (!user_id) {
      return undefined;
    } else {
      const url = generatePath(
        `/:region/:language/stylingbook/:type/user/${user_id}`,
        {
          ...params
        } as RouterUrlType
      );
      return url;
    }
  };

  // click go to detail page
  const goToDetailPageUrl = (styleId: string): string => {
    const url = generatePath(
      `/:region/:language/stylingbook/:type/${styleId}`,
      {
        ...params
      } as RouterUrlType
    );
    return url;
  };

  // init detail styling data
  const initDetailData = () => {
    // stylehint related styling init
    dispatch(RelatedStylingStylehintActions.initRelatedStylingStylehint());
    // official related styling init
    dispatch(RelatedStylingOfficialActions.initRelatedStylingOfficial());
    // detail data init
    dispatch(DetailStylingActions.initDetailStyling());
    setRelatedList([]);
    setProductList([]);
    setTitleList(null);
    setProductIds("");
    setCurrentTab("");
  };

  // click related styling
  const clickRelatedStyling = (event: any, index: number, id: string) => {
    // ga
    const num = index + 1;
    const str = num < 10 ? `0${num}` : num;
    sendTrackEvent({
      brand: transformBrandForGoogleAnalytics(config.brand_name),
      event: TRACK_EVENT,
      eventAction: "recommend",
      eventCategory:
        type === StylingType.official
          ? "sb_OfficialStyling_click"
          : "sb_StyleHint_click",
      eventLabel: `${str}_${id}`
    });
    // To resolve the hotkey click event
    if (event.ctrlKey || event.metaKey || event.shiftKey || event.altKey) {
      return;
    }
    setItemToggleActive(false);
    initDetailData();
    setIsClickRelated(true);
    dispatch(DetailStylingActions.getDetailStylingLoading(true));
    dispatch(DetailStylingActions.setFromPageName(DetailFromPageEnum.related));
    dispatch(DetailStylingActions.setPreviousDetailStyling(productIds));
    dispatch(DetailStylingActions.initCarouselList());
    setCarouselList([]);
  };

  // handle click hashtag -> go to top page
  const handleClickHashtag = (tag: string) => {
    // ga
    sendTrackEvent({
      brand: transformBrandForGoogleAnalytics(config.brand_name),
      event: TRACK_EVENT,
      eventAction: "hashtag",
      eventCategory: "sb_StyleHint_click",
      eventLabel: tag
    });
    // init top data - in order to fix goback logic
    initTopPageAllData(dispatch);

    const tab = config.firebaseData.sh_tab_gender;
    type T = keyof GenderObjType;
    const data = stylingGender as T;
    let url;
    if (tab[data]) {
      url = generatePath(
        `/:region/:language/stylingbook/:type/${stylingGender}?hashtag=${tag}`,
        {
          ...params
        } as RouterUrlType
      );
    } else {
      url = generatePath("/:region/:language/stylingbook/:type/women", {
        ...params
      } as RouterUrlType);
    }
    navigate(url);
  };

  // click breadcrumbs to do ga
  const handleTop = (label: string) => {
    let str = "";
    if (label.length > 0) {
      str = label;
    } else {
      switch (config.brand_name) {
        case BRAND_LIST.UQ:
          str = BRAND_LIST.UQ.toUpperCase();
          break;
        case BRAND_LIST.GU:
          str = BRAND_LIST.GU.toUpperCase();
          break;
        case BRAND_LIST.PL:
          str = BRAND_LIST.PL.toUpperCase();
          break;
        default:
          str = BRAND_LIST.UQ.toUpperCase();
          break;
      }
      str = `${str}TOP`;
    }
    // ga
    sendTrackEvent({
      brand: transformBrandForGoogleAnalytics(config.brand_name),
      event: TRACK_EVENT,
      eventAction: "breadcrumbs",
      eventCategory:
        type === StylingType.official
          ? "sb_OfficialStyling_click"
          : "sb_StyleHint_click",
      eventLabel: str
    });
  };

  // click product items to do ga
  const handlePDP = (gender: string, label: string) => {
    // ga
    sendTrackEvent({
      brand: transformBrandForGoogleAnalytics(config.brand_name),
      event: TRACK_EVENT,
      eventAction:
        type === StylingType.official
          ? `item_${gender !== "" ? gender : "WOMEN"}`
          : "item_StyleHint",
      eventCategory:
        type === StylingType.official
          ? "sb_OfficialStyling_click"
          : "sb_StyleHint_click",
      eventLabel: label
    });
  };

  // handle wish whether toggled
  const handleWishIsToggled = (id: string): boolean => {
    if (type === StylingType.official) {
      return favoriteSBStylingList.includes(id);
    } else {
      return favoriteSHStylingList.includes(id);
    }
  };

  // handle wish count display -> over 999 display 999+
  const handleWishCountDisplay = (count: number): string => {
    if (count > 999) {
      return "999+";
    } else {
      return count.toString();
    }
  };

  // display wish count
  const wishCountDisplay = (count: number): string | undefined => {
    // official styling fetch count api
    if (
      type === StylingType.official &&
      !stylingDetailData.likesCountLoading &&
      isShowWishCount(type) &&
      count > 0
    ) {
      return handleWishCountDisplay(count);
    }
    // stylehint styling fetch detail api -> last31DaysLikes
    else if (isShowWishCount(type) && count > 0) {
      return handleWishCountDisplay(count);
    } else {
      return undefined;
    }
  };

  // breadcrumb area
  const navigationPath = (
    <BreadcrumbGroup className="fr-ec-mb-spacing-05">
      <Breadcrumb
        element="a"
        href={config.basePath}
        onClick={() => handleTop("")}
      >
        {t("text_breadcrumbs_top")}
      </Breadcrumb>
      <Link
        to={breadcrumbStylingbookUrl}
        onClick={() => {
          initTopPageAllData(dispatch);
          return handleTop(
            type === StylingType.official ? "LOOKBOOKTOP" : "STYLEHINTTOP"
          );
        }}
      >
        <Breadcrumb element="button">
          {type === StylingType.official
            ? `${t("text_styling_book_header_title")} ${t(
                "text_styling_book_official_styling"
              )}`
            : `${t("text_styling_book_header_title")} ${t(
                "text_styling_book_stylehint"
              )}`}
        </Breadcrumb>
      </Link>
      <Breadcrumb element="a">{`Style No.${stylingId}`}</Breadcrumb>
    </BreadcrumbGroup>
  );

  // page title area
  const pageHeading = (
    <>
      {stylingDetailData.errorCode === "" ? (
        <HeaderPage
          className="fr-ec-mb-spacing-05"
          title={
            type === StylingType.official
              ? t("text_styling_book_official_styling_page_title")
              : t("text_styling_book_stylehint_page_title")
          }
        />
      ) : (
        <HeaderPage
          className="fr-ec-mb-spacing-05"
          title={t("text_styling_book_header_title")}
        />
      )}
    </>
  );

  const showCarouselImage = (item: CarouselListType) => (
    <ImageEnhancements
      className={`carousel_${item.styleId}`}
      absolutePositionContent={
        type === StylingType.stylehint &&
        isMobile &&
        item.productTagsList.length > 0 ? (
          <ItemTags
            children={item.productTagsList.map((p) => (
              <ItemTag
                key={p.productId}
                popupOnClick={() => handleClickPDPLink(p)}
                isActive
                labelTitle={p.productName}
                labelSubTitle={`${t("text_size")}: ${
                  p.gender
                } ${getSizeForItemInfo(p.size)}`}
                toggleArrow={!!isActiveProduct(p)}
                popupPosition={p.position}
                positionCoordinates={p.center}
              />
            ))}
            variant="stylingBook"
            isItemToggleActive={itemToggleActive}
            onToggle={() => {
              setItemToggleActive(!itemToggleActive);
              // ga
              sendTrackEvent({
                brand: transformBrandForGoogleAnalytics(config.brand_name),
                event: TRACK_EVENT,
                eventAction: "Hangar_icon",
                eventCategory: "sb_StyleHint_click",
                eventLabel: `${stylingDetailData.detailInfo.userInfo?.name}_${stylingId}`
              });
            }}
          />
        ) : undefined
      }
      padding={false}
      key={item.styleId}
      topRightContent={
        isShowWishIcon(type) ? (
          <ButtonToggle
            icon="heart"
            count={wishCountDisplay(item.wishCount)}
            variant="icon"
            isToggled={handleWishIsToggled(item.styleId.toString())}
            onClick={(e: any) => {
              preventBubbling(e);
              if (!favoritesStylingLoading) {
                handleClickWishIcon(ImageType.carousel, item.styleId);
              }
            }}
          />
        ) : undefined
      }
    >
      <Image
        ratio="3x4"
        src={`${item.styleImageUrl}_r-1000-1333`}
        alt={
          type === StylingType.official
            ? t("text_styling_book_official_detail_id_alt_tag", {
                officialProducts: productList
                  .map((p) => p.productName)
                  .slice(0, 2)
                  .join("、")
              })
            : t("text_styling_book_stylehint_detail_id_alt_tag", {
                stylehintProducts: productList
                  .map((p) => p.productName)
                  .slice(0, 2)
                  .join("、")
              })
        }
        onClick={() => {
          setItemToggleActive(!itemToggleActive);
          // ga
          sendTrackEvent({
            brand: transformBrandForGoogleAnalytics(config.brand_name),
            event: TRACK_EVENT,
            eventAction: "Hangar_icon",
            eventCategory: "sb_StyleHint_click",
            eventLabel: `${stylingDetailData.detailInfo.userInfo?.name}_${stylingId}`
          });
        }}
      />
    </ImageEnhancements>
  );

  // show carousel list
  const showCarouselList = () => (
    <Carousel
      carouselAriaLabel="Styling Detail Carousel"
      indicator="none"
      onIndexChange={(index: number) => handleIndexChange(index)}
      index={stylingDetailData.carouselIndex}
      className={
        type === StylingType.stylehint
          ? "fr-ec-mb-spacing-05"
          : "fr-ec-mb-spacing-06"
      }
    >
      {carouselList.map((item: CarouselListType) => showCarouselImage(item))}
    </Carousel>
  );

  // username and store name click ga
  const gaCallback = (
    element: HTMLElement,
    clickedItem?: string,
    gaAction?: string
  ) => {
    sendTrackEvent({
      brand: transformBrandForGoogleAnalytics(config.brand_name),
      event: TRACK_EVENT,
      eventAction: gaAction || "",
      eventCategory: "sb_StyleHint_click",
      eventLabel: clickedItem || ""
    });
  };

  // carousel area
  const mediaContent = (
    <>
      {carouselList.length > 0 && showCarouselList()}
      {type === StylingType.stylehint &&
        !stylingDetailData.loading &&
        stylingDetailData.detailInfo.userInfo && (
          <GutterContainer>
            {stylingDetailData.detailInfo.userInfo.isFromInstagram ? (
              <StyleUserInfo
                className="fr-ec-mb-spacing-06"
                variant="small"
                isBadge={false}
                approvedDate={`${formatDate(
                  stylingDetailData.detailInfo.userInfo.validatedDate
                )} ${t("text_style_styling_date_update")}`.concat(
                  stylingDetailData.detailInfo?.postTag === "Promotion"
                    ? ` · ${t("text_styling_promotion_hashtag")}`
                    : ""
                )}
                // approvedDateText={t("text_style_styling_date_update")}
                userName={stylingDetailData.detailInfo.userInfo.name}
                personalPageLink={userPageUrl(
                  stylingDetailData.detailInfo.userInfo.id
                )}
              />
            ) : (
              <StyleUserInfo
                className="fr-ec-mb-spacing-06"
                variant="small"
                isBadge={isStaff(
                  stylingDetailData.detailInfo.userInfo.userType
                )}
                approvedDate={`${formatDate(
                  stylingDetailData.detailInfo.userInfo.validatedDate
                )} ${t("text_style_styling_date_update")}`.concat(
                  stylingDetailData.detailInfo?.postTag === "Promotion"
                    ? ` · ${t("text_styling_promotion_hashtag")}`
                    : ""
                )}
                // approvedDateText={t("text_style_styling_date_update")}
                userName={stylingDetailData.detailInfo.userInfo.name}
                modelHeight={userHeight(
                  stylingDetailData.detailInfo.modelHeight || 0
                )}
                heightUnit={t("text_style_model_height")}
                profileImage={
                  <Image
                    alt={t("text_styling_book_stylehint_user_id_alt_tag", {
                      userName: `${stylingDetailData.detailInfo.userInfo.name}${
                        isStaff(
                          stylingDetailData.detailInfo.userInfo.userType
                        ) && stylingDetailData.detailInfo.userInfo.storeName
                          ? `、${stylingDetailData.detailInfo.userInfo.storeName}`
                          : ""
                      }`
                    })}
                    noImage={{ imageSize: "smallest", imageType: "user" }}
                    ratio="1x1"
                    src={stylingDetailData.detailInfo.userInfo.image || ""}
                  />
                }
                storeInfo={
                  isStaff(stylingDetailData.detailInfo.userInfo.userType)
                    ? stylingDetailData.detailInfo.userInfo.storeName
                    : undefined
                }
                userReview={stylingDetailData.detailInfo.userInfo.comment}
                storeLink={handleStoreLink(
                  stylingDetailData.detailInfo.userInfo.storeId,
                  stylingDetailData.detailInfo.userInfo.region
                )}
                personalPageLink={userPageUrl(
                  stylingDetailData.detailInfo.userInfo.id
                )}
                storeNameGaLabel={
                  stylingDetailData.detailInfo.userInfo.storeId?.toString() ||
                  ""
                }
                storeNameGaAction="shop"
                userNameGaLabel={
                  stylingDetailData.detailInfo.userInfo.name || ""
                }
                userNameGaAction="privatepage"
                gaCallback={gaCallback}
              />
            )}
          </GutterContainer>
        )}
    </>
  );

  const handlePrice = (product: NewProductDetailType): number | null => {
    if (isNotEmptyObj(product.price)) {
      return !product.price.promo
        ? product.price.base.value
        : product.price.promo.value;
    } else {
      return null;
    }
  };

  const handleDualPrice = (product: NewProductDetailType): number | null => {
    if (
      isNotEmptyObj(product.price) &&
      SB_ENV(config.brand_name, config.region).showDualPrice
    ) {
      return product.price.isDualPrice && product.price.base
        ? product.price.base.value
        : null;
    } else {
      return null;
    }
  };

  const handleDiscount = (product: NewProductDetailType): string => {
    if (
      isNotEmptyObj(product.price) &&
      SB_ENV(config.brand_name, config.region).showDualPrice
    ) {
      return product.price.isDualPrice && product.price.discountPercentage
        ? product.price.discountPercentage.toString()
        : "";
    } else {
      return "";
    }
  };

  // product info component
  const productInfoComp = (product: NewProductDetailType) => {
    const flags = product.flags;
    const newFlags = handleFlagsSort(
      flags.priceFlags,
      flags.productFlags,
      t,
      product.promotionText
    );
    const productFlags = newFlags.product_flag;
    const priceFlags = newFlags.price_flag;
    const _price = handlePrice(product);
    const _dualPrice = handleDualPrice(product);
    const _discount = handleDiscount(product);
    return (
      <>
        <FlagText element="p" variant="large" className="fr-ec-mb-spacing-01">
          {product.gender}
        </FlagText>
        <Title element="p" variant="large" className="fr-ec-mb-spacing-02">
          {product.productName}
        </Title>
        <Body element="p" variant="small" className="fr-ec-mb-spacing-01">
          {`${t("text_color")}: ${
            isNotEmptyObj(product.color) ? product.color.displayCode : "-"
          } ${isNotEmptyObj(product.color) ? product.color.name : "-"}`}
        </Body>
        {type === StylingType.stylehint && isDisplaySizeLabel(product.size) && (
          <Body element="p" variant="small" className="fr-ec-mb-spacing-01">
            {`${t("text_size")}: ${product.size}`}
          </Body>
        )}
        {isNotEmptyObj(flags) && flags.productFlags.length > 0 && (
          <FlagText
            element="p"
            variant="large"
            className="fr-ec-mb-spacing-01"
            color={productFlags?.color}
          >
            {productFlags?.name}
          </FlagText>
        )}
        {_price !== null && (
          <Price
            element="p"
            size="small"
            variant={newFlags.price_color}
            discount={formatDiscount(_discount)}
            originalPrice={
              _dualPrice !== null
                ? t("currency_format", {
                    price: formatPrice(_dualPrice)
                  })
                : ""
            }
          >
            {t("currency_format", {
              price: formatPrice(_price)
            })}
          </Price>
        )}
        {isNotEmptyObj(flags) &&
          flags.priceFlags.length > 0 &&
          priceFlags?.map((p: { name: string; color: flagColorType }) => (
            <FlagText element="p" variant="large" color={p.color}>
              {p.name}
            </FlagText>
          ))}
      </>
    );
  };
  // discontinued product info component
  const productDiscontinuedComp = (product: NewProductDetailType) => (
    <>
      <FlagText element="p" variant="large" className="fr-ec-mb-spacing-01">
        {product.gender}
      </FlagText>
      <Title element="p" variant="large" className="fr-ec-mb-spacing-02">
        {product.productName}
      </Title>
    </>
  );

  const productImagePhase3 = (product: NewProductDetailType) => (
    <ProductTile
      name={""}
      variant="horizontal-small"
      ecRenewal
      media={
        <Image
          src={product.pdpImageUrl}
          alt={t("text_styling_book_product_id_alt_tag", {
            productName: product.productName,
            gender: `(${product.gender})`
          })}
          ratio={getEcRenewalImageRatio()}
          noImage={{ imageSize: "small", imageType: "product" }}
        />
      }
      buttonToggle={
        (isGUNativeApp() && getUA.indexOf(FR_UserAgent) <= -1) ||
        !config.isApp ? (
          <ButtonToggle
            icon="heart"
            variant="icon"
            isToggled={false}
            onClick={(e: any) => {
              preventBubbling(e);
              if (!isPopupShown) {
                handleClickWishIcon(ImageType.items, product.l2Id, product);
              }
            }}
          />
        ) : undefined
      }
      informationContent={productInfoComp(product)}
    />
  );

  const productImage = (product: NewProductDetailType) => (
    <MediaObject
      key={`${product.l2Id}_${product.productId}`}
      mediaPosition="left"
      variation="large"
      media={
        <ImageEnhancements
          padding={false}
          topRightContent={
            (isGUNativeApp() && getUA.indexOf(FR_UserAgent) <= -1) ||
            !config.isApp ? (
              <ButtonToggle
                icon="heart"
                variant="icon"
                isToggled={false}
                onClick={(e: any) => {
                  preventBubbling(e);
                  if (!isPopupShown) {
                    handleClickWishIcon(ImageType.items, product.l2Id, product);
                  }
                }}
              />
            ) : undefined
          }
        >
          <Image
            alt={t("text_styling_book_product_id_alt_tag", {
              productName: product.productName,
              gender: `(${product.gender})`
            })}
            ratio={getEcRenewalImageRatio()}
            src={product.pdpImageUrl}
          />
        </ImageEnhancements>
      }
    >
      {productInfoComp(product)}
    </MediaObject>
  );

  // product card area
  const productCard = (product: NewProductDetailType) => {
    if (product.active !== undefined && !product.active) {
      return (
        <MediaObject
          key={`${product.l2Id}_${product.productId}`}
          mediaPosition="left"
          variation="large"
          media={
            <Image
              noImage={{
                imageSize: "large",
                imageType: "labelonly",
                noImageLabel: t("text_product_unavailable")
              }}
              alt={t("text_styling_book_product_id_alt_tag", {
                productName: product.productName,
                gender: `(${product.gender})`
              })}
              src=""
              ratio={getEcRenewalImageRatio()}
            />
          }
        >
          {productDiscontinuedComp(product)}
        </MediaObject>
      );
    } else {
      return (
        <Tile
          key={`${product.l2Id}_${product.productId}`}
          href={handlePDPUrl(product)}
          onClick={() => handlePDP(product.gender, product.l2Id)}
        >
          {getEcRenewalCompatibility()
            ? productImagePhase3(product)
            : productImage(product)}
        </Tile>
      );
    }
  };

  // product list content
  const productListContent = (
    <>
      <CollectionList
        keylines="between-items"
        spacing="16"
        className="fr-ec-mb-spacing-02"
      >
        {productList.map((product: NewProductDetailType) =>
          productCard(product)
        )}
      </CollectionList>
      <Keyline className="fr-ec-mb-spacing-04" />
      <Caption element="p" color="secondary">
        {t("text_stylehint_non_listed_items_message_01")}
      </Caption>
    </>
  );

  // product area (position tab)
  const productArea = () => {
    if (
      type === StylingType.official &&
      stylingDetailData.detailInfo.models.length > 1 &&
      hasProductForMutiModel(stylingDetailData.detailInfo.models)
    ) {
      return (
        <TabGroup
          genderTypography={false}
          variant="small-swipeable"
          className="fr-ec-mb-spacing-02 fr-ec-mt-spacing-04"
        >
          {stylingDetailData.detailInfo.models.map((item) => (
            <Tab
              key={item.positionId}
              id={item.positionId}
              isActive={item.positionId === currentTab}
              label={item.positionName || ""}
              onClick={() => handleChangeTab(item.positionId || "")}
            >
              {productList.length > 0 ? productListContent : <></>}
            </Tab>
          ))}
        </TabGroup>
      );
    } else if (productList.length > 0) {
      return productListContent;
    }
    return null;
  };

  // main content - product list
  const mainContent = (
    <GutterContainer
      className={
        getEcRenewalCompatibility() && isMobile
          ? "fr-ec-mb-spacing-06"
          : "fr-ec-mb-spacing-07"
      }
    >
      <Display variant="display4" element="h2" className="fr-ec-mb-spacing-02">
        {t("text_wearing_items")}
      </Display>
      {stylingDetailData.loading ? (
        <LoadingIndicator variant="normal" />
      ) : (
        productArea()
      )}
    </GutterContainer>
  );

  // handle full page loading
  const handleFullLoading = () =>
    (stylingDetailData.fromPage === DetailFromPageEnum.related ||
      stylingDetailData.fromPage === "") &&
    stylingDetailData.loading;

  // related styling area
  const subContent = () => {
    isShowError.current = false;
    return (
      <>
        {handleFullLoading() && <LoadingIndicator variant="normal" />}
        {type === StylingType.stylehint &&
        !stylingDetailData.loading &&
        stylingDetailData.detailInfo.hashtags &&
        stylingDetailData.detailInfo.hashtags.length > 0 ? (
          <GutterContainer
            className={
              getEcRenewalCompatibility() && isMobile
                ? "fr-ec-mb-spacing-06"
                : "fr-ec-mb-spacing-07"
            }
          >
            <Display
              element="h2"
              variant="display4"
              className="fr-ec-mb-spacing-04"
            >
              {t("text_styling_book_hashtag_title")}
            </Display>
            <TagFilterGroup>
              {stylingDetailData.detailInfo.hashtags.map((item: string) => (
                <TagFilter
                  key={item}
                  disabled={false}
                  value={`#${item}`}
                  dismissable={false}
                  onClick={() => handleClickHashtag(item)}
                />
              ))}
            </TagFilterGroup>
          </GutterContainer>
        ) : undefined}
        {!stylingDetailData.loading && relatedList.length > 0 && (
          <>
            <GutterContainer className="fr-ec-mb-spacing-04">
              <Display element="h2" variant="display4">
                {t("text_styling_book_related_styling_title")}
              </Display>
            </GutterContainer>
            <StylingGrid
              gridItems={relatedList.map(
                (item: RelatedListType, index: number) => (
                  <>
                    <Link
                      to={goToDetailPageUrl(item.styleId)}
                      onClick={(event) =>
                        clickRelatedStyling(event, index, item.styleId)
                      }
                      key={item.styleId}
                    >
                      <Tile element="button">
                        <ImageEnhancements
                          padding={false}
                          topRightContent={
                            isShowWishIcon(type) ? (
                              <ButtonToggle
                                icon="heart"
                                variant="icon"
                                isToggled={handleWishIsToggled(
                                  item.styleId.toString()
                                )}
                                onClick={(e: any) => {
                                  preventBubbling(e);
                                  if (!favoritesStylingLoading) {
                                    handleClickWishIcon(
                                      ImageType.styles,
                                      item.styleId.toString()
                                    );
                                  }
                                }}
                              />
                            ) : undefined
                          }
                        >
                          <Image
                            alt={t(
                              type === StylingType.official
                                ? "text_styling_book_official_styling_id_alt_tag"
                                : "text_styling_book_stylehint_styling_id_alt_tag",
                              {
                                brand: t("text_styling_book_meta_title_brand"),
                                styleId: item.styleId
                              }
                            )}
                            ratio="3x4"
                            src={`${item.imageUrl}_r-600-800` || ""}
                            loading="lazy"
                          />
                        </ImageEnhancements>
                      </Tile>
                    </Link>
                    {item.userInfo && (
                      <>
                        <Spacer element="div" variant="spacing-02" />
                        {item.userInfo.isFromInstagram ? (
                          <StyleUserInfo
                            variant="default"
                            isBadge={false}
                            userName={item.userInfo.name}
                          />
                        ) : (
                          <StyleUserInfo
                            variant="default"
                            isBadge={isStaff(item.userInfo.userType)}
                            heightUnit={t("text_style_model_height")}
                            modelHeight={userHeight(item.modelHeight || 0)}
                            profileImage={
                              <Image
                                alt={t(
                                  "text_styling_book_stylehint_user_id_alt_tag",
                                  {
                                    userName: `${item.userInfo.name}${
                                      isStaff(item.userInfo.userType) &&
                                      item.userInfo.storeName
                                        ? `、${item.userInfo.storeName}`
                                        : ""
                                    }`
                                  }
                                )}
                                noImage={{
                                  imageSize: "smallest",
                                  imageType: "user"
                                }}
                                ratio="1x1"
                                src={item.userInfo.image || ""}
                                loading="lazy"
                              />
                            }
                            storeInfo={
                              isStaff(item.userInfo.userType)
                                ? item.userInfo.storeName
                                : undefined
                            }
                            storeLink={handleStoreLink(
                              item.userInfo.storeId,
                              item.userInfo.region
                            )}
                            userName={item.userInfo.name}
                            personalPageLink={userPageUrl(item.userInfo.id)}
                          />
                        )}
                      </>
                    )}
                  </>
                )
              )}
              className="fr-ec-mb-spacing-07"
              itemHasUserInfo={type === StylingType.stylehint}
            />
          </>
        )}
        {isMobile && !stylingDetailData.loading && (
          <GutterContainer className="fr-ec-mb-spacing-07">
            <StyleHintBanner type="detail" />
          </GutterContainer>
        )}
      </>
    );
  };

  // render error
  const renderError = () => {
    isShowError.current = true;
    if (stylingDetailData.errorCode === "404") {
      metaTitle.current = t("text_404");
      metaDescription.current = t("text_404");
      return (
        <>
          <NotFound
            text={
              type === StylingType.official
                ? t("text_official_styling_top_page_link")
                : t("text_stylehint_top_page_link")
            }
            type={
              type === StylingType.official
                ? StylingType.official
                : StylingType.stylehint
            }
          />
          <Spacer element="div" variant="spacing-08" />
        </>
      );
    } else {
      metaTitle.current = t("text_error_title");
      metaDescription.current = t("text_503_error_description");
      return (
        <>
          <SystemError />
          <Spacer element="div" variant="spacing-08" />
        </>
      );
    }
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{metaTitle.current}</title>
        <meta name="description" content={metaDescription.current} />
        <meta
          name="robots"
          content={isShowError.current ? "noindex,nofollow" : "index,follow"}
        />
        {!isShowError.current && (
          <>
            {hrefLangList.map((item) => (
              <link
                key={item.hrefLang}
                rel="alternate"
                hrefLang={item.hrefLang}
                href={`${window.location.origin}/${item.alternateHref}`}
              />
            ))}
            <link
              rel="canonical"
              href={`${window.location.origin}${location.pathname}`}
            />
            <script type="application/ld+json" id="breadcrumb">
              {breadcrumbJson}
            </script>
          </>
        )}
      </Helmet>
      <TemplateMediaInformation
        mainContent={
          handleFullLoading() || stylingDetailData.errorCode !== "" ? (
            <></>
          ) : (
            mainContent
          )
        }
        mediaContent={
          handleFullLoading() || stylingDetailData.errorCode !== "" ? (
            <></>
          ) : (
            mediaContent
          )
        }
        navigationPath={
          !config.isApp && !getEcRenewalCompatibility()
            ? navigationPath
            : undefined
        }
        pageHeading={pageHeading}
        subContent={
          stylingDetailData.errorCode !== "" ? renderError() : subContent()
        }
        toggleStickyInteraction
        toggleMinHeight={stylingDetailData.errorCode !== ""}
      />
      {!config.isApp && getEcRenewalCompatibility() && (
        <LayoutWrapper element="section" className="fr-ec-breadcrump-wrapper">
          <Layout gutter={{ sm: false, md: false, lg: true }}>
            {navigationPath}
          </Layout>
        </LayoutWrapper>
      )}
      <GoTop />
      {config.showLogin && <LoginModal />}
      {config.showWishIconError && <ErrorModal />}
      {isPopupShown && (
        <FavoritesPopup
          closePopup={() => setIsPopupShown(false)}
          stylingId={stylingId}
          productInfo={currentItemInfo.productInfo}
          userInfo={
            type === StylingType.stylehint
              ? stylingDetailData.detailInfo.userInfo
              : undefined
          }
          modelHeight={stylingDetailData.detailInfo?.modelHeight || 0}
          modelWearingColor={
            getEcRenewalCompatibility()
              ? currentItemInfo.productInfo.color.code
              : null
          }
        />
      )}
    </>
  );
};

export default DetailStyling;
